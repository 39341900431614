import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';
import { Link } from '@material-ui/core';

interface ResourceState {
  show: boolean;
}
class DeleteConfirmationModal extends React.Component<
  { isShow; onModalYesClick(); onModalNoClick() },
  ResourceState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      show: this.props.isShow
    };
  }
  componentDidMount() {
    console.log(this.state.show);
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleYesClick = () => {
    this.props.onModalYesClick();
  };
  handleNoClick = () => {
    this.props.onModalNoClick();
  };
  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={() => this.handleClose()}
        backdrop='static'
        className='dash-confirm-modal'
      >
        <Modal.Body>
          <Row>
            <Col>
              <p className='dash-confirm-modal-body'>
                Are you sure you want to delete this Task Template?
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='float-right'>
                <Link
                  className='margin-separator dash-confirm-modal-actions dash-confirm-modal-actions_No mr-3'
                  onClick={() => {
                    this.handleNoClick();
                    this.handleClose();
                  }}
                >
                  No
                </Link>

                <Link
                  className='dash-confirm-modal-actions dash-confirm-modal-actions_Yes'
                  onClick={this.handleYesClick}
                >
                  Yes
                </Link>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DeleteConfirmationModal;
