import { get, post } from 'config/api';

const Matter_API = 'Matter';

export const Matters = {
  index: () => get(`${Matter_API}/GetMatters`),
  single: (id: number) => get(`${Matter_API}/GetMatter?MatterId=${id}`),
  create: (matter: object) => post(`${Matter_API}/SaveMatter`, matter),
  lookups: {
    stages: () => get(`${Matter_API}/GetAllMatterStages`),
    statuses: () => get(`${Matter_API}/GetAllMatterStatus`),
    types: () => get(`${Matter_API}/GetMatterTypes`),
  },
};
