/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:8effaa19-3e78-4460-b2c9-c50329391367",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_pIeCMOqtc",
    "aws_user_pools_web_client_id": "72egr1e57idpj757qv8lg2q9dr",
    "oauth": {},
    "aws_user_files_s3_bucket": "users-profile122920-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "dashAPI",
            "endpoint": "https://szpa2kktch.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
