import { get, post } from 'config/api';

export const Templates = {
  matterTemplates: () => get(`Matter/GetTemplateMatters`),
  taskTemplates: () => get(`Task/GetTasksTemplates`),
  singleMatterTemplate: (id: number) =>
    get(`Matter/GetTemplateMatter?TemplateMatterId=${id}`),
  singleTaskTemplate: (id: number) =>
    get(`Task/GetTaskTemplate?TaskTemplateId=${id}`),
  createMatterTemplate: (template: object) =>
    post(`Matter/SaveTemplateMatter`, template),
  createTaskTemplate: (template: object) =>
    post(`Task/SaveTaskTemplate`, template),
  setInactiveTaskTemplate: (inactiveObj: object) =>
    post(`Task/setIsActiveTaskTemplate`, inactiveObj),
};
