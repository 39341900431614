import React, { useState } from 'react';
import TasksBoardStatus from './tasksBoardStatus';
import TasksBoardDueDate from './taskBoardDueDate';
import { Button, ButtonGroup, Tabs, Tab, Row, Col } from 'react-bootstrap';
export default function TasksBoard(props) {
  const [key, setKey] = useState('DueDate');
  return (
    <div>
      <Row className='mb-4'>
      <Col md={12} className="d-flex justify-content-center">
            <div className="float-left position-absolute dash-left-3 dash-top-3">
          <ButtonGroup>
            <Button
              className='btn btn-xs btn-info dash-back-button mb-0 '
               onClick={() => props.history.goBack()}
            >
              Back
            </Button>
          </ButtonGroup>
          </div>
          <div className='dash-page-title'>
            <h3 className='mb-0 text-center'>Tasks Board</h3>
          <div className='dash-line-icon'></div>
          </div>
        </Col>
      </Row>
      <Tabs
        id='board'
        className='dash-tabs'
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey='DueDate' title='DueDate' className='dash-tab pb-4'>
          <TasksBoardDueDate />
        </Tab>
        <Tab eventKey='Status' title='Status' className='dash-tab pb-4'>
          <TasksBoardStatus />
        </Tab>
      </Tabs>
    </div>
  );
}
