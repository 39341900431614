import { get, post, put } from 'config/api';

const Session_API = 'Session';

export const Sessions = {
  index: () => get(`${Session_API}/GetSessions`),
  zoomMeetingsList: () => get(`Zoom/ListMeetings`),
  scheduleZoomMeeting: (zoomData: object) =>
    post(`zoom/ScheduleMeeting`, zoomData),
  single: (id: number) => get(`${Session_API}/GetSession?SessionId=${id}`),
  create: (session: object) => post(`${Session_API}/SaveSession`, session),
  update: (user: object) => put(`${Session_API}/SaveUser`, user),
  delete: (id: number) => post(`${Session_API}/DeleteSession/${id}`)
};
