import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { Row, Container, Col, Button, Form, InputGroup } from 'react-bootstrap';

import { Auth } from 'aws-amplify';

import useForm from '../../shared/useForm';
import classNames from 'classnames';

const VerifyCode = (props: any) => {
  const isEmailVerified: any = props.location.state.email_verified;
  const isPhoneVerified: any = props.location.state.phone_verified;

  const history: any = useHistory();
  const [loading, setLoading] = useState(false);
  const stateSchema = {
    code: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    code: {
      required: true,
    },
  };

  const _handleClick = async () => {
    const currentUser = await Auth.currentAuthenticatedUser()
      .then((data) => {
        return data;
      })
      .catch(() => {
        return false;
      });

    if (currentUser && currentUser.attributes.phone_number_verified) {
      const result = await Auth.verifyCurrentUserAttributeSubmit('email', code);
      if (result === 'SUCCESS') {
        setLoading(true);
        await Auth.signOut({ global: true });
        const location = {
          pathname: '/login',
        };
        toast.success('Code verified!');
        setTimeout(function () {
          history.push(location);
        }, 1000); 
      }
    } else {
      try {
        setLoading(true);
        const username: any = props.location.state.username;
        await Auth.confirmSignUp(username, code);
        await Auth.signOut({ global: true });
        const location = {
          pathname: '/login',
        };
        setTimeout(function () {
          history.push(location);
        }, 1000); 
      } catch (err) {
        alert(err.message);
        toast.error('Something went wront, try again');
      }
    }
  };

  const { values, errors, dirty, handleOnChange, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    _handleClick
  );

  const { code } = values;

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      _handleClick();
    }
  };

  return (
    <div className='animated fadeInLeft h-100 totalSize'>
      <BrowserRouter>
        <Container fluid className='dash-entry-container'>
          <Row className={classNames('justify-content-center h-100 dash-register-container',!isEmailVerified && isPhoneVerified?'dash-verify-email-container':'dash-verify-phone-container')}>
            {loading && (
              <div className='dash-page-spinner-container'>
                <Col>
                  <Spinner
                    className='mr-1 dash-page-spinner'
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                  />
                </Col>
              </div>
            )}
            <Col md={{ span: 5, offset: 4 }} className='my-auto text-center'>
              {/* <img src={logo} className='dash-entry-logo mb-5 pb-4' alt='' /> */}
              <div className='dash-register-content'>
                <div className='card-body'>
                  {!isEmailVerified && isPhoneVerified && (
                    <h3 className='dash-register-title mb-3'>
                      Verify Your Email
                    </h3>
                  )}
                  {!isPhoneVerified && (
                    <h3 className='dash-register-title mb-3'>
                      Verify Your Phone
                    </h3>
                  )}
                  <Form name='form' noValidate>
                    <Row className='form-group'>
                      <label className='col-sm-5 dash-form-label '>
                        Enter Verification code
                      </label>
                      <Col className='col-sm-7 pr-0'>
                        <InputGroup>
                          <input
                            type='text'
                            className='form-control dash-form-control'
                            placeholder='Verification Code'
                            name='code'
                            onKeyDown={_handleKeyDown}
                            onChange={handleOnChange}
                            value={code}
                            required
                          />
                        </InputGroup>
                        {errors.code && dirty.code && (
                          <p className='error mb-0'>{errors.code}</p>
                        )}
                      </Col>
                    </Row>

                    <Button
                      variant='outline-light'
                      className='dash-primary-button mt-3 mx-auto'
                      onClick={_handleClick}
                      disabled={disable}
                    >
                      Verify
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    </div>
  );
};

export default VerifyCode;
