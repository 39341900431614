import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import CreateTaskAdmin from '../Admin/tasks/createTaskAdmin';
//import EditTaskAdmin from "../Admin/tasks/editTaskAdmin";
// import data from './taskBoard.data';
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';
import {
  Card,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import UserCard from '../profile/userCard';
import ChannelCounterComponent from 'shared/channelCounterComponent';
import { Tasks } from 'services/Api';

interface TaskState {
  openTasks: any[];
  inProgressTasks: any[];
  blockedTasks: any[];
  doneTasks: any[];
  openCount: number;
  inProgressCount: number;
  blockedCount: number;
  doneCount: number;
  taskData: {
    TaskId: null;
    Matter: { MatterId: '' };
    Resource: { UserId: '' };
    TaskStatus: { TaskStatusId: '' };
    Priority: { PriorityId: '' };
    DueDate: Date;
    TaskTitle: '';
    BillRate: '';
    Cost: '';
    EffortAct: '';
    EffortEst: '';
    BillingCode: '';
    Instructions: '';
    BillingNarrativeId: '1';
  };
  isAddShowing: Boolean;
  isAddInprogressShowing: Boolean;
  isAddBlockedShowing: Boolean;
  isAddDoneShowing: Boolean;
  isEditShowing: Boolean;
  isEditInProgressShowing: Boolean;
  isEditBlockedShowing: Boolean;
  isEditDoneShowing: Boolean;
  EditId: string;
  AddStatus: string;
  currentUserID: string;
}
class TasksBoardStatus extends React.Component<any, TaskState> {
  private status = {
    open: 1,
    inProgress: 2,
    blocked: 3,
    done: 4,
  };
  private editModalRef: any;
  private addModalRef: any;
  constructor(props: any) {
    super(props);
    this.editModalRef = React.createRef();
    this.addModalRef = React.createRef();
    this.state = {
      openTasks: [],
      inProgressTasks: [],
      blockedTasks: [],
      doneTasks: [],
      openCount: 0,
      inProgressCount: 0,
      blockedCount: 0,
      doneCount: 0,
      taskData: {
        TaskId: null,
        Matter: { MatterId: '' },
        Resource: { UserId: '' },
        TaskStatus: { TaskStatusId: '' },
        Priority: { PriorityId: '' },
        DueDate: new Date(),
        TaskTitle: '',
        BillRate: '',
        Cost: '',
        EffortAct: '',
        EffortEst: '',
        BillingCode: '',
        Instructions: '',
        BillingNarrativeId: '1',
      },
      isAddShowing: false,
      isAddInprogressShowing: false,
      isAddBlockedShowing: false,
      isAddDoneShowing: false,
      isEditShowing: false,
      isEditInProgressShowing: false,
      isEditBlockedShowing: false,
      isEditDoneShowing: false,
      EditId: '',
      AddStatus: '',
      currentUserID: '',
    };
  }
  componentDidMount() {
    let currentUser: any = localStorage.getItem('userData');
    const currentUserID: any = JSON.parse(currentUser ? currentUser : '{}');
    this.setState({ currentUserID: currentUserID.UserId }); 
    this.fetchData();
  }
  fetchData = async () => {
    await Tasks.index()
      .then(async (response) => {
        let templateData: any = [];
        for (let temp of response.data) {
          if (temp.Resource?.ProfileImageURL) {
            temp['imageUrl'] = await Storage.get(
              temp.Resource?.ProfileImageURL
            );
          }
          templateData.push(temp);
        }

        const openData = templateData.filter(
          (task) => task.TaskStatus?.TaskStatusId === this.status.open
        );
        const inProgressData = templateData.filter(
          (task) => task.TaskStatus?.TaskStatusId === this.status.inProgress
        );
        const blockedData = templateData.filter(
          (task) => task.TaskStatus?.TaskStatusId === this.status.blocked
        );
        const doneData = templateData.filter(
          (task) => task.TaskStatus?.TaskStatusId === this.status.done
        );
        this.setState({
          openTasks: openData,
          openCount: openData.length,
          inProgressTasks: inProgressData,
          inProgressCount: inProgressData.length,
          blockedTasks: blockedData,
          blockedCount: blockedData.length,
          doneTasks: doneData,
          doneCount: doneData.length,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  movedTask = async (e, status) => {
    let taskId = e.item.id;
    console.log('mpved', e);
    await Tasks.single(taskId).then((response) => {
      this.setState(
        (state) => ({
          taskData: Object.assign(
            {},
            state.taskData,
            { TaskId: response.data.TaskId },
            {
              Matter: { MatterId: response.data.MatterTask[0]?.Matter?.MatterId },
            },
            {
              Resource: { UserId: response.data.Resource?.UserId },
            },
            { TaskStatus: { TaskStatusId: status } },
            { Priority: { PriorityId: response.data.Priority.PriorityId } },
            { DueDate: new Date(response.data.DueDate) },
            { TaskTitle: response.data.TaskTitle },
            { BillRate: response.data.BillRate },
            { Cost: response.data.Cost },
            { EffortAct: response.data.EffortAct },
            { EffortEst: response.data.EffortEst },
            { BillingCode: response.data.BillingCode },
            { Instructions: response.data.Instructions },
            { BillingNarrativeId: '1' }
          ),
        }),
          () => {
            Tasks.create(this.state.taskData)
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        );
        if (status === this.status.open) {
          this.setState({ openCount: this.state.openCount + 1 });
        } else if (status === this.status.inProgress) {
          this.setState({ inProgressCount: this.state.inProgressCount + 1 });
        } else if (status === this.status.blocked) {
          this.setState({ blockedCount: this.state.blockedCount + 1 });
        } else {
          this.setState({ doneCount: this.state.doneCount + 1 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  removedTask = async (e, status) => {
    if (status === this.status.open) {
      this.setState({ openCount: this.state.openCount - 1 });
    } else if (status === this.status.inProgress) {
      this.setState({ inProgressCount: this.state.inProgressCount - 1 });
    } else if (status === this.status.blocked) {
      this.setState({ blockedCount: this.state.blockedCount - 1 });
    } else {
      this.setState({ doneCount: this.state.doneCount - 1 });
    }
  };
  handlemodalClose = async () => {
    this.setState({
      isAddBlockedShowing: false,
      isAddDoneShowing: false,
      isAddInprogressShowing: false,
      isAddShowing: false,
      isEditBlockedShowing: false,
      isEditDoneShowing: false,
      isEditShowing: false,
      isEditInProgressShowing: false,
    });

    this.fetchData();
  };
  itemDoubledClick = (e, id) => {
    this.setState({ isEditShowing: true });
    this.setState({ EditId: id });
  };
  handleAddClick = (e, status) => {
    this.setState({ isAddShowing: true });
    this.setState({ AddStatus: status });
  };
  render() {
    const {
      openTasks,
      inProgressTasks,
      doneTasks,
      blockedTasks,
      openCount,
      inProgressCount,
      doneCount,
      blockedCount,
    } = this.state;
    let myclass = this;
    return (
      <Container>
        <Row>
          <Col md="3">
            <Card className="dash-tasks-status-card  dash-tasks-status-card--blue">
              <Card.Header>
                Open
                <span
                  className={
                    openCount > 0
                      ? 'dash-tasks-status-card_count ml-2'
                      : 'd-none'
                  }
                >
                  ({openCount})
                </span>
                <i
                  className="fa fa-plus cursor-pointer float-right mt-1"
                  aria-hidden="true"
                  onClick={(event) =>
                    this.handleAddClick(event, this.status.open)
                  }
                ></i>
                {this.state.isAddShowing && (
                  <CreateTaskAdmin
                    ref={this.addModalRef}
                    isAddShowing={this.state.isAddShowing}
                    onModalCloseClick={() => this.handlemodalClose()}
                    statusIdParam={this.state.AddStatus}
                  />
                )}
              </Card.Header>
              {myclass.state.isEditShowing && (
                <CreateTaskAdmin
                  ref={myclass.addModalRef}
                  isAddShowing={myclass.state.isEditShowing}
                  taskId={myclass.state.EditId}
                  onModalCloseClick={() => myclass.handlemodalClose()}
                />
              )}
              <ReactSortable
                list={openTasks}
                setList={(newState) => this.setState({ openTasks: newState })}
                animation={150}
                group="shared-group-name"
                onAdd={(event) => this.movedTask(event, this.status.open)}
                onRemove={(event) => this.removedTask(event, this.status.open)}
              >
                {openTasks.map((item: any) => (
                  <Item
                    id={item.TaskId}
                    key={item.TaskId}
                    className="dash-tasks-status-item  cursor-pointer d-flex align-items-center"
                    onDoubleClick={(event) =>
                      myclass.itemDoubledClick(event, item.TaskId)
                    }
                  >
                    {' '}
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover
                          id="user-Data"
                          className="dash-user-card-popover"
                        >
                          <UserCard userId={item?.Resource?.UserId} />
                        </Popover>
                      }
                    >
                     <Avatar
                        className={classNames(
                          'dash-grid-avatar cursor-pointer',
                          item?.Resource?.UserId === myclass.state.currentUserID
                            ? 'd-none'
                            : ''
                        )}
                        src={item.imageUrl}
                        alt=""
                      />
                    </OverlayTrigger>
                    <span className="dash-tasks-status-title">
                      {item.TaskTitle}
                    </span>
                    <ChannelCounterComponent
                      search={{ id: item.TaskId, type: 'TASK', showImg: false, object: item, }}
                    />
                  </Item>
                ))}
              </ReactSortable>
            </Card>
          </Col>
          <Col md="3">
            <Card className="dash-tasks-status-card  dash-tasks-status-card--green">
              <Card.Header>
                In Progress
                <span
                  className={
                    inProgressCount > 0
                      ? 'dash-tasks-status-card_count ml-2'
                      : 'd-none'
                  }
                >
                  ({inProgressCount})
                </span>
                <i
                  className="fa fa-plus cursor-pointer float-right mt-1"
                  aria-hidden="true"
                  onClick={(event) =>
                    this.handleAddClick(event, this.status.inProgress)
                  }
                ></i>
              </Card.Header>
              <ReactSortable
                list={inProgressTasks}
                setList={(newState) =>
                  this.setState({ inProgressTasks: newState })
                }
                animation={150}
                group="shared-group-name"
                onAdd={(event) => this.movedTask(event, this.status.inProgress)}
                onRemove={(event) =>
                  this.removedTask(event, this.status.inProgress)
                }
              >
                {inProgressTasks.map((item: any) => (
                  <Item
                    id={item.TaskId}
                    key={item.TaskId}
                    className="dash-tasks-status-item  cursor-pointer d-flex align-items-center"
                    onDoubleClick={(event) =>
                      myclass.itemDoubledClick(event, item.TaskId)
                    }
                  >
                    {' '}
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover
                          id="user-Data"
                          className="dash-user-card-popover"
                        >
                        </Popover>
                      }
                    >
                      <Avatar
                        className={classNames(
                          'dash-grid-avatar cursor-pointer',
                          item?.Resource?.UserId === myclass.state.currentUserID
                            ? 'd-none'
                            : ''
                        )}
                        src={item.imageUrl}
                        alt=""
                      />
                    </OverlayTrigger>
                    <span className="dash-tasks-status-title">
                      {item.TaskTitle}
                    </span>
                    <ChannelCounterComponent
                      search={{ id: item.TaskId, type: 'TASK', showImg: false, object: item, }}
                    />
                  </Item>
                ))}
              </ReactSortable>
            </Card>
          </Col>

          <Col md="3">
            <Card className="dash-tasks-status-card  dash-tasks-status-card--pink">
              <Card.Header>
                Blocked
                <span
                  className={
                    blockedCount > 0
                      ? 'dash-tasks-status-card_count ml-2'
                      : 'd-none'
                  }
                >
                  ({blockedCount})
                </span>
                <i
                  className="fa fa-plus cursor-pointer float-right mt-1"
                  aria-hidden="true"
                  onClick={(event) =>
                    this.handleAddClick(event, this.status.blocked)
                  }
                ></i>
              </Card.Header>
              <ReactSortable
                list={blockedTasks}
                setList={(newState) =>
                  this.setState({ blockedTasks: newState })
                }
                animation={150}
                group="shared-group-name"
                onAdd={(event) => this.movedTask(event, this.status.blocked)}
                onRemove={(event) =>
                  this.removedTask(event, this.status.blocked)
                }
              >
                {blockedTasks.map((item: any) => (
                  <Item
                    id={item.TaskId}
                    key={item.TaskId}
                    className="dash-tasks-status-item  cursor-pointer d-flex align-items-center"
                    onDoubleClick={(event) =>
                      myclass.itemDoubledClick(event, item.TaskId)
                    }
                  >
                    {' '}
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover
                          id="user-Data"
                          className="dash-user-card-popover"
                        >
                          <UserCard userId={item?.Resource?.UserId} /> 
                        </Popover>
                      }
                    >
                       <Avatar
                        className={classNames(
                          'dash-grid-avatar cursor-pointer',
                          item?.Resource?.UserId === myclass.state.currentUserID
                            ? 'd-none'
                            : ''
                        )}
                        src={item.imageUrl}
                        alt=""
                      />
                    </OverlayTrigger>
                    <span className="dash-tasks-status-title">
                      {item.TaskTitle}
                    </span>
                    <ChannelCounterComponent
                      search={{ id: item.TaskId, type: 'TASK', showImg: false, object: item }}
                    />
                  </Item>
                ))}
              </ReactSortable>
            </Card>
          </Col>
          <Col md="3">
            <Card className="dash-tasks-status-card  dash-tasks-status-card--gray">
              <Card.Header>
                Done
                <span
                  className={
                    doneCount > 0
                      ? 'dash-tasks-status-card_count ml-2'
                      : 'd-none'
                  }
                >
                  ({doneCount})
                </span>
                <i
                  className="fa fa-plus cursor-pointer float-right mt-1"
                  aria-hidden="true"
                  onClick={(event) =>
                    this.handleAddClick(event, this.status.done)
                  }
                ></i>
              </Card.Header>
              <ReactSortable
                list={doneTasks}
                setList={(newState) => this.setState({ doneTasks: newState })}
                animation={150}
                group="shared-group-name"
                onAdd={(event) => this.movedTask(event, this.status.done)}
                onRemove={(event) => this.removedTask(event, this.status.done)}
              >
                {doneTasks.map((item: any) => (
                  <Item
                    id={item.TaskId}
                    key={item.TaskId}
                    className="dash-tasks-status-item  cursor-pointer d-flex align-items-center"
                    onDoubleClick={(event) =>
                      myclass.itemDoubledClick(event, item.TaskId)
                    }
                  >
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover
                          id="user-Data"
                          className="dash-user-card-popover"
                        >
                          <UserCard userId={item?.Resource?.UserId} /> 
                        </Popover>
                      }
                    >
                       <Avatar
                        className={classNames(
                          'dash-grid-avatar cursor-pointer',
                          item?.Resource?.UserId === myclass.state.currentUserID
                            ? 'd-none'
                            : ''
                        )}
                        src={item.imageUrl}
                        alt=""
                      />
                    </OverlayTrigger>
                    <span className="dash-tasks-status-title">
                      {item.TaskTitle}
                    </span>
                    <ChannelCounterComponent
                      search={{ id: item.TaskId, type: 'TASK', showImg: false, object: item }}
                    />
                  </Item>
                ))}
              </ReactSortable>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default TasksBoardStatus;

const Item = styled.div`
  flex-grow: 1;
  padding: 0.3rem;
  border-radius: 0.2rem;
  background-color: #fff6;
  margin-bottom: 0.3rem;
  &.sortable-ghost {
    background-color: #c8ebfb;
  }
`;
