import React from 'react';
import { Spinner, Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CreateMeeting from './createMeeting';
import { Sessions } from 'services/Api';
import Tooltip from '@material-ui/core/Tooltip';

interface SessionState {
  posts: any;
  loading: boolean;
  isAddShowing: boolean;
  isEditShowing: boolean;
  editId: any;
  props: any;
}

class Session extends React.Component<{}, SessionState> {
  private addModalRef: any;
  constructor(props: any) {
    super(props);
    this.addModalRef = React.createRef();
    this.state = {
      posts: [],
      loading: false,
      isAddShowing: false,
      isEditShowing: false,
      editId: '',
      props: this.props
    };
  }
  fetch = async () => {
    try {
      this.setState({ loading: true });
      let sessionItems = await Sessions.index();
      let filteredSessionsActive: any[] = [];
      let filteredSessionsCancel: any[] = [];
      let filteredMeetingsActive = sessionItems.data.map((m) => {
        if (m.IsActive === true) {
          filteredSessionsActive.push(m)
          return m;
        }
        return null;
      });
      let filteredMeetingsCancel = sessionItems.data.map((m) => {
        if (m.IsActive === false) {
          filteredSessionsCancel.push(m)
          return m;
        }
        return null;
      });
      filteredMeetingsCancel = filteredSessionsCancel;
      filteredMeetingsActive = filteredSessionsActive; 
      let filteredMeetingsActiveByDate = filteredMeetingsActive.sort((a, b) => {
        return new Date(b.StartTime).getTime() -
          new Date(a.StartTime).getTime()
      })
      let filteredMeetingsCancelByDate = filteredMeetingsCancel.sort((a, b) => {
        return new Date(b.StartTime).getTime() -
          new Date(a.StartTime).getTime()
      })
      const filteredAllMeetings = [...filteredMeetingsActiveByDate, ...filteredMeetingsCancelByDate];
      this.setState({ posts: filteredAllMeetings });
      this.setState({ loading: false });
    } catch (err) {
      alert(err.message ? err.message : err);
    }
  };
  componentDidMount() {
    this.fetch();
  }
  handlemodalClose = async () => {
    this.setState({ isAddShowing: false, isEditShowing: false, loading: true });
    this.fetch();
  };
  handelIsEditShowing = (e: any, id: any) => {
    this.setState({ editId: id, isEditShowing: true });
  };

  cancelMeeting = async (sessionId) => {
    await Sessions.delete(sessionId);
    this.setState({ loading: true });
    const sessionItems = await Sessions.index();
    this.setState({ posts: sessionItems.data });
    this.setState({ loading: false });
    this.fetch();
  };

  render() {
    const { posts } = this.state;
    let myclass = this;
    return (
      <div>
        {this.state.loading && (
          <div className='dash-page-spinner-container'>
            <Col>
              <Spinner
                className='mr-1 dash-page-spinner'
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
            </Col>
          </div>
        )}

        <div className='dash-grid-body'>
          <Row>
            <Col md={3} className="float-left">
              <Button
                className='btn btn-xs btn-info '
                onClick={() => this.state.props.history.goBack()}
              >
                Back
              </Button>
              <button
                className='btn dash-primary-button ml-2 	'
                onClick={() => this.setState({ isAddShowing: true })}
              >
                Add Session
              </button>
            </Col>
            <Col md={6}>
              <div className='dash-page-title'>
                <h3 className='mb-0 text-center'>Meetings</h3>
              </div>
              <div className='dash-line-icon'></div>
            </Col>
            <Col md={3} className="float-right text-right">
              <Link
                type='button'
                className='btn dash-primary-button mr-2'
                to='/initSession/zoom'
              >
                Zoom Session
              </Link>
              {/* <Link
                type='button'
                className='btn dash-primary-button'
                to='/initSession/twilio'
              >
                Twilio Session
              </Link> */}

              {this.state.isAddShowing && (
                <CreateMeeting
                  ref={this.addModalRef}
                  isAddShowing={this.state.isAddShowing}
                  onModalCloseClick={() => this.handlemodalClose()}
                />
              )}
            </Col>
          </Row>
          <table className='table margin-separator-top3'>
            <thead>
              <tr>
                <th className='width-200' />
                <th className='width-300'>Title</th>
                <th className='width-300'>Matter</th>
                <th className='width-300'>Date</th>
                <th className='width-300'>Task</th>
                <th className='width-200'>Status</th>
                <th className='width-200' />
              </tr>
            </thead>
            <tbody>
              {posts.map(function (session: any, i) {
                return (
                  <tr key={'session-' + i}>
                    <td>
                      {session.IsActive && (
                        <Tooltip
                          classes={{
                            tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                            arrow: 'dash-tooltip-arrow' // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement='top'
                          title='Edit'
                        >
                          <button
                            className='dash-show-message-button cursor-pointer'
                            onClick={(event) =>
                              myclass.handelIsEditShowing(
                                event,
                                session.SessionId
                              )
                            }
                          >
                            <i className='far fa-edit'></i>
                          </button>
                        </Tooltip>
                      )}
                      {myclass.state.isEditShowing &&
                        myclass.state.editId === session.SessionId && (
                          <CreateMeeting
                            ref={myclass.addModalRef}
                            isAddShowing={myclass.state.isEditShowing}
                            sessionId={myclass.state.editId}
                            onModalCloseClick={() => myclass.handlemodalClose()}
                          />
                        )}
                    </td>
                    <td className='dash-word-break-all'>{session.Title}</td>
                    <td className='dash-word-break-all'>
                      {session.Matter
                        ? session.Matter.MatterTitle
                        : 'No Matter releated'}
                    </td>
                    <td>
                      <Moment format='YYYY/MM/DD'>{session.StartTime}</Moment>
                    </td>
                    <td className='dash-word-break-all'>{session.Task ? session.Task.TaskTitle : 'General'}</td>
                    <td>{session.IsActive ? 'Active' : 'Cancelled'}</td>
                    <td>
                      {session.IsActive && (
                        <button
                          onClick={() =>
                            myclass.cancelMeeting(session.SessionId)
                          }
                          className='dash-delete-button'
                        >
                          Cancel Meeting
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Session;
