import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetCurrentChannel,
  setNewMessageNotification,
} from "redux/actions/channelActions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function NotificationChannelManager() {
  const history: any = useHistory();

  const dispatch = useDispatch();
  const newMessage = useSelector((state:any) => state.channelReducer.newMessage);

  useEffect(() => {
    return () => {
      dispatch(setNewMessageNotification(null));
    };
  });

  const toastHandler = () => {
    dispatch(SetCurrentChannel(newMessage.Channel));
    history.push(`/inChannel`);
  };

  return (
    <>
      {newMessage &&
        toast.dark(
          <div onClick={() => toastHandler()}>
            {newMessage.message}
            <br /> author: {newMessage.messageSender.FullName}
          </div>
        )}
    </>
  );
}
