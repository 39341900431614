import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import validator from 'validator';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Multiselect } from 'multiselect-react-dropdown';
import { v4 as uuid } from 'uuid';
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { Roles, Firms, States, Departments, Users } from 'services/Api';
import config from '../../../aws-exports';
import CancelConfirmationModal from '../../CancelConfirmationModal/cancelConfirmationModal';

interface ResourceState {
  UserId: null;
  FullName: '';
  RoleId: any;
  Roles: any;
  StateId: any;
  States: any;
  FirmIds: any;
  Firms: any;
  DepartmentId: any;
  Departments: any;
  Email: any;
  City: any;
  ZipCode: any;
  Mobile: any;
  MobileFormat: any;
  Fax: any;
  BirthDate: any;
  BillRate: any;
  Address: any;
  show: boolean;
  loading: boolean;
  showErrors: boolean;
  loadingData: boolean;
  formTouched: boolean;
  international: boolean;
  showCancelConfirm: boolean;
  maxBirthDate: Date;
  selectedRoleAllowedFirm: boolean;
}
class NewUser extends React.Component<
  { isAddShowing; onModalCloseClick(); userId? },
  ResourceState
> {
  private multiSelectRef: any;

  constructor(props: any) {
    super(props);
    this.multiSelectRef = React.createRef();

    this.state = {
      UserId: null,
      FullName: '',
      RoleId: null,
      Roles: [],
      StateId: null,
      States: [],
      FirmIds: null,
      Firms: [],
      DepartmentId: null,
      Departments: [],
      Email: '',
      City: null,
      ZipCode: null,
      Mobile: '',
      MobileFormat: '',
      Fax: null,
      BirthDate: null,
      BillRate: null,
      Address: null,
      show: this.props.isAddShowing,
      loading: false,
      showErrors: false,
      loadingData: false,
      formTouched: false,
      international: false,
      showCancelConfirm: false,
      maxBirthDate: moment(new Date()).subtract(1, 'days').toDate(),
      selectedRoleAllowedFirm: false,
    };
  }

  componentDidMount() {
    if (this.props.userId) {
      this.setState({ loadingData: true });
    }
    this.fetchData();
    this.setAwsConfig();
  }

  setAwsConfig = async () => {
    await Auth.currentCredentials().then((credentials) => {
      AWS.config.update({
        region: 'us-east-2',
        credentials: Auth.essentialCredentials(credentials),
      });
    });
  };

  fetchData = async () => {
    await Roles.index()
      .then((response) => {
        this.setState({ Roles: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    await Firms.index()
      .then((response) => {
        const firms = response.data.map((Firm) => {
          return {
            ...Firm,
            FirmName: `${Firm.FirmName} (${Firm.City})`,
          };
        });
        this.setState({ Firms: firms });
      })
      .catch((error) => {
        console.log(error);
      });
    await States.index()
      .then((response) => {
        this.setState({ States: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    await Departments.index()
      .then((response) => {
        this.setState({ Departments: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.userId) {
      const myId = this.props.userId;

      Users.single(myId)
        .then((response) => {
          let firmIds = response.data.UserFirm?.map(({ Firm }) => {
            return { ...Firm, FirmName: `${Firm.FirmName} (${Firm.City})` };
          });
          this.setState({
            UserId: response.data.UserId,
            FullName: response.data.FullName,
            RoleId: response.data.Role?.RoleId,
            StateId: response.data.StateId,
            FirmIds: firmIds,
            DepartmentId: response.data.DepartmentId,
            Email: response.data.Email,
            City: response.data.City,
            ZipCode: response.data.ZipCode,
            Mobile: response.data.Mobile,
            MobileFormat: response.data.Mobile,
            Fax: response.data.Fax,
            BirthDate: response.data.BirthDate
              ? new Date(response.data.BirthDate)
              : null,
            BillRate: response.data.BillRate,
            Address: response.data.Address,
            loadingData: false,
            selectedRoleAllowedFirm: response.data.Role?.AllowMultipleFirm,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  createCognitoUser = (phone) => {
    const currentUser: any = localStorage.getItem('userData');
    const currentUserObj: any = JSON.parse(currentUser ? currentUser : '{}');
    let InvitedBy = currentUserObj.FullName;
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const username = uuid();
    let params = {
      UserPoolId: config.aws_user_pools_id,
      Username: username,
      ForceAliasCreation: false,
      DesiredDeliveryMediums: ['EMAIL'],
      UserAttributes: [
        {
          Name: 'email',
          Value: this.state.Email,
        },
        {
          Name: 'phone_number',
          Value: phone,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
        {
          Name: 'phone_number_verified',
          Value: 'true',
        },
        {
          Name: 'custom:invited_by',
          Value: InvitedBy,
        },
      ],
    };

    return cognito
      .adminCreateUser(params)
      .promise()
      .then((data) => data)
      .catch((err) => ({
        err: err.message,
      }));
  };
  changeCKHandler = (e: any, name: any) => {
    this.setState({ international: !this.state.international });
  };
  checkPhonePattern = (e) => {
    // const re = /[+0-9() ]+/g;
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseFloat(value) : value;
    this.setState(
      (prevState: any) => ({
        ...prevState,
        [name]: value,
      }),
      () => {
        if (name === 'RoleId') {
          this.setSelectedAllowMultibleFirms(value);
        }
      }
    );
    this.setState({ formTouched: true });
  };

  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));
    this.setState({ formTouched: true });
  };

  changeMultiSelect = (selectedList: any, selectedItem: any) => {
    this.setState({
      FirmIds: selectedList,
    });
    if (!this.state.UserId) {
      this.setState({ City: selectedList[0].City });

      if (selectedList[0] && selectedList[0].State) {
        this.setState({ StateId: selectedList[0].State.StateId });
      }
    }
    this.setState({ formTouched: true });
  };

  submitHandler = async (e: any) => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (
      this.state.FullName !== '' &&
      this.state.Email !== '' &&
      this.state.Mobile !== ''
    ) {
      let phone = this.state.Mobile;
      if (!phone.includes('+') && !phone.includes('+1')) {
        phone = phone.length === 10 ? `+1${phone}` : `+${phone}`;
      }
      phone = phone.startsWith('+00') ? phone.replace('+00', '+') : phone;
      this.setState({ loading: true });

      let userObj: any = {
        Role: this.state.RoleId ? { RoleId: this.state.RoleId } : null,
        FullName: this.state.FullName,
        Email: this.state.Email,
        Address: this.state.Address,
        City: this.state.City,
        StateId: this.state.StateId,
        ZipCode: this.state.ZipCode,
        Mobile: phone,
        Fax: this.state.Fax,
        BirthDate: this.state.BirthDate
          ? moment(new Date(this.state.BirthDate)).format('YYYY-MM-DD HH:mm:ss')
          : null,
        Firms:
          this.state.FirmIds && this.state.FirmIds.length > 0
            ? this.state.FirmIds
            : null,
        DepartmentId: this.state.DepartmentId,
        BillRate: this.state.BillRate ? this.state.BillRate : 0.0,
        IsPending: 1,
        Password: 'Dash2020',
        IsUsingTwillio: '1',
        DefaultPage: 'Firms',
        UserId: this.state.UserId,
      };

      try {
        if (this.props.userId) {
          userObj.UserName = await Auth.currentUserInfo().then(
            ({ sub }) => sub
          );
          userObj.UserId = this.props.userId;
          await Users.update(userObj);
          this.setState({ loading: false });
          toast.success('Data saved successfully!');
          this.handleClose();
        } else {
          userObj.UserName = await this.createCognitoUser(phone);
          if (!userObj.UserName.err) {
            userObj.UserName = userObj.UserName.User.Attributes[0].Value;
            await Users.createAuth(userObj);
            this.setState({ loading: false });
            toast.success('Data saved successfully!');
            this.handleClose();
          } else {
            toast.error(userObj.UserName.err);
            this.setState({ loading: false });
          }
        }
      } catch (error) {
        toast.error('Data not saved');
        this.setState({ loading: false });
      }
    }
    if (this.state.international === false) {
      if (this.state.MobileFormat.length === 10) {
        let mobile = this.state.MobileFormat;
        let code = '1';
        parseInt(mobile);
        mobile = mobile.replace(/-/g, '');
        let newMobile = code + mobile;
        this.setState({ Mobile: newMobile });
      }
    }
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalCloseClick();
  };

  handleCloseCancelConfirm = () => {
    this.setState({ showCancelConfirm: false });
  };
  setSelectedAllowMultibleFirms = (roleId) => {
    const AllowFirms = this.state.Roles.filter(
      (role) => role.RoleId.toString() === roleId
    );

    if (AllowFirms.length === 0) {
      this.setState({ selectedRoleAllowedFirm: false });
      return;
    }
    this.setState({ selectedRoleAllowedFirm: AllowFirms[0].AllowMultipleFirm });
    if (!AllowFirms[0].AllowMultipleFirm) {
      this.setState({ FirmIds: [] });
    }
  };
  render() {
    const { States, Roles, Departments, Firms } = this.state;
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size='xl'
          backdrop='static'
          className='pt-5'
          scrollable={true}
        >
          <form onSubmit={this.submitHandler}>
            <div className='mb-0'>
              <Row>
                <Col md={12}>
                  <Card className='dash-card'>
                    <Modal.Header className='p-0'>
                      <Card.Header className='dash-card-header'>
                        <span className='dash-card-title'>
                          {this.props.userId ? 'Edit' : 'Add'} User
                        </span>
                        <ButtonGroup className='float-right'>
                          <Button
                            type='reset'
                            className='dash-secondary-button margin-separator'
                            onClick={() => {
                              this.state.formTouched
                                ? this.setState({ showCancelConfirm: true })
                                : this.handleClose();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            className='dash-primary-button'
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <Spinner
                                className='mr-1 dash-button-spinner'
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}

                            {this.state.loading ? (
                              <span>Saving...</span>
                            ) : (
                              <span>Save</span>
                            )}
                          </Button>
                        </ButtonGroup>
                      </Card.Header>
                    </Modal.Header>{' '}
                    {this.state.loadingData && (
                      <div className='dash-page-spinner-container'>
                        <Col>
                          <Spinner
                            className='mr-1 dash-page-spinner'
                            as='span'
                            animation='border'
                            role='status'
                            aria-hidden='true'
                          />
                        </Col>
                      </div>
                    )}
                    <Modal.Body className='px-0 pt-0 pb-5 dash-card-body'>
                      <Card.Body>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Name
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='FullName'
                              name='FullName'
                              value={this.state.FullName || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.FullName) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>

                          <Col md={2}>
                            <div className='dash-form-label'>
                              Role{' '}
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <select
                              name='RoleId'
                              className='form-control dash-form-control'
                              placeholder='None'
                              onChange={this.changeHandler}
                              value={this.state.RoleId || ''}
                            >
                              <option value='0'>None</option>
                              {Roles.map((x: any, i) => (
                                <option key={'x' + i} value={x.RoleId}>
                                  {x.RoleName}
                                </option>
                              ))}
                            </select>
                            {this.state.showErrors === true &&
                              !this.state.RoleId && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>State</div>
                          </Col>

                          <Col md={4}>
                            <select
                              name='StateId'
                              className='form-control dash-form-control'
                              placeholder='None'
                              onChange={this.changeHandler}
                              value={this.state.StateId || ''}
                            >
                              <option value='0'>None</option>
                              {States.map((x: any, i) => (
                                <option key={'x' + i} value={x.StateId}>
                                  {x.StateName}
                                </option>
                              ))}
                            </select>
                          </Col>

                          <Col md={2}>
                            <div className='dash-form-label'>
                              Firm<span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <Multiselect
                              ref={this.multiSelectRef}
                              options={Firms} // Options to display in the dropdown
                              selectedValues={this.state.FirmIds} // Preselected value to persist in dropdown
                              onSelect={this.changeMultiSelect} // Function will trigger on select event
                              showCheckbox={true}
                              displayValue='FirmName' // Property name to display in the dropdown options
                              singleSelect={
                                this.state.selectedRoleAllowedFirm
                                  ? false
                                  : true
                              }
                            />
                            {this.state.showErrors === true &&
                              !this.state.FirmIds && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>Department</div>
                          </Col>

                          <Col md={4}>
                            <select
                              name='DepartmentId'
                              className='form-control dash-form-control'
                              placeholder='None'
                              onChange={this.changeHandler}
                              value={this.state.DepartmentId || ''}
                            >
                              <option value='0'>None</option>
                              {Departments.map((x: any, i) => (
                                <option key={'x' + i} value={x.DepartmentId}>
                                  {x.DepartmentName}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col md={2}>
                            <div className='dash-form-label'>
                              Email
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='email'
                              className='form-control dash-form-control'
                              id='Email'
                              name='Email'
                              value={this.state.Email || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.Email) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>City</div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='City'
                              name='City'
                              value={this.state.City || ''}
                              onChange={this.changeHandler}
                            />
                          </Col>
                          <Col md={2}>
                            <div className='dash-form-label'>
                              Mobile
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>
                          <Col md={4}>
                            {!this.state.international === true && (
                              <InputMask
                                mask='9999999999'
                                type='text'
                                className='form-control dash-form-control'
                                id='MobileFormat'
                                name='MobileFormat'
                                placeholder='[area code]-xxx-xxxx'
                                value={this.state.MobileFormat || ''}
                                onChange={this.changeHandler}
                                onKeyPress={this.checkPhonePattern}
                              />
                            )}
                            {this.state.international === true && (
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='Mobile'
                                name='Mobile'
                                placeholder='[country code]-[area code]-xxx-xxxx'
                                value={this.state.Mobile || ''}
                                onChange={this.changeHandler}
                                onKeyPress={this.checkPhonePattern}
                              />
                            )}
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.Mobile) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.international}
                                  onChange={this.changeCKHandler}
                                  name='international'
                                  color='secondary'
                                />
                              }
                              label='International'
                            />
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className='dash-form-label'>Birth Date</div>
                          </Col>
                          <Col md={4}>
                            <label className='dash-datepicker'>
                              <DatePicker
                                name='BirthDate'
                                className='form-control dash-form-control'
                                selected={this.state.BirthDate || ''}
                                onChange={(e) =>
                                  this.changeDateHandler(e, 'BirthDate')
                                }
                                showYearDropdown
                                maxDate={this.state.maxBirthDate}
                                popperPlacement='right'
                                dropdownMode='select'
                              />
                            </label>
                          </Col>
                          <Col md={2}>
                            <div className=' dash-form-label'>Fax</div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='Fax'
                              name='Fax'
                              value={this.state.Fax || ''}
                              onChange={this.changeHandler}
                            />
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>Bill Rate $</div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='number'
                              step='any'
                              lang='nb'
                              className='form-control dash-form-control'
                              id='BillRate'
                              name='BillRate'
                              value={this.state.BillRate || null}
                              onChange={this.changeHandler}
                            />
                          </Col>
                          <Col md={2}>
                            <div className='dash-form-label'>Address</div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='Address'
                              name='Address'
                              value={this.state.Address || ''}
                              onChange={this.changeHandler}
                            />
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}></Col>
                          <Col className='dash-form-label text-left'>
                            Fields with{' '}
                            <span className='dash-require-astrike'></span> are
                            required fields.
                          </Col>
                        </Row>
                      </Card.Body>
                    </Modal.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </form>
        </Modal>
        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={this.state.showCancelConfirm}
            onModalYesClick={() => this.handleClose()}
            onModalNoClick={() => this.setState({ showCancelConfirm: false })}
          ></CancelConfirmationModal>
        )}
      </React.Fragment>
    );
  }
}

export default NewUser;
