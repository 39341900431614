import React from 'react';
import { Row, Card, Col, Modal, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import validator from 'validator';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
// import moment from 'moment';
import CancelConfirmationModal from '../../CancelConfirmationModal/cancelConfirmationModal';
import { Sessions, Matters } from 'services/Api';

interface MeetingState {
  Title: string;
  StartTime: Date;
  Duration: any;
  Description: string;
  ZoomMeetingId: any;
  MatterId: any;
  TaskId: any;
  meetings: any;
  tasks: any;
  matters: any;
  show: boolean;
  loading: boolean;
  showErrors: boolean;
  loadingData: boolean;
  formTouched: boolean;
  showCancelConfirm: boolean;
  SessionId: any;
}
class CreateMeeting extends React.Component<
  {
    isAddShowing;
    onModalCloseClick(type, title);
    sessionId?;
  },
  MeetingState
> {
  // private params: any;
  private DueDate = {
    today: 1,
    thisWeek: 2,
    thisMonth: 3,
    later: 4,
  };
  private url = 'https://api.dash.law';
  constructor(props: any) {
    super(props);

    this.state = {
      Title: '',
      StartTime: new Date(),
      Duration: '',
      Description: '',
      ZoomMeetingId: null,
      MatterId: null,
      TaskId: null,
      meetings: [],
      tasks: [],
      matters: [],
      show: this.props.isAddShowing,
      loading: false,
      showErrors: false,
      loadingData: false,
      formTouched: false,
      showCancelConfirm: false,
      SessionId: '',
    };
  }

  componentDidMount() {
    if (this.props.sessionId) {
      this.setState({ loadingData: true });
    }
    this.fetchData();
  }

  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));
  };

  fetchData = async () => {
    let sessionId = this.props.sessionId;
    if (sessionId) {
      let response: any;

      try {
        response = await Sessions.single(sessionId);
      } catch (err) {
        alert(err.message ? err.message : err);
      }

      this.setState({
        Title: response.data.Title,
        Duration: response.data.Duration,
        Description: response.data.Description,
        StartTime: validator.isDate(`${new Date(response.data.StartTime)}`)
          ? new Date(response.data.StartTime)
          : new Date(),
        SessionId: response.data.SessionId,
        MatterId: response.data?.Matter?.MatterId,
        TaskId: response.data?.Task?.TaskId,
      });
    }

    try {
      this.setState({ loadingData: true });
      const meetingsList: any = await Sessions.zoomMeetingsList();
      this.setState({ meetings: meetingsList.data.meetings });

      const mattersList = await Matters.index();
      this.setState({ matters: mattersList.data });
      const matter = mattersList.data.find(
        (m) => Number(this.state.MatterId) === Number(m.MatterId),
      );
      if (matter) {
        const tasks = matter.MatterTask.map((matterTask) => {
          return matterTask.Task;
        });
        this.setState({ tasks: tasks });
      }
    } catch (err) {
      alert(err.message ? err.message : err);
    }
    this.setState({ loadingData: false });
  };

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  selectChangeHandler = (e: any) => {
    this.changeHandler(e);
    const newChange = {};
    newChange[e.target.name] = e.target.value;
    const MatterId: number = e.target.value;
    const matter = this.state.matters.find(
      (matter) => matter.MatterId === Number(MatterId),
    );
    if (!matter) {
      return;
    }
    const tasks = matter.MatterTask.map((matterTask) => {
      return matterTask.Task;
    });

    this.setState<never>({ tasks: tasks, newChange });
  };

  submitHandler = async (e: any) => {
    e.preventDefault();
    const zoomData = {
      topic: this.state.Title,
      type: '2',
      start: this.state.StartTime,
      duration: this.state.Duration,
    };

    const scheduleZoomResponse: any = await Sessions.scheduleZoomMeeting(
      zoomData,
    );

    const matter = this.state.matters.find(
      (m) => Number(this.state.MatterId) === Number(m.MatterId),
    );
    const task = this.state.tasks.find(
      (t) => Number(this.state.TaskId) === Number(t.TaskId),
    );

    const newSession = {
      Title: this.state.Title,
      StartTime: this.state.StartTime,
      Duration: this.state.Duration,
      Description: this.state.Description,
      ZoomMeetingId: scheduleZoomResponse.data.id,
      ZoomLink: scheduleZoomResponse.data.start_url,
      Matter: matter,
      Task: task,
    };

    const EditSession = {
      SessionId: this.state.SessionId,
      Title: this.state.Title,
      StartTime: this.state.StartTime,
      Duration: this.state.Duration,
      Description: this.state.Description,
      ZoomMeetingId: scheduleZoomResponse.data.id,
      ZoomLink: scheduleZoomResponse.data.start_url,
      Matter: matter,
      Task: task,
    };

    try {
      if (this.props.sessionId > 0) {
        await Sessions.create(EditSession);
      } else {
        await Sessions.create(newSession);
      }
      this.setState({ loading: false });
      toast.success('Data saved successfully!');
     
      this.handleClose();
    } catch (err) {
      toast.error('Data not saved');
      this.setState({ loading: false });
      this.setState({ showErrors: true });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalCloseClick('meeting', this.state.Title);
  };

  render() {
    const { matters, meetings, tasks } = this.state;
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size="xl"
          backdrop="static"
          className="pt-5"
          scrollable={true}
        >
          <div>
            <form onSubmit={this.submitHandler}>
              <div className="mb-0">
                <Row>
                  <Col md={12}>
                    <Card className="dash-card">
                      <Modal.Header className="p-0">
                        <Card.Header className="dash-card-header">
                          <span className="dash-card-title">
                            {this.state.SessionId
                              ? 'Edit Session'
                              : 'Create Session'}
                          </span>
                          <ButtonGroup className="float-right">
                            <Button
                              type="button"
                              variant="secondary"
                              size="sm"
                              onClick={this.handleClose}
                              className="dash-secondary-button margin-separator"
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="dash-primary-button"
                            >
                              Save
                            </Button>
                          </ButtonGroup>
                        </Card.Header>
                      </Modal.Header>
                      {this.state.loadingData && (
                        <div className="dash-page-spinner-container">
                          <Col>
                            <Spinner
                              className="mr-1 dash-page-spinner"
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                            />
                          </Col>
                        </div>
                      )}
                      <Modal.Body className="px-0 pt-0 pb-5 dash-card-body">
                        <Card.Body>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">
                                Title
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type="text"
                                className="form-control dash-form-control"
                                id="Title"
                                name="Title"
                                value={this.state.Title}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.Title) && (
                                  <span className="dash-error-message">
                                    Required
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">Meeting</div>
                            </Col>
                            <Col md={4}>
                              <select
                                id="priorities"
                                name="priorities"
                                className="form-control dash-form-control"
                                placeholder="All"
                              >
                                <option>All</option>
                                {meetings.map((x: any) => (
                                  <option key={x.id} value={x.id}>
                                    {x.topic}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">Matter</div>
                            </Col>
                            <Col md={4}>
                              <select
                                id="MatterId"
                                name="MatterId"
                                className="form-control dash-form-control"
                                placeholder="All"
                                onChange={this.selectChangeHandler}
                                value={this.state.MatterId || ''}
                              >
                                <option>All</option>
                                {matters.map((x: any) => (
                                  <option key={x.MatterId} value={x.MatterId}>
                                    {x.MatterTitle}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">Task</div>
                            </Col>
                            <Col md={4}>
                              <select
                                id="TaskId"
                                name="TaskId"
                                className="form-control dash-form-control"
                                placeholder="All"
                                onChange={this.selectChangeHandler}
                                value={this.state.TaskId || ''}
                              >
                                <option>All</option>
                                {tasks.map((x: any) => (
                                  <option key={x.TaskId} value={x.TaskId}>
                                    {x.TaskTitle}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">Day</div>
                            </Col>
                            <Col md={4}>
                              <label className="dash-datepicker">
                                <DatePicker
                                  className="form-control dash-form-control"
                                  id="DueDate"
                                  name="DueDate"
                                  selected={this.state.StartTime}
                                  onChange={(e) =>
                                    this.changeDateHandler(e, 'StartTime')
                                  }
                                  popperPlacement="right"
                                />
                              </label>
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">
                                Duration
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                className="form-control dash-form-control"
                                type="number"
                                id="Duration"
                                name="Duration"
                                value={this.state.Duration}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                this.state.Duration < 1 && (
                                  <span className="dash-error-message">
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className=" dash-form-label">
                                Description
                              </div>
                            </Col>
                            <Col md={10}>
                              <textarea
                                id="Description"
                                name="Description"
                                value={this.state.Description}
                                onChange={this.changeHandler}
                                className="form-control dash-form-control"
                                data-rows="3"
                              ></textarea>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}></Col>
                            <Col className="dash-form-label text-left">
                              Fields with{' '}
                              <span className="dash-require-astrike"></span> are
                              required fields.
                            </Col>
                          </Row>
                        </Card.Body>
                      </Modal.Body>{' '}
                    </Card>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </Modal>
        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={this.state.showCancelConfirm}
            onModalYesClick={() => this.handleClose()}
            onModalNoClick={() => this.setState({ showCancelConfirm: false })}
          ></CancelConfirmationModal>
        )}
      </React.Fragment>
    );
  }
}

export default CreateMeeting;
