import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClient } from "redux/actions/clientChatActions";
import socketIOClient from "socket.io-client";
import { UpdateChannels } from "redux/actions/channelActions";

export default function ChannelHandler() {
  const url = 'https://api.dash.law';
  const dispatch = useDispatch();
  const storedClient =
    useSelector((state:any) => state.clientChatReducer.client) || null;
  useEffect(() => {
    const loadClient = async () => {
      if (!storedClient) {
        const socket = await socketIOClient(url);
        await socket.on("msgToClient", (message) => {
          dispatch(UpdateChannels(message));
        });
        dispatch(setClient(socket));
      }
    };

    loadClient();

  }, [dispatch, storedClient]);

  return <> </>;
}
