import React from 'react';
import {
  Row,
  Card,
  Col,
  Modal,
  Spinner,
  Button,
  ButtonGroup
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Calendar } from 'services/Api';

interface ResourceState {
  id: any;
  UserId: any;
  Title: string;
  Description: string;
  formTouched: boolean;
  StartDate: any;
  EndDate: any;
  MinDate: any;
  show: boolean;
  showError: any;
  loadingData: boolean;
  showCancelConfirm: boolean;
  loading: boolean;
}
class NewCalendarEvent extends React.Component<
  { onModalClose(type, title); isModalOpen; event },
  ResourceState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      id: this.props.event.eventId || null,
      UserId: this.props.event.userId || null,
      Title: this.props.event.title || '',
      Description: this.props.event.desc || '',
      StartDate: this.props.event.start || '',
      EndDate: this.props.event.end || '',
      formTouched: false,
      show: this.props.isModalOpen,
      loadingData: false,
      showError: false,
      MinDate: Date().toLocaleString(),
      showCancelConfirm: false,
      loading: false
    };
  }

  componentDidMount() {
    const currentUser: any = JSON.parse(
      localStorage.getItem('userData') || '{}'
    );
    this.setState({ UserId: currentUser.UserId });
  }

  getCalendarEventObj = () => {
    return {
      CalendarId: this.state.id || null,
      UserId: this.state.UserId,
      Title: this.state.Title,
      Description: this.state.Description,
      StartDate: this.state.StartDate,
      EndDate: this.state.EndDate
    };
  };

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e
    }));
    this.setState({ formTouched: true });
  };

  submitHandler = async (e: any) => {
    e.preventDefault();
    this.setState({ showError: true });
    if (this.state.Title !== '' &&
      this.state.EndDate !== '' &&
      this.state.StartDate !== '') {
      const eventObj = this.getCalendarEventObj();
      const saveCalendar = await Calendar.create(eventObj);
      this.setState({ id: saveCalendar.data.CalendarId });
      toast.success('Data saved successfully!');
      this.handleClose();
    } else {
      toast.error('Data not saved');
    }
  };

  handleClose = () => {
    this.setState({ show: false });
    this.setState({
      StartDate: '',
      EndDate: '',
      Title: '',
      Description: '',
    });
    console.log(this.state.EndDate)
    this.props.onModalClose('event', {
      userId: this.state.UserId,
      title: this.state.Title,
      desc: this.state.Description,
      start: this.state.StartDate,
      end: this.state.EndDate,
      eventId: this.state.id
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size='xl'
          backdrop='static'
          className='pt-5'
          scrollable={true}
        >
          <form onSubmit={this.submitHandler}>
            <div className='mb-0'>
              <Row>
                <Col md={12}>
                  <Card className='dash-card'>
                    <Modal.Header className='p-0'>
                      <Card.Header className='dash-card-header'>
                        <span className='dash-card-title'>
                          {this.props.event.eventId ? 'Edit ' : 'Add '}
                          Calendar Event
                        </span>
                        <ButtonGroup className='float-right'>
                          <Button
                            type='button'
                            onClick={() => {
                              this.handleClose();
                            }}
                            size='sm'
                            className='dash-secondary-button margin-separator'
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            className='dash-primary-button'
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <Spinner
                                className='mr-1 dash-button-spinner'
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}

                            {this.state.loading ? (
                              <span>Saving...</span>
                            ) : (
                              <span>Save</span>
                            )}
                          </Button>
                        </ButtonGroup>
                      </Card.Header>
                    </Modal.Header>
                    <Modal.Body className='px-0 pt-0 pb-5 dash-card-body'>
                      <Card.Body>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Title
                            <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={10}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='Title'
                              name='Title'
                              value={this.state.Title}
                              onChange={this.changeHandler}
                            />
                            {this.state.showError === true &&
                              (!this.state.Title) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Start Date
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <DatePicker
                              name='StartDate'
                              showTimeSelect
                              className='form-control dash-form-control'
                              selected={this.state.StartDate}
                              onChange={(e) =>
                                this.changeDateHandler(e, 'StartDate')
                              }
                              onFocus={(e) => e.target.blur()}
                            />
                            {this.state.showError === true &&
                              (!this.state.StartDate) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>

                          <Col md={2}>
                            <div className=' dash-form-label'>
                              End Date
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <DatePicker
                              name='EndDate'
                              showTimeSelect
                              className='form-control dash-form-control'
                              selected={this.state.EndDate}
                              disabled={this.state.StartDate === ''}
                              minDate={new Date(this.state.StartDate)}
                              onChange={(e) =>
                                this.changeDateHandler(e, 'EndDate')
                              } 
                              onFocus={(e) => e.target.blur()}
                            />
                            {this.state.showError === true &&
                              (!this.state.EndDate) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>Description</div>
                          </Col>
                          <Col md={10}>
                            <textarea
                              id='Description'
                              name='Description'
                              value={this.state.Description}
                              onChange={this.changeHandler}
                              className='form-control dash-form-control'
                              data-rows='3'
                            ></textarea>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Modal.Body>{' '}
                  </Card>
                </Col>
              </Row>
            </div>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default NewCalendarEvent;
