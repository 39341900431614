import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Multiselect } from 'multiselect-react-dropdown';
import validator from 'validator';
import CancelConfirmationModal from '../CancelConfirmationModal/cancelConfirmationModal';
import { Users, LawTypes, States, Firms } from 'services/Api';

interface ResourceState {
  FirmName: string;
  City: string;
  AddressLine1: string;
  AddressLine2: string;
  StateId: any;
  ZipCode: string;
  PracticeGroup: string;
  Office: string;
  Phone: string;
  BillingGuidelines: string;
  FirmId: any;
  LawTypeId: any;
  States: [];
  LawTypes: [];
  Users: [];
  UserIds: [];
  show: boolean;
  loading: boolean;
  showErrors: boolean;
  loadingData: boolean;
  formTouched: boolean;
  showCancelConfirm: boolean;
}
class NewFirm extends React.Component<
  { isAddShowing; onModalCloseClick(); firmId? },
  ResourceState
> {
  private multiselectRef: any;

  constructor(props: any) {
    super(props);
    this.multiselectRef = React.createRef();

    this.state = {
      FirmName: '',
      City: '',
      AddressLine1: '',
      AddressLine2: '',
      StateId: null,
      ZipCode: '',
      PracticeGroup: '',
      Office: '',
      Phone: '',
      BillingGuidelines: '',
      FirmId: null,
      LawTypeId: null,
      States: [],
      LawTypes: [],
      Users: [],
      UserIds: [],
      show: this.props.isAddShowing,
      loading: false,
      showErrors: false,
      loadingData: false,
      formTouched: false,
      showCancelConfirm: false,
    };
  }

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    this.setState({ formTouched: true });
  };
  changeMultiSelect = (selectedList: any, selectedItem: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      UserIds: selectedList,
    }));
    this.setState({ formTouched: true });
  };
  componentDidMount() {
    if (this.props.firmId) {
      this.setState({ loadingData: true });
    }

    // const userObj: any = Users.index();
    // this.setState({ Users: userObj.data });

    States.index()
      .then((response) => {
        console.log(response.data);
        this.setState({ States: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    LawTypes.index()
      .then((response) => {
        console.log(response.data);
        this.setState({ LawTypes: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    Users.index()
      .then((response) => {
        console.log(response.data);
        this.setState({ Users: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.firmId) {
      let myId = this.props.firmId;

      Firms.single(myId)
        .then((response) => {
          let userIds = response.data.UserFirm?.map(({ User }) => User);

          this.setState({
            FirmId: response.data.FirmId,
            FirmName: response.data.FirmName,
            City: response.data.City,
            AddressLine1: response.data.AddressLine1,
            AddressLine2: response.data.AddressLine2,
            ZipCode: response.data.ZipCode,
            Office: response.data.Office,
            PracticeGroup: response.data.PracticeGroup,
            BillingGuidelines: response.data.BillingGuidelines,
            Phone: response.data.Phone,
            LawTypeId: response.data.LawType?.LawTypeId,
            StateId: response.data.State?.StateId,
            UserIds: userIds,
            loadingData: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  submitHandler = (e: any) => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (
      this.state.FirmName !== '' &&
      this.state.AddressLine1 !== '' &&
      this.state.City !== '' &&
      this.state.ZipCode !== '' &&
      this.state.PracticeGroup !== '' &&
      this.state.Office !== '' &&
      this.state.Phone !== '' &&
      this.state.BillingGuidelines !== ''
    ) {
      this.setState({ loading: true });

      const newFirm = {
        firm: {
          LawType: {
            LawTypeId:
              this.state.LawTypeId === '0' ? null : this.state.LawTypeId,
          },
          FirmName: this.state.FirmName,
          AddressLine1: this.state.AddressLine1,
          AddressLine2: this.state.AddressLine2,
          City: this.state.City,
          State: {
            StateId: this.state.StateId === '0' ? null : this.state.StateId,
          },
          ZipCode: this.state.ZipCode,
          PracticeGroup: this.state.PracticeGroup,
          Office: this.state.Office,
          Phone: this.state.Phone,
          BillingGuidelines: this.state.BillingGuidelines,
          FirmId: this.state.FirmId,
        },
        users: this.state.UserIds.length > 0 ? this.state.UserIds : null,
      };

      Firms.create(newFirm)
        .then((response) => {
          this.setState({ loading: false });
          toast.success('Data saved successfully!');
          this.handleClose();
        })
        .catch((error) => {
          toast.error('Data not saved');
          this.setState({ loading: false });
        });
    }
  };
  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalCloseClick();
  };
  handleCloseCancelConfirm = () => {
    this.setState({ showCancelConfirm: false });
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size='xl'
          backdrop='static'
          className='pt-5'
          scrollable={true}
        >
          <form onSubmit={this.submitHandler}>
            <div className='mb-0'>
              <Row>
                <Col md={12}>
                  <Card className='dash-card'>
                    <Modal.Header className='p-0'>
                      <Card.Header className='dash-card-header'>
                        <span className='dash-card-title'>
                          {this.props.firmId ? 'Edit' : 'Add'} Firm
                        </span>
                        <ButtonGroup className='float-right'>
                          <Button
                            type='button'
                            className='dash-secondary-button margin-separator'
                            onClick={() => {
                              this.state.formTouched
                                ? this.setState({ showCancelConfirm: true })
                                : this.handleClose();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            className='dash-primary-button'
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <Spinner
                                className='mr-1 dash-button-spinner'
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}

                            {this.state.loading ? (
                              <span>Saving...</span>
                            ) : (
                              <span>Save</span>
                            )}
                          </Button>
                        </ButtonGroup>
                      </Card.Header>
                    </Modal.Header>{' '}
                    {this.state.loadingData && (
                      <div className='dash-page-spinner-container'>
                        <Col>
                          <Spinner
                            className='mr-1 dash-page-spinner'
                            as='span'
                            animation='border'
                            role='status'
                            aria-hidden='true'
                          />
                        </Col>
                      </div>
                    )}
                    <Modal.Body className='px-0 pt-0 pb-5 dash-card-body'>
                      <Card.Body>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Firm Name
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='FirmName'
                              name='FirmName'
                              value={this.state.FirmName || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.FirmName) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>

                          <Col md={2}>
                            <div className='dash-form-label'>
                              City
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              name='City'
                              value={this.state.City || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.City) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Address Line 1
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='AddressLine1'
                              name='AddressLine1'
                              value={this.state.AddressLine1 || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.AddressLine1) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>

                          <Col md={2}>
                            <div className='dash-form-label'>
                              Address Line 2
                            </div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              name='AddressLine2'
                              value={this.state.AddressLine2 || ''}
                              onChange={this.changeHandler}
                            />
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>State</div>
                          </Col>

                          <Col md={4}>
                            <select
                              className='form-control dash-form-control'
                              id='StateId'
                              name='StateId'
                              value={this.state.StateId || ''}
                              onChange={this.changeHandler}
                            >
                              <option value='0'>None</option>
                              {this.state.States.map((x: any, i) => (
                                <option key={x.StateId} value={x.StateId}>
                                  {x.StateName}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col md={2}>
                            <div className=' dash-form-label'>Law Type</div>
                          </Col>

                          <Col md={4}>
                            <select
                              className='form-control dash-form-control'
                              id='LawTypeId'
                              name='LawTypeId'
                              value={this.state.LawTypeId || ''}
                              onChange={this.changeHandler}
                            >
                              <option value='0'>None</option>
                              {this.state.LawTypes.map((x: any, i) => (
                                <option key={x.LawTypeId} value={x.LawTypeId}>
                                  {x.LawTypeName}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Practice Group
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='PracticeGroup'
                              name='PracticeGroup'
                              value={this.state.PracticeGroup || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.PracticeGroup) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>

                          <Col md={2}>
                            <div className='dash-form-label'>
                              Zip Code
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              name='ZipCode'
                              value={this.state.ZipCode || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.ZipCode) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Phone
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='Phone'
                              name='Phone'
                              value={this.state.Phone || ''}
                              placeholder='[country code]-[area code]-xxx-xxxx'
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.Phone) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>

                          <Col md={2}>
                            <div className='dash-form-label'>
                              Office
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              name='Office'
                              value={this.state.Office || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(this.state.Office) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className='dash-form-label'>User(s)</div>
                          </Col>
                          <Col md={4}>
                            <Multiselect
                              ref={this.multiselectRef}
                              options={this.state.Users} // Options to display in the dropdown
                              selectedValues={this.state.UserIds} // Preselected value to persist in dropdown
                              onSelect={this.changeMultiSelect} // Function will trigger on select event
                              showCheckbox={true}
                              displayValue='FullName' // Property name to display in the dropdown options
                            />
                          </Col>
                          <Col md={2}>
                            <div className=' dash-form-label'>
                              Billing GuideLines
                              <span className='dash-require-astrike'></span>
                            </div>
                          </Col>

                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='BillingGuidelines'
                              name='BillingGuidelines'
                              value={this.state.BillingGuidelines || ''}
                              onChange={this.changeHandler}
                            />
                            {this.state.showErrors === true &&
                              validator.isEmpty(
                                this.state.BillingGuidelines
                              ) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )}
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}></Col>
                          <Col className='dash-form-label text-left'>
                            Fields with{' '}
                            <span className='dash-require-astrike'></span> are
                            required fields.
                          </Col>
                        </Row>
                      </Card.Body>
                    </Modal.Body>{' '}
                  </Card>
                </Col>
              </Row>
            </div>
          </form>
        </Modal>
        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={this.state.showCancelConfirm}
            onModalYesClick={() => this.handleClose()}
            onModalNoClick={() => this.setState({ showCancelConfirm: false })}
          ></CancelConfirmationModal>
        )}
      </React.Fragment>
    );
  }
}

export default NewFirm;
