import { get, post } from 'config/api';

const MessageCenter_API = 'MessageCenter';

export const MessageCenter = {
  channels: (userId) => get(`${MessageCenter_API}/GetChannels/${userId}`),
  channelByUser: () =>
    get(`${MessageCenter_API}/GetUserChannelData`),
    videoToken: (username : any, matter : any) =>
    get(`${MessageCenter_API}/GenerateVideoToken?Identity=${username}&Room=${matter}`),
  create: (message: object) =>
    post(`${MessageCenter_API}/CreateMessage`, message),
  createUserMetaData: (metadata: object) =>
    post(`${MessageCenter_API}/SaveUserMetadata`, metadata),
  isAvailableChannel: (channeltype, id) => 
    get(`${MessageCenter_API}/allowToSeeChannel?id=${id}&channelType=${channeltype}`),
};
