import { Storage } from "aws-amplify";

export const fileLoadedReadyToSave = async (fileName, fileData, fileType) => {
  let fileKey;

  await Storage.put(fileName, fileData, {
    bucket: "dashpublic",
    contentType: fileType,
    level: "public",
  })
    .then(async (result: any) => {
      console.log(result);
      fileKey = result.key;
    })
    .catch((err) => console.log(err));
  return fileKey;
};
