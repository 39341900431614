const colors = {
  dash_accent: "#09548d",
  dash_blue1: "#127b90",
  dash_h_blue2: "#06b5d8",
  dash_blue3: "#00e6ff",
  dash_blue4: "#676a8a",
  dash_light_grey1: "#c9c9c9",
  dash_light_grey1_opacity30: "#e0e0e04c",
  dash_grey1: "#333333",
  dash_grey2: "#3b3b3b",
  dash_grey3: "#4a4a4a",
  dash_grey4: "#5c5a5a",
  dash_grey5: "#686868",
  dash_grey6: "#707070",
  dash_red: "#ff0000",
  dash_dark_red: "#9e2121",
  dash_status_blue: "#84b4c8",
  dash_status_gray: "#cfcfc4",
  dash_status_orange: "#fdcf76",
  dash_status_green: "#81d381",
  dash_status_pink: "#ffa7b6",
  dash_status_red: "#e84e45",
  dash_status_blue_body: "#dfeef5",
  dash_status_gray_body: "#e0e0d8",
  dash_status_orange_body: "#fcf0cf",
  dash_status_green_body: "#daf5da",
  dash_status_pink_body: "#fdcfd7",
  dash_status_red_body: "#ffe8e6",
  dash_black: "#232323",
};
export default colors;
