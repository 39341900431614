import React from "react";

// import Icon from '@material-ui/core/Icon';

export default function SessionLawyer() {
  return (
    <div>
      <table className="table table-striped table-center margin-separator-top">
        <thead>
          <tr>
            <th />
            <th>Matter</th>
            <th>Resource Name</th>
            <th>Date</th>
            <th>Plaintive</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button className="btn btn-xs btn-info margin-separator">
                <i className="fas fa-video" /> Start Session
              </button>
              <button className="dash-edit-button"> Edit</button>
            </td>
            <td>Criminal</td>
            <td>Jerry Maguire</td>
            <td>2020-03-18</td>
            <td>Andrew Garfield</td>
            <td>
              <button className="dash-delete-button"> Cancel</button>
            </td>
          </tr>
          <tr>
            <td>
              <button className="btn btn-xs btn-info margin-separator">
                <i className="fas fa-video" /> Start Session
              </button>
              <button className="dash-edit-button"> Edit</button>
            </td>
            <td>Criminal</td>
            <td>Anne Hathaway</td>
            <td>2020-02-26</td>
            <td>Harry Gateway</td>
            <td>
              <button className="dash-delete-button"> Cancel</button>
            </td>
          </tr>
          <tr>
            <td>
              <button className="btn btn-xs btn-info margin-separator">
                <i className="fas fa-video" /> Start Session
              </button>
              <button className="dash-edit-button"> Edit</button>
            </td>
            <td>larceny</td>
            <td>Mark Wahlberg</td>
            <td>2020-03-18</td>
            <td>Tony Stark</td>
            <td>
              <button className="dash-delete-button"> Cancel</button>
            </td>
          </tr>
          <tr>
            <td>
              <button className="btn btn-xs btn-info margin-separator">
                <i className="fas fa-video" /> Start Session
              </button>
              <button className="dash-edit-button"> Edit</button>
            </td>
            <td>larceny</td>
            <td>Sienna Miller</td>
            <td>2020-02-26</td>
            <td>Henry Cavegill</td>
            <td>
              <button className="dash-delete-button"> Cancel</button>
            </td>
          </tr>
          <tr>
            <td>
              <button className="btn btn-xs btn-info margin-separator">
                <i className="fas fa-video" /> Start Session
              </button>
              <button className="dash-edit-button"> Edit</button>
            </td>
            <td>Criminal</td>
            <td>Christian Bale</td>
            <td>2020-03-18</td>
            <td>Johan Delaware</td>
            <td>
              <button className="dash-delete-button"> Cancel</button>
            </td>
          </tr>

          {/* <tr>
                  <td> 
               <td>
               <a href={session.join_url}>
                <button className="btn btn-xs btn-info margin-separator" type="button">
                <i className="fas fa-video"/>   Start Session
                </button>
               </a>
               <button className="btn btn-xs btn-info margin-separator-top2"><i className="fa fa-edit" /> Edit</button>
             </td>
              <td className="margin-separator-top3" >{session.topic}</td>
              <td className="margin-separator2">Jerry Maguire</td>
              <td className="margin-separator2">2020-03-18</td>
              <td className="margin-separator2">Andrew Garfield</td>
              <td>
              <button className="btn btn-xs btn-danger pull-right" ><i className="far fa-calendar-times" /> Cancel</button>
            </td>
            </td>
            </tr> */}
        </tbody>
      </table>
    </div>
  );
}
