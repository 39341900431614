import React, { useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import * as XLSX from "xlsx";
import Col from "react-bootstrap/Col";
import {
  Button,
  ButtonGroup,
  Spinner,
  Accordion,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSync } from "@fortawesome/free-solid-svg-icons";
//import history from "../../../services/history";
import CreateTaskAdmin from "../tasks/createTaskAdmin";
//import { baseUrl } from "../../../services/backend";
import AddMatter from "./addMatter";
import UploadDocumentModal from "../../UploadDocumentModal/uploadDocumentModal";
import { Storage } from "aws-amplify";
import { Avatar } from "@material-ui/core";
import classNames from "classnames";
import { Link } from "react-router-dom";
import UserCard from "../../profile/userCard";
import ChannelCounterComponent from "shared/channelCounterComponent";
import { Users, Matters, Priorities, Clients } from "services/Api";
import Tooltip from "@material-ui/core/Tooltip";
import Workbook from "react-excel-workbook";

export default function MattersManagement(props: any, history) {
  const filterInitialState = {
    matterTitle: "",
    typeId: "",
    stageId: "",
    priorityId: "",
    statusId: "",
    responsibleId: "",
    clientId: 0,
    userId: "",
    matterTask: "",
  };

  const searchForm: any = useRef<HTMLFormElement>(null);
  let addModalRef: any = useRef<any>(null);
  let uploadModalRef: any = useRef<any>(null);
  let fileUploader: any = useRef<any>(null);
  const [allMatters, setAllMatters] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [status, setStatus] = useState([]);
  const [stage, setStage] = useState([]);
  const [clients, setClients] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [matterFilter, setMatterFilter] = useState(filterInitialState);
  const [runSearch, setRunSearch] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [displayedMatters, setDisplayedMatters] = useState([]);
  const [isEditShowing, setIsEditShowing] = useState(false);
  const [isAddShowing, setIsAddShowing] = useState(false);
  const [isUploadShowing, setIsUploadShowing] = useState(false);
  const [isNewTaskMatterShowing, setIsNewTaskMatterShowing] = useState(false);
  const [editId, seteditId] = useState(false);
  const [uploadId, setUploadId] = useState(false);
  const [newTaskMatterId, setNewTaskMatterId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentUserID, setCurrentUserID] = useState();
  const [readfile, setReadFile] = useState<File>();

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchMatters();
      const userObj: any = await Users.index();
      setUsers(userObj.data);
      //get CaseTypes
      await Matters.lookups
        .types()
        .then((response) => {
          setTypes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      //get priorities
      await Priorities.index()
        .then((response) => {
          setPriorities(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get status
      await Matters.lookups
        .statuses()
        .then((response) => {
          setStatus(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get stage
      await Matters.lookups
        .stages()
        .then((response) => {
          setStage(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get clients
      await Clients.index()
        .then((response) => {
          setClients(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    let currentUser: any = localStorage.getItem("userData");
    const currentUserID: any = JSON.parse(currentUser ? currentUser : "{}");
    setCurrentUserID(currentUserID.UserId);
    _handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runSearch]);
  const handleRefresh = async () => {
    setLoading(true);
    await fetchMatters();
    setLoading(false);
  };
  const fetchMatters = async () => {
    let clientIdParam = props.location.state?.clientId;
    let userIdParam = props.location.state?.userId;
    let matterTaskParam = props.location.state?.matterTask;
    if (matterTaskParam === undefined) {
      matterTaskParam = "    ";
    }
    await Matters.index()
      // .then((res: any) => res.json())
      .then((response) => {
        setAllMatters(response.data);
        if (matterTaskParam !== 0) {
          setMatterFilter({
            ...matterFilter,
            matterTitle: matterTaskParam,
          });
        }
        if (userIdParam > 0) {
          setMatterFilter({
            ...matterFilter,
            responsibleId: userIdParam,
          });
        }
        if (clientIdParam > 0) {
          setMatterFilter({
            ...matterFilter,
            clientId: clientIdParam,
          });
        }
        setRunSearch(!runSearch);
      })
      //  .then(() => setRunSearch(!runSearch))
      .catch((error) => console.log(error));
  };
  const _handleSearch = () => {
    const filteredMatters = allMatters.filter((mymatter: any) => {
      return (
        mymatter.MatterTitle.toLowerCase().includes(
          matterFilter.matterTitle.trim().toLowerCase()
        ) &&
        (mymatter.MatterStatus?.MatterStatusId.toString() ===
          matterFilter.statusId.toString() ||
          matterFilter.statusId === "") &&
        (mymatter.Priority?.PriorityId.toString() ===
          matterFilter.priorityId.toString() ||
          matterFilter.priorityId === "") &&
        (mymatter.MatterType?.MatterTypeId.toString() ===
          matterFilter.typeId.toString() ||
          matterFilter.typeId === "") &&
        (mymatter.MatterStage?.MatterStageId.toString() ===
          matterFilter.stageId.toString() ||
          matterFilter.stageId === "") &&
        (mymatter.Client?.ClientId.toString() ===
          matterFilter.clientId.toString() ||
          matterFilter.clientId === 0) &&
        (mymatter.Manager?.UserId.toString() ===
          matterFilter.responsibleId.toString() ||
          matterFilter.responsibleId === "") &&
        (mymatter.User?.UserId.toString() === matterFilter.userId.toString() ||
          matterFilter.userId === "")
      );
    });
    if (
      filteredMatters.length !== displayedMatters.length &&
      filteredMatters.length === 0
    ) {
      setNoResults(true);
    } else if (
      props.location.state?.matterTask === undefined &&
      filteredMatters.length !== displayedMatters.length &&
      filteredMatters.length === 0
    ) {
      setNoResults(true);
    } else if (props.location.state?.matterTask === undefined &&
      filteredMatters.length === displayedMatters.length &&
      displayedMatters.length === 0) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
    setDisplayedMatters(filteredMatters);
  };
  const orderByTitle = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.MatterTitle.localeCompare(b.MatterTitle)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByResponsible = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.Manager?.FullName.localeCompare(b.Manager?.FullName)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByClient = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.Client?.ClientName.localeCompare(b.Client?.ClientName)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByStatus = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.MatterStatus?.MatterStatusName.localeCompare(b.MatterStatus?.MatterStatusName)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByTotalEffort = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.TotalEffort - b.TotalEffort
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByTotalBilling = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.TotalBilling.localeCompare(b.TotalBilling)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByType = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.MatterType?.MatterTypeName.localeCompare(b.MatterType?.MatterTypeName)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByStage = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.MatterStage?.MatterStageName.localeCompare(b.MatterStage?.MatterStageName)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByPriority = () => {
    const myData: any = allMatters;
    myData.sort((a, b) => {
      return a.Priority?.PriorityName.localeCompare(b.Priority?.PriorityName)
    });
    if (myData[1] === displayedMatters[1]) {
      const reverseData = myData.reverse()
      setDisplayedMatters(reverseData);
    } else {
      setDisplayedMatters(myData);
    }
    setRunSearch(!runSearch);
  };
  const _handleReset = () => {
    setMatterFilter(filterInitialState);
    searchForm.current.reset();
    setRunSearch(!runSearch);
  };
  const submitForm = (e: any) => {
    e.preventDefault();
    _handleSearch();
  };
  // const onChangeFile = (event: React.FormEvent) => {
  //   const files = (e.target as HTMLInputElement).files

  //   if (files && files.length > 0) {
  //     setReadFile(files[0])
  //   }
  // }
  const readCurrentFile = () => {
    const currentFile: any = readfile
    console.log(readfile)
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target!.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      console.log("Data>>>", data);// shows the excel data converted to json
    };
    reader.readAsBinaryString(currentFile);
  }
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      _handleSearch();
    }
  };
  const handlemodalClose = async () => {
    setLoading(true);

    setIsEditShowing(false);
    setIsAddShowing(false);
    setIsNewTaskMatterShowing(false);
    setIsUploadShowing(false);
    await Matters.index()
      .then((response) => {
        setAllMatters(response.data);
        setDisplayedData(response.data);
      })
      .then(() => {
        setRunSearch(!runSearch);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    //setMatterFilter(matterFilter);
  };

  const handleModalCancel = () => {
    setIsEditShowing(false);
    setIsAddShowing(false);
    setIsNewTaskMatterShowing(false);
    setIsUploadShowing(false);
  };

  const setDisplayedData = async (data) => {
    let templateData: any = [];
    for (let temp of data) {
      if (temp.Manager?.ProfileImageURL) {
        temp["imageUrl"] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    setDisplayedMatters(templateData);
  };
  const handelIsEditShowing = (e, id) => {
    seteditId(id);
    setIsEditShowing(true);
  };
  const handelIsUploadShowing = (e, id) => {
    setUploadId(id);
    setIsUploadShowing(true);
  };
  const handelIsNewTaskMatterShowing = (e, id) => {
    setNewTaskMatterId(id);
    setIsNewTaskMatterShowing(true);
  };
  return (
    <div>
      {loading && (
        <div className="dash-page-spinner-container">
          <Col>
            <Spinner
              className="mr-1 dash-page-spinner"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </Col>
        </div>
      )}

      <div className="dash-grid-body">
        <Row className="mb-4">
          <Col md={12} className="d-flex justify-content-center">
            <div className="float-left position-absolute dash-left-3 dash-top-3">
              <ButtonGroup>
                <Button
                  className="btn btn-xs btn-info dash-back-button mb-0 "
                  onClick={() => props.history.goBack()}
                >
                  Back
                </Button>

                <Button
                  className="btn btn-xs btn-info dash-back-button mb-0 " style={{ marginLeft: 30 }}
                >

                  <a style={{ color: 'inherit' }} target="_blank noopener noreferrer" href="https://integration.kim.ai/"> Go to Kim</a>
                </Button>
              </ButtonGroup>
            </div>
            <div className="dash-page-title">
              <h3 className="mb-0 text-center">Matters</h3>
              <div className="dash-line-icon"></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accordion defaultActiveKey="1">
              <Card className="dash-card">
                <Card.Header className="dash-card-header">
                  <span className="dash-card-title">Search Criteria</span>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="float-right"
                  >
                    <FontAwesomeIcon
                      className="dash-card-title"
                      icon={faFilter}
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Card.Body className="dash-card-body">
                  <Accordion.Collapse eventKey="0">
                    <form ref={searchForm} onSubmit={submitForm}>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label"> Title </div>
                        </Col>
                        <Col md={4}>
                          <input
                            type="text"
                            className="form-control dash-form-control"
                            id="title"
                            name="matterTitle"
                            value={matterFilter.matterTitle}
                            onChange={(e) =>
                              setMatterFilter({
                                ...matterFilter,
                                matterTitle: e.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </Col>

                        <Col md={2}>
                          <div className="dash-form-label">Type</div>
                        </Col>
                        <Col md={4}>
                          <select
                            name="typeId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={matterFilter.typeId}
                            onChange={(evt) =>
                              setMatterFilter({
                                ...matterFilter,
                                typeId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {types.map((x: any, i) => (
                              <option key={"x" + i} value={i + 1}>
                                {x.MatterTypeName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Stage</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="stageId"
                            name="stageId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={matterFilter.stageId}
                            onChange={(evt) =>
                              setMatterFilter({
                                ...matterFilter,
                                stageId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {stage.map((x: any, i) => (
                              <option key={"x" + i} value={i + 1}>
                                {x.MatterStageName}
                              </option>
                            ))}
                          </select>
                        </Col>

                        <Col md={2}>
                          <div className="dash-form-label">Priority</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="priorityId"
                            name="priorityId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={matterFilter.priorityId}
                            onChange={(evt) =>
                              setMatterFilter({
                                ...matterFilter,
                                priorityId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {priorities.map((x: any, i) => (
                              <option key={x.PriorityId} value={x.PriorityId}>
                                {x.PriorityName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Status</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="statusId"
                            name="statusId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={matterFilter.statusId}
                            onChange={(evt) =>
                              setMatterFilter({
                                ...matterFilter,
                                statusId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {status.map((x: any, i) => (
                              <option
                                key={x.MatterStatusId}
                                value={x.MatterStatusId}
                              >
                                {x.MatterStatusName}
                              </option>
                            ))}
                          </select>
                        </Col>

                        <Col md={2}>
                          <div className="dash-form-label">Responsible</div>
                        </Col>
                        <Col md={4}>
                          <select
                            name="responsibleId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={matterFilter.responsibleId}
                            onChange={(evt) =>
                              setMatterFilter({
                                ...matterFilter,
                                responsibleId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {users.map((x: any, i) => (
                              <option key={x.UserId} value={x.UserId}>
                                {x.FullName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Client</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="clientId"
                            name="clientId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={matterFilter.clientId}
                            onChange={(evt) =>
                              setMatterFilter({
                                ...matterFilter,
                                clientId: Number(evt.target.value),
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {clients.map((x: any, i) => (
                              <option key={x.ClientId} value={x.ClientId}>
                                {x.ClientName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </form>
                  </Accordion.Collapse>
                  <div className="clearfix">
                    <button
                      type="button"
                      className="btn btn-reset btn-w-m mr-2"
                      onClick={() => setIsAddShowing(true)}
                    >
                      Add Matter
                    </button>
                    {isAddShowing && (
                      <AddMatter
                        ref={addModalRef}
                        isAddShowing={isAddShowing}
                        onModalCloseClick={() => handlemodalClose()}
                        handleModalCancel={handleModalCancel}
                      />
                    )}
                    <div>
                      <button className="mt-2 btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset">
                        <input
                          type="file"
                          id="readfile"
                          ref={fileUploader}
                          onChange={(e) => {
                            setReadFile(e!.target!.files![0]);
                          }}
                        />
                      </button>
                      {readfile && (
                        <button className="mt-2 ml-2 btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset"
                          onClick={(e) => {
                            readCurrentFile()
                          }}>
                          Read File
                        </button>
                      )}

                    </div>

                    <Workbook
                      filename="matters.xlsx"
                      element={
                        <button className="mt-2 btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset">
                          Export Data
                        </button>
                      }
                    >
                      <Workbook.Sheet data={displayedMatters} name="matters">
                        <Workbook.Column label="Title" value="MatterTitle" />
                        <Workbook.Column
                          label="Type"
                          value={(col) =>
                            col.MatterType?.MatterTypeName
                              ? col.MatterType?.MatterTypeName
                              : ""
                          }
                        />
                        <Workbook.Column
                          label="Stage"
                          value={(col) =>
                            col.MatterStage?.MatterStageName
                              ? col.MatterStage?.MatterStageName
                              : ""
                          }
                        />
                        <Workbook.Column
                          label="Priority"
                          value={(col) =>
                            col.Priority?.PriorityName
                              ? col.Priority?.PriorityName
                              : ""
                          }
                        />
                        <Workbook.Column
                          label="Responsible"
                          value={(col) =>
                            col.Manager?.FullName ? col.Manager?.FullName : ""
                          }
                        />
                        <Workbook.Column
                          label="Client"
                          value={(col) =>
                            col.Client?.ClientName ? col.Client?.ClientName : ""
                          }
                        />
                        <Workbook.Column
                          label="Status"
                          value={(col) =>
                            col.MatterStatus?.MatterStatusName
                              ? col.MatterStatus?.MatterStatusName
                              : ""
                          }
                        />
                        <Workbook.Column
                          label="Total Effort(Est.)"
                          value="TotalEffort"
                        />
                        <Workbook.Column
                          label="Total Billing"
                          value="TotalBilling"
                        />
                      </Workbook.Sheet>
                    </Workbook>
                    <ButtonGroup className="float-right">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="dash-secondary-button margin-separator"
                        onClick={() => _handleReset()}
                      >
                        Reset
                      </Button>
                      <Button
                        size="sm"
                        type="button"
                        className="dash-primary-button"
                        onClick={_handleSearch}
                      >
                        Search
                      </Button>
                    </ButtonGroup>
                  </div>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        </Row>
        <div>
          <table className="table table-striped table-center mt-1">

            <thead style={{ overflow: 'visible' }}>
              <tr>
                <th>
                  <Tooltip
                    classes={{
                      tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                      arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement="top"
                    title="Refresh Data"
                  >
                    <button
                      className="dash-show-message-button dash-refresh-button"
                      onClick={handleRefresh}
                    >
                      <FontAwesomeIcon
                        className="dash-card-title"
                        icon={faSync}
                      />
                    </button>
                  </Tooltip>
                </th>
                <th></th>
                <th className='th-click' onClick={() => orderByTitle()}>Title
                  <Tooltip
                    classes={{
                      tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                      arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement='top'
                    title='Click on the header to sort the data'
                  >
                    <i className='fas fa-info-circle ml-2'></i>
                  </Tooltip></th>
                <th className='th-click' onClick={() => orderByType()}>Type</th>
                <th className='th-click' onClick={() => orderByStage()}>Stage</th>
                <th className='th-click' onClick={() => orderByPriority()}>Priority</th>
                <th className='th-click' onClick={() => orderByResponsible()}>Responsible</th>
                <th className='th-click' onClick={() => orderByClient()}> Client</th>
                <th className='th-click' onClick={() => orderByStatus()}>Status</th>
                {/* <th>Resource</th> */}
                <th className='th-click' onClick={() => orderByTotalEffort()}>Total Effort(Est.)</th>
                <th className='th-click' onClick={() => orderByTotalBilling()}>Total Billing</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className='tbody-fx'>
              {displayedMatters &&
                displayedMatters.map((c: any, index) => (
                  <tr key={index}>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                          arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement="top"
                        title="Upload Document"
                      >
                        <button
                          className="dash-show-message-button cursor-pointer"
                          // onClick={() => history.push(`/EditMatter/${c.MatterId}`)}
                          onClick={(event) =>
                            handelIsUploadShowing(event, c.MatterId)
                          }
                        >
                          <i className="fas fa-paperclip"></i>
                        </button>
                      </Tooltip>
                      {isUploadShowing && uploadId === c.MatterId && (
                        <UploadDocumentModal
                          ref={uploadModalRef}
                          isModalShowing={isUploadShowing}
                          itemId={uploadId}
                          onModalCloseClick={() => handlemodalClose()}
                          handleModalCancel={handleModalCancel}
                          itemName={c.MatterTitle}
                          resourceType="Matter"
                        />
                      )}
                    </td>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                          arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement="top"
                        title="Edit"
                      >
                        <button
                          className="dash-show-message-button cursor-pointer"
                          // onClick={() => history.push(`/EditMatter/${c.MatterId}`)}
                          onClick={(event) =>
                            handelIsEditShowing(event, c.MatterId)
                          }
                        >
                          <i className="far fa-edit"></i>
                        </button>
                      </Tooltip>
                      {isEditShowing && editId === c.MatterId && (
                        <AddMatter
                          ref={addModalRef}
                          isAddShowing={isEditShowing}
                          matterId={editId}
                          onModalCloseClick={() => handlemodalClose()}
                          handleModalCancel={handleModalCancel}
                        />
                      )}
                    </td>
                    <td className="dash-word-break-all">{c.MatterTitle}</td>
                    <td>{c.MatterType?.MatterTypeName}</td>
                    <td>{c.MatterStage?.MatterStageName}</td>
                    <td>{c.Priority?.PriorityName}</td>
                    <td>
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        overlay={
                          <Popover
                            id="user-Data"
                            className="dash-user-card-popover"
                          >
                            <UserCard userId={c.Manager?.UserId} />
                          </Popover>
                        }
                      >
                        <div className="d-flex align-items-center  cursor-pointer">
                          <Avatar
                            className={classNames(
                              "dash-grid-avatar",
                              c.Manager?.UserId === currentUserID ? "d-none" : ""
                            )}
                            src={c.imageUrl}
                            alt=""
                          />
                          <span className="align-middle">
                            {c.Manager?.FullName}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td>{c.Client?.ClientName}</td>
                    <td>{c.MatterStatus?.MatterStatusName}</td>
                    {/* <td>
                  {c.Tasks &&
                    c.Tasks.map((task: any, index2) => (
                      <div key={index2}>{task.Resource.ResourceTypeName},</div>
                    ))}
                </td> */}
                    <td>{c.TotalEffort}</td>
                    <td>{c.TotalBilling}</td>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                          arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement="top"
                        title="Tasks"
                      >
                        <div>
                          <Link
                            className="dash-show-message-button cursor-pointer d-block"
                            to={{
                              pathname: "/matterTasks",
                              state: { matterId: c.MatterId },
                            }}
                          >
                            <i className="tasks-color-icon fas fa-thumbtack"></i>
                          </Link>

                        </div>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                          arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement="top"
                        title="New Task"
                      >
                        <div>
                          <button
                            className="dash-show-message-button cursor-pointer"
                            onClick={(event) =>
                              handelIsNewTaskMatterShowing(event, c.MatterId)
                            }
                          >
                            <i className="tasks-color-icon fas fa-plus"></i>
                          </button>
                          {isNewTaskMatterShowing && (
                            <CreateTaskAdmin
                              ref={addModalRef}
                              isAddShowing={isNewTaskMatterShowing}
                              filteredMatterP={newTaskMatterId}
                              onModalCloseClick={() => handlemodalClose()}
                            />
                          )}
                        </div>
                      </Tooltip>
                    </td>
                    <td>
                      <div className="dash-startChat-icon">
                        <ChannelCounterComponent
                          search={{
                            id: c.MatterId,
                            type: "MATTER",
                            showImg: true,
                            object: c,
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                          arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement="top"
                        title="View Documents"
                      >
                        <Link
                          className="dash-show-message-button cursor-pointer d-block"
                          to={{
                            pathname: "/documentCenter",
                            state: { matterId: c.MatterId },
                          }}
                        >
                          <span className="dash-icons-document-center dash-font-size-26"></span>
                        </Link>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {noResults && (
          <h3 className="margin-separator-top-search text-center text-white">
            No results found
          </h3>
        )}
      </div>
    </div>
  );
}
