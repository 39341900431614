import React from 'react';
import './session.css';
import Moment from 'react-moment';
import { ButtonGroup } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Sessions } from 'services/Api';

interface SessionState {
  posts: [];
}
class SessionTwilio extends React.Component<{ history }, SessionState> {
  private params: any;
  private sessionType: string;
  constructor(props: any) {
    super(props);
    this.params = this.props;
    this.sessionType = this.params.type;
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    Sessions.index()
      .then((response) => {
        this.setState({ posts: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  initSession(session: any) {
    return (event: React.MouseEvent) => {
      if (this.params.match.params.type === 'zoom'){
        const win = window.open(session.ZoomLink, '_blank');
        if (win != null) {
          win.focus(); 
        }
      } else {
        this.props.history.push({
          pathname: '/twilioRoom',
          state: { matter: session.Description },
        });
      }
      event.preventDefault();
    };
  }
  render() {
    return (
      <div className="dash-grid-body">
        <div className="mb-1">
          <h4 className="dash-grid-header float-left">
            {this.params.match.params.type === 'zoom' ? 'Zoom Sessions' : 'Twilio Sessions'}
          </h4>

          <ButtonGroup className="float-right">
            <Link
              className="btn btn-xs btn-info margin-separator"
              to="/session"
            >
              Back
            </Link>
          </ButtonGroup>
        </div>
        <table className="table table-striped margin-separator-top3">
          <thead className="text-table">
            <tr>
              <th />
              <th>Title</th>
              <th>Matter</th>
              <th>Date</th>
              <th>Task</th>
            </tr>
          </thead>
          <tbody>
            {this.state.posts.map((session: any, i) => {
              return (
                <tr key={'session-' + i}>
                {session.IsActive && (
                   <React.Fragment>
                   <td>
                     <button
                       className="btn btn-xs btn-info margin-separator"
                       type="button"
                       onClick={this.initSession(session)}
                     >
                       <i className="fas fa-video" /> Start Session
                     </button>
                   </td>
                   <td className='dash-word-break-all'>{session.Title}</td>
                   <td className='dash-word-break-all'>
                     {session.Matter
                       ? session.Matter.MatterTitle
                       : 'No Matter releated'}
                   </td>
                   <td>
                     <Moment format="YYYY/MM/DD">{session.StartTime}</Moment>
                   </td>
                   <td className='dash-word-break-all'>{session.Task ? session.Task.TaskTitle : 'General'}</td>
                   </React.Fragment>
                )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SessionTwilio;
