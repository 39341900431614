import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import {
  sendMessage,
  saveMetadataChannels,
  SetCurrentChannel,
} from '../../../redux/actions/channelActions';
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';
import classNames from 'classnames';
import { Users } from 'services/Api';
import UserCard from '../../profile/userCard';

export default function ChannelComponent() {
  const dispatch = useDispatch();
  let currentUser = useSelector((state: any) => state.userReducer.user) || null;
  if (!currentUser) {
    currentUser = JSON.parse(localStorage.getItem('userData') || '{}');
  }
  const client =
    useSelector((state: any) => state.clientChatReducer.client) || null;

  let channel = useSelector(
    (state: any) => state.channelReducer.currentChannel,
  );
  const [messages, setMessages] = useState({ ...channel });
  let targetRef: any = useRef<any>(null);

  const [message, setMessage] = useState('');
  const [chatWithUser, setChatWithUser] = useState([
    {
      Id: '',
      Name: '',
      image: '',
    },
  ]);
  //colors for chat box
  var colors = [
    '#85A8BA',
    '#FFFD96',
    '#F47C7C',
    '#F4CFDF',
    '#EEE0C9',
    '#9AC8EB',
    '#CCD4BF',
    '#E7CBA9',
    '#EEBAB2',
    '#D0BCAC',
    '#F5E2E4',
    '#E5DB9C',
    '#BEB4C5',
    '#E6A57E',
    '#218B82',
    '#9AD9DB',
    '#E5DBD9',
    '#98D4BB',
    '#EB96AA',
    '#C6C9D0',
    '#D5E4C3',
    '#E5B3BB',
    '#F9968B',
    '#F27348',
    '#B8E0F6',
    '#A4CCE3',
    '#DEC4D6',
    '#7B92AA',
    '#84A6D6',
    '#4382BB',
    '#F7CE76',
    '#E7CBA9',
  ];
  const setChannelData = async () => {
    setChatWithUser([]);
    if (channel) {
      let channelData: any = { ...channel };

      channelData.Messages = [];
      for (let chnl of channel.Messages) {
        if (chnl.messageSender?.ProfileImageURL) {
          chnl.messageSender['imageUrl'] = await Storage.get(
            chnl.messageSender?.ProfileImageURL,
          );
        }
        channelData.Messages.push(chnl);
      }
      setMessages(channelData);
    }
  };
  const setChatUsers = async (channel) => {
    if (channel) {
      let chatWithUsers: any = [];
      for (let chnl of channel.Messages) {
        if (
          chnl &&
          chnl.messageSender?.UserId !== undefined &&
          chnl &&
          chnl.messageSender?.UserId !== currentUser.UserId
        ) {
          chatWithUsers.push({
            Id: chnl.messageSender?.UserId,
            image: chnl.messageSender?.ProfileImageURL
              ? await Storage.get(chnl.messageSender?.ProfileImageURL)
              : '',
            Name: chnl.messageSender?.FullName,
          });
        }
      }
      if (channel.ChannelType === 'TASK') {
        if (
          channel.Task?.User?.UserId !== undefined &&
          channel.Task?.User?.UserId !== currentUser.UserId
        ) {
          chatWithUsers.push({
            Id: channel.Task?.User?.UserId,
            image: channel.Task?.User?.ProfileImageURL
              ? await Storage.get(channel.Task?.User?.ProfileImageURL)
              : '',
            Name: channel.Task?.User?.FullName,
          });
        }
        if (
          channel.Task?.Resource?.UserId !== undefined &&
          channel.Task?.Resource?.UserId !== currentUser.UserId
        ) {
          chatWithUsers.push({
            Id: channel.Task?.Resource?.UserId,
            image: channel.Task?.Resource?.ProfileImageURL
              ? await Storage.get(channel.Task?.Resource?.ProfileImageURL)
              : '',
            Name: channel.Task?.Resource?.FullName,
          });
        }
      } else if (channel.ChannelType === 'MATTER') {
        if (
          channel.Matter?.Manager?.UserId !== undefined &&
          channel.Matter?.Manager?.UserId !== currentUser.UserId
        ) {
          chatWithUsers.push({
            Id: channel.Matter?.Manager?.UserId,
            image: channel.Matter?.Manager?.ProfileImageURL
              ? await Storage.get(channel.Matter?.Manager?.ProfileImageURL)
              : '',
            Name: channel.Matter?.Manager?.FullName,
          });
        }
        if (
          channel.Matter?.User?.UserId !== undefined &&
          channel.Matter?.User?.UserId !== currentUser.UserId
        ) {
          chatWithUsers.push({
            Id: channel.Matter?.User?.UserId,
            image: channel.Matter?.User?.ProfileImageURL
              ? await Storage.get(channel.Matter?.User?.ProfileImageURL)
              : '',
            Name: channel.Matter?.User?.FullName,
          });
        }
      } else if (channel.ChannelType === 'GROUP') {
        if (channel.Group.GroupUser) {
          for (let groupUser of channel.Group.GroupUser) {
            if (
              groupUser.UserId !== undefined &&
              groupUser.UserId !== currentUser.UserId
            ) {
              chatWithUsers.push({
                Id: groupUser.UserId,
                image: groupUser.User.ProfileImageURL
                  ? await Storage.get(groupUser.User.ProfileImageURL)
                  : '',
                Name: groupUser.User.FullName,
              });
            }
          }
        }
        if (channel.Group.Users) {
          for (let groupUser of channel.Group.Users) {
            if (
              groupUser.UserId !== undefined &&
              groupUser.UserId !== currentUser.UserId
            ) {
              chatWithUsers.push({
                Id: groupUser.UserId,
                image: groupUser.ProfileImageURL
                  ? await Storage.get(groupUser.ProfileImageURL)
                  : '',
                Name: groupUser.FullName,
              });
            }
          }
        }
      }

      setChatWithUser(
        chatWithUsers.filter(
          (ele, ind) =>
            ind === chatWithUsers.findIndex((elem) => elem.Id === ele.Id),
        ),
      );
    }
  };
  useEffect(() => {
    if (!channel) {
      const channelObj = JSON.parse(localStorage.getItem('channel') || '{}');
      if (channelObj) {
        dispatch(SetCurrentChannel(channelObj));
        setChannelData();
        setChatUsers(channelObj);
      }
    } else {
      setChatUsers(channel);
    }
    if (channel && channel.Id) {
      localStorage.setItem('channel', JSON.stringify(channel));
      const metadata = {
        lastMessageReadedIndex: channel.Messages.length,
        userId: currentUser.UserId,
        channelId: channel.Id,
        lastMessageSentIndex: channel.Messages.length,
      };
      dispatch(saveMetadataChannels(metadata));
      setChannelData();
      setChatUsers(channel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, currentUser, dispatch]);
  function handleFormSubmit(event: any) {
    event.preventDefault();
    const messageObj = {
      userId: currentUser.UserId,
      message,
      channel: channel,
    };
    let emails: any = [];
    setMessage('');
    dispatch(sendMessage(messageObj, client));
    chatWithUser.forEach((user: any) => {
      Users.single(parseInt(user.Id))
        .then((response) => {
          emails.push(response.data.Email)

        })
        .catch((error) => {
          console.log(error);
        });
    });
    emails.forEach((email: any) => {
      const data: any = {
        service_id: 'gmail',
        template_id: 'message_center',
        user_id: 'user_fX9sCZwfJb7tcYniHvvLe',
        template_params: {
          'from_name': currentUser.FullName,
          'message': message,
          'to_name': email
        }
      };
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    };
    fetch('https://api.emailjs.com/api/v1.0/email/send', requestOptions)
        .then(response => console.log(response))
    });
  }
  return (
    <div>
      <Link
        type="button"
        className="btn btn-xs btn-info mb-3"
        to={{
          pathname: '/messageCenter',
        }}
      >
        <span className="button-title">Back</span>
      </Link>
      {chatWithUser && chatWithUser.length > 0 && (
        <>
          <h5 className="text-white dash-chat-with-title">Chat With:</h5>
          <Row className="text-white mb-4">
            {chatWithUser.map(function (chatUser: any, i: any) {
              return (
                <Col key={'user' + i} md="auto">
                  <div>
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover
                          id="user-Data"
                          className="dash-user-card-popover"
                        >
                          <UserCard userId={chatUser.Id} />
                        </Popover>
                      }
                    >
                      <div>
                        <Avatar
                          className="dash-message-avatar cursor-pointer mr-1 float-left"
                          src={chatUser.image}
                          alt=""
                        />
                        <span className="d-flex pt-2 dash-chat-with-user">
                          {' '}
                          {chatUser.Name}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Col>
              );
            })}
          </Row>
        </>
      )}
      <div className="dash-chat-container py-5">
        {messages &&
          messages.Messages?.map(function (message: any, i: any) {
            var random_color =
              colors[Math.floor(Math.random() * colors.length)];

            return (
              <React.Fragment key={'chat-' + i}>
                {currentUser.UserId !== message.messageSender?.UserId && (
                  <Row key={'chat-' + i} className={classNames(' mb-2 mx-2')}>
                    <Col
                      md={5}
                      key={'session-' + i}
                      className={classNames(' mb-4')}
                    >
                      {message.messageSender.FullName && (
                        <span key={'message-' + i} className="author"></span>
                      )}
                      <Row>
                        <Col md={2} className="align-self-end">
                          <label className="mb-0" ref={targetRef}>
                            <Avatar
                              className="dash-message-avatar dash-message-avatar_chat mx-auto mb-1"
                              src={message.messageSender.imageUrl}
                              alt=""
                            />
                          </label>
                        </Col>
                        <Col
                          className="dash-chat-body"
                          style={{
                            borderLeft: '5px solid',
                            borderLeftColor: random_color,
                          }}
                        >
                          <Row>
                            <Col>
                              <span className=" dash-message-date">
                                {message.messageSender.FullName}
                              </span>
                            </Col>
                            <Col>
                              <span className="float-right dash-message-date">
                                {moment(message.createdAt).format(
                                  'MM-DD-YYYY hh:mm:ss',
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <span>{message.message}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {currentUser.UserId === message.messageSender?.UserId && (
                  <Row key={'chat-' + i} className={classNames(' mb-2 mx-2')}>
                    <Col
                      md={{ span: 5, offset: 7 }}
                      key={'session-' + i}
                      className={classNames(' mb-4')}
                    >
                      {message.messageSender.FullName && (
                        <span key={'message-' + i} className="author"></span>
                      )}
                      <Row>
                        <Col
                          className={classNames(
                            'dash-chat-body mb-4',
                            currentUser.UserId === message.messageSender?.UserId
                              ? 'dash-chat-body_currentUser'
                              : '',
                          )}
                          style={{
                            borderRight: '5px solid',
                            borderRightColor: random_color,
                          }}
                        >
                          <Row>
                            <Col className="text-left">
                              <span className="dash-message-date">
                                {message.messageSender.FullName}
                              </span>
                            </Col>
                            <Col>
                              <span className="float-right dash-message-date">
                                {moment(message.createdAt).format(
                                  'MM-DD-YYYY hh:mm:ss',
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-right">
                              <span>{message.message}</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="align-self-center" md={2}>
                          <label ref={targetRef}>
                            <Avatar
                              className="dash-message-avatar dash-message-avatar_chat mx-auto"
                              src={message.messageSender.imageUrl}
                              alt=""
                            />
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            );
          })}
      </div>
      <form className="MessageForm">
        <Row>
          <Col md={11} className="pr-0">
            <input
              type="text"
              className="form-control dash-form-control"
              id="message"
              name="message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Col>
          <Col md={1}>
            <button className="dash-primary-button" onClick={handleFormSubmit}>
              Submit
            </button>
          </Col>
        </Row>

        <div className="button-container">
          <div></div>
        </div>
      </form>
    </div>
  );
}
