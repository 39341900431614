import React, { useState, useRef } from "react";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Card,
  Spinner,
  Accordion,
} from "react-bootstrap";
import "../../assets/sharedDesign/sharedDesign.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSync } from "@fortawesome/free-solid-svg-icons";
import NewFirm from "./newFirm";
//import EditFirmUser from "./editFirmUser";
import { Link } from "react-router-dom";
import { Users, Firms, States, LawTypes } from "services/Api";
import Tooltip from "@material-ui/core/Tooltip";
import Workbook from "react-excel-workbook";

export default function FirmUsers(props: any) {
  const filterInitialState = {
    FullName: "",
    StateId: "",
    LawTypeId: "",
    UserId: "",
  };

  const searchForm: any = useRef<HTMLFormElement>(null);
  let addModalRef: any = useRef<any>(null);
  const [firms, setFirms] = useState([]);
  const [lawTypes, setLawTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [users, setUsers] = useState([]);
  const [displayedFirms, setDisplayedFirms] = useState([]);
  const [firmFilter, setFirmFilter] = useState(filterInitialState);
  const [isEditShowing, setIsEditShowing] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [isAddShowing, setIsAddShowing] = useState(false);
  const [editId, seteditId] = useState(false);
  const [runSearch, setRunSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === "number" ? parseInt(value) : value;
    setFirmFilter({
      ...firmFilter,
      [name]: value,
    });
  };

  React.useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      let userIdParam = props.location.state?.userId;

      // const userObj: any = await Users.index();
      // setUsers(userObj.data);

      await Firms.index()
        .then((response) => {
          setFirms(response.data);
          setDisplayedFirms(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      await States.index()
        .then((response) => {
          setStates(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      await LawTypes.index()
        .then((response) => {
          setLawTypes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      await Users.index()
        .then((response) => {
          setUsers(response.data);
          if (userIdParam > 0) {
            setFirmFilter({
              ...firmFilter,
              UserId: userIdParam,
            });
            setRunSearch(!runSearch);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFirms]);

  const _handleSearch = () => {
    const filteredFirms = firms.filter((firm: any) => {
      return (
        firm.FirmName.toLowerCase().includes(
          firmFilter.FullName.toLowerCase()
        ) &&
        (firm.LawType?.LawTypeId.toString() ===
          firmFilter.LawTypeId.toString() ||
          firmFilter.LawTypeId === "") &&
        (firm.State?.StateId.toString() === firmFilter.StateId.toString() ||
          firmFilter.StateId === "") &&
        (firm.UserFirm?.find(
          (user) => user.UserId === Number.parseInt(firmFilter.UserId)
        ) ||
          firmFilter.UserId === "")
      );
    });
    if (
      filteredFirms.length !== displayedFirms.length &&
      filteredFirms.length === 0
    ) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
    setDisplayedFirms(filteredFirms);
  };

  React.useEffect(() => {
    _handleSearch();
    console.log(displayedFirms, 'firms');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runSearch]);

  const _handleReset = async () => {
    await setFirmFilter(filterInitialState);
    setRunSearch(!runSearch);
  };
  // setFirmFilter([...firmFilter, { FirmName: null, roleId: null}]);
  // setFirmFilter((firmFilter: any) => filterInitialState);

  const submitForm = (e: any) => {
    e.preventDefault();
    _handleSearch();
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      _handleSearch();
    }
  };

  const handlemodalClose = async () => {
    setLoading(true);
    setIsEditShowing(false);
    setIsAddShowing(false);

    await Firms.index()
      .then((response) => {
        setFirms(response.data);
        setDisplayedFirms(response.data);
      })
      .then(() => {
        setRunSearch(!runSearch);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    //setMatterFilter(matterFilter);
  };

  const orderByFirm = () => {
    const myData: any = firms;
    myData.sort((a, b) => {
      return a.FirmName.localeCompare(b.FirmName)
    });
    if (myData[1] === displayedFirms[1]) {
      const reverseData = myData.reverse()
      setDisplayedFirms(reverseData);
    } else {
      setDisplayedFirms(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByCity = () => {
    const myData: any = firms;
    myData.sort((a, b) => {
      return a.City.localeCompare(b.City)
    });
    if (myData[1] === displayedFirms[1]) {
      const reverseData = myData.reverse()
      setDisplayedFirms(reverseData);
    } else {
      setDisplayedFirms(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByLawType = () => {
    const myData: any = firms;
    myData.sort((a, b) => {
      return a.LawType?.LawTypeName.localeCompare(b.LawType?.LawTypeName)
    });
    if (myData[1] === displayedFirms[1]) {
      const reverseData = myData.reverse()
      setDisplayedFirms(reverseData);
    } else {
      setDisplayedFirms(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByState = () => {
    const myData: any = firms;
    myData.sort((a, b) => {
      return a.State?.StateName.localeCompare(b.State?.StateName)
    });
    if (myData[1] === displayedFirms[1]) {
      const reverseData = myData.reverse()
      setDisplayedFirms(reverseData);
    } else {
      setDisplayedFirms(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByAdress = () => {
    const myData: any = firms;
    myData.sort((a, b) => {
      return a.AddressLine1.localeCompare(b.AddressLine1)
    });
    if (myData[1] === displayedFirms[1]) {
      const reverseData = myData.reverse()
      setDisplayedFirms(reverseData);
    } else {
      setDisplayedFirms(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByPhone = () => {
    const myData: any = firms;
    myData.sort((a, b) => {
      return a.Phone.localeCompare(b.Phone)
    });
    if (myData[1] === displayedFirms[1]) {
      const reverseData = myData.reverse()
      setDisplayedFirms(reverseData);
    } else {
      setDisplayedFirms(myData);
    }
    setRunSearch(!runSearch);
  };

  const handelIsEditShowing = (e, id) => {
    seteditId(id);
    setIsEditShowing(true);
  };
  const handleRefresh = async () => {
    setLoading(true);
    await Firms.index()
      .then((response) => {
        setFirms(response.data);
        setRunSearch(!runSearch);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      {loading && (
        <div className="dash-page-spinner-container">
          <Col>
            <Spinner
              className="mr-1 dash-page-spinner"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </Col>
        </div>
      )}
      <div className="dash-grid-body">
        <Row className="mb-4">
          <Col md={12} className="d-flex justify-content-center">
            <div className="float-left position-absolute dash-left-3 dash-top-3">
              <ButtonGroup>
                <Button
                  className="btn btn-xs btn-info dash-back-button mb-0 "
                  onClick={() => props.history.goBack()}
                >
                  Back
                </Button>
              </ButtonGroup>
            </div>
            <div className="dash-page-title">
              <h3 className="mb-0 text-center">Firms</h3>
              <div className="dash-line-icon"></div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Accordion>
              <Card className="dash-card">
                <Card.Header className="dash-card-header">
                  <span className="dash-card-title">Search Criteria</span>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="float-right"
                  >
                    <FontAwesomeIcon
                      className="dash-card-title"
                      icon={faFilter}
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Card.Body className="dash-card-body">
                  <Accordion.Collapse eventKey="0">
                    <form ref={searchForm} onSubmit={submitForm}>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Full Name</div>
                        </Col>
                        <Col md={4}>
                          <input
                            type="text"
                            className="form-control dash-form-control"
                            id="FullName"
                            name="FullName"
                            value={firmFilter.FullName}
                            onChange={changeHandler}
                            onKeyDown={_handleKeyDown}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="dash-form-label">State</div>
                        </Col>
                        <Col md={4}>
                          <select
                            name="StateId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={firmFilter.StateId}
                            onChange={changeHandler}
                            onKeyDown={_handleKeyDown}
                          >
                            <option value="">All </option>
                            {states.map((x: any, i) => (
                              <option value={x.StateId} key={x.StateId}>
                                {x.StateName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Law Type</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="LawTypeId"
                            name="LawTypeId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={firmFilter.LawTypeId}
                            onChange={changeHandler}
                            onKeyDown={_handleKeyDown}
                          >
                            <option value="">All </option>
                            {lawTypes.map((x: any, i) => (
                              <option value={x.LawTypeId} key={x.LawTypeId}>
                                {x.LawTypeName}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col md={2}>
                          <div className="dash-form-label">Users</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="UserId"
                            name="UserId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={firmFilter.UserId}
                            onChange={changeHandler}
                            onKeyDown={_handleKeyDown}
                          >
                            <option value="">All </option>
                            {users.map((x: any, i) => (
                              <option value={x.UserId} key={x.UserId}>
                                {x.FullName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </form>
                  </Accordion.Collapse>
                  <div className="clearfix">
                    <button
                      type="button"
                      className="btn btn-reset btn-w-m mr-2"
                      onClick={() => setIsAddShowing(true)}
                    >
                      Add Firm
                    </button>
                    {isAddShowing && (
                      <NewFirm
                        ref={addModalRef}
                        isAddShowing={isAddShowing}
                        onModalCloseClick={() => handlemodalClose()}
                      />
                    )}
                    <Workbook
                      filename="firms.xlsx"
                      element={
                        <button className="btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset">
                          Export Data
                        </button>
                      }
                    >
                      <Workbook.Sheet data={displayedFirms} name="Firms">
                        <Workbook.Column label="Firm Name" value="FirmName" />
                        <Workbook.Column label="City" value="City" />
                        <Workbook.Column
                          label="Law Type"
                          value={(col) =>
                            col.LawType?.LawTypeName
                              ? col.LawType?.LawTypeName
                              : ""
                          }
                        />
                        <Workbook.Column
                          label="State"
                          value={(col) =>
                            col.State?.StateName ? col.State?.StateName : ""
                          }
                        />
                        <Workbook.Column label="Address" value="AddressLine1" />
                        <Workbook.Column label="Phone" value="Phone" />
                      </Workbook.Sheet>
                    </Workbook>
                    <ButtonGroup className="float-right">
                      <Button
                        type="reset"
                        //variant="outline-secondary"
                        className="dash-secondary-button mr-2"
                        // size="sm"
                        onClick={() => _handleReset()}
                      >
                        Reset
                      </Button>
                      <Button
                        size="sm"
                        type="submit"
                        className="dash-primary-button"
                        onClick={_handleSearch}
                      >
                        Search
                      </Button>
                    </ButtonGroup>
                  </div>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        </Row>

        {/* <table-client [(ngModel)]="PagedRows" [dataSource]="FilteredRows" [gridOptions]="GridOptions">
     <div class="table-header clearfix">
       <export-csv-client [options]="ExportCsvOptions"></export-csv-client>
       <button class="btn btn-xs  btn-search" (click)="InviteUser()">
         <i class="fa fa-user-plus"> Invite Member</i>
       </button>
    
       <Pagination></Pagination>
     </div> */}
        <table className="table table-striped table-center mt-1">
          <thead>
            <tr>
              <th className="width-50">
                {" "}
                <Tooltip
                  classes={{
                    tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                    arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                  }}
                  arrow
                  placement="top"
                  title="Refresh Data"
                >
                  <button
                    className="dash-show-message-button dash-refresh-button"
                    onClick={handleRefresh}
                  >
                    <FontAwesomeIcon
                      className="dash-card-title"
                      icon={faSync}
                    />
                  </button>
                </Tooltip>
              </th>
              <th className="width-200 th-click" onClick={() => orderByFirm()}>Firm Name
                <Tooltip
                  classes={{
                    tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                    arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                  }}
                  arrow
                  placement='top'
                  title='Click on the header to sort the data'
                >
                  <i className='fas fa-info-circle ml-2'></i>
                </Tooltip></th>
              <th className="width-200 th-click" onClick={() => orderByCity()}>City</th>
              <th className="width-250 th-click" onClick={() => orderByLawType()}>Law Type</th>
              <th className="width-300 th-click" onClick={() => orderByState()}>State</th>
              <th className="width-300 th-click" onClick={() => orderByAdress()}>Address</th>
              <th className="width-300 th-click" onClick={() => orderByPhone()}>Phone</th>
              <th className="width-50 " />
              <th className="width-50" />
            </tr>
          </thead>
          <tbody>
            {displayedFirms.map((f: any, index) => (
              <tr key={'session-' + index}>
                <td>
                  <Tooltip
                    classes={{
                      tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                      arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement="top"
                    title="Edit"
                  >
                    <button
                      className="dash-show-message-button cursor-pointer"
                      onClick={(event) =>
                        handelIsEditShowing(event, f.FirmId)
                      }
                    >
                      <i className="far fa-edit"></i>
                    </button>
                  </Tooltip>
                  {isEditShowing && editId === f.FirmId && (
                    <NewFirm
                      ref={addModalRef}
                      isAddShowing={isEditShowing}
                      onModalCloseClick={() => handlemodalClose()}
                      firmId={editId}
                    />
                  )}
                </td>
                <td className="dash-word-break-all">{f.FirmName}</td>
                <td className="left">{f.City}</td>
                <td>{f.LawType?.LawTypeName}</td>
                <td>{f.State?.StateName}</td>
                <td>{f.AddressLine1}</td>
                <td>{f.Phone}</td>
                <td>
                  <Tooltip
                    classes={{
                      tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                      arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement="top"
                    title="Tasks"
                  >
                    <div>
                      <Link
                        className="dash-show-message-button cursor-pointer d-block"
                        to={{
                          pathname: "/Tasks",
                          state: { firmId: f.FirmId },
                        }}
                      >
                        <span className="dash-icons-task dash-font-size-22"></span>
                      </Link>
                    </div>
                  </Tooltip>
                </td>
                <td>
                  <Tooltip
                    classes={{
                      tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                      arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement="top"
                    title="Users"
                  >
                    <div>
                      <Link
                        className="dash-show-message-button cursor-pointer d-block"
                        to={{
                          pathname: "/users",
                          state: { firmId: f.FirmId },
                        }}
                      >
                        <span className="dash-icons-users dash-font-size-22"></span>
                      </Link>
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {noResults && (
          <h3 className="margin-separator-top-search text-center text-white">
            No results found
          </h3>
        )}
      </div>
    </div>
  );
}
