import React from "react";
import {
  saveMetadataChannels,
} from '../../../redux/actions/channelActions';
import {
  Row,
  Col,
  Accordion,
  Card,
  Button,
  ButtonGroup,
  Spinner,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSync, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
// import history from "../../../services/history";
import { Avatar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import UserCard from "../../profile/userCard";
import { SetCurrentChannel } from "redux/actions/channelActions";
import { buildChannelObj } from "shared/utils";
import { Users, Matters, Tasks, Priorities } from "services/Api";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import Moment from "react-moment";
import moment from "moment";



export default function MessageCenter({ history }) {
  const dispatch = useDispatch();
  const [matters, setMatters] = React.useState([]);
  const [priorities, setPriorities] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [tasks, setTasks] = React.useState([]);
  const channels = useSelector((state: any) => state.channelReducer.channels);
  const metadataChannels = useSelector(
    (state: any) => state.channelMetadataReducer.channelsMetadata
  );
  const currentUser = useSelector((state: any) => state.userReducer.user);

  const [filteredChannels, setFilteredChannels] = React.useState([]);
  const [initialized, setInitialized] = React.useState<any>(false);

  if (filteredChannels.length === 0 && channels.length > 0 && !initialized) {
    setFilteredChannels([...orderByLastMessage(channels)]);
    setInitialized(true);
  }
  const user = useSelector((state: any) => state.userReducer.user) || null;
  const [enabledButtonMatter, setEnabledButtonMatter] =
    React.useState<any>(false);
  const [enabledButtonTask, setEnabledButtonTask] = React.useState<any>(false);
  const [enabledButtonUser, setEnabledButtonUser] = React.useState<any>(false);
  const [matterSelected, setValueMatter] = React.useState<any>(null);
  const [taskSelected, setValueTask] = React.useState<any>(null);  const [userSelected, setValueUser] = React.useState<any>(null);
  const [prioritySelected, setValuePriority] = React.useState<any>(null);
  const [titleSelected, setTitleSelected] = React.useState<any>(null);
  const [noResults, setNoResults] = React.useState<any>(false);
  const [statusSelected, setStatusSelected] = React.useState<any>(null);

  const [loading, setLoading] = React.useState<any>(true);

  React.useEffect(() => {
    async function setChat() {
      setLoading(true);
      setEnabledButtonMatter(false);
      setLoading(false);
    }
    setChat();

    async function fetchData() {
      const userObj: any = await Users.index();
      setUsers(userObj.data);

      await Matters.index()
        .then((response) => {
          setMatters(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      await Tasks.index()
        .then((response) => {
          setTasks(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get priorities
      await Priorities.index()
        .then((response) => {
          setPriorities(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get status
      await Tasks.status()
        .then((response) => {
          setStatus(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeMatter = (e) => {
    let myvalue = e.target.value;
    const matter = matters.find(
      (currentMatter: any) => currentMatter.MatterId === Number(e.target.value)
    );
    setValueMatter(matter);

    if (e.target.value !== "null") {
      setEnabledButtonMatter(true);
    }
    if (myvalue === "All") {
      setEnabledButtonMatter(false);
    }
  };

  const onChangeTask = (e) => {
    let myvalue = e.target.value;
    const task = tasks.find(
      (currentTask: any) => currentTask.TaskId === Number(e.target.value)
    );
    setValueTask(task);

    if (e.target.value !== "null") {
      setEnabledButtonTask(true);
    }
    if (myvalue === "All") {
      setEnabledButtonTask(false);
    }
  };

  const onChangeUser = (e) => {
    let myvalue = e.target.value;
    const user = users.find(
      (currentUser: any) => currentUser.UserId === Number(e.target.value)
    );
    setValueUser(user);
    if (e.target.value !== "null") {
      setEnabledButtonUser(true);
    }
    if (myvalue === "All") {
      setEnabledButtonUser(false);
    }
  };
  const onReset = () => {
    setNoResults(false);
    setTitleSelected("");
    setValueMatter({ MatterId: "" });
    setValuePriority({ PriorityId: "" });
    setValueTask({ TaskId: "" });
    setValueUser({ UserId: "" });
    setStatusSelected({ TaskStatusId: "" });
    setFilteredChannels([...orderByLastMessage(channels)]);
  };
  const onChangePriority = (e) => {
    const priority = priorities.find(
      (currentPriority: any) =>
        currentPriority.PriorityId === Number(e.target.value)
    );
    setValuePriority(priority);
  };

  const onChangeStatus = (e) => {
    const currentStatus = status.find(
      (cStatus: any) => cStatus.TaskStatusId === Number(e.target.value)
    );
    setStatusSelected(currentStatus);
  };

  const loadMessageCenterData = (Type) => {
    let channel;
    if (Type === "MATTER") {
      channel = channels.find(
        (currentChannel) =>
          currentChannel.Matter &&
          currentChannel.Matter.MatterId === matterSelected.MatterId
      );
      if (!channel) {
        channel = buildChannelObj({
          ChannelType: Type,
          Matter: matterSelected,
        });
      }
    } else if (Type === "TASK") {
      channel = channels.find(
        (currentChannel) =>
          currentChannel.Task &&
          currentChannel.Task.TaskId === taskSelected.TaskId
      );
      if (!channel) {
        channel = buildChannelObj({ ChannelType: Type, Task: taskSelected });
      }
    } else if (Type === "GROUP") {
      const Users = [user, userSelected];
      const Group = {
        Users,
      };
      const channelObj = buildChannelObj({ ChannelType: Type, Group });
      channel = channels.find(
        (currentChannel) =>
          currentChannel.Group &&
          currentChannel.Group.GroupName === channelObj.Group.GroupName
      );
      if (!channel) {
        channel = channelObj;
      }
    }

    dispatch(SetCurrentChannel(channel));
    history.push(`/inChannel`);
  };

  function loadChannelForMessageCenter(channel) {
    dispatch(SetCurrentChannel(channel));
    history.push(`/inChannel`);
  }

  function getStyle(channel) {
    const currentMetadata: any = metadataChannels.find(
      (metadataChannel: any) =>
        metadataChannel.Channel && metadataChannel.Channel.Id === channel.Id
    );
    if (
      currentMetadata &&
      currentMetadata.lastMessageReadIndex === channel.Messages.length
    ) {
      return {};
    } else {
      return { borderColor: "red", borderWidth: 5, borderStyle: "outset" };
    }
  }

  const filterChannels = () => {
    let FilteredArray = channels;
    if (matterSelected) {
      FilteredArray = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Matter &&
          currentChannel.Matter.MatterId === matterSelected.MatterId
      );
    }
    if (taskSelected) {
      const FilteredArrayMatterTask = FilteredArray.filter((currentChannel) => {
        if (currentChannel.Matter) {
          const task = currentChannel.Matter.MatterTask.find(
            (task) => task.Task.TaskId === taskSelected.TaskId
          );
          if (task) {
            return currentChannel;
          }
        }
        return null;
      });

      const FilteredArrayTask = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Task &&
          currentChannel.Task.TaskId === taskSelected.TaskId
      );

      FilteredArray = FilteredArrayTask.concat(FilteredArrayMatterTask);
    }

    if (userSelected) {
      const FilteredArrayMatterTask = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Matter &&
          currentChannel.Matter.Manager &&
          currentChannel.Matter.Manager.UserId === userSelected.UserId
      );

      const FilteredArrayTask = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Task &&
          currentChannel.Task.Resource &&
          currentChannel.Task.Resource.UserId === userSelected.UserId
      );

      FilteredArray = FilteredArrayTask.concat(FilteredArrayMatterTask);
    }

    if (prioritySelected) {
      const FilteredArrayMatter = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Matter &&
          currentChannel.Matter.Priority &&
          currentChannel.Matter.Priority.PriorityId ===
          prioritySelected.PriorityId
      );

      const FilteredArrayMatterTask = FilteredArray.filter((currentChannel) => {
        if (currentChannel.Matter) {
          const task = currentChannel.Matter.MatterTask.find(
            (task) =>
              task.Task.Priority.PriorityId === prioritySelected.PriorityId
          );
          if (task) {
            return currentChannel;
          }
        }
        return null;
      });

      const FilteredArrayTask = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Task &&
          currentChannel.Task.Priority &&
          currentChannel.Task.Priority.PriorityId ===
          prioritySelected.PriorityId
      );

      FilteredArray = FilteredArrayTask.concat(FilteredArrayMatterTask).concat(
        FilteredArrayMatter
      );
    }

    if (statusSelected) {
      const FilteredArrayMatterTask = FilteredArray.filter((currentChannel) => {
        if (currentChannel.Matter) {
          const task = currentChannel.Matter.MatterTask.find(
            (task) =>
              task.Task.TaskStatus.TaskStatusId === statusSelected.TaskStatusId
          );
          if (task) {
            return currentChannel;
          }
        }
        return null;
      });

      const FilteredArrayTask = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Task &&
          currentChannel.Task.TaskStatus &&
          currentChannel.Task.TaskStatus.TaskStatusId ===
          statusSelected.TaskStatusId
      );

      FilteredArray = FilteredArrayTask.concat(FilteredArrayMatterTask);
    }

    if (titleSelected) {
      const FilteredArrayMatterTask = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Matter &&
          currentChannel.Matter.MatterTitle.toLowerCase().match(
            titleSelected.toLowerCase()
          )
      );

      const FilteredArrayTask = FilteredArray.filter(
        (currentChannel) =>
          currentChannel.Task &&
          currentChannel.Task.TaskTitle.toLowerCase().match(
            titleSelected.toLowerCase()
          )
      );

      FilteredArray = FilteredArrayTask.concat(FilteredArrayMatterTask);
    }
    setNoResults(FilteredArray.length === 0);
    setFilteredChannels([...orderByLastMessage(FilteredArray)]);
  };
  const handleRefresh = async () => {
    setLoading(true);
    setFilteredChannels([...orderByLastMessage(channels)]);
    setLoading(false);
  };
  const handleRead = async () => {
    setLoading(true)
    function readAllMessages() {
      metadataChannels.forEach((myChannel: any) => {
        localStorage.setItem('channel', JSON.stringify(myChannel));
        if (myChannel.Channel && myChannel.Channel.Id) {

          const metadata = {
            lastMessageReadedIndex: myChannel.Channel.Messages.length,
            userId: currentUser.UserId,
            channelId: myChannel.Channel.Id,
            lastMessageSentIndex: myChannel.Channel.Messages.length 
          };
          dispatch(saveMetadataChannels(metadata));
        }
      });
    }
    readAllMessages();
    setTimeout(() => {window.location.reload()}, 8000);
    
  };
  if (loading) {
    return (
      <div className="dash-page-spinner-container">
        <Col>
          <Spinner
            className="mr-1 dash-page-spinner"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </Col>
      </div>
    );
  } else {
    return (
      <div className="dash-grid-body">
        <Row className="mb-4">
          <Col md={12} className="d-flex justify-content-center">
            <div className="float-left position-absolute dash-left-3 dash-top-3">
              <ButtonGroup>
                <Button
                  className="btn btn-xs btn-info dash-back-button mb-0 "
                  onClick={() => history.goBack()}
                >
                  Back
                </Button>
              </ButtonGroup>
            </div>
            <div className="dash-page-title">
              <h3 className="mb-0 text-center">Message Center</h3>
              <div className="dash-line-icon"></div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="mb-4">
            <Accordion defaultActiveKey="0">
              <Card className="dash-card">
                <Card.Header className="dash-card-header">
                  <span className="dash-card-title">Search Criteria</span>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="float-right"
                  >
                    <FontAwesomeIcon
                      className="dash-card-title"
                      icon={faFilter}
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Card.Body className="dash-card-body">
                  <Accordion.Collapse eventKey="0">
                    <form className="m-3">
                      <Row className="form-group">
                        <Col md="6">
                          <Row>
                            <Col md="4" className="text-right">
                              <label className="dash-form-label">Matter</label>
                            </Col>
                            <Col md="7" className="pr-0">
                              <select
                                id="matters"
                                name="matters"
                                className="form-control dash-form-control"
                                placeholder="All"
                                value={matterSelected?.MatterId}
                                onChange={onChangeMatter}
                              >
                                <option value="">All</option>
                                {matters.map((x: any, i) => (
                                  <option key={"x" + i} value={x.MatterId}>
                                    {x.MatterTitle}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md={1} className="text-right">
                              <Tooltip
                                classes={{
                                  tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                                  arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                                }}
                                arrow
                                placement="top"
                                title="Start Chat"
                              >
                                <div
                                  className={
                                    !enabledButtonMatter
                                      ? "dash-disabled-wrapper w-40"
                                      : ""
                                  }
                                >
                                  <button
                                    className={classNames(
                                      "dash-new-chat-icon",
                                      !enabledButtonMatter
                                        ? "dash-disabled"
                                        : ""
                                    )}
                                    onClick={() =>
                                      loadMessageCenterData("MATTER")
                                    }
                                  >
                                    <i className="fas fa-comments"></i>
                                  </button>
                                </div>
                              </Tooltip>
                            </Col>
                          </Row>
                        </Col>

                        <Col md="6">
                          <Row>
                            <Col md="4" className="text-right">
                              <label className="dash-form-label">Tasks</label>
                            </Col>
                            <Col md="7" className="pr-0">
                              <select
                                id="tasks"
                                name="tasks"
                                className="form-control dash-form-control"
                                placeholder="All"
                                value={taskSelected?.TaskId}
                                onChange={onChangeTask}
                              >
                                <option value="">All</option>
                                {tasks.map((x: any, i) => (
                                  <option key={"x" + i} value={x.TaskId}>
                                    {x.TaskTitle}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md={1} className="text-right">
                              <Tooltip
                                classes={{
                                  tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                                  arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                                }}
                                arrow
                                placement="top"
                                title="Start Chat"
                              >
                                <div
                                  className={
                                    !enabledButtonTask
                                      ? "dash-disabled-wrapper w-40"
                                      : ""
                                  }
                                >
                                  <button
                                    className={classNames(
                                      "dash-new-chat-icon",
                                      !enabledButtonTask ? "dash-disabled" : ""
                                    )}
                                    onClick={() =>
                                      loadMessageCenterData("TASK")
                                    }
                                  >
                                    <i className="fas fa-comments"></i>
                                  </button>
                                </div>
                              </Tooltip>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md="6">
                          <Row>
                            <Col md="4" className="text-right">
                              <label className="dash-form-label">Title</label>
                            </Col>
                            <Col md="8">
                              <input
                                type="text"
                                className="form-control dash-form-control"
                                value={titleSelected}
                                onChange={(event) =>
                                  setTitleSelected(event.target.value)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row>
                            <Col md="4" className="text-right">
                              <label className="dash-form-label">Status</label>
                            </Col>
                            <Col md="8">
                              <select
                                id="status"
                                name="status"
                                className="form-control dash-form-control"
                                placeholder="All"
                                onChange={onChangeStatus}
                                value={statusSelected?.TaskStatusId}
                              >
                                <option value="">All</option>
                                {status.map((x: any, i) => (
                                  <option key={"x" + i} value={x.TaskStatusId}>
                                    {x.TaskStatusName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md="6">
                          <Row>
                            <Col md="4" className="text-right">
                              <label className="dash-form-label">
                                Priority
                              </label>
                            </Col>
                            <Col md="8">
                              <select
                                id="priorities"
                                name="priorities"
                                className="form-control dash-form-control"
                                placeholder="All"
                                onChange={onChangePriority}
                                value={prioritySelected?.PriorityId}
                              >
                                <option value="">All</option>
                                {priorities.map((x: any, i) => (
                                  <option key={"x" + i} value={x.PriorityId}>
                                    {x.PriorityName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row>
                            <Col md="4" className="text-right">
                              <label className="dash-form-label">User</label>
                            </Col>
                            <Col md="7" className="pr-0">
                              <select
                                id="users"
                                name="users"
                                className="form-control dash-form-control"
                                placeholder="All"
                                onChange={onChangeUser}
                                value={userSelected?.UserId}
                              >
                                <option>All</option>
                                {users.map((x: any, i) => (
                                  <option key={"x" + i} value={x.UserId}>
                                    {x.FullName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md={1} className="text-right">
                              <Tooltip
                                classes={{
                                  tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                                  arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                                }}
                                arrow
                                placement="top"
                                title="Start Chat"
                              >
                                <div
                                  className={
                                    !enabledButtonUser
                                      ? "dash-disabled-wrapper w-40"
                                      : ""
                                  }
                                >
                                  <button
                                    className={classNames(
                                      "dash-new-chat-icon",
                                      !enabledButtonUser ? "dash-disabled" : ""
                                    )}
                                    onClick={() =>
                                      loadMessageCenterData("GROUP")
                                    }
                                  >
                                    <i className="fas fa-comments"></i>
                                  </button>
                                </div>
                              </Tooltip>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  </Accordion.Collapse>

                  <div className="clearfix">
                    <h5 style={{ color: "#ffffff" }}>
                      To start a new chat, click on the icon{" "}
                      <i className="fas fa-comments"></i> next to matter, task
                      or user.
                    </h5>
                    <div className="float-right m-2">
                      <button
                        type="button"
                        className="dash-secondary-button  mr-2"
                        onClick={onReset}
                      >
                        Reset
                      </button>
                      <button
                        onClick={filterChannels}
                        type="button"
                        className="dash-primary-button"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tooltip
              classes={{
                tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
              }}
              arrow
              placement="top"
              title="Mark all unread messages as read"
            >
              <button
                className="dash-show-message-button mt-1 mb-4 dash-refresh-button"
                onClick={handleRead}
              >
                <FontAwesomeIcon className="dash-card-title" icon={faCheckDouble} />
              </button>
            </Tooltip>
            <Tooltip
              classes={{
                tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
              }}
              arrow
              placement="top"
              title="Refresh Data"
            >
              <button
                className="dash-show-message-button mt-1 mb-4 dash-refresh-button"
                onClick={handleRefresh}
              >
                <FontAwesomeIcon className="dash-card-title" icon={faSync} />
              </button>
            </Tooltip>
          </Col>
        </Row>
        {filteredChannels.map(function (session: any, i) {
          return (
            <Row
              style={getStyle(session)}
              key={"session-" + i}
              className="dash-message-view p-3 mx-0"
            >
              <Col md={3}>
                <div className="dash-message-left" ng-click="EditTask(true)">
                  <p>
                    <span className="dash-message-left-label">
                      {session.ChannelType === "MATTER"
                        ? "Matter: "
                        : session.ChannelType === "TASK"
                          ? "Task: "
                          : "DM "}
                    </span>
                    <span className="dash-message-left-text">
                      {session.ChannelType === "MATTER"
                        ? session?.Matter?.MatterTitle
                        : session.ChannelType === "TASK"
                          ? session?.Task?.TaskTitle
                          : ""}
                    </span>
                  </p>

                  <p>
                    <button
                      className="dash-primary-button"
                      onClick={() => loadChannelForMessageCenter(session)}
                    >
                      Join Channel
                    </button>
                  </p>
                </div>
              </Col>
              <Col md={9} className="dash-message-right pl-0">
                <Row>
                  <Col md="auto">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover
                          id="user-Data"
                          className="dash-user-card-popover"
                        >
                          <UserCard
                            userId={
                              session.Messages.length > 0
                                ? session.Messages[session.Messages.length - 1]
                                  .messageSender.UserId
                                : ""
                            }
                          />
                        </Popover>
                      }
                    >
                      <Avatar
                        className="dash-message-avatar cursor-pointer"
                        src={session.imageUrl}
                        alt=""
                      />
                    </OverlayTrigger>
                  </Col>
                  <Col className="dash-message-container py-2">
                    <span className="dash-message-author">
                      {session.Messages.length > 0
                        ? session.Messages[session.Messages.length - 1]
                          .messageSender.FullName
                        : ""}
                      :
                    </span>

                    <span>
                      {session.Messages.length > 0
                        ? session.Messages[session.Messages.length - 1].message
                        : ""}
                    </span>
                    <div>
                      <Moment format="YYYY/MM/DD HH:mm:ss">
                        {session.Messages.length > 0
                          ? session.Messages[session.Messages.length - 1]
                            .createdAt
                          : ""}
                      </Moment>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
        {noResults && (
          <h3 className="margin-separator-top-search text-center text-white">
            No results found
          </h3>
        )}
      </div>
    );
  }
}

function orderByLastMessage(listChannels = []) {
  let filterArray = listChannels.filter(
    (channel: any) => channel.Messages.length > 0
  );

  return filterArray.sort((a: any, b: any) =>
    moment(b.Messages[b.Messages.length - 1].createdAt).diff(
      moment(a.Messages[a.Messages.length - 1].createdAt)
    )
  );
}
