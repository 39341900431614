export const actionTypes = {
  // GET TWLIO CLIENT
  GET_CLIENT_START: 'GET_CLIENT_START',
  GET_CLIENT_SUCCESS: 'GET_CLIENT_SUCCESS',
  GET_CLIENT_FAIL: 'GET_CLIENT_FAIL',

  //GET CHANNELS
  GET_CHANNELS_START: 'GET_CHANNELS_START',
  GET_CHANNELS_SUCCESS: 'GET_CHANNELS_SUCCESS',
  GET_CHANNELS_FAIL: 'GET_CHANNELS_FAIL',
  ADD_NEW_CHANNEL: 'ADD_NEW_CHANNEL',
  UPDATE_CHANNELS: 'UPDATE_CHANNELS',

  //GET CHANNELS METADATA
  GET_CHANNELS_METADATA_START: 'GET_CHANNELS_METADATA_START',
  GET_CHANNELS_METADATA_SUCCESS: 'GET_CHANNELS_METADATA_SUCCESS',
  GET_CHANNELS_METADATA_FAIL: 'GET_CHANNELS_METADATA_FAIL',
  SET_METADATA_CHANNELS: 'SET_METADATA_CHANNELS',
  UPDATE_METADATA_ITEM: 'UPDATE_METADATA_ITEM',

  //SET CURRENT CHANNEL
  SET_CURRENT_CHANNEL: 'SET_CURRENT_CHANNEL',

  //SET CURRENT USER
  SET_CURRENT_USER: 'SET_CURRENT_USER',

  //SET PENDING MESSAGES
  SET_PENDING_MESSAGES: 'SET_PENDING_MESSAGES',


  //CREATE NEW CHANNEL
  CREATE_CHANNEL_START: 'CREATE_CHANNEL_START',
  CREATE_CHANNEL_SUCCESS: 'CREATE_CHANNEL_SUCCESS',
  CREATE_CHANNEL_FAIL: 'CREATE_CHANNEL_FAIL',

  //SEND MESSAGE
  SEND_MESSAGE_START: 'SEND_MESSAGE_START',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAIL: 'SEND_MESSAGE_FAIL',

  //SET CHANNELS
  SET_JOIN_CHANNEL_START: 'SET_JOIN_CHANNEL_START',
  SET_JOIN_CHANNEL_SUCCESS: 'SET_JOIN_CHANNEL_SUCCESS',
  SET_JOIN_CHANNEL_FAIL: 'SET_JOIN_CHANNEL_FAIL',

  //SET MESSAGE CENTER DATA
  SET_CHANNEL_MESSAGE_CENTER: 'SET_CHANNEL_MESSAGE_CENTER',

  //listeners
  START_LOADING_LISTENERS: 'START_LOADING_LISTENERS',
  END_LOADING_LISTENERS: 'END_LOADING_LISTENERS',
  LISTENERS_LOAD_START: 'LISTENERS_LOAD_START',

  //counter
  SET_COUNTER_LOADED: 'SET_COUNTER_LOADED',

  //LOAD_MESSAGE
  LOAD_MESSAGE_FOR_CHANNEL: 'LOAD_MESSAGE_FOR_CHANNEL',
  SET_NEW_MESSAGE_NOTIFICATION: 'SET_NEW_MESSAGE_NOTIFICATION',

  //LOG OUT
  SIGN_OUT: 'SIGN_OUT',
}
