import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CancelConfirmationModal from '../CancelConfirmationModal/cancelConfirmationModal';
import Dropzone from 'react-dropzone';

import { fileLoadedReadyToSave } from 'services/UploadFileToS3';
import { DocumentCenter } from 'services/Api/DocumentCenter';
interface ResourceState {
  show: boolean;
  itemId: any;
  itemName: string;
  showCancelConfirm: boolean;
  formTouched: boolean;
  loading: boolean;
  files: any;
  resourceType: string;
}
class UploadDocumentModal extends React.Component<
  {
    isModalShowing;
    onModalCloseClick();
    itemId;
    itemName;
    handleModalCancel;
    resourceType;
  },
  ResourceState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      itemId: this.props.itemId,
      itemName: this.props.itemName,
      show: this.props.isModalShowing,
      showCancelConfirm: false,
      formTouched: false,
      loading: false,
      files: null,
      resourceType: this.props.resourceType,
    };
  }

  setFilesToSave = async (files) => {
    const filesList: any = [];
    console.log('files');
    console.log(files);

    for await (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        console.log('load');
        console.log(file);

        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        var formData = new FormData();
        formData.append('file', file);
        console.log(formData);
        console.log('formData');
        console.log(binaryStr);

        const fileName = `${this.state.resourceType}/${this.state.itemId}/${file.name}`;
        let fileTypeArr = file.type.split('/');
        let fileType = fileTypeArr[1] === 'jpeg' ? 'jpg' : fileTypeArr[1];
        const savedFileKey = await fileLoadedReadyToSave(
          fileName,
          formData,
          fileType
        );
        filesList.push({
          fileURL: savedFileKey,
          fileType: fileType,
          name: file.name,
        });
        console.log(filesList);
      };
    }
    return filesList;
  };

  readFile = (file: any) => {
    const filesList: any = [];
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.readAsDataURL(file);
      var formData = new FormData();

      fr.onload = async () => {
        formData.append('file', file);
        console.log(formData);
        console.log(fr.result);

        const fileName = `${this.state.resourceType}/${this.state.itemId}/${file.name}`;
        let fileTypeArr = file.type.split('/');
        let fileType = fileTypeArr[1] === 'jpeg' ? 'jpg' : fileTypeArr[1];
        const savedFileKey = await fileLoadedReadyToSave(
          fileName,
          // formData,
          fr.result,
          fileType
        );
        filesList.push({
          fileURL: savedFileKey,
          fileType: fileType,
          name: file.name,
        });
        resolve(filesList);
      };
      // fr.readAsText(file);
    });
  };

  onDrop = (selectedFiles) => {
    const files = this.state.files;
    this.setState({ files: files ? [...selectedFiles, ...files] : selectedFiles });
  };

  componentDidMount() {
    if (this.state.itemId) {
      console.log();
    }
  }

  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalCloseClick();
  };
  handleCloseCancelConfirm = () => {
    this.setState({ showCancelConfirm: false });
  };
  handleChangeFilesStatus = ({ meta, file }, status) => {
    // if (status === 'removed') {
    //   setChoosedFileButNotSaved(false);
    // }
    // if (status === 'done') {
    //   setChoosedFileButNotSaved(true);
    // }
  };

  saveDocument = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const files = this.state.files;
    if (!files) {
      this.setState({ loading: false });
      return;
    }
    const filesToDb: any = {};
    let promises: any = []; // collect all promises
    for (const file of files) {
      promises.push(this.readFile(file));
    }

    Promise.all(promises) // wait for the resolutions
      .then(async (results) => {
        filesToDb['files'] = results.flat();
        filesToDb['type'] = this.state.resourceType;
        filesToDb['id'] = this.state.itemId;
        console.log(filesToDb);

        // const savedDbFile =
        try {
          await DocumentCenter.create(filesToDb).then(() => {
            toast.success('Files Saved Successfully!');
            this.setState({ loading: false });
            this.handleClose();
          });
        } catch (err) {
          alert(err);
        }
      });
  };

  removeFile = (file) => () => {
    console.log('removeFile...');
    let filteredFiles = this.state.files;
    filteredFiles.splice(filteredFiles.indexOf(file), 1);
    this.setState({ files: filteredFiles });
  };
  render() {
    let files: any;
    if (this.state.files) {
      files = this.state.files.map((file) => (
        <li key={ file.name } className='list-group-item'>
          {file.name }{ ' ' }
          <button
            className='float-right dash-show-message-button text-dark'
            onClick={ this.removeFile(file) }
          >
            <i className='far fa-trash-alt'></i>
          </button>
        </li>
      ));
    }
    return (
      <React.Fragment>
        <Modal
          show={ this.state.show }
          onHide={ () => this.handleClose() }
          size='xl'
          backdrop='static'
          className='pt-5'
          scrollable={ true }
        >
          <form>
            <div className='mb-0'>
              <Row>
                <Col md={ 12 }>
                  <Card className='dash-card'>
                    <Modal.Header className='p-0'>
                      <Card.Header className='dash-card-header'>
                        <span className='dash-card-title'>
                          Attach Document(s) to { this.state.itemName }
                        </span>
                        <ButtonGroup className='float-right'>
                          <Button
                            type='button'
                            className='dash-secondary-button margin-separator'
                            onClick={ () => {
                              this.state.formTouched
                                ? this.setState({ showCancelConfirm: true })
                                : this.handleClose();
                            } }
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            className='dash-primary-button'
                            disabled={ this.state.loading }
                            onClick={ this.saveDocument }
                          >
                            { this.state.loading && (
                              <Spinner
                                className='mr-1 dash-button-spinner'
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            ) }

                            { this.state.loading ? (
                              <span>Saving...</span>
                            ) : (
                              <span>Save</span>
                            ) }
                          </Button>
                        </ButtonGroup>
                      </Card.Header>
                    </Modal.Header>
                    <Modal.Body className='px-0 pt-0 pb-5 dash-card-body'>
                      <Card.Body>
                        <Row className='form-group'>
                          <Col md={ 12 }>
                            <Dropzone
                              onDrop={ (files) => this.onDrop(files) }
                              multiple={ true }
                            >
                              { ({ getRootProps, getInputProps }) => (
                                <section className='container'>
                                  <div
                                    { ...getRootProps({
                                      className: 'dash-dropzone',
                                    }) }
                                  >
                                    <input { ...getInputProps() } />
                                    <p className='dash-dropzone-text'>
                                      Drag and drop some files here, or click to
                                      select files
                                    </p>
                                  </div>
                                  {files?.length > 0 && (
                                    <aside className='dash-dropzone-selected mt-2'>
                                      <h4 className='mt-2 text-white'>
                                        Selected Files
                                      </h4>
                                      <ul className='list-group'>{ files }</ul>
                                    </aside>
                                  ) }
                                </section>
                              ) }
                            </Dropzone>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Modal.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </form>
        </Modal>
        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={ this.state.showCancelConfirm }
            onModalYesClick={ () => this.handleClose() }
            onModalNoClick={ () => this.setState({ showCancelConfirm: false }) }
          ></CancelConfirmationModal>
        ) }
      </React.Fragment>
    );
  }
}

export default UploadDocumentModal;
