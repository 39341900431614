import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/components/taskCalendar/react-big-calendar.css";

import "./index.scss";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Provider } from 'react-redux'
import store from './redux/store'

ReactDOM.render(
<Provider store={store}>
<App />
</Provider>,
   document.getElementById("root"));
toast.configure({
  position: "bottom-right",
  autoClose: 10000,
  draggable: false,
  className: "dash-toaster",
  //etc you get the idea
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
