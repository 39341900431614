import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import colors from "../../assets/scss/colors.js";
import { Tasks } from "services/Api";
require("highcharts/modules/exporting")(Highcharts);

interface interfaceState {
  openTasks: any;
  blockedTasks: any;
  inProgressTasks: any;
  doneTasks: any;
  fixedBilling: any;
  currentProfit: any;
  maxProfit: any;
}

class NegativeChart extends React.Component<{ history }, interfaceState> {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
      openTasks: 0,
      blockedTasks: 0,
      inProgressTasks: 0,
      doneTasks: 0,
      fixedBilling: 0,
      currentProfit: 0,
      maxProfit: 0,
      //   hoverData: null
    };
  }

  async componentDidMount() {
    const userObj = JSON.parse(localStorage.getItem("userData") || "{}");
    let openCounter = 0;
    let blockedCounter = 0;
    let inProgressCounter = 0;
    let doneCounter = 0;
    let currentProfitCounter = 0;
    let maxProfitCounter = 0;
    let billingCounter = parseFloat(userObj.BillRate);
    const { data: tasksObj } = await Tasks.index();
    const listTasks = tasksObj.map((task) => {
      return {
        ...task,
        BillRate: task.BillRate ? parseFloat(task.BillRate) : 0.0,
        Cost: task.Cost ? parseFloat(task.Cost) : 0.0,
        EffortAct: task.EffortAct ? parseFloat(task.EffortAct) : 0.0,
        EffortEst: task.EffortEst ? parseFloat(task.EffortEst) : 0.0,
      };
    });
    listTasks.forEach((item) => {
      if (item.TaskStatus && item.TaskStatus.TaskStatusName === "Open") {
        openCounter = openCounter + item.BillRate;
      } else if (
        item.TaskStatus &&
        item.TaskStatus.TaskStatusName === "Blocked"
      ) {
        blockedCounter = blockedCounter + item.BillRate;
      } else if (
        item.TaskStatus &&
        item.TaskStatus.TaskStatusName === "In Progress"
      ) {
        inProgressCounter = inProgressCounter + item.BillRate;
      } else if (item.TaskStatus && item.TaskStatus.TaskStatusName === "Done") {
        doneCounter = doneCounter + item.BillRate;
      }
      return {
        item,
      };
    });
    maxProfitCounter =
      openCounter + blockedCounter + inProgressCounter + doneCounter;
    maxProfitCounter = maxProfitCounter - billingCounter;
    currentProfitCounter = doneCounter - billingCounter;

    if (currentProfitCounter < 0) {
      currentProfitCounter = currentProfitCounter * -1;
    }

    if (maxProfitCounter < 0) {
      maxProfitCounter = maxProfitCounter * -1;
    }

    this.setState({ currentProfit: currentProfitCounter });
    this.setState({ maxProfit: maxProfitCounter });
    this.setState({ blockedTasks: blockedCounter });
    this.setState({ inProgressTasks: inProgressCounter });
    this.setState({ openTasks: openCounter });
    this.setState({ doneTasks: doneCounter });
    this.setState({ fixedBilling: billingCounter });
  }

  handleClickEvent = (e) => {
    if (e.point.category.toLowerCase() === "blocked") {
      this.props.history.push({
        pathname: "/tasks",
        state: { filteredStatus: 3 },
      });
    } else if (e.point.category.toLowerCase() === "in progress") {
      this.props.history.push({
        pathname: "/tasks",
        state: { filteredStatus: 2 },
      });
    } else if (e.point.category.toLowerCase() === "open") {
      this.props.history.push({
        pathname: "/tasks",
        state: { filteredStatus: 1 },
      });
    } else if (e.point.category.toLowerCase() === "done") {
      this.props.history.push({
        pathname: "/tasks",
        state: { filteredStatus: 4 },
      });
    }
  };
  //   setHoverData = (e) => {
  //     // The chart is not updated because `chartOptions` has not changed.
  //     this.setState({ hoverData: e.target.category })
  //   }

  render() {
    const config = {
      chart: { type: "bar" },
      title: { text: ["Budget"] },
      yAxis: {
        gridLineColor: colors.dash_grey1,
        labels: {
          style: {
            color: colors.dash_black,
            font: "12px Segoe UI, Verdana, sans-serif",
          },
        },
      },
      xAxis: {
        categories: [
          "Open",
          "In Progress",
          "Blocked",
          "Done",
          "Fixed Cost",
          "Current Profit",
          "Max Profit",
        ],
        gridLineColor: colors.dash_grey1,
        lineColor: colors.dash_grey1,
        labels: {
          style: {
            color: colors.dash_black,
            font: "12px Segoe UI, Verdana, sans-serif",
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: "$",
        valueSuffix: " USD",
      },
      plotOptions: {
        point: {},
        series: {
          events: {
            click: (e) => {
              this.handleClickEvent(e);
            },
          },
          colorByPoint: true,
          colors: [
            "#84b4c8",
            "#81d381",
            "#ffa7b6",
            "#cfcfc4",
            "#0606FE",
            "#0606FE",
            "#0606FE",
          ],
          negativeColor: "red",
          cursor: "pointer",
          borderWidth: 0,
        },
      },

      series: [
        {
          cursor: "pointer",
          name: "In",
          point: {},
          data: [
            this.state.openTasks,
            this.state.inProgressTasks,
            this.state.blockedTasks,
            this.state.doneTasks,
            this.state.fixedBilling,
            this.state.currentProfit,
            this.state.maxProfit,
          ],
        },
      ],
      navigation: {
        buttonOptions: {
          height: 30,
          width: 30,
          symbolX: 16,
          symbolY: 15,
          theme: {
            fill: "#E3E3E8",
          },
        },
      },
    };

    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={config}
          containerProps={{ style: { height: "400px" } }}
        />
        {/* <h3>Hovering over {hoverData}</h3> */}
      </div>
    );
  }
}

export default NegativeChart;
