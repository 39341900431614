import { actionTypes } from '../constants/actionTypes';

export function setCurrentUser(user) {
    return async (dispatch) => { 
        dispatch(actionReducer(user, actionTypes.SET_CURRENT_USER));
    }
}

export function signOut() {
    return async (dispatch) => { 
        dispatch(actionReducer(null, actionTypes.SIGN_OUT));
    }
}


const actionReducer = (payload, type) => ({
    type,
    payload,
})
