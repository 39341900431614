import { actionTypes } from "../constants/actionTypes";

export const initialState = {
  channels: [],
  currentChannel: null,
  loading: false,
  error: null,
  newMessage: null,
  calledFromTheBackend: false,
};

function channelReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CHANNELS_START: {
      return {
        ...state,
        error: null,
        loading: true,
        calledFromTheBackend: true,
      };
    }
    case actionTypes.GET_CHANNELS_SUCCESS: {
      return {
        ...state,
        channels: action.payload,
        loading: false,
        error: null,
      };
    }
    case actionTypes.SET_CURRENT_CHANNEL: {
      return {
        ...state,
        currentChannel: action.payload,
      };
    }
    case actionTypes.GET_CHANNELS_FAIL:
    case actionTypes.CREATE_CHANNEL_FAIL:
    case actionTypes.SEND_MESSAGE_FAIL:
    case actionTypes.SET_JOIN_CHANNEL_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actionTypes.ADD_NEW_CHANNEL: {
      return {
        ...state,
        channels: [...state.channels, action.payload],
      };
    }
    case actionTypes.CREATE_CHANNEL_START:
    case actionTypes.SEND_MESSAGE_START:
    case actionTypes.SET_JOIN_CHANNEL_START: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case actionTypes.CREATE_CHANNEL_SUCCESS: {
      const channel = {
        ...action.payload,
        Messages: [],
      };
      return {
        ...state,
        error: null,
        loading: false,
        callFinished: true,
        channels: [...state.channels, channel],
        currentChannel: channel,
      };
    }
    case actionTypes.SEND_MESSAGE_SUCCESS: {
      const message = action.payload;
      let channel: any = state.channels.find(
        (ch: any) => ch.Id === message.Channel.Id
      );
      if (channel) {
        channel.Messages.push(action.payload);
      }
      return {
        ...state,
        currentChannel: channel ? { ...channel } : { ...message.Channel },
        channels: channel
          ? [...state.channels]
          : [...state.channels, message.Channel],
        error: null,
        loading: false,
        callFinished: true,
      };
    }
    case actionTypes.UPDATE_CHANNELS:
      {
        const messageObj = action.payload;
        if (
          state.currentChannel &&
          state.currentChannel?.["Id"] === messageObj.Channel.Id
        ) {
          const messageExist = isMessageExist(
            messageObj.Id,
            state.currentChannel?.["Messages"]
          );
          if (!messageExist) {
            let currentChannel = state.channels.find(
              (ch: any) => ch.Id === messageObj.Channel.Id
            );
            currentChannel = messageObj.Channel;
            return {
              ...state,
              channels: [...state.channels],
              currentChannel: currentChannel,
            };
          }
        } else {
          console.log('aca cae')
          let index = state.channels.findIndex(
            (ch: any) => ch.Id === messageObj.Channel.Id
          );
          state.channels.splice(index, 1);
          return {
            ...state,
            channels: [...state.channels, messageObj.Channel],
            newMessage: { ...messageObj },
          };
        }
      }
      break;
    case actionTypes.SET_NEW_MESSAGE_NOTIFICATION: {
      return {
        ...state,
        newMessage: action.payload,
      };
    }
    default:
      return state;
  }
}

function isMessageExist(id, messages = []) {
  return messages.find((message: any) => message.id === id);
}

export default channelReducer;
