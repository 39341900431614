import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, Spinner, ButtonGroup, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSync } from '@fortawesome/free-solid-svg-icons';
import NewClient from './newClient';
//import EditClient from "./editClient";
// import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import '../../../assets/sharedDesign/sharedDesign.scss';
import { Clients } from 'services/Api';
import Tooltip from '@material-ui/core/Tooltip';
import Workbook from 'react-excel-workbook';

interface ClientState {
  clients: [];
  clientFilter: {
    ClientName: '';
    ContactName: '';
  };
  isAddShowing: Boolean;
  isEditShowing: Boolean;
  editId: string;
  noResults: Boolean;
  props: any;
  displayedClients: [];
  loading: boolean;
}
class ClientsManagement extends React.Component<{}, ClientState> {
  private addModalRef: any;
  private currentTasks: any;
  constructor(props: any) {
    super(props);
    this.addModalRef = React.createRef();
    this.state = {
      clients: [],
      clientFilter: {
        ClientName: '',
        ContactName: '',
      },
      isAddShowing: false,
      isEditShowing: false,
      editId: '',
      noResults: false,
      props: this.props,
      displayedClients: [],
      loading: false,
    };
  }
  changeHandler = (e: any) => {
    let { name, value } = e.target;
    this.setState((state) => ({
      clientFilter: Object.assign({}, state.clientFilter, {
        [name]: value,
      }),
    }));
  };

  componentDidMount() {
    this.fetchApi();
  }

  orderByClientName() {
    let myData: any = this.state.displayedClients;
    myData.sort((a, b) => {
      return a.ClientName.localeCompare(b.ClientName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedClients: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedClients: myData });
      this.currentTasks = this.state.displayedClients;
    }
  }
  orderByContact() {
    let myData: any = this.state.displayedClients;
    myData.sort((a, b) => {
      return a.ContactName.localeCompare(b.ContactName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedClients: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedClients: myData });
      this.currentTasks = this.state.displayedClients;
    }
  }
  orderByEmail() {
    let myData: any = this.state.displayedClients;
    myData.sort((a, b) => {
      return a.ContactEmail.localeCompare(b.ContactEmail)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedClients: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedClients: myData });
      this.currentTasks = this.state.displayedClients;
    }
  }
  orderByMobile() {
    let myData: any = this.state.displayedClients;
    myData.sort((a, b) => {
      return a.ContactMobile.localeCompare(b.ContactMobile)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedClients: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedClients: myData });
      this.currentTasks = this.state.displayedClients;
    }
  }

  fetchApi = async () => {
    try {
      this.setState({ loading: true });
      const clientsObj = await Clients.index();
      this.setState({
        clients: clientsObj.data,
        displayedClients: clientsObj.data,
      });
      this.handleSearch();
      this.currentTasks = clientsObj.data;
      this.setState({ loading: false });
    } catch (err) {
      alert(err.message ? err.message : err);
    }
  };

  handleSearch = () => {
    const filteredClients: any = this.state.clients.filter((client: any) => {
      return (
        client.ClientName.toLowerCase().includes(
          this.state.clientFilter.ClientName.toLowerCase()
        ) &&
        client.ContactName.toLowerCase().includes(
          this.state.clientFilter.ContactName.toLowerCase()
        )
      );
    });
    if (
      filteredClients.length !== this.state.displayedClients.length &&
      filteredClients.length === 0
    ) {
      this.setState({ noResults: true });
    } else {
      this.setState({ noResults: false });
    }
    this.setState({ displayedClients: filteredClients });
  };

  handleReset = () => {
    this.setState(
      (state) => ({
        clientFilter: Object.assign(
          {},
          state.clientFilter,
          { ClientName: '' },
          { ContactName: '' }
        ),
      }),
      () => {
        this.handleSearch();
      }
    );
  };
  submitForm = (e: any) => {
    e.preventDefault();
    this.handleSearch();
  };
  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };
  handlemodalClose = async () => {
    this.setState({ isAddShowing: false, isEditShowing: false, loading: true });
    this.fetchApi();
  };
  handelIsEditShowing = (e: any, id: any) => {
    this.setState({ editId: id, isEditShowing: true });
  };
  render() {
    const { displayedClients, clientFilter } = this.state;
    let myclass = this;
    return (
      <div>
        {this.state.loading && (
          <div className='dash-page-spinner-container'>
            <Col>
              <Spinner
                className='mr-1 dash-page-spinner'
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
            </Col>
          </div>
        )}

        <div className='dash-grid-body'>
          <Row className='mb-4'>
            <Col md={12} className='d-flex justify-content-center'>
              <div className='float-left position-absolute dash-left-3 dash-top-3'>
                <ButtonGroup>
                  <Button
                    className='btn btn-xs btn-info dash-back-button mb-0 '
                    onClick={() => this.state.props.history.goBack()}
                  >
                    Back
                  </Button>
                </ButtonGroup>
              </div>
              <div className='dash-page-title'>
                <h3 className='mb-0 text-center'>Clients</h3>
                <div className='dash-line-icon'></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Accordion>
                <Card className='dash-card'>
                  <Card.Header className='dash-card-header'>
                    <span className='dash-card-title'>Search Criteria</span>
                    <Accordion.Toggle
                      as={Button}
                      variant='link'
                      eventKey='0'
                      className='float-right'
                    >
                      <FontAwesomeIcon
                        className='dash-card-title'
                        icon={faFilter}
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Card.Body className='dash-card-body'>
                    <Accordion.Collapse eventKey='0'>
                      <form onSubmit={this.submitForm}>
                        <Card.Body className='dash-card-body'>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>Client Name</div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='CompanyName'
                                name='ClientName'
                                value={clientFilter.ClientName}
                                onChange={this.changeHandler}
                                onKeyDown={this.handleKeyDown}
                              />
                            </Col>

                            <Col md={2}>
                              <div className='dash-form-label'>
                                Contact Name
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='ContactName'
                                name='ContactName'
                                value={clientFilter.ContactName}
                                onChange={this.changeHandler}
                                onKeyDown={this.handleKeyDown}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </form>
                    </Accordion.Collapse>

                    <div className='clearfix'>
                      <button
                        type='button'
                        className='btn btn-reset btn-w-m  mr-2'
                        onClick={() => this.setState({ isAddShowing: true })}
                      >
                        Add Client
                      </button>
                      {this.state.isAddShowing && (
                        <NewClient
                          ref={this.addModalRef}
                          isAddShowing={this.state.isAddShowing}
                          onModalCloseClick={() => this.handlemodalClose()}
                        />
                      )}
                      <Workbook
                        filename='clients.xlsx'
                        element={
                          <button className='btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset'>
                            Export Data
                          </button>
                        }
                      >
                        <Workbook.Sheet data={displayedClients} name='clients'>
                          <Workbook.Column
                            label='Client Name'
                            value='ClientName'
                          />
                          <Workbook.Column
                            label='Contact'
                            value='ContactName'
                          />
                          <Workbook.Column label='Email' value='ContactEmail' />
                          <Workbook.Column
                            label='Mobile'
                            value='ContactMobile'
                          />
                        </Workbook.Sheet>
                      </Workbook>

                      <ButtonGroup className='float-right clearfix'>
                        <Button
                          type='button'
                          variant='secondary'
                          size='sm'
                          className='dash-secondary-button margin-separator'
                          onClick={this.handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          size='sm'
                          type='button'
                          className='dash-primary-button'
                          onClick={this.handleSearch}
                        >
                          Search
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <table className='table table-striped table-center mt-1'>
                <thead>
                  <tr>
                    <th>
                      {' '}
                      <Tooltip
                        classes={{
                          tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                          arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement='top'
                        title='Refresh Data'
                      >
                        <button
                          className='dash-show-message-button dash-refresh-button'
                          onClick={this.fetchApi}
                        >
                          <FontAwesomeIcon
                            className='dash-card-title'
                            icon={faSync}
                          />
                        </button>
                      </Tooltip>
                    </th>
                    <th className='th-click' onClick={() => this.orderByClientName()}>Client Name
                      <Tooltip
                        classes={{
                          tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                          arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement='top'
                        title='Click on the header to sort the data'
                      >
                        <i className='fas fa-info-circle ml-2'></i>
                      </Tooltip></th>
                    <th className='th-click' onClick={() => this.orderByContact()}>Contact</th>
                    <th className='th-click' onClick={() => this.orderByEmail()}>Email</th>
                    <th className='th-click' style={{ width: '120px' }} onClick={() => this.orderByMobile()}>Mobile</th>

                    <th className='width-50'></th>
                    <th className='width-50'></th>
                  </tr>
                </thead>
                <tbody>
                  {displayedClients.map(function (session: any, i) {
                    return (
                      <tr key={'session-' + i}>
                        <td>
                          <Tooltip
                            classes={{
                              tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                              arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                            }}
                            arrow
                            placement='top'
                            title='Edit'
                          >
                            <button
                              className='dash-show-message-button cursor-pointer'
                              onClick={(event) =>
                                myclass.handelIsEditShowing(
                                  event,
                                  session.ClientId
                                )
                              }
                            >
                              <i className='far fa-edit'></i>
                            </button>
                          </Tooltip>
                          {myclass.state.isEditShowing &&
                            myclass.state.editId === session.ClientId && (
                              <NewClient
                                ref={myclass.addModalRef}
                                isAddShowing={myclass.state.isEditShowing}
                                clientId={myclass.state.editId}
                                onModalCloseClick={() =>
                                  myclass.handlemodalClose()
                                }
                              />
                            )}
                        </td>
                        <td className='dash-word-break-all'>{session.ClientName}</td>
                        <td className='dash-word-break-all'>{session.ContactName}</td>

                        <td>
                          <a
                            className='dash-email-link'
                            href={'mailto:' + session.ContactEmail}
                          >
                            {session.ContactEmail}
                          </a>
                        </td>
                        <td>{session.ContactMobile}</td>
                        <td>
                          <Tooltip
                            classes={{
                              tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                              arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                            }}
                            arrow
                            placement='top'
                            title='Tasks'
                          >
                            <div>
                              <Link
                                className='dash-show-message-button cursor-pointer d-block'
                                to={{
                                  pathname: '/tasks',
                                  state: { clientId: session.ClientId },
                                }}
                              >
                                <span className='dash-icons-task dash-font-size-22'></span>
                              </Link>
                            </div>
                          </Tooltip>
                        </td>

                        <td>
                          <Tooltip
                            classes={{
                              tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                              arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                            }}
                            arrow
                            placement='top'
                            title='Matters'
                          >
                            <div>
                              <Link
                                className='dash-show-message-button cursor-pointer d-block'
                                to={{
                                  pathname: '/matters/mattersManagement',
                                  state: { clientId: session.ClientId },
                                }}
                              >
                                <span className='dash-icons-matter dash-font-size-26'></span>
                              </Link>
                            </div>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {this.state.noResults && (
                <h3 className='margin-separator-top-search text-center text-white'>
                  No results found
                </h3>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ClientsManagement;
