import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import DeleteConfirmationModal from '../../DeleteConfirmationModal/DeleteConfirmationModal'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
// import Icon from "@material-ui/core/Icon";
// import { Link } from "react-router-dom";
import {
  Button,
  Spinner,
  ButtonGroup,
  Accordion,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSync } from '@fortawesome/free-solid-svg-icons';
import CreateTaskAdmin from '../tasks/createTaskAdmin';
//import EditTaskAdmin from "../tasks/editTaskAdmin";
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';
import UserCard from '../../profile/userCard';
import { Templates } from 'services/Api';
import Tooltip from '@material-ui/core/Tooltip';
import Workbook from 'react-excel-workbook';
import Moment from 'react-moment';

export default function TaskTemplates(props: any) {

  const filterInitialState = {
    templateTitle: '',
    matterTemplateId: '',
  };
  const searchForm: any = useRef<HTMLFormElement>(null);
  let editModalRef: any = useRef<any>(null);
  let addModalRef: any = useRef<any>(null);
  const [tempaltes, setTempaltes] = useState([]);
  const [idToDelete, setidToDelete] = useState([]);
  const [displayedTempaltes, setDisplayedTempaltes] = useState([]);
  const [tempalteFilter, setTempalteFilter] = useState(filterInitialState);
  const [matterTemplates, setMatterTemplates] = useState([]);
  const [runSearch, setRunSearch] = useState(false);
  const [isAddShowing, setIsAddShowing] = useState(false);
  const [isDeleteShowing, setIsDeleteShowing] = useState(false);
  const [matterTemplateParam, setMatterTemplateParam] = useState([]);
  const [isEditShowing, setIsEditShowing] = useState(false);
  const [editId, setEditId] = useState();
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentUserID, setCurrentUserID] = useState();

  React.useEffect(() => {
    let currentUser: any = localStorage.getItem('userData');
    const currentUserID: any = JSON.parse(currentUser ? currentUser : '{}');
    setCurrentUserID(currentUserID.UserId);
    _handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runSearch]);
  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      let matterTemplateIdParam = props.location.state?.matterTemplateId;
      setMatterTemplateParam(matterTemplateIdParam);
      await Templates.taskTemplates()
        .then(async (response) => {
          setTempaltes(response.data);
          setDisplayedData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      await Templates.matterTemplates()
        .then(async (response) => {
          setMatterTemplates(response.data);
          if (matterTemplateIdParam) {
            setTempalteFilter({
              ...tempalteFilter,
              matterTemplateId: matterTemplateIdParam,
            });
            setRunSearch(!runSearch);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    }
    fetchMyAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTempaltes, setMatterTemplates]);
  const changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    setTempalteFilter({
      ...tempalteFilter,
      [name]: value,
    });
  };
  const _handleSearch = () => {
    const filteredTasks = tempaltes.filter((template: any) => {
      return (
        template.Title.toLowerCase().includes(
          tempalteFilter.templateTitle.toLowerCase(),
        ) &&
        (template.MatterTemplateTaskTemplate[0]?.MatterTemplateId.toString() ===
          tempalteFilter.matterTemplateId.toString() ||
          tempalteFilter.matterTemplateId === '')
      );
    });
    if (
      filteredTasks.length !== displayedTempaltes.length &&
      filteredTasks.length === 0
    ) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
    setDisplayedTempaltes(filteredTasks);
  };
  const handleRefresh = async () => {
    setLoading(true);
    await Templates.taskTemplates()
      .then(async (response) => {
        setTempaltes(response.data);
      })
      .then(() => {
        setRunSearch(!runSearch);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const _handleReset = () => {
    setTempalteFilter(filterInitialState);
    searchForm.current.reset();
    setRunSearch(!runSearch);
  };
  const submitForm = (e: any) => {
    e.preventDefault();
    _handleSearch();
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      _handleSearch();
    }
  };
  const orderByTitle = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.Title.localeCompare(b.Title)
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByMatterTemplate = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.MatterTemplateTaskTemplate[0]?.MatterTemplate
        ?.MatterTemplateTitle.localeCompare(b.MatterTemplateTaskTemplate[0]?.MatterTemplate
          ?.MatterTemplateTitle)
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByPriority = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.Priority?.PriorityName.localeCompare(b.Priority?.PriorityName)
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByDueDate = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.DueDate.toString().localeCompare(b.DueDate.toString())
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByStatus = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.TaskStatus?.TaskStatusName.localeCompare(b.TaskStatus?.TaskStatusName)
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByUser = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.Resource?.FullName.localeCompare(b.Resource?.FullName)
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByEffort = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.EffortEst - b.EffortEst
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByBilling = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.BillRate - b.BillRate
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByCreated = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.createdAt.localeCompare(b.createdAt)
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const orderByUpdated = () => {
    const myData: any = tempaltes;
    myData.sort((a, b) => {
      return a.updatedAt.localeCompare(b.updatedAt)
    });
    if (myData[1] === displayedTempaltes[1]) {
      const reverseData = myData.reverse()
      setDisplayedTempaltes(reverseData);
    } else {
      setDisplayedTempaltes(myData);
    }
    setRunSearch(!runSearch);
  };
  const setDisplayedData = async (data) => {
    let templateData: any = [];
    for (let temp of data) {
      if (temp.Resource?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Resource?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    setDisplayedTempaltes(templateData);
    setLoading(false);
  };
  const handlemodalClose = async () => {
    setLoading(true);

    setIsEditShowing(false);
    setIsAddShowing(false);

    await Templates.taskTemplates()
      .then(async (response) => {
        setTempaltes(response.data);
        setDisplayedData(response.data);
      })
      .then(() => {
        setRunSearch(!runSearch);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    //setTemplateFilter(templateFilter);
  };
  const handledelete = async (id) => {
    setIsDeleteShowing(true);
    setidToDelete(id)
  };
  const handledeleteClose = async () => {
    setLoading(true);
    const id = idToDelete as any;
    setInactiveTemplate(id);
    setIsDeleteShowing(false);
  };
  const handelIsEditShowing = (e, id) => {
    setEditId(id);
    setIsEditShowing(true);
  };

  const setInactiveTemplate = async (id: number) => {
    const inactiveObj = {
      id: id,
      isActive: false,
    };
    await Templates.setInactiveTaskTemplate(inactiveObj);
    handleRefresh();
  }
  return (
    <div>
      {loading && (
        <div className="dash-page-spinner-container">
          <Col>
            <Spinner
              className="mr-1 dash-page-spinner"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </Col>
        </div>
      )}
      <div className="dash-grid-body">
        <Row className="mb-4">
          <Col md={12} className="d-flex justify-content-center">
            <div className="float-left position-absolute dash-left-3 dash-top-3">
              <ButtonGroup>
                <Button
                  className="btn btn-xs btn-info dash-back-button mb-0 "
                  onClick={() => props.history.goBack()}
                >
                  Back
                </Button>
              </ButtonGroup>
            </div>
            <div className="dash-page-title">
              <h3 className="mb-0 text-center">Task Templates</h3>
              <div className="dash-line-icon"></div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Accordion>
              <Card className="dash-card">
                <Card.Header className="dash-card-header">
                  <span className="dash-card-title">Search Criteria</span>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="float-right"
                  >
                    <FontAwesomeIcon
                      className="dash-card-title"
                      icon={faFilter}
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Card.Body className="dash-card-body">
                  <Accordion.Collapse eventKey="0">
                    <form ref={searchForm} onSubmit={submitForm}>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Matter Template</div>
                        </Col>
                        <Col md={4}>
                          <select
                            name="matterTemplateId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={tempalteFilter.matterTemplateId}
                            onChange={changeHandler}
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All </option>
                            {matterTemplates &&
                              matterTemplates.map((type: any, key) => (
                                <option value={type.TemplateMatterId} key={key}>
                                  {type.MatterTemplateTitle}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md={2}>
                          <div className="dash-form-label">Template Title</div>
                        </Col>
                        <Col md={4}>
                          <input
                            type="text"
                            className="form-control dash-form-control"
                            id="templateTitle"
                            name="templateTitle"
                            value={tempalteFilter.templateTitle}
                            onChange={changeHandler}
                            onKeyDown={handleKeyDown}
                          />
                        </Col>{' '}
                      </Row>
                    </form>
                  </Accordion.Collapse>
                  <div className="clearfix">
                    <button
                      type="button"
                      className="btn btn-reset btn-w-m mr-2"
                      onClick={() => setIsAddShowing(true)}
                    >
                      Add Task Template
                    </button>
                    {isAddShowing && (
                      <CreateTaskAdmin
                        ref={addModalRef}
                        isAddShowing={isAddShowing}
                        matterTemplateP={matterTemplateParam}
                        onModalCloseClick={() => handlemodalClose()}
                        isTemplate="true"
                      />
                    )}
                    <Workbook
                      filename="task Template.xlsx"
                      element={
                        <button className="btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset">
                          Export Data
                        </button>
                      }
                    >
                      <Workbook.Sheet
                        data={displayedTempaltes}
                        name="task Template"
                      >
                        <Workbook.Column
                          label="Matter Template"
                          value={(col) =>
                            col.MatterTemplateTaskTemplate[0]?.MatterTemplate
                              ?.MatterTemplateTitle
                              ? col.MatterTemplateTaskTemplate[0]
                                ?.MatterTemplate?.MatterTemplateTitle
                              : ''
                          }
                        />
                        <Workbook.Column label="Title" value="Title" />
                        <Workbook.Column
                          label="Priority"
                          value={(col) =>
                            col.Priority?.PriorityName
                              ? col.Priority?.PriorityName
                              : ''
                          }
                        />
                        <Workbook.Column label="DueDate" value="DueDate" />
                        <Workbook.Column
                          label="Status"
                          value={(col) =>
                            col.TaskStatus?.TaskStatusName
                              ? col.TaskStatus?.TaskStatusName
                              : ''
                          }
                        />
                        <Workbook.Column
                          label="User"
                          value={(col) =>
                            col.Resource?.FullName ? col.Resource?.FullName : ''
                          }
                        />
                        <Workbook.Column
                          label="Effort(Est.)"
                          value={(col) =>
                            col.EffortEst ? col.EffortEst + ' hrs' : ''
                          }
                        />
                        <Workbook.Column label="Billing" value="BillRate" />
                      </Workbook.Sheet>
                    </Workbook>
                    <ButtonGroup className="float-right">
                      <Button
                        //variant="outline-secondary"
                        className="dash-secondary-button mr-2"
                        // size="sm"
                        onClick={() => _handleReset()}
                      >
                        Reset
                      </Button>
                      <Button
                        //size="sm"
                        className="dash-primary-button"
                        onClick={_handleSearch}
                      >
                        Search
                      </Button>
                    </ButtonGroup>
                  </div>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <table className="table table-striped table-center mt-1">
          <thead>
            <tr>
              <th>
                <Tooltip
                  classes={{
                    tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                    arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                  }}
                  arrow
                  placement="top"
                  title="Refresh Data"
                >
                  <button
                    className="dash-show-message-button dash-refresh-button"
                    onClick={handleRefresh}
                  >
                    <FontAwesomeIcon
                      className="dash-card-title"
                      icon={faSync}
                    />
                  </button>
                </Tooltip>
              </th>
              <th className='th-click' onClick={() => orderByMatterTemplate()}>Matter Template
                <Tooltip
                  classes={{
                    tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                    arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                  }}
                  arrow
                  placement='top'
                  title='Click on the header to sort the data'
                >
                  <i className='fas fa-info-circle ml-2'></i>
                </Tooltip></th>
              <th className='th-click' onClick={() => orderByTitle()}>Title</th>
              <th className='th-click' onClick={() => orderByPriority()}>Priority</th>
              <th className='th-click' onClick={() => orderByDueDate()}>DueDate</th>
              <th className='th-click' onClick={() => orderByStatus()}>Status</th>
              <th className='th-click' onClick={() => orderByUser()}>User</th>
              <th className='th-click' onClick={() => orderByEffort()}>Effort(Est.)</th>
              <th className='th-click' onClick={() => orderByBilling()}>Billing</th>
              <th className='th-click' onClick={() => orderByCreated()}> Created</th>
              <th className='th-click' onClick={() => orderByUpdated()}> Updated</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {displayedTempaltes &&
              displayedTempaltes.map((taskTemplate: any, index) => (
                <tr key={index}>
                  <td>
                    <Tooltip
                      classes={{
                        tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                        arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                      }}
                      arrow
                      placement="top"
                      title="Edit"
                    >
                      <button
                        className="dash-show-message-button cursor-pointer"
                        onClick={(event) =>
                          handelIsEditShowing(
                            event,
                            taskTemplate.TaskTemplateId,
                          )
                        }
                      >
                        <i className="far fa-edit"></i>
                      </button>
                    </Tooltip>
                    {isEditShowing &&
                      editId === taskTemplate.TaskTemplateId && (
                        <CreateTaskAdmin
                          ref={editModalRef}
                          isAddShowing={isEditShowing}
                          taskId={editId}
                          onModalCloseClick={() => handlemodalClose()}
                          isTemplate="true"
                        />
                      )}
                  </td>
                  <td className="dash-word-break-all">
                    {
                      taskTemplate.MatterTemplateTaskTemplate[0]?.MatterTemplate
                        ?.MatterTemplateTitle
                    }
                  </td>

                  <td className="dash-word-break-all">{taskTemplate.Title}</td>
                  <td>{taskTemplate.Priority?.PriorityName}</td>
                  <td>{taskTemplate.DueDate}</td>
                  <td>
                    {taskTemplate.TaskStatus?.TaskStatusId === 1 && (
                      <span className="badge dash-status-badge_open">
                        {taskTemplate.TaskStatus?.TaskStatusName}
                      </span>
                    )}
                    {taskTemplate.TaskStatus?.TaskStatusId === 2 && (
                      <span className="badge dash-status-badge_inProgress">
                        {taskTemplate.TaskStatus?.TaskStatusName}
                      </span>
                    )}
                    {taskTemplate.TaskStatus?.TaskStatusId === 3 && (
                      <span className="badge dash-status-badge_blocked">
                        {taskTemplate.TaskStatus?.TaskStatusName}
                      </span>
                    )}
                    {taskTemplate.TaskStatus?.TaskStatusId === 4 && (
                      <span className="badge dash-status-badge_done">
                        {taskTemplate.TaskStatus?.TaskStatusName}
                      </span>
                    )}
                  </td>
                  <td>
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover
                          id="user-Data"
                          className="dash-user-card-popover"
                        >
                          <UserCard userId={taskTemplate.Resource?.UserId} />
                        </Popover>
                      }
                    >
                      <div className="d-flex align-items-center cursor-pointer">
                        <Avatar
                          className={classNames(
                            'dash-grid-avatar',
                            taskTemplate.Resource?.UserId === currentUserID
                              ? 'd-none'
                              : '',
                          )}
                          src={taskTemplate.imageUrl}
                          alt=""
                        />
                        <span className="align-middle">
                          {taskTemplate.Resource?.FullName}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </td>
                  <td>{taskTemplate.EffortEst} hrs</td>
                  <td>${taskTemplate.BillRate}</td>
                  <td>
                    <Moment format="YYYY/MM/DD HH:mm:ss">
                      {taskTemplate.createdAt}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="YYYY/MM/DD HH:mm:ss">
                      {taskTemplate.updatedAt}
                    </Moment>
                  </td>
                  <td>
                    <Tooltip
                      classes={{
                        tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                        arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                      }}
                      arrow
                      placement="top"
                      title="Delete"
                    >
                      <button
                        className="dash-show-message-button cursor-pointer"
                        onClick={() => handledelete(taskTemplate.TaskTemplateId)
                        }
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {noResults && (
          <h3 className="margin-separator-top-search text-center text-white">
            No results found
          </h3>
        )}
        {isDeleteShowing && (
          <DeleteConfirmationModal
            isShow={isDeleteShowing}
            onModalYesClick={() => handledeleteClose()}
            onModalNoClick={() => setIsDeleteShowing(false)}
          ></DeleteConfirmationModal>
        )}
      </div>
    </div>
  );
}
