import React from 'react';
import { Switch, HashRouter } from 'react-router-dom';
import RouteWrapper from './Route';
import { Route } from 'react-router-dom';
import UserSignIn from 'components/login/login';
import UserSignUp from 'components/register/register';
import UserVerify from 'components/verifyCode/verifyCode';
import UserChangePassword from 'components/ChangePassword/ChangePassword';
import UserForgotPassword from 'components/ForgotPassword';
import FirmsList from 'components/Firms/firms';
import TasksCalendar from 'components/taskCalendar/taskCalendar';
import ViewChart from 'components/taskDashboard/viewChart';
import MatterTemplates from 'components/Matters/matterTemplates';
import TasksBoard from 'components/TasksBoard/taskBoard';
import TasksBoardStatus from 'components/TasksBoard/tasksBoardStatus';
import TasksBoardDueDate from 'components/TasksBoard/taskBoardDueDate';
import CreateTaskAdmin from 'components/Admin/tasks/createTaskAdmin';
import Users from 'components/Admin/users/users';
import ClientsManagement from 'components/Admin/clients/clientsManagement';
import Session from 'components/Admin/session/session';
import MattersManagement from 'components/Admin/matters/mattersManagement';
import AdminTasks from 'components/Admin/tasks/tasks';
import TaskTemplates from 'components/Admin/settings/taskTemplate';
import SessionLawyer from 'components/session/sessionLawyer';
import MessageCenter from 'components/Admin/messageCenter/messangeCenter';
import ScheduleMeeting from 'components/Admin/session/scheduleMeeting';
// import Reports from 'components/Reports/Reports';
import SessionTwilio from 'components/Admin/session/sessionTwilio';
import CreateSession from 'components/Admin/session/createSession';
import TwilioApp from 'components/Admin/session/twilioApp';
import EditSession from 'components/Admin/session/editSession';
import NewFirm from 'components/Firms/newFirm';
import ChatMessage from 'components/Admin/messageCenter/chatMessage';
import Profile from 'components/profile/profile';
import DocumentCenter from 'components/DocumentCenter/documentCenter';

import { AllRoles } from 'types/roles';

export default function Routes() {
  // const { SystemAdmin } = Roles.Admin;
  return (
    <HashRouter>
      <Switch>
        <Route exact path='/' component={UserSignIn} />
        <Route path='/login' component={UserSignIn} />
        <Route path='/register/:type?'
          component={(props) => <UserSignUp {...props} />} />
        <Route path='/verifyCode' component={UserVerify} />
        <Route path='/changePassword' component={UserChangePassword} />
        <Route path='/forgotPassword' component={UserForgotPassword} />
        <RouteWrapper
          path='/matterTasks/:matterId?'
          component={(props) => <AdminTasks {...props} />}
        />
        <RouteWrapper
          path='/firms/:userId?'
          component={(props) => <FirmsList {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/tasksCalendar'
          component={TasksCalendar}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/dashboard'
          component={ViewChart}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/tasksBoard'
          component={TasksBoard}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/matters/mattersManagement/:clientId?/:userId?'
          component={(props) => <MattersManagement {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/matters/mattersManagement'
          component={(props) => <MattersManagement {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper path='/session' component={Session} roles={AllRoles} />
        <RouteWrapper
          path='/sessionLawyer'
          component={SessionLawyer}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/createSession'
          component={CreateSession}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/inChannel'
          component={ChatMessage}
          roles={AllRoles}
        />
        <RouteWrapper path='/profile' component={Profile} roles={AllRoles} />
        <RouteWrapper
          path='/twilioRoom'
          component={TwilioApp}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/matterTemplates'
          component={MatterTemplates}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/tasksBoardStatus'
          component={TasksBoardStatus}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/tasksBoardDueDate'
          component={TasksBoardDueDate}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/createTask/:statusId?/:dueDate?'
          component={(props) => <CreateTaskAdmin {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/users/:firmId?'
          component={(props) => <Users {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/clients/clientsManagement'
          component={ClientsManagement}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/tasks/:userId?/:clientId?/:statusId?/:firmId'
          component={(props) => <AdminTasks {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/tasks'
          component={(props) => <AdminTasks {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/settings/taskTemplate/:matterTemplateId?'
          component={(props) => <TaskTemplates {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/messageCenter'
          component={MessageCenter}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/messageCenterByMatter/:matterId?'
          component={(props) => <MessageCenter {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/messageCenterByResource/:resourceId?'
          component={(props) => <MessageCenter {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/messageCenterByTask/:taskId?'
          component={(props) => <MessageCenter {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/scheduleMeeting'
          component={ScheduleMeeting}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/initSession/:type'
          component={(props) => <SessionTwilio {...props} />}
          roles={AllRoles}
        />
        <RouteWrapper
          path='/editSession/:id'
          component={(props) => <EditSession {...props.match.params} />}
          roles={AllRoles}
        />
        <RouteWrapper path='/newFirm' component={NewFirm} roles={AllRoles} />
        <RouteWrapper
          path='/documentCenter/:matterId?/:taskId?'
          component={(props) => <DocumentCenter {...props} />}
          roles={AllRoles}
        />
      </Switch>
    </HashRouter>
  );
}
