import axios from 'axios';
import { baseURL } from 'services/Api/config';
import { Auth } from 'aws-amplify';

const apiClient = axios.create({ baseURL });

const setupInterceptors = (token, history) => {
  apiClient.interceptors.request.use(
    async (config: any) => {
      !config.headers.authorization &&
        (config.headers['authorization'] = token);

      config.headers.authorization &&
        (config.headers.common['authorization'] = token);

      return config;
    },
    (error: any) => Promise.reject(error)
  );

  apiClient.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      if (error.response?.data.statusCode === 401) {
        await Auth.signOut({ global: true });
        localStorage.removeItem('user');
        localStorage.removeItem('userData');
        history.push('/');
      }
      return Promise.reject(error.response?.data);
    }
  );
};

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy, setupInterceptors };
