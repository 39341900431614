import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Auth } from 'aws-amplify';
import { Navbar } from 'react-bootstrap';
import { useUserDispatch } from 'contexts/UserAuthContext';
import NavLinks from './NavLinks';
import { UserActionTypes } from 'types/user';
import { useDispatch, useSelector } from 'react-redux';
import { getChannels, getChannelsMetadata } from 'redux/actions/channelActions';
import { setCurrentUser, signOut } from 'redux/actions/userActions';
import ChannelHandler from 'shared/channelHandler';
import NotificationChannelManager from 'components/Admin/messageCenter/notificationManager';

const ITEM_HEIGHT = 48;

export default function Header() {
  const history: any = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const dispatch = useUserDispatch();
  const reduxDispatch = useDispatch();
  const isChannelGet = useSelector(
    (state: any) => state.channelReducer.calledFromTheBackend,
  );
  const isChannelMetadataGet = useSelector(
    (state: any) => state.channelMetadataReducer.calledFromTheBackend,
  );
  const client =
    useSelector((state: any) => state.clientChatReducer.client) || null;
  const [userRole, setUserRole] = React.useState();
  useEffect(() => {
    const userObj = JSON.parse(localStorage.getItem('userData') || '{}');
    reduxDispatch(setCurrentUser(userObj));
    setUserRole(userObj.Role.RoleName);
    if (!isChannelGet) {
      reduxDispatch(getChannels(userObj));
    }

    if (!isChannelMetadataGet) {
      reduxDispatch(getChannelsMetadata());
    }
    if (!userObj.isDarkMode) {
      document.body.classList.replace('dark', 'light');
    } else {
      document.body.classList.replace('light', 'dark');
    }
  }, [isChannelGet, isChannelMetadataGet, reduxDispatch]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpen = (event: any) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const handleLogOut = async () => {
    const user = await Auth.signOut({ global: true });
    dispatch({
      type: UserActionTypes.SUCCESS,
      payload: user,
    });
    if (client) {
      await client.disconnect();
    }
    reduxDispatch(signOut());
    localStorage.clear();
    history.push('/');
  };

  const handleRedirect = () => {
    const user = localStorage.getItem('userData') || '{}';
    const userObj = JSON.parse(user);
    const currentUserRole = userObj.Role;
    if (
      currentUserRole.RoleName === 'System Admin' ||
      currentUserRole.RoleName === 'State Admin' ||
      currentUserRole.RoleName === 'County Admin' ||
      currentUserRole.RoleName === 'Court Admin' ||
      currentUserRole.RoleName === 'Department Admin' ||
      currentUserRole.RoleName === 'Outside Law Firm Admin' ||
      currentUserRole.RoleName === 'Law Firm Admin'
    ) {
      if (userObj.DefaultPage === 'Firms') {
        const location = {
          pathname: '/firms',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Matters') {
        const location = {
          pathname: '/matters/mattersManagement',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Users') {
        const location = {
          pathname: '/users',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Tasks') {
        const location = {
          pathname: '/tasks',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Performance') {
        const location = {
          pathname: '/dashboard',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Tasks Calendar') {
        const location = {
          pathname: '/tasksCalendar',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Tasks Board') {
        const location = {
          pathname: '/tasksBoard',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Clients') {
        const location = {
          pathname: '/clients/clientsManagement',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Task Template') {
        const location = {
          pathname: '/settings/taskTemplate',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Templates') {
        const location = {
          pathname: '/matterTemplates',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Message Center') {
        const location = {
          pathname: '/messageCenter',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Meetings') {
        const location = {
          pathname: '/session',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'DocumentCenter') {
        const location = {
          pathname: '/documentCenter',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else {
        const location = {
          pathname: '/matters/mattersManagement',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      }
    } else {
      if (userObj.DefaultPage === 'Meetings') {
        const location = {
          pathname: '/session',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Firms') {
        const location = {
          pathname: '/firms',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Clients') {
        const location = {
          pathname: '/clients/clientsManagement',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Templates') {
        const location = {
          pathname: '/matterTemplates',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Matters') {
        const location = {
          pathname: '/matters/mattersManagement',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Tasks') {
        const location = {
          pathname: '/tasks',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Performance') {
        const location = {
          pathname: '/Dashboard',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Tasks Calendar') {
        const location = {
          pathname: '/tasksCalendar',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Tasks Board') {
        const location = {
          pathname: '/tasksBoard',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'Message Center') {
        const location = {
          pathname: '/messageCenter',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else if (userObj.DefaultPage === 'DocumentCenter') {
        const location = {
          pathname: '/documentCenter',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      } else {
        const location = {
          pathname: '/matters/mattersManagement',
          state: {
            role: userObj.FullName,
          },
        };
        history.push(location);
      }
    }

  }

  return (
    <div>
      <ChannelHandler></ChannelHandler>
      <NotificationChannelManager></NotificationChannelManager>
      <Navbar className="dash-header-container">
        <Navbar.Brand onClick={handleRedirect}>
          <div className="dash-header-logo d-block align-top"></div>

          <span className="dash-header-user-role">{userRole}</span>
        </Navbar.Brand>
        {NavLinks && <NavLinks />}
        <section className="dash-header-more-menu">
          <IconButton
            color="inherit"
            aria-label="More Options"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={handleOpen}>Settings</MenuItem>
            <div style={{ padding: 200 }} className="dash-header-more-menu">
              <Menu
                id="setting-menu"
                anchorEl={anchorE2}
                keepMounted
                open={open2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleClose2}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    history.push('/profile');
                    handleClose();
                  }}
                >
                  Admin
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push('/users');
                    handleClose();
                  }}
                >
                  Users
                </MenuItem>
              </Menu>
            </div>
            <MenuItem
              onClick={() => {
                history.push('/profile');
                handleClose();
              }}
            >
              My Profile
            </MenuItem>
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </Menu>
        </section>
      </Navbar>
    </div>
  );
}
