import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import Moment from 'react-moment';
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';

import { Users } from 'services/Api';

interface ResourceState {
  UserId: any;
  FullName: '';
  Role: any;
  State: any;
  Department: any;
  Email: any;
  City: any;
  Mobile: any;
  Fax: any;
  BirthDate: any;
  BillRate: any;
  Address: any;
  loadingData: boolean;
  imgSrc: any;
}
class UserCard extends React.Component<{ userId }, ResourceState> {
  constructor(props: any) {
    super(props);

    this.state = {
      UserId: this.props.userId,
      FullName: '',
      Role: null,
      State: null,
      Department: null,
      Email: '',
      City: null,
      Mobile: '',
      Fax: null,
      BirthDate: null,
      BillRate: null,
      Address: null,
      loadingData: false,
      imgSrc: '',
    };
  }

  componentDidMount() {
    this.setState({ loadingData: true });
    this.fetchData();
  }

  fetchData = async () => {
    if (this.props.userId) {
      let myId = this.props.userId;

      Users.single(myId)
        .then(async (response) => {
          this.setState({
            UserId: response.data.UserId,
            FullName: response.data.FullName,
            Role: response.data.Role?.RoleName,
            State: response.data.StateName,
            Department: response.data.DepartmentName,
            Email: response.data.Email,
            City: response.data.City,
            Mobile: response.data.Mobile,
            Fax: response.data.Fax,
            BirthDate: new Date(response.data.BirthDate),
            BillRate: response.data.BillRate,
            Address: response.data.Address,
            imgSrc: await Storage.get(response.data.ProfileImageURL),
            loadingData: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    let userImg = '';
    if (this.state.imgSrc) {
      userImg = this.state.imgSrc;
    }
    return (
      <React.Fragment>
        <div className='mb-0'>
          {this.state.loadingData && (
            <div className='dash-page-spinner-container'>
              <Col>
                <Spinner
                  className='mr-1 dash-page-spinner'
                  as='span'
                  animation='border'
                  role='status'
                  aria-hidden='true'
                />
              </Col>
            </div>
          )}
          <div className='m-4'>
            <Row>
              <Col className='dash-user-card-left-side'>
                <Avatar
                  alt=''
                  src={userImg}
                  className='dash-profile-user-img cursor-pointer mb-3'
                />
                <h5 className='text-center mb-3'>{this.state.FullName}</h5>
                <p className='text-center'>
                  <a
                    className='dash-email-link'
                    href={'mailto:' + this.state.Email}
                  >
                    {this.state.Email}
                  </a>
                </p>
                <p className='text-center'>{this.state.Mobile}</p>
              </Col>
              <Col>
                <p>Role: {this.state.Role}</p>
                <p>Department: {this.state.Department}</p>
                <p>Fax: {this.state.Fax}</p>
                <p>Address: {this.state.Address}</p>
                <p>City: {this.state.City}</p>
                <p>
                  BirthDate:{' '}
                  <Moment format='YYYY/MM/DD'>{this.state.BirthDate}</Moment>
                </p>
                <p>State: {this.state.State}</p>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserCard;
