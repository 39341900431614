import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, Spinner, ButtonGroup, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faSync,
  faBinoculars,
} from '@fortawesome/free-solid-svg-icons';
//import EditClient from "./editClient";
// import Icon from '@material-ui/core/Icon';
import {
  Matters,
  Tasks,
  DocumentCenter as DocumentCenterAPI,
} from 'services/Api';
//import { Storage } from 'aws-amplify';

import Tooltip from '@material-ui/core/Tooltip';
import FileViewer from 'react-file-viewer';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import UploadDocumentModal from 'components/UploadDocumentModal/uploadDocumentModal';
import axios from 'axios';
interface DocumentState {
  documents: any;
  documentFilter: {
    matterId: '';
    taskId: '';
  };
  noResults: Boolean;
  props: any;
  displayedDocuments: any;
  loading: boolean;
  matters: [];
  tasks: [];
  openDialog: boolean;
  openedFilePath: any;
  openedFileType: '';
  openedFileName: '';
  openedFileId: '';
  currentMatter: any;
  currentTask: any;
  uploadId: any;
  type: string;
}
class DocumentCenter extends React.Component<{ location }, DocumentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      documents: [],
      documentFilter: {
        matterId: this.props.location?.state?.matterId
          ? this.props.location.state.matterId
          : '',
        taskId: this.props.location?.state?.taskId
          ? this.props.location.state.taskId
          : '',
      },
      noResults: false,
      props: props,
      displayedDocuments: [],
      loading: false,
      matters: [],
      tasks: [],
      openDialog: false,
      openedFilePath: null,
      openedFileType: '',
      openedFileName: '',
      openedFileId: '',
      currentMatter: null,
      currentTask: null,
      type: '',
      uploadId: null,
    };
  }
  changeHandler = (e: any) => {
    let { name, value } = e.target;
    this.setState((state) => ({
      documentFilter: Object.assign({}, state.documentFilter, {
        [name]: value,
      }),
    }));
  };

  onChangeMatter = (e) => {
    const matter = this.state.matters.find(
      (currentMatter: any) => currentMatter.MatterId === Number(e.target.value)
    );

    if (e.target.value !== 'null') {
      this.setState({ currentMatter: matter });
    }

    this.changeHandler(e);
  };

  onChangeTask = (e) => {
    const task = this.state.tasks.find(
      (currentTask: any) => currentTask.TaskId === Number(e.target.value)
    );

    if (e.target.value !== 'null') {
      this.setState({ currentTask: task });
    }

    this.changeHandler(e);
  };

  componentDidMount() {
    this.fetchApi();
  }

  fetchApi = async () => {
    try {
      this.setState({ loading: true });
      await DocumentCenterAPI.index()
        .then((response) => {
          this.setState({
            documents: response.data,
            displayedDocuments: response.data,
            loading: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ loading: true });
      await Matters.index()
        .then((response) => {
          this.setState({ matters: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
      await Tasks.index()
        .then((response) => {
          this.setState({ tasks: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
      this.handleSearch();
      this.setState({ loading: false });
    } catch (err) {
      alert(err.message ? err.message : err);
    }
  };

  handleSearch = () => {
    const filteredDocuments: any = this.state.documents.filter(
      (document: any) => {
        return (
          (document.matter?.MatterId?.toString() ===
            this.state.documentFilter.matterId.toString() ||
            this.state.documentFilter.matterId === '') &&
          (document.task?.TaskId.toString() ===
            this.state.documentFilter.taskId.toString() ||
            this.state.documentFilter.taskId === '')
        );
      }
    );
    if (
      filteredDocuments.length !== this.state.displayedDocuments.length &&
      filteredDocuments.length === 0
    ) {
      this.setState({ noResults: true });
    } else {
      this.setState({ noResults: false });
    }
    this.setState({ displayedDocuments: filteredDocuments });
  };

  handleReset = () => {
    this.setState(
      (state) => ({
        documentFilter: Object.assign(
          {},
          state.documentFilter,
          { matterId: '' },
          { taskId: '' }
        ),
      }),
      () => {
        this.handleSearch();
      }
    );
  };
  submitForm = (e: any) => {
    e.preventDefault();
    this.handleSearch();
  };
  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  handelIsUploadShowing = (type) => {
    if (this.state.currentMatter) {
      this.setState({ uploadId: this.state.currentMatter.MatterId });
      this.setState({ type: type });
    }

    if (this.state.currentTask) {
      this.setState({ uploadId: this.state.currentTask.TaskId });
      this.setState({ type: type });
    }
  };

  onError(e) {
    console.log(e, 'error in file-viewer');
  }
  handleOpenFile = async (filePath, fileType, fileName, fileId) => {
    console.log(filePath);
    await axios
      .get(
        `https://dashpublic.s3.us-east-2.amazonaws.com/public/${filePath}`
        //   , {
        //   level: 'public',
        //   bucket: 'dashpublic',
        // }
      )
      .then(async (result) => {
        console.log(result);

        this.setState({
          openDialog: true,

          openedFilePath: result.data,
          openedFileType: fileType,
          openedFileName: fileName,
          openedFileId: fileId,
        });
        console.log(this.state.openedFilePath);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({
      openDialog: false,
      openedFilePath: '',
      openedFileType: '',
      openedFileName: '',
    });
  };

  handlemodalClose = async () => {
    this.setState({ uploadId: null });

    this.setState({ loading: true });
    await DocumentCenterAPI.index()
      .then((response) => {
        this.setState({
          documents: response.data,
          displayedDocuments: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ loading: false });
  };

  handleModalCancel = () => {
    this.setState({ uploadId: null });
  };

  render() {
    const { displayedDocuments, documentFilter, tasks, matters } = this.state;
    let docClass = this;
    return (
      <div>
        {this.state.loading && (
          <div className='dash-page-spinner-container'>
            <Col>
              <Spinner
                className='mr-1 dash-page-spinner'
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
            </Col>
          </div>
        ) }

        <div className='dash-grid-body'>
          <Row className='mb-4'>
          <Col md={12} className='d-flex justify-content-center'>
              <div className='float-left position-absolute dash-left-3 dash-top-3'>
              <ButtonGroup>
                <Button
                  className='btn btn-xs btn-info dash-back-button mb-0 '
                  onClick={ () => this.state.props.history.goBack() }
                >
                  Back
                </Button>
              </ButtonGroup>
              </div>
              <div className='dash-page-title'>
                <h3 className='mb-0 text-center'>Document Center</h3>
              <div className='dash-line-icon'></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={ 12 }>
              <Accordion defaultActiveKey='0'>
                <Card className='dash-card'>
                  <Card.Header className='dash-card-header'>
                    <span className='dash-card-title'>Search Criteria</span>
                    <Accordion.Toggle
                      as={ Button }
                      variant='link'
                      eventKey='0'
                      className='float-right'
                    >
                      <FontAwesomeIcon
                        className='dash-card-title'
                        icon={ faFilter }
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Card.Body className='dash-card-body'>
                    <Accordion.Collapse eventKey='0'>
                      <div>
                        <Card.Body className='dash-card-body'>
                          <Row className='form-group'>
                            <Col md='6'>
                              <Row>
                                <Col md='2' className='text-right'>
                                  <label className='dash-form-label'>
                                    Matter
                                  </label>
                                </Col>
                                <Col md='6' className='pr-0'>
                                  <select
                                    id='matters'
                                    name='matterId'
                                    className='form-control dash-form-control'
                                    placeholder='All'
                                    onChange={ this.onChangeMatter }  
                                    value={ documentFilter.matterId }
                                  >
                                    <option value="">All</option>
                                    { matters.map((x: any, i) => (
                                      <option value={x.MatterId} key={x.MatterId}>
                                        {x.MatterTitle}
                                      </option>
                                    )) }
                                  </select>
                                </Col>
                                <Col md={ 1 } className='text-right'>
                                  <Tooltip
                                    classes={ {
                                      tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                                      arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                                    } }
                                    arrow
                                    placement='top'
                                    title='Upload Documents'
                                  >
                                    <button
                                      className='dash-show-message-button cursor-pointer'
                                      onClick={ (event) => {
                                        this.handelIsUploadShowing('Matter');
                                      } }
                                    >
                                      <i className='fas fa-cloud-upload-alt'></i>
                                    </button>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Col>

                            <Col md='6'>
                              <Row>
                                <Col md='2' className='text-right'>
                                  <label className='dash-form-label'>
                                    Tasks
                                  </label>
                                </Col>
                                <Col md='6' className='pr-0'>
                                  <select
                                    id='tasks'
                                    name='taskId'
                                    className='form-control dash-form-control'
                                    placeholder='All'
                                    onChange={this.onChangeTask}
                                    value={documentFilter.taskId}
                                  >
                                    <option value="">All</option>
                                    { tasks.map((x: any, i) => (
                                      <option value={x.TaskId} key={x.TaskId} >
                                        {x.TaskTitle }
                                      </option>
                                    )) }
                                  </select>
                                </Col>
                                <Col md={ 1 } className='text-right'>
                                  <Tooltip
                                    classes={ {
                                      tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                                      arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                                    } }
                                    arrow
                                    placement='top'
                                    title='Upload Documents'
                                  >
                                    <button
                                      className='dash-show-message-button cursor-pointer'
                                      // onClick={() => history.push(`/EditMatter/${c.MatterId}`)}
                                      onClick={ (event) =>
                                        this.handelIsUploadShowing('Task')
                                      }
                                    >
                                      <i className='fas fa-cloud-upload-alt'></i>
                                    </button>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                        { this.state.uploadId && (
                          <UploadDocumentModal
                            isModalShowing={ this.state.uploadId }
                            itemId={
                              this.state.type === 'Matter'
                                ? this.state.currentMatter.MatterId
                                : this.state.currentTask.TaskId
                            }
                            onModalCloseClick={ () => this.handlemodalClose() }
                            handleModalCancel={ this.handleModalCancel }
                            itemName={
                              this.state.type === 'Matter'
                                ? this.state.currentMatter.MatterTitle
                                : this.state.currentTask.TaskTitle
                            }
                            resourceType={ this.state.type }
                          />
                        ) }
                      </div>
                    </Accordion.Collapse>

                    <div className='clearfix'>
                      <ButtonGroup className='float-right clearfix'>
                        <Button
                          type='button'
                          variant='secondary'
                          size='sm'
                          className='dash-secondary-button margin-separator'
                          onClick={ this.handleReset }
                        >
                          Reset
                        </Button>
                        <Button
                          size='sm'
                          type='button'
                          className='dash-primary-button'
                          onClick={ this.handleSearch }
                        >
                          Search
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col md={ 12 }>
              <table className='table table-striped table-center mt-1'>
                <thead>
                  <tr>
                    <th>
                      <Tooltip
                        classes={ {
                          tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                          arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                        } }
                        arrow
                        placement='top'
                        title='Refresh Data'
                      >
                        <button
                          className='dash-show-message-button dash-refresh-button'
                          onClick={ this.fetchApi }
                        >
                          <FontAwesomeIcon
                            className='dash-card-title'
                            icon={ faSync }
                          />
                        </button>
                      </Tooltip>
                    </th>
                    <th>Document Name</th>
                    <th>Type</th>
                    <th>Related Task/Matter</th>
                  </tr>
                </thead>
                <tbody>
                  { displayedDocuments.map(function (doc: any, i) {
                    return (
                      <tr key={ 'doc-' + i }>
                        <td>
                          <Tooltip
                            classes={ {
                              tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                              arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                            } }
                            arrow
                            placement='top'
                            title='View Document'
                          >
                            <button
                              className='dash-show-message-button'
                              onClick={ () =>
                                docClass.handleOpenFile(
                                  doc.document.fileURL,
                                  doc.document.fileType,
                                  doc.document.name,
                                  doc.id
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className='dash-card-title'
                                icon={ faBinoculars }
                              />
                            </button>
                          </Tooltip>
                        </td>
                        <td>{ doc.document.name }</td>
                        <td>{ doc.type }</td>
                        <td>
                          { doc.matter
                            ? doc.matter.MatterTitle
                            : doc.task.TaskTitle }
                        </td>
                      </tr>
                    );
                  }) }
                </tbody>
              </table>
              { this.state.noResults && (
                <h3 className='margin-separator-top-search text-center text-white'>
                  No results found
                </h3>
              ) }
            </Col>
          </Row>
        </div>
        <Dialog
          fullScreen
          open={ docClass.state.openDialog }
          onClose={ docClass.handleClose }
        >
          <AppBar className='dash-appBar'>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={ docClass.handleClose }
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
              <h4> { docClass.state.openedFileName } </h4>
            </Toolbar>
          </AppBar>

          {/* <img
            // width='500'
            // height='800'
            style={{ width: '500px', height: '500px' }}
            alt={docClass.state.openedFileName}
            src={docClass.state.openedFilePath}
          /> */}
          <FileViewer
            key={ docClass.state.openedFileId }
            fileType={ docClass.state.openedFileType }
            filePath={ docClass.state.openedFilePath }
            onError={ docClass.onError }
          />
        </Dialog>
      </div>
    );
  }
}

export default DocumentCenter;
