import { get, post } from 'config/api';

const User_API = 'User';

export const Users = {
  index: () => get(`${User_API}/AllUsers`),
  single: (id: number) => get(`${User_API}/GetUserById?UserId=${id}`),
  singleByUsername: (username: string) =>
    get(`${User_API}/GetUserByUserName?UserName=${username}`),
  create: (user: object) => post(`${User_API}/SaveUser`, user),
  createAuth: (user: object) => post(`${User_API}/save`, user),
  update: (user: object) => post(`${User_API}/UpdateUserProfile`, user),
};
