import React from 'react';
import { Row, Card, Col, Modal, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import moment from 'moment';
import validator from 'validator';
import CancelConfirmationModal from '../../CancelConfirmationModal/cancelConfirmationModal';
import { Users, Tasks, Templates, Matters, Priorities } from 'services/Api';

interface TaskState {
  TaskId: null;
  MatterId: any;
  UserId: any;
  TaskStatusId: any;
  PriorityId: any;
  DueDate: any;
  TaskTitle: string;
  BillRate: any;
  Cost: any;
  EffortAct: any;
  EffortEst: any;
  BillingCode: string;
  Instructions: string;
  TaskStatus: null;
  matters: [];
  priorities: [];
  taskStatus: [];
  Users: [];
  twilioToken: any;
  show: boolean;
  statusIdParam: any;
  dueDateParam: any;
  matterTemplateP: any;
  filteredMatterP: any;
  loading: boolean;
  isTemplate: boolean;
  TaskTemplate: string;
  TaskTemplates: [];
  TaskTemplateId: any;
  showErrors: boolean;
  loadingData: boolean;
  formTouched: boolean;
  showCancelConfirm: boolean;
  dueDateDaysOff: number;
}
class CreateTaskAdmin extends React.Component<
  {
    isAddShowing;
    onModalCloseClick(type, title);
    statusIdParam?;
    dueDateParam?;
    matterTemplateP?;
    filteredMatterP?;
    isTemplate?;
    taskId?;
  },
  TaskState
> {
  // private params: any;
  private DueDate = {
    today: 1,
    thisWeek: 2,
    thisMonth: 3,
    later: 4,
  };
  constructor(props: any) {
    super(props);

    this.state = {
      TaskId: null,
      MatterId: null,
      UserId: null,
      TaskStatusId: null,
      PriorityId: '',
      DueDate: new Date(),
      TaskTitle: '',
      BillRate: 1,
      Cost: 0,
      EffortAct: 0,
      EffortEst: 0,
      BillingCode: '',
      Instructions: '',
      TaskStatus: null,
      matters: [],
      priorities: [],
      taskStatus: [],
      Users: [],
      twilioToken: '',
      show: this.props.isAddShowing,
      statusIdParam: this.props.statusIdParam,
      dueDateParam: this.props.dueDateParam,
      matterTemplateP: this.props.matterTemplateP,
      filteredMatterP: this.props.filteredMatterP,
      loading: false,
      isTemplate: this.props.isTemplate,
      TaskTemplate: '',
      TaskTemplates: [],
      TaskTemplateId: null,
      showErrors: false,
      loadingData: false,
      formTouched: false,
      showCancelConfirm: false,
      dueDateDaysOff: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.props.taskId) {
      this.setState({ loadingData: true });
    }
    let matterTP = this.state.matterTemplateP;
    if (matterTP > 0) {
      this.setState(
        (state) => ({
          MatterId: matterTP,
        }),
        () => {
          this.fetchData();
        },
      );
    }
    let filteredMatterP = this.state.filteredMatterP;
    if (filteredMatterP > 0) {
      this.setState({ loadingData: true });
      this.setState({ MatterId: filteredMatterP });
    }
    let statusId = this.state.statusIdParam;
    let dueDateParam = this.state.dueDateParam;
    let date = new Date();
    let dateToShow: Date;
    if (statusId > 0) {
      this.setState(
        (state) => ({
          TaskStatusId: statusId,
        }),
        () => {
          this.fetchData();
        },
      );
    } else if (dueDateParam > 0) {
      if (dueDateParam === this.DueDate.today) {
        dateToShow = date;
      } else if (dueDateParam === this.DueDate.thisWeek) {
        dateToShow = moment(date).endOf('week').toDate();
      } else if (dueDateParam === this.DueDate.thisMonth) {
        dateToShow = moment(date).endOf('month').toDate();
      } else {
        dateToShow = moment(date).add(1, 'month').startOf('month').toDate();
      }
      this.setState(
        (state) => ({
          DueDate: dateToShow,
        }),
        () => {
          this.fetchData();
        },
      );
    } else {
      this.fetchData();
    }
    this.setState({ loading: false });
  }

  fetchData = async () => {
    if (this.state.isTemplate) {
      const matterTemplates = await Templates.matterTemplates();
      this.setState({ matters: matterTemplates.data });
    } else {
      const matters = await Matters.index();
      this.setState({ matters: matters.data });
    }

    const userObj: any = await Users.index();
    this.setState({ Users: userObj.data });

    await Priorities.index()
      .then((response) => {
        this.setState({ priorities: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    await Tasks.status()
      .then((response) => {
        this.setState({ taskStatus: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    const taskTemplatesRequest = await Templates.taskTemplates();
    this.setState({ TaskTemplates: taskTemplatesRequest.data });

    let taskId = this.props.taskId;
    if (taskId) {
      let response: any;

      try {
        if (this.state.isTemplate) {
          response = await Templates.singleTaskTemplate(taskId);
        } else {
          response = await Tasks.single(taskId);
        }
      } catch (err) {
        alert(err.message ? err.message : err);
      }
      this.setState({
        TaskId: this.state.isTemplate
          ? response.data.TaskTemplateId
          : response.data.TaskId,
        TaskTitle: this.state.isTemplate
          ? response.data.Title
          : response.data.TaskTitle,
        DueDate: new Date(response.data.DueDate),
        Cost: response.data.Cost,
        EffortAct: response.data.EffortAct,
        EffortEst: response.data.EffortEst,
        BillRate: response.data.BillRate,
        Instructions: response.data.Instructions,
        BillingCode: response.data.BillingCode,
        PriorityId: response.data.Priority?.PriorityId,
        TaskStatusId: response.data.TaskStatus?.TaskStatusId,
        UserId: response.data.Resource?.UserId,
        MatterId:
          response.data.MatterTask && response.data.MatterTask.length > 0
            ? response.data.MatterTask[0].MatterId
            : response.data.MatterTemplateTaskTemplate &&
              response.data.MatterTemplateTaskTemplate.length > 0
              ? response.data.MatterTemplateTaskTemplate[0].MatterTemplateId
              : null,
        loadingData: false,
        TaskTemplate: response.data.TaskTemplate,
        TaskTemplateId: response.data.TaskTemplate
          ? response.data.TaskTemplate.TaskTemplateId
          : '',
        dueDateDaysOff: this.state.isTemplate ? response.data.DueDate : 0,
      });
    }
    this.setState({ loadingData: false });
    this.setState({ loading: false });
  };

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    if (
      this.state.EffortAct > 0 &&
      this.state.BillRate > 0 &&
      name === 'BillRate'
    ) {
      let effortAct = this.state.EffortAct;
      let billrate = this.state.BillRate;
      this.setState({ Cost: effortAct * billrate });
    }

    if (name === 'UserId') {
      const user: any = this.state.Users.find(
        (u: any) => parseInt(value) === u.UserId,
      );
      if (user) {
        if (parseFloat(user.BillRate) > 0) {
          this.setState({ BillRate: parseFloat(user.BillRate) });
        } else {
          this.setState({ BillRate: 1.0 });
        }
      }
    }
    value = type === 'number' ? parseFloat(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    this.setState({ formTouched: true });
  };

  changeTemplate = (e: any) => {
    this.changeHandler(e);
    const templateId: number = e.target.value;
    const template: any = this.state.TaskTemplates.find(
      (taskTemplate: any) => taskTemplate.TaskTemplateId === Number(templateId),
    );
    if (template) {
      this.setState({
        UserId: template.Resource ? template.Resource.UserId : null,
        TaskStatusId: template.TaskStatus.TaskStatusId,
        PriorityId: template.Priority.PriorityId,
        DueDate: new Date().setDate(new Date().getDate() + template.DueDate),
        BillRate: template.BillRate,
        Cost: template.Cost,
        EffortAct: template.EffortAct,
        EffortEst: template.EffortEst,
        BillingCode: template.BillingCode,
        Instructions: template.Instructions,
        TaskTitle: template.Title,
      });
    }
  };

  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));
    this.setState({ formTouched: true });
  };
  changeCostHandler = (value, name) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    this.setState({ formTouched: true });
  };
  submitHandler = async (e: any) => {
    e.preventDefault();
    this.setState({ showErrors: true });
    let valid: Boolean = false;
    if (this.state.isTemplate) {
      if (
        this.state.TaskTitle !== '' &&
        this.state.BillRate.toString() !== '' &&
        this.state.EffortAct.toString() !== '' &&
        this.state.EffortEst.toString() !== '' &&
        this.state.Cost.toString() !== '' &&
        this.state.BillingCode.toString() !== '' &&
        this.state.PriorityId.toString() !== '0' &&
        this.state.PriorityId !== '' &&
        parseInt(this.state.EffortAct) > 0 &&
        parseInt(this.state.EffortEst) > 0 &&
        parseInt(this.state.Cost) > 0 &&
        parseInt(this.state.BillRate) > 0
      ) {
        valid = true;
      } else {
        valid = false;
      }
    } else if (
      this.state.TaskTitle !== '' &&
      this.state.BillRate.toString() !== '' &&
      this.state.EffortAct.toString() !== '' &&
      this.state.EffortEst.toString() !== '' &&
      this.state.Cost.toString() !== '' &&
      this.state.DueDate.toString() !== '' &&
      this.state.BillingCode.toString() !== '' &&
      parseInt(this.state.EffortAct) > 0 &&
      parseInt(this.state.EffortEst) > 0 &&
      parseInt(this.state.Cost) > 0 &&
      parseInt(this.state.BillRate) > 0
    ) {
      valid = true;
    } else {
      valid = false;
    }
    if (valid) {
      this.setState({ loading: true });

      let taskObj = {
        Resource: {
          UserId: Number(this.state.UserId) === 0 ? null : this.state.UserId,
        },
        TaskStatus: { TaskStatusId: this.state.TaskStatusId },
        Priority: {
          PriorityId:
            Number(this.state.PriorityId) === 0 ? null : this.state.PriorityId,
        },
        BillRate: this.state.BillRate,
        Cost: this.state.Cost,
        EffortAct: this.state.EffortAct,
        DueDate: this.state.DueDate,
        EffortEst: this.state.EffortEst,
        BillingCode: this.state.BillingCode,
        Instructions: this.state.Instructions,
        BillingNarrativeId: '1',
      };
      if (this.state.isTemplate) {
        taskObj['TaskTemplateId'] = this.state.TaskId;
        taskObj['Title'] = this.state.TaskTitle;
        taskObj['MatterTemplateId'] = this.state.MatterId;
        taskObj['DueDate'] = this.state.dueDateDaysOff;
      } else {
        taskObj['TaskId'] = this.state.TaskId;
        taskObj['TaskTitle'] = this.state.TaskTitle;
        taskObj['MatterId'] =
          this.state.MatterId === '0' ? null : this.state.MatterId;

        if (
          this.state.TaskTemplateId &&
          this.state.TaskTemplateId > 0
        ) {
          taskObj['TaskTemplate'] = {
            TaskTemplateId:
              this.state.TaskTemplateId?.toString() === '0'
                ? null
                : Number(this.state.TaskTemplateId),
          };
        }
        taskObj['DueDate'] = moment(new Date(this.state.DueDate)).format(
          'YYYY-MM-DD HH:mm:ss',
        );
      }

      try {
        if (this.state.isTemplate) {
          await Templates.createTaskTemplate(taskObj);
        } else {
          await Tasks.create(taskObj);
        }

        this.setState({ loading: false });
        toast.success('Data saved successfully!');
        this.handleClose();
      } catch (err) {
        toast.error('Data not saved');
        this.setState({ loading: false });
      }
    }
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalCloseClick('task', this.state.TaskTitle);
  };

  render() {
    const { matters, priorities, taskStatus, Users, TaskTemplates } =
      this.state;
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size="xl"
          backdrop="static"
          className="pt-5"
          scrollable={true}
        >
          <form onSubmit={this.submitHandler}>
            <div className="mb-0">
              <Row>
                <Col md={12}>
                  <Card className="dash-card">
                    <Modal.Header className="p-0">
                      <Card.Header className="dash-card-header">
                        <span className="dash-card-title">
                          {this.props.taskId ? 'Edit ' : 'Add '}
                          {this.state.isTemplate && <span>Template </span>}Task
                        </span>
                        <ButtonGroup className="float-right">
                          <Button
                            type="button"
                            onClick={() => {
                              this.state.formTouched
                                ? this.setState({ showCancelConfirm: true })
                                : this.handleClose();
                            }}
                            size="sm"
                            className="dash-secondary-button margin-separator"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            className="dash-primary-button"
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <Spinner
                                className="mr-1 dash-button-spinner"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}

                            {this.state.loading ? (
                              <span>Saving...</span>
                            ) : (
                              <span>Save</span>
                            )}
                          </Button>
                        </ButtonGroup>
                      </Card.Header>{' '}
                    </Modal.Header>
                    {this.state.loadingData && (
                      <div className="dash-page-spinner-container">
                        <Col>
                          <Spinner
                            className="mr-1 dash-page-spinner"
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                          />
                        </Col>
                      </div>
                    )}
                    <Modal.Body className="px-0 pt-0 pb-5 dash-card-body">
                      <Card.Body>
                        <div className="dash-card-subsection mb-5">
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">
                                Task Title
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={!this.state.isTemplate ? 4 : 10}>
                              <input
                                type="text"
                                className="form-control dash-form-control"
                                id="TaskTitle"
                                name="TaskTitle"
                                value={this.state.TaskTitle || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.TaskTitle) && (
                                  <span className="dash-error-message">
                                    Required
                                  </span>
                                )}
                            </Col>
                            {!this.state.isTemplate && (
                              <React.Fragment>
                                <Col md={2}>
                                  <div className="dash-form-label">
                                    Task Template
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <select
                                    id="TaskTemplateId"
                                    name="TaskTemplateId"
                                    onChange={(event) =>
                                      this.changeTemplate(event)
                                    }
                                    className="form-control dash-form-control"
                                    placeholder="None"
                                    value={this.state.TaskTemplateId || ''}
                                    disabled={this.props.taskId ? true : false}
                                  >
                                    <option selected hidden value="-1">
                                      None
                                    </option>
                                    {TaskTemplates.map((x: any, i) => (
                                      <option
                                        key={'x' + i}
                                        value={x.TaskTemplateId}
                                      >
                                        {x.Title}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">
                                {this.state.isTemplate
                                  ? 'Matter Template'
                                  : 'Matter'}
                              </div>
                            </Col>
                            <Col md={4}>
                              <select
                                id="MatterId"
                                name="MatterId"
                                onChange={this.changeHandler}
                                className="form-control dash-form-control"
                                placeholder="None"
                                value={this.state.MatterId || ''}
                              >
                                <option value="0">None </option>
                                {matters.map((x: any, i) => (
                                  <option
                                    key={'x' + i}
                                    value={
                                      !this.state.isTemplate
                                        ? x.MatterId
                                        : x.TemplateMatterId
                                    }
                                  >
                                    {!this.state.isTemplate
                                      ? x.MatterTitle
                                      : x.MatterTemplateTitle}
                                  </option>
                                ))}
                                
                              </select>
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">
                                Priority
                                {this.state.isTemplate && (
                                  <span className="dash-require-astrike"></span>
                                )}
                              </div>
                            </Col>

                            <Col md={4}>
                              <select
                                id="PriorityId"
                                name="PriorityId"
                                onChange={this.changeHandler}
                                className="form-control dash-form-control"
                                placeholder="None"
                                value={this.state.PriorityId || ''}
                              >
                                <option selected hidden value="-1">
                                  None
                                </option>
                                {priorities.map((x: any, i) => (
                                  <option key={'x' + i} value={x.PriorityId}>
                                    {x.PriorityName}
                                  </option>
                                ))}
                              </select>
                              {this.state.isTemplate &&
                                this.state.showErrors === true &&
                                this.state.PriorityId <= 0 && (
                                  <span className="dash-error-message">
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">User</div>
                            </Col>
                            <Col md={4}>
                              <select
                                name="UserId"
                                className="form-control dash-form-control"
                                placeholder="None"
                                value={this.state.UserId || ''}
                                onChange={this.changeHandler}
                              >
                                <option value="0">None </option>
                                {Users.map((x: any, i) => (
                                  <option value={x.UserId} key={x.UserId}>
                                    {x.FullName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">
                                Cost $
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                id="Cost"
                                name="Cost"
                                type="number"
                                step="any"
                                lang="nb"
                                className="form-control dash-form-control"
                                value={this.state.Cost || null}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                this.state.Cost <= 0 && (
                                  <span className="dash-error-message">
                                    The cost must to be greater than 0
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">
                                Actl. Effort in hrs
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type="number"
                                step="any"
                                lang="nb"
                                className="form-control dash-form-control"
                                id="EffortAct"
                                name="EffortAct"
                                value={this.state.EffortAct || null}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                this.state.EffortAct <= 0 && (
                                  <span className="dash-error-message">
                                    The actually effort must to be greater than
                                    0
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">
                                Due Date
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            {!this.state.isTemplate && (
                              <Col md={4}>
                                <label className="dash-datepicker">
                                  <DatePicker
                                    className="form-control dash-form-control"
                                    id="DueDate"
                                    showTimeSelect
                                    name="DueDate"
                                    selected={new Date(this.state.DueDate)}
                                    onChange={(e) =>
                                      this.changeDateHandler(e, 'DueDate')
                                    }
                                    onFocus={(e) => e.target.blur()}
                                    popperPlacement="left"
                                  />
                                  {this.state.showErrors === true &&
                                    !this.state.DueDate && (
                                      <span className="dash-error-message">
                                        Required
                                      </span>
                                    )}
                                </label>
                              </Col>
                            )}
                            {this.state.isTemplate && (
                              <Col md={4}>
                                <input
                                  type="number"
                                  className="form-control dash-form-control"
                                  id="dueDateDaysOff"
                                  max="1000"
                                  name="dueDateDaysOff"
                                  value={this.state.dueDateDaysOff || ''}
                                  onChange={this.changeHandler}
                                />
                                {this.state.showErrors === true &&
                                  !this.state.DueDate && (
                                    <span className="dash-error-message">
                                      Required
                                    </span>
                                  )}
                              </Col>
                            )}
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">
                                Est. Effort in hrs
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type="number"
                                step="any"
                                lang="nb"
                                className="form-control dash-form-control"
                                id="EffortEst"
                                name="EffortEst"
                                value={this.state.EffortEst || null}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                this.state.EffortEst <= 0 && (
                                  <span className="dash-error-message">
                                    The estimated effort must to be greater than 0
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">
                                Billing Code
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type="text"
                                className="form-control dash-form-control"
                                id="BillingCode"
                                name="BillingCode"
                                value={this.state.BillingCode || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(
                                  this.state.BillingCode.toString(),
                                ) && (
                                  <span className="dash-error-message">
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className="dash-form-label">
                                Bill Rate $
                                <span className="dash-require-astrike"></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type="number"
                                step="any"
                                lang="nb"
                                className="form-control dash-form-control"
                                id="BillRate"
                                name="BillRate"
                                value={this.state.BillRate || null}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                this.state.BillRate <= 0 && (
                                  <span className="dash-error-message">
                                    the bill rate must to be greater than 0
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className="dash-form-label">
                                Status
                                {this.state.isTemplate && (
                                  <span className="dash-require-astrike"></span>
                                )}
                              </div>
                            </Col>
                            {!this.state.isTemplate && (
                              <React.Fragment>
                                <Col md={4}>
                                  <select
                                    id="TaskStatusId"
                                    name="TaskStatusId"
                                    onChange={this.changeHandler}
                                    className="form-control dash-form-control"
                                    placeholder="None"
                                    value={this.state.TaskStatusId || ''}
                                  >
                                    <option selected hidden value="-1">
                                      None
                                    </option>
                                    {taskStatus.map((x: any, i) => (
                                      <option
                                        key={'x' + i}
                                        value={x.TaskStatusId}
                                      >
                                        {x.TaskStatusName}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </React.Fragment>
                            )}
                            {this.state.isTemplate && (
                              <React.Fragment>
                                <Col md={4}>
                                  <select
                                    id="TaskStatusId"
                                    name="TaskStatusId"
                                    onChange={this.changeHandler}
                                    className="form-control dash-form-control"
                                    placeholder="None"
                                    value={this.state.TaskStatusId || ''}
                                  >
                                    <option selected hidden value="-1">
                                      None
                                    </option>
                                    {taskStatus.map((x: any, i) => (
                                      <option
                                        key={'x' + i}
                                        value={x.TaskStatusId}
                                      >
                                        {x.TaskStatusName}
                                      </option>
                                    ))}
                                  </select>
                                  {this.state.showErrors === true &&
                                    this.state.TaskStatusId <= 0 && (
                                      <span className="dash-error-message">
                                        Required
                                      </span>
                                    )}
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>
                          <Row className="form-group">
                            <Col md={2}>
                              <div className=" dash-form-label">
                                Instructions
                                {/* <span className="dash-require-astrike"></span> */}
                              </div>
                            </Col>
                            <Col md={10}>
                              <textarea
                                id="Instructions"
                                name="Instructions"
                                value={this.state.Instructions || ''}
                                onChange={this.changeHandler}
                                className="form-control dash-form-control"
                                data-rows="3"
                              ></textarea>
                              {/* {this.state.showErrors === true &&
                              validator.isEmpty(this.state.Instructions) && (
                                <span className='dash-error-message'>
                                  Required
                                </span>
                              )} */}
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col md={2}></Col>
                            <Col className="dash-form-label text-left">
                              Fields with{' '}
                              <span className="dash-require-astrike"></span> are
                              required fields.
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Modal.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </form>
        </Modal>
        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={this.state.showCancelConfirm}
            onModalYesClick={() => this.handleClose()}
            onModalNoClick={() => this.setState({ showCancelConfirm: false })}
          ></CancelConfirmationModal>
        )}
      </React.Fragment>
    );
  }
}

export default CreateTaskAdmin;
