import { actionTypes } from "../constants/actionTypes";

export const initialState = {
  channelsMetadata: [],
  loading: false,
  error: null,
  calledFromTheBackend: false,
};

function channelMetadataReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CHANNELS_METADATA_START: {
      return {
        ...state,
        error: null,
        loading: true,
        calledFromTheBackend: true,
      };
    }
    case actionTypes.GET_CHANNELS_METADATA_SUCCESS: {
      return {
        ...state,
        channelsMetadata: action.payload,
        loading: false,
        error: null,
      };
    }
    case actionTypes.GET_CHANNELS_METADATA_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actionTypes.SET_METADATA_CHANNELS: {
      return {
        ...state,
        channelsMetadata: action.payload,
      };
    }
    case actionTypes.UPDATE_METADATA_ITEM: {
      const newMetadataUpdate = action.payload;
      let index = state.channelsMetadata.findIndex(
        (cmt: any) =>
          cmt.Channel && cmt.Channel.Id === newMetadataUpdate.Channel.Id
      );
      state.channelsMetadata.splice(index, 1);
      return {
        ...state,
        channelsMetadata: [...state.channelsMetadata, newMetadataUpdate],
      };
    }
    default:
      return state;
  }
}

export default channelMetadataReducer;
