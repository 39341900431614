import React from 'react';
import {
  Row,
  Card,
  Col,
  Modal,
  Spinner,
  Button,
  ButtonGroup
} from 'react-bootstrap';
// import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Sessions, Tasks, Matters } from 'services/Api';

interface ScheduleState {
  SessionId: any;
  Title: string;
  StartTime: Date;
  Duration: any;
  Description: string;
  ZoomMeetingId: any;
  MatterId: any;
  TaskId: any;
  meetings: any;
  tasks: any;
  matters: any;
  loading: boolean;
  formTouched: boolean;
  showCancelConfirm: boolean;
  show: boolean;
}

class SessionEdit extends React.Component<
  { onModalClose(type, title); isModalOpen; sessionId },
  ScheduleState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      SessionId: null,
      Title: '',
      StartTime: new Date(),
      Duration: '',
      Description: '',
      ZoomMeetingId: null,
      MatterId: null,
      TaskId: null,
      meetings: [],
      tasks: [],
      matters: [],
      loading: false,
      formTouched: false,
      showCancelConfirm: false,
      show: this.props.isModalOpen
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const meetingLookups = await Sessions.zoomMeetingsList();
      const taskLookups = await Tasks.index();
      const MatterLookups = await Matters.index();
      const currentSession = await Sessions.single(this.props.sessionId);

      this.setState({
        SessionId: currentSession.data.SessionId,
        Title: currentSession.data.Title,
        // StartTime: currentSession.data.StartTime,
        Duration: currentSession.data.Duration,
        Description: currentSession.data.Description,
        ZoomMeetingId: currentSession.data.ZoomMeetingId,
        MatterId: currentSession.data.MatterId,
        TaskId: currentSession.data.TaskId,
        meetings: meetingLookups.data.meetings,
        tasks: taskLookups.data,
        matters: MatterLookups.data
      });
    } catch (err) {
      alert(`Error on fetching data ${err.message ? err.message : err}`);
    }
  };
  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value
    }));

    this.setState({ formTouched: true });
  };

  submitHandler = async (e: any) => {
    e.preventDefault();
    const zoomData = {
      topic: this.state.Title,
      type: '2',
      start: this.state.StartTime,
      duration: this.state.Duration
    };

    const zoomResponse = await Sessions.scheduleZoomMeeting(zoomData);

    const session = {
      Title: this.state.Title,
      StartTime: this.state.StartTime,
      Duration: this.state.Duration,
      Description: this.state.Description,
      ZoomMeetingId: zoomResponse.data.id,
      ZoomLink: zoomResponse.data.start_url,
      MatterId: this.state.MatterId,
      TaskId: this.state.TaskId,
      SessionId: this.state.SessionId
    };

    await Sessions.create(session)
      .then(async () => {
        this.setState({ loading: false });
        toast.success('Data saved successfully!');
        this.handleClose();
      })
      .catch((error) => {
        toast.error('Data not saved');
        this.setState({ loading: false });
      });
    // history.push('/session');
  };

  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e
    }));
    this.setState({ formTouched: true });
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalClose('session', this.state.Title);
  };

  render() {
    const { meetings, matters, tasks } = this.state;

    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size='xl'
          backdrop='static'
          className='pt-5'
          scrollable={true}
        >
          <form onSubmit={this.submitHandler}>
            <div className='mb-0'>
              <Row>
                <Col md={12}>
                  <Card className='dash-card'>
                    <Modal.Header className='p-0'>
                      <Card.Header className='dash-card-header'>
                        <span className='dash-card-title'>Edit Session</span>
                        <ButtonGroup className='float-right'>
                          <Button
                            type='button'
                            onClick={() => {
                              this.state.formTouched
                                ? this.setState({ showCancelConfirm: true })
                                : this.handleClose();
                            }}
                            size='sm'
                            className='dash-secondary-button margin-separator'
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            className='dash-primary-button'
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <Spinner
                                className='mr-1 dash-button-spinner'
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}

                            {this.state.loading ? (
                              <span>Saving...</span>
                            ) : (
                              <span>Save</span>
                            )}
                          </Button>
                        </ButtonGroup>
                      </Card.Header>
                    </Modal.Header>
                    <Modal.Body className='px-0 pt-0 pb-5 dash-card-body'>
                      <Card.Body>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className='dash-form-label'>Title</div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control'
                              id='Title'
                              name='Title'
                              value={this.state.Title}
                              onChange={this.changeHandler}
                            />
                          </Col>
                          <Col md={2}>
                            <div className='dash-form-label'>Meeting</div>
                          </Col>
                          <Col md={4}>
                            <select
                              id='priorities'
                              name='priorities'
                              className='form-control dash-form-control'
                              placeholder='All'
                              value={this.state.ZoomMeetingId || ''}
                            >
                              <option>All</option>
                              {meetings.map((x: any) => (
                                <option key={x.id} value={x.id}>
                                  {x.topic}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className='dash-form-label'>Matter</div>
                          </Col>
                          <Col md={4}>
                            <select
                              id='priorities'
                              name='priorities'
                              className='form-control dash-form-control'
                              placeholder='All'
                              value={this.state.MatterId || ''}
                            >
                              <option>All</option>
                              {matters.map((x: any) => (
                                <option key={x.MatterId} value={x.MatterId}>
                                  {x.MatterTitle}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col md={2}>
                            <div className='dash-form-label'>Task</div>
                          </Col>
                          <Col md={4}>
                            <select
                              id='priorities'
                              name='priorities'
                              className='form-control dash-form-control'
                              placeholder='All'
                              value={this.state.TaskId || ''}
                            >
                              <option>All</option>
                              {tasks.map((x: any) => (
                                <option key={x.TaskId} value={x.TaskId}>
                                  {x.TaskTitle}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                        {/* <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Start time</div>
                        </Col>
                        <Col md={4}>
                          <label className="dash-datepicker">
                            <DatePicker
                              className="form-control dash-form-control"
                              id="DueDate"
                              name="DueDate"
                              selected={this.state.StartTime}
                              onChange={(e) =>
                                this.changeDateHandler(e, 'StartTime')
                              }
                            />
                          </label>
                        </Col>
                        <Col md={2}>
                          <div className="dash-form-label">Duration</div>
                        </Col>
                        <Col md={4}>
                          <input
                            type="number"
                            className="form-control dash-form-control"
                            id="Duration"
                            name="Duration"
                            value={this.state.Duration}
                            onChange={this.changeHandler}
                          />
                        </Col>
                      </Row> */}
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className=' dash-form-label'>Description</div>
                          </Col>
                          <Col md={10}>
                            <textarea
                              id='Description'
                              name='Description'
                              value={this.state.Description}
                              onChange={this.changeHandler}
                              className='form-control dash-form-control'
                              data-rows='3'
                            ></textarea>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Modal.Body>{' '}
                  </Card>
                </Col>
              </Row>
            </div>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SessionEdit;
