import { get, post } from 'config/api';

const Task_API = 'Task';

export const Tasks = {
  index: () => get(`${Task_API}/GetTasks`),
  single: (id: number) => get(`${Task_API}/GetTask?TaskId=${id}`),
  create: (task: object) => post(`${Task_API}/SaveTask`, task),
  status: () => get(`${Task_API}/GeTasksStatus`),
};
