import { actionTypes } from '../constants/actionTypes'

export const initialState = {
    user: null,
    loading: false,
    error: false,
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_CURRENT_USER: {
            return {
                ...state,
                user: action.payload,
            }
        }
        default:
            return state
    }
}

export default userReducer
