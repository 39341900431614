import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetCurrentChannel } from 'redux/actions/channelActions';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { buildChannelObj } from 'shared/utils';

export default function ChannelCounterComponent({
  search = { id: null, type: '', showImg: false, object: null },
}) {
  const dispatch = useDispatch();
  const history: any = useHistory();

  const metadataChannels = useSelector(
    (state:any) => state.channelMetadataReducer.channelsMetadata,
  );
  const channels = useSelector((state:any) => state.channelReducer.channels);
  const user = useSelector((state:any) => state.userReducer.user) || null;
  const [pendingMessages, setPendingMessages] = useState(0);
  let [channelFound, setChannelFound] = useState(null);

  useEffect(() => {
    let counter = 0;
    let channel;
    let metadata;
    if (search.type === 'MATTER') {
      channel = channels.find(
        (currentChannel) =>
          currentChannel.Matter && currentChannel.Matter.MatterId === search.id,
         
      );
    } else if (search.type === 'TASK') {
      channel = channels.find(
        (currentChannel) =>
          currentChannel.Task && currentChannel.Task.TaskId === search.id,
      );
    } else if (search.type === 'GROUP') {
      channel = channels.find((currentChannel) => {
        const group = currentChannel.Group;
        const groupUser = group && group.GroupUser ? group.GroupUser : [];
        const isUserInGroup = groupUser.find(
          (groupU) => groupU.UserId === search.id,
        );
        if (isUserInGroup) {
          return currentChannel;
        } else {
          return null;
        }
      });
    }

    if (channel) {
      metadata = metadataChannels.find(
        (metadataChannel: any) =>
          metadataChannel.Channel && metadataChannel.Channel.Id === channel.Id,
      );
      if (metadata) {
        counter = channel.Messages.length - metadata.lastMessageReadIndex;
        setPendingMessages((currentCounter) => (currentCounter = counter));
        setChannelFound(channel);
      } else {
        setPendingMessages(
          (currentCounter) => (currentCounter = channel.Messages.length),
        );
        setChannelFound(channel);
      }
    }

    return () => {
      setPendingMessages(0);
    };
  }, [dispatch, channels, metadataChannels, search]);

  const handler = () => {
    if (!channelFound) {
      let channel;
      if (search.type === 'MATTER') {
        channel = buildChannelObj({
          ChannelType: search.type,
          Matter: search.object,
        });
      } else if (search.type === 'TASK') {
        channel = buildChannelObj({
          ChannelType: search.type,
          Task: search.object,
        });
      } else if (search.type === 'GROUP') {
        const Users = [user, search.object];
        const Group = {
          Users,
        };
        channel = buildChannelObj({ ChannelType: search.type, Group });
      }

      dispatch(SetCurrentChannel(channel));
    } else {
      dispatch(SetCurrentChannel(channelFound));
    }
    history.push(`/inChannel`);
  };

  return (
    <Tooltip
      classes={{
        tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
        arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
      }}
      arrow
      placement="top"
      title="Start Chat"
    >
      <div
        onClick={() => handler()}
        className="dash-show-message-button cursor-pointer"
      >
        {search.showImg && (
          <span className="dash-icons-message dash-font-size-26"></span>
        )}
        <span className="position-relative badge badge-pill dash-icon-badge dash-icon-badge_message_grid">
          {pendingMessages > 0 ? pendingMessages : ''}
        </span>
      </div>
    </Tooltip>
  );
}
