export const VALUE: string = "value";
export const ERROR: string = "error";
export const REQUIRED_FIELD_ERROR: string = "This is required field";

function is_bool(value: any) {
  return typeof value === "boolean";
}

/**
 * Determines a value if it's an object
 *
 * @param {object} value
 */
export function is_object(value: any) {
  return typeof value === "object" && value !== null;
}

/**
 *
 * @param {string} value
 * @param {boolean} isRequired
 */
export function is_required(value: any, isRequired: boolean) {
  if (!value && isRequired) return REQUIRED_FIELD_ERROR;
  return "";
}

export function get_prop_values(stateSchema: any, prop: any) {
  return Object.keys(stateSchema).reduce((field: any, key: any) => {
    field[key] = is_bool(prop) ? prop : stateSchema[key][prop];

    return field;
  }, {});
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function buildChannelObj(data: any): any {
  let channel: any;
  switch (data.ChannelType) {
    case "MATTER": {
      channel = {
        MatterId: data.Matter.MatterId,
        Matter: data.Matter,
        TaskId: null,
        ChannelType: "MATTER",
        Group: null,
        Messages: [],
      };
      break;
    }
    case "TASK": {
      channel = {
        MatterId: null,
        TaskId: data.Task.TaskId,
        Task: data.Task,
        ChannelType: "TASK",
        Group: null,
        Messages: [],
      };
      break;
    }
    case "GROUP": {
      const userIds = data.Group.Users.map((user) => {
        return user.UserId;
      });
      channel = {
        MatterId: null,
        TaskId: null,
        ChannelType: "GROUP",
        Group: {
          GroupName: generateGroupNameForTwilio(userIds),
          Users: data.Group.Users,
        },
        Messages: [],
      };
      break;
    }
    default: {
      channel = {};
      break;
    }
  }

  return channel;
}

function generateGroupNameForTwilio(userIds: number[]): string {
  userIds = userIds.sort();
  return `Group-${userIds.join("-")}`;
}

export function ValidateIdentity(identity: string) {
  let filteredIdentity = "";

  if (!identity.includes("+") && !identity.includes("+1")) {
    const emailOrPhoneCheck = /\S+@\S+\.\S+/;
    !emailOrPhoneCheck.test(identity)
      ? identity.length === 10
        ? (filteredIdentity = `+1${identity}`)
        : (filteredIdentity = `+${identity}`)
      : (filteredIdentity = identity);

    filteredIdentity = filteredIdentity.startsWith("+00")
      ? filteredIdentity.replace("+00", "+")
      : filteredIdentity;
  }

  filteredIdentity = filteredIdentity ? filteredIdentity : identity;
  filteredIdentity = filteredIdentity.toLowerCase();
  return filteredIdentity;
}
