import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import moment from 'moment';
import validator from 'validator';
import CancelConfirmationModal from '../../CancelConfirmationModal/cancelConfirmationModal';
import {
  Users,
  Matters,
  Priorities,
  Clients,
  Firms,
  Templates,
} from 'services/Api';

interface ResourceState {
  MatterId: null;
  Title: any;
  Client: any;
  Manager: any;
  TotalEffort: any;
  TotalBilling: any;
  Summary: any;
  MatterType: any;
  MatterStage: any;
  Priority: any;
  MatterStatus: any;
  priorities: [];
  stages: [];
  matterStatuses: [];
  types: [];
  clients: [];
  responsibles: [];
  twilioToken: any;
  show: boolean;
  Firms: [];
  FirmId: any;
  DueDate: any;
  ArrivalDate: any;
  loading: boolean;
  isTemplate: boolean;
  MatterTemplate: any;
  MatterTemplates: [];
  showErrors: boolean;
  loadingData: boolean;
  formTouched: boolean;
  receiveMatterTemplate: any;
  IsPublic: boolean;
  showCancelConfirm: boolean;
}
class AddMatter extends React.Component<
  {
    handleModalCancel;
    isAddShowing;
    onModalCloseClick();
    isTemplate?;
    matterId?;
    matterTemplateToReceive?;
  },
  ResourceState
> {
  TaskTemplates: any = [];
  constructor(props: any) {
    super(props);

    this.state = {
      MatterId: null,
      Title: '',
      Client: null,
      Manager: null,
      TotalEffort: '',
      TotalBilling: '',
      Summary: '',
      MatterType: null,
      MatterStage: '',
      Priority: '',
      MatterStatus: '',
      priorities: [],
      stages: [],
      matterStatuses: [],
      types: [],
      clients: [],
      responsibles: [],
      twilioToken: '',
      show: this.props.isAddShowing,
      Firms: [],
      FirmId: null,
      IsPublic: false,
      DueDate: new Date(),
      ArrivalDate: new Date(),
      loading: false,
      receiveMatterTemplate: this.props.matterTemplateToReceive,
      isTemplate: this.props.isTemplate,
      MatterTemplate: {},
      MatterTemplates: [],
      showErrors: false,
      loadingData: false,
      formTouched: false,
      showCancelConfirm: false,
    };
  }

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseFloat(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    this.setState({ formTouched: true });
  };

  componentDidMount() {
    if (this.props.matterId) {
      this.setState({ loadingData: true });
    }
    this.fetchData();
  }

  handleChangeIsPublic = () => {
    this.setState({ IsPublic: !this.state.IsPublic });
  };

  fetchData = async () => {
    const userObj: any = await Users.index();
    this.setState({ responsibles: userObj.data });

    await Priorities.index()
      .then((response) => {
        this.setState({ priorities: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    await Matters.lookups
      .stages()
      .then((response) => {
        this.setState({ stages: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    await Matters.lookups
      .statuses()
      .then((response) => {
        this.setState({ matterStatuses: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    await Matters.lookups
      .types()
      .then((response) => {
        this.setState({ types: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    await Clients.index()
      .then((response) => {
        this.setState({ clients: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    await Firms.index()
      .then((response) => {
        console.log(response.data);
        this.setState({ Firms: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    await Templates.matterTemplates()
      .then((response) => {
        console.log(response.data);
        this.setState({ MatterTemplates: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    let receivedMatterTemplate = this.props.matterTemplateToReceive;
    if (receivedMatterTemplate > 0) {
      this.setState({ MatterTemplate: receivedMatterTemplate });
    }

    const matterId = this.props.matterId;
    if (matterId) {
      try {
        let response: any;
        if (this.state.isTemplate) {
          response = await Templates.singleMatterTemplate(matterId);
        } else {
          response = await Matters.single(matterId);
        }

        this.setState(
          (state) => ({
            MatterId: this.state.isTemplate
              ? response.data.TemplateMatterId
              : response.data.MatterId,
            Title: this.state.isTemplate
              ? response.data.MatterTemplateTitle
              : response.data.MatterTitle,
            MatterTemplate: this.state.isTemplate
              ? ''
              : response.data.MatterTemplate,
            Client: response.data.Client?.ClientId,
            TotalBilling: response.data.TotalBilling,
            Summary: response.data.Summary,
            Manager: response.data.Manager?.UserId,
            TotalEffort: response.data.TotalEffort,
            Priority: response.data.Priority?.PriorityId,
            MatterStatus: response.data.MatterStatus?.MatterStatusId,
            MatterStage: response.data.MatterStage?.MatterStageId,
            MatterType: response.data.MatterType?.MatterTypeId,
            FirmId: response.data.Firm?.FirmId,
            DueDate: new Date(response.data.DueDate),
            ArrivalDate: new Date(response.data.ArrivalDate),
            loadingData: false,
            IsPublic: response.data.IsPublic,
          })
          // () => this.setState({ loadingData: false })
        );
      } catch (err) {
        alert(err.message ? err.message : err);
      }
    }
  };

  submitHandler = async (e: any) => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (
      this.state.Title !== '' &&
      this.state.TotalBilling !== '' &&
      this.state.TotalEffort !== '' &&
      this.state.MatterStage.toString() !== '0' &&
      this.state.MatterStatus.toString() !== '0' &&
      this.state.Priority.toString() !== '0' &&
      this.state.Summary !== ''
    ) {
      this.setState({ loading: true });
      let matterObj = {
        matter: {
          Summary: this.state.Summary,
          MatterType: {
            MatterTypeId:
              this.state.MatterType === '0' ? null : this.state.MatterType,
          },
          MatterStage: { MatterStageId: this.state.MatterStage },
          Priority: { PriorityId: this.state.Priority },
          Manager: {
            UserId: this.state.Manager === '0' ? null : this.state.Manager,
          },
          Client: {
            ClientId: this.state.Client === '0' ? null : this.state.Client,
          },
          MatterStatus: { MatterStatusId: this.state.MatterStatus },
          TotalEffort: this.state.TotalEffort,
          TotalBilling: this.state.TotalBilling,
          DueDate: moment(new Date(this.state.DueDate)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          Firm: {
            FirmId: this.state.FirmId === '0' ? null : this.state.FirmId,
          },
          ArrivalDate: moment(new Date(this.state.ArrivalDate)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          MatterTemplate:
            this.state.MatterTemplate === '0'
              ? null
              : this.state.MatterTemplate,
          MatterId: this.state.MatterId,
          MatterTitle: this.state.Title,
        },
        tasks: this.TaskTemplates,
      };
      let matterTemplateObj = {
        matterTemplate: {
          Summary: this.state.Summary,
          MatterType: {
            MatterTypeId:
              this.state.MatterType === '0' ? null : this.state.MatterType,
          },
          MatterStage: { MatterStageId: this.state.MatterStage },
          Priority: { PriorityId: this.state.Priority },
          Manager: {
            UserId: this.state.Manager === '0' ? null : this.state.Manager,
          },
          Client: {
            ClientId: this.state.Client === '0' ? null : this.state.Client,
          },
          MatterStatus: { MatterStatusId: this.state.MatterStatus },
          TotalEffort: this.state.TotalEffort,
          TotalBilling: this.state.TotalBilling,
          DueDate: moment(new Date(this.state.DueDate)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          Firm: {
            FirmId: this.state.FirmId === '0' ? null : this.state.FirmId,
          },
          ArrivalDate: moment(new Date(this.state.ArrivalDate)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),

          TemplateMatterId: this.state.MatterId,
          MatterTemplateTitle: this.state.Title,
          IsPublic: this.state.IsPublic,
        },
        taskTemplates: [],
      };

      try {
        if (this.state.isTemplate) {
          await Templates.createMatterTemplate(matterTemplateObj);
        } else {
          await Matters.create(matterObj);
        }
        this.setState({ loading: false });
        toast.success('Data saved successfully!');
        this.handleClose();
      } catch (err) {
        toast.error('Data not saved');
        this.setState({ loading: false });
      }
    }
  };
  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalCloseClick();
  };
  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));
    this.setState({ formTouched: true });
  };
  changeMatterTemplate = (e: any) => {
    this.changeHandler(e);
    let MatterTemplateID: number = e.target.value;
    if (e.target.value > 0) {
      Templates.singleMatterTemplate(MatterTemplateID)
        .then((response) => {
          var todayDate = new Date(this.state.ArrivalDate);
          var templateArrivalDate = new Date(response.data.ArrivalDate);
          var templateDueDate = new Date(response.data.DueDate);
          templateDueDate.setDate(
            templateDueDate.getDate() - templateArrivalDate.getDate()
          );
          todayDate.setDate(todayDate.getDate() + templateDueDate.getDate());
          this.setState((state) => ({
            Client: response.data.Client?.ClientId,
            TotalBilling: response.data.TotalBilling,
            Summary: response.data.Summary,
            Manager: response.data.Manager?.UserId,
            TotalEffort: response.data.TotalEffort,
            Priority: response.data.Priority?.PriorityId,
            MatterStatus: response.data.MatterStatus?.MatterStatusId,
            MatterStage: response.data.MatterStage?.MatterStageId,
            MatterType: response.data.MatterType?.MatterTypeId,
            FirmId: response.data.Firm?.FirmId,
            DueDate: new Date(todayDate),
            MatterTemplate: response.data,
          }));
          this.TaskTemplates = response.data.MatterTemplateTaskTemplate.map(
            (templateData) => {
              return {
                Resource: templateData.TaskTemplate?.Resource,
                TaskStatus: templateData.TaskTemplate?.TaskStatus,
                Priority: templateData.TaskTemplate?.Priority,
                dueDateDaysOffset: templateData.TaskTemplate?.DueDate,
                BillRate: templateData.TaskTemplate?.BillRate,
                Cost: templateData.TaskTemplate?.Cost,
                EffortAct: templateData.TaskTemplate?.EffortAct,
                EffortEst: templateData.TaskTemplate.EffortEst,
                BillingCode: templateData.TaskTemplate?.BillingCode,
                Instructions: templateData.TaskTemplate?.Instructions,
                BillingNarrativeId:
                  templateData.TaskTemplate?.BillingNarrativeId,
                TaskTitle: templateData.TaskTemplate?.Title,
              };
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    const {
      stages,
      priorities,
      matterStatuses,
      types,
      clients,
      responsibles,
      Firms,
      MatterTemplates,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size='xl'
          backdrop='static'
          className='pt-5'
          scrollable={true}
        >
          <div>
            <form onSubmit={this.submitHandler} id='save-form' noValidate>
              <div className='mb-0'>
                <Row>
                  <Col md={12}>
                    <Card className='dash-card'>
                      <Modal.Header className='p-0'>
                        <Card.Header className='dash-card-header'>
                          <span className='dash-card-title'>
                            {this.props.matterId ? 'Edit' : 'Add'} Matter
                            {this.state.isTemplate && <span> Template</span>}
                          </span>
                          <ButtonGroup className='float-right'>
                            <Button
                              type='reset'
                              className='dash-secondary-button margin-separator'
                              onClick={
                                this.props.handleModalCancel
                                //   () => {
                                //   this.state.formTouched
                                //     ? this.setState({ showCancelConfirm: true })
                                //     : this.handleClose();
                                // }
                              }
                            >
                              Cancel
                            </Button>

                            <Button
                              type='submit'
                              className='dash-primary-button'
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <Spinner
                                  className='mr-1 dash-button-spinner'
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                />
                              )}

                              {this.state.loading ? (
                                <span>Saving...</span>
                              ) : (
                                <span>Save</span>
                              )}
                            </Button>
                          </ButtonGroup>
                        </Card.Header>
                      </Modal.Header>{' '}
                      {this.state.loadingData && (
                        <div className='dash-page-spinner-container'>
                          <Col>
                            <Spinner
                              className='mr-1 dash-page-spinner'
                              as='span'
                              animation='border'
                              role='status'
                              aria-hidden='true'
                            />
                          </Col>
                        </div>
                      )}
                      <Modal.Body className='px-0 pt-0 pb-5 dash-card-body'>
                        <Card.Body>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Title
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={!this.state.isTemplate ? 4 : 10}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='Title'
                                name='Title'
                                value={this.state.Title || ''}
                                onChange={this.changeHandler}
                                required
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.Title) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                            {!this.state.isTemplate && (
                              <React.Fragment>
                                <Col md={2}>
                                  <div className='dash-form-label'>
                                    Matter Template
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <select
                                    className='form-control dash-form-control'
                                    id='MatterTemplate'
                                    name='MatterTemplate'
                                    value={
                                      this.state.MatterTemplate
                                        ?.TemplateMatterId ||
                                      this.state.MatterTemplate ||
                                      ''
                                    }
                                    onChange={(event) =>
                                      this.changeMatterTemplate(event)
                                    }
                                    disabled={
                                      this.props.matterId ? true : false
                                    }
                                  >
                                    <option value={0}>None</option>
                                    {MatterTemplates.map((x: any, i) => (
                                      <option
                                        key={'x' + i}
                                        value={x.TemplateMatterId}
                                      >
                                        {x.MatterTemplateTitle}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>Client Name</div>
                            </Col>
                            <Col md={4}>
                              <select
                                className='form-control dash-form-control'
                                id='Client'
                                name='Client'
                                value={this.state.Client || ''}
                                onChange={this.changeHandler}
                              >
                                <option value='0'>None</option>
                                {clients.map((x: any, i) => (
                                  <option key={'x' + i} value={x.ClientId}>
                                    {x.ClientName}
                                  </option>
                                ))}
                              </select>
                            </Col>

                            <Col md={2}>
                              <div className='dash-form-label'>Type</div>
                            </Col>

                            <Col md={4}>
                              <select
                                name='MatterType'
                                className='form-control dash-form-control'
                                placeholder='None'
                                onChange={this.changeHandler}
                                value={this.state.MatterType || ''}
                              >
                                <option value='0'>None</option>
                                {types.map((x: any, i) => (
                                  <option key={'x' + i} value={x.MatterTypeId}>
                                    {x.MatterTypeName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Stage
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <select
                                id='MatterStage'
                                name='MatterStage'
                                className='form-control dash-form-control'
                                placeholder='None'
                                onChange={this.changeHandler}
                                value={this.state.MatterStage || ''}
                              >
                                <option selected hidden value='-1'>
                                  None
                                </option>
                                {stages.map((x: any, i) => (
                                  <option key={'x' + i} value={x.MatterStageId}>
                                    {x.MatterStageName}
                                  </option>
                                ))}
                              </select>
                              {this.state.showErrors === true &&
                                (validator.isEmpty(
                                  this.state.MatterStage.toString()
                                ) ||
                                  this.state.MatterStage.toString() ===
                                    '0') && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>

                            <Col md={2}>
                              <div className='dash-form-label'>
                                Priority
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>

                            <Col md={4}>
                              <select
                                id='priorities'
                                name='Priority'
                                onChange={this.changeHandler}
                                value={this.state.Priority}
                                className='form-control dash-form-control'
                                placeholder='None'
                              >
                                <option selected hidden value='-1'>
                                  None
                                </option>
                                {priorities.map((x: any, i) => (
                                  <option key={'x' + i} value={x.PriorityId}>
                                    {x.PriorityName}
                                  </option>
                                ))}
                              </select>
                              {this.state.showErrors === true &&
                                (validator.isEmpty(
                                  this.state.Priority.toString()
                                ) ||
                                  this.state.Priority.toString() === '0') && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Status
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <select
                                id='MatterStatus'
                                name='MatterStatus'
                                onChange={this.changeHandler}
                                className='form-control dash-form-control'
                                placeholder='None'
                                value={this.state.MatterStatus || ''}
                              >
                                <option selected hidden value='-1'>
                                  None
                                </option>
                                {matterStatuses.map((x: any, i) => (
                                  <option
                                    key={'x' + i}
                                    value={x.MatterStatusId}
                                  >
                                    {x.MatterStatusName}
                                  </option>
                                ))}
                              </select>
                              {this.state.showErrors === true &&
                                (validator.isEmpty(
                                  this.state.MatterStatus.toString()
                                ) ||
                                  this.state.MatterStatus.toString() ===
                                    '0') && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>Responsible</div>
                            </Col>
                            <Col md={4}>
                              <select
                                className='form-control dash-form-control'
                                id='Manager'
                                name='Manager'
                                value={this.state.Manager || ''}
                                onChange={this.changeHandler}
                              >
                                <option value='0'>None</option>
                                {responsibles.map((x: any, i) => (
                                  <option key={'x' + i} value={x.UserId}>
                                    {x.FullName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Total Effort
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='number'
                                className='form-control dash-form-control'
                                id='TotalEffort'
                                name='TotalEffort'
                                value={this.state.TotalEffort || null}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                !this.state.TotalEffort && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Total Billing $
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='number'
                                step='any'
                                lang='nb'
                                className='form-control dash-form-control'
                                id='TotalBilling'
                                name='TotalBilling'
                                value={this.state.TotalBilling || null}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                !this.state.TotalBilling && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Arrival Date
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <label className='dash-datepicker'>
                              <DatePicker
                                    className='form-control dash-form-control'
                                    id='ArrivalDate'
                                    name='ArrivalDate'
                                    selected={this.state.ArrivalDate}
                                    onChange={(e) =>
                                      this.changeDateHandler(e, 'ArrivalDate')
                                    }
                                    onFocus={(e) => e.target.blur()}
                                    popperPlacement='right'
                                  />
                                {this.state.showErrors === true &&
                                  (!this.state.ArrivalDate) && (
                                    <span className='dash-error-message'>
                                      Required
                                    </span>
                                  )}
                              </label>
                            </Col>

                            <Col md={2}>
                              <div className='dash-form-label'>
                                Due Date
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <label className='dash-datepicker'>
                              <DatePicker
                                    className='form-control dash-form-control'
                                    id='DueDate'
                                    name='DueDate'
                                    selected={this.state.DueDate}
                                    onChange={(e) =>
                                      this.changeDateHandler(e, 'DueDate')
                                    }
                                    onFocus={(e) => e.target.blur()}
                                    popperPlacement='right-end'
                                    minDate={new Date(this.state.ArrivalDate)}
                                  />
                                {this.state.showErrors === true &&
                                  (!this.state.DueDate) && (
                                    <span className='dash-error-message'>
                                      Required
                                    </span>
                                  )}
                              </label>
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>Firm</div>
                            </Col>

                            <Col md={4}>
                              <select
                                className='form-control dash-form-control'
                                id='FirmId'
                                name='FirmId'
                                value={this.state.FirmId || ''}
                                onChange={this.changeHandler}
                              >
                                <option value='0'>None</option>
                                {Firms.map((x: any, i) => (
                                  <option key={'x' + i} value={x.FirmId}>
                                    {x.FirmName}
                                  </option>
                                ))}
                              </select>
                            </Col>

                            <Col md={2}>
                              <div className='dash-form-label'>
                                Summary
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <textarea
                                id='Summary'
                                name='Summary'
                                value={this.state.Summary || ''}
                                onChange={this.changeHandler}
                                className='form-control dash-form-control'
                                data-rows='3'
                              ></textarea>
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.Summary) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          {this.state.isTemplate && (
                            <Row className='form-group'>
                              <Col md={2}>
                                <div className='dash-form-label'>Is Public</div>
                              </Col>
                              <Col md={4}>
                                <div className='custom-control  custom-checkbox dash-checkbox mb-3'>
                                  <input
                                    type='checkbox'
                                    className='custom-control-input dash-checkbox-input'
                                    id='isPublic'
                                    onChange={this.handleChangeIsPublic}
                                    checked={this.state.IsPublic}
                                  />
                                  <label
                                    className='custom-control-label dash-checkbox-label'
                                    htmlFor='isPublic'
                                  ></label>
                                </div>
                              </Col>
                            </Row>
                          )}{' '}
                          <Row className='form-group'>
                            <Col md={2}></Col>
                            <Col className='dash-form-label text-left'>
                              Fields with{' '}
                              <span className='dash-require-astrike'></span> are
                              required fields.
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}></Col>
                            {this.state.IsPublic && (
                              <Col className='dash-form-label text-left'>
                                This is a public matter available for anyone in
                                DASH. If you wish to make changes, it will
                                impact all users
                              </Col>
                            )}
                          </Row>
                        </Card.Body>
                      </Modal.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </Modal>
        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={this.state.showCancelConfirm}
            onModalYesClick={() => this.handleClose()}
            onModalNoClick={() => this.setState({ showCancelConfirm: false })}
          ></CancelConfirmationModal>
        )}
      </React.Fragment>
    );
  }
}

export default AddMatter;
