import { actionTypes } from '../constants/actionTypes'

export const initialState = {
  obj: null,
  channel: null,
};

function messageCenterReducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CHANNEL_MESSAGE_CENTER: {
        return {
            obj: action.payload.obj,
            channel: action.payload.channel
        }
    }
    default:
      return state
  }
}

export default messageCenterReducer
