import { actionTypes } from '../constants/actionTypes'

export const initialState = {
  client: null,
  loading: false,
  error: false,
};

function clientChatReducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CLIENT_START: {
      return {
        ...state,
        loading: true
      }
    }
    case actionTypes.GET_CLIENT_SUCCESS: {
      return {
        ...state,
        client: action.payload,
        loading: false,
        error: false
      }
    }
    case actionTypes.GET_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    default:
      return state
  }
}

export default clientChatReducer
