import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import {
  Button,
  ButtonGroup,
  Spinner,
  Accordion,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSync } from '@fortawesome/free-solid-svg-icons';
//import history from "../../../services/history";
import NewUser from './newUser';
//import EditUser from "./editUser";
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import UserCard from '../../profile/userCard';
import ChannelCounterComponent from 'shared/channelCounterComponent';
import { Users as GetUsers, Roles, Firms } from 'services/Api';
import Tooltip from '@material-ui/core/Tooltip';
import Workbook from 'react-excel-workbook';

interface UserState {
  users: [];
  userFilter: { name: ''; roleId: ''; firmId: '' };
  displayedUsers: [];
  roles: [];
  firms: [];
  isAddShowing: Boolean;
  isEditShowing: Boolean;
  editId: string;
  loading: boolean;
  noResults: boolean;
  currentUserID: string;
  props: any;
}
class Users extends React.Component<{ location }, UserState> {
  private addModalRef: any;
  private params: any;
  private currentTasks: any;
  constructor(props: any) {
    super(props);
    this.addModalRef = React.createRef();
    this.state = {
      users: [],
      userFilter: { name: '', roleId: '', firmId: '' },
      displayedUsers: [],
      roles: [],
      firms: [],
      isAddShowing: false,
      isEditShowing: false,
      editId: '',
      loading: false,
      noResults: false,
      currentUserID: '',
      props: this.props,
    };
  }

  changeHandler = (e: any) => {
    let { name, value } = e.target;
    this.setState((state) => ({
      userFilter: Object.assign({}, state.userFilter, {
        [name]: value,
      }),
    }));
  };

  fetch = async () => {
    let currentUser: any = localStorage.getItem('userData');
    const currentUserID: any = JSON.parse(currentUser ? currentUser : '{}');
    this.setState({ loading: true, currentUserID: currentUserID.UserId });
    let myclass = this;
    myclass.params = myclass.props;
    let firmId = this.props.location.state?.firmId;
    GetUsers.index()
      .then((response) => {
        console.log(response.data);

        this.setState({
          users: response.data,
        });
        this.setDisplayedData(response.data);
        this.currentTasks = response.data;
        if (firmId > 0) {
          this.setState(
            (state) => ({
              userFilter: Object.assign({}, state.userFilter, {
                firmId: firmId,
              }),
            }),
            () => {
              this.handleSearch();
            }
          );
        }
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
      });

    Roles.index()
      .then((response) => {
        this.setState({
          roles: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    Firms.index()
      .then((response) => {
        this.setState({
          firms: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidMount() {
    this.fetch();
  }
  orderByName() {
    let myData: any = this.state.displayedUsers;
    myData.sort((a, b) => {
      return a.FullName.localeCompare(b.FullName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedUsers: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedUsers: myData });
      this.currentTasks = this.state.displayedUsers;
    }
  }
  orderByRole() {
    let myData: any = this.state.displayedUsers;
    myData.sort((a, b) => {
      return a.Role?.RoleName.localeCompare(b.Role?.RoleName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedUsers: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedUsers: myData });
      this.currentTasks = this.state.displayedUsers;
    }
  }
  orderByMobile() {
    let myData: any = this.state.displayedUsers;
    myData.sort((a, b) => {
      return a.Mobile?.localeCompare(b.Mobile);
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedUsers: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedUsers: myData });
      this.currentTasks = this.state.displayedUsers;
    }
  }
  orderByEmail() {
    let myData: any = this.state.displayedUsers;
    myData.sort((a, b) => {
      return a.Email.localeCompare(b.Email)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedUsers: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedUsers: myData });
      this.currentTasks = this.state.displayedUsers;
    }
  }
  handleSearch = () => {
    const filteredUsers: any = this.state.users.filter((user: any) => {
      return (
        user.FullName.toLowerCase().includes(
          this.state.userFilter.name.toLowerCase()
        ) &&
        (user.Role?.RoleId.toString() ===
          this.state.userFilter.roleId.toString() ||
          this.state.userFilter.roleId === '') &&
        (user.UserFirm?.find(
          (user) =>
            user.FirmId === Number.parseInt(this.state.userFilter.firmId)
        ) ||
          this.state.userFilter.firmId === '')
      );
      //&&
      // task.TaskStatusId.toString() === taskFilter.priorityId.toString()
    });
    if (
      filteredUsers.length !== this.state.displayedUsers.length &&
      filteredUsers.length === 0
    ) {
      this.setState({ noResults: true });
    } else {
      this.setState({ noResults: false });
    }
    this.setState({ displayedUsers: filteredUsers });
  };

  handleReset = () => {
    this.setState(
      (state) => ({
        userFilter: Object.assign(
          {},
          state.userFilter,
          { name: '' },
          { roleId: '' },
          { firmId: '' }
        ),
      }),
      () => {
        this.handleSearch();
      }
    );
    // searchForm.current.reset();
    //console.log(searchForm);
    //searchForm.reset()
    //this.handleSearch();
  };

  submitForm = (e: any) => {
    e.preventDefault();
    this.handleSearch();
  };

  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  handlemodalClose = async () => {
    this.setState({ isAddShowing: false, isEditShowing: false, loading: true });
    this.fetch();
  };
  setDisplayedData = async (data) => {
    let templateData: any = [];
    for (let temp of data) {
      if (temp.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.ProfileImageURL);
      }
      templateData.push(temp);
    }
    this.setState(
      (state) => ({ displayedUsers: templateData }),
      () => {
        this.handleSearch();
        this.setState({ loading: false });
      }
    );
  };
  handelIsEditShowing = (e, id) => {
    this.setState({ editId: id, isEditShowing: true });
  };
  handleRefresh = async () => {
    this.setState({ loading: true });
    await GetUsers.index()
      .then((response: any) => {
        this.setState({ users: response.data });
        this.handleSearch();
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    let myclass = this;
    return (
      <div>
        {this.state.loading && (
          <div className='dash-page-spinner-container'>
            <Col>
              <Spinner
                className='mr-1 dash-page-spinner'
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
            </Col>
          </div>
        )}

        <div className='dash-grid-body'>
          <Row className='mb-4'>
            <Col md={12} className="d-flex justify-content-center">
              <div className="float-left position-absolute dash-left-3 dash-top-3">
                <ButtonGroup>
                  <Button
                    className='btn btn-xs btn-info dash-back-button mb-0'
                    onClick={() => this.state.props.history.goBack()}
                  >
                    Back
                  </Button>
                </ButtonGroup>
              </div>
              <div className='dash-page-title'>
                <h3 className='mb-0 text-center'>Users</h3>
                <div className='dash-line-icon'></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Accordion defaultActiveKey='0'>
                <Card className='dash-card'>
                  <Card.Header className='dash-card-header'>
                    <span className='dash-card-title'>Search Criteria</span>
                    <Accordion.Toggle
                      as={Button}
                      variant='link'
                      eventKey='1'
                      className='float-right'
                    >
                      <FontAwesomeIcon
                        className='dash-card-title'
                        icon={faFilter}
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Card.Body className='dash-card-body'>
                    <Accordion.Collapse eventKey='1'>
                      <form onSubmit={this.submitForm}>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className='dash-form-label '>Name</div>
                          </Col>
                          <Col md={4}>
                            <input
                              type='text'
                              className='form-control dash-form-control '
                              id='title'
                              name='name'
                              value={this.state.userFilter.name}
                              onChange={this.changeHandler}
                              onKeyDown={this.handleKeyDown}
                            />
                          </Col>

                          <Col md={2}>
                            <div className='dash-form-label '>Role</div>
                          </Col>
                          <Col md={4}>
                            <select
                              name='roleId'
                              className='form-control dash-form-control '
                              value={this.state.userFilter.roleId}
                              onChange={this.changeHandler}
                              onKeyDown={this.handleKeyDown}
                            >
                              <option>All</option>
                              {this.state.roles &&
                                this.state.roles.map((user: any, key) => (
                                  <option value={user.RoleId} key={key}>
                                    {user.RoleName}
                                  </option>
                                ))}
                            </select>
                          </Col>
                        </Row>
                        <Row className='form-group'>
                          <Col md={2}>
                            <div className='dash-form-label'>Firm Name</div>
                          </Col>
                          <Col md={4}>
                            <select
                              name='firmId'
                              className='form-control dash-form-control'
                              value={this.state.userFilter.firmId}
                              onChange={this.changeHandler}
                              onKeyDown={this.handleKeyDown}
                            >
                              <option value=''>All</option>
                              {this.state.firms &&
                                this.state.firms.map((firm: any, key) => (
                                  <option value={firm.FirmId} key={key}>
                                    {firm.FirmName}
                                  </option>
                                ))}
                            </select>
                          </Col>
                        </Row>
                      </form>
                    </Accordion.Collapse>
                    <div className='clearfix'>
                      <button
                        className='btn btn-reset btn-w-m mr-2'
                        onClick={() => this.setState({ isAddShowing: true })}
                      >
                        Add and Invite User
                      </button>
                      {this.state.isAddShowing && (
                        <NewUser
                          ref={this.addModalRef}
                          isAddShowing={this.state.isAddShowing}
                          onModalCloseClick={() => this.handlemodalClose()}
                        />
                      )}
                      <Workbook
                        filename='users.xlsx'
                        element={
                          <button className='btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset'>
                            Export Data
                          </button>
                        }
                      >
                        <Workbook.Sheet
                          data={this.state.displayedUsers}
                          name='Users'
                        >
                          <Workbook.Column label='Name' value='FullName' />
                          <Workbook.Column
                            label='Role'
                            value={(col) =>
                              col.Role?.RoleName ? col.Role?.RoleName : ''
                            }
                          />
                          <Workbook.Column label='Mobile' value='Mobile' />
                          <Workbook.Column label='Email' value='Email' />
                        </Workbook.Sheet>
                      </Workbook>
                      <ButtonGroup className='float-right clearfix'>
                        <Button
                          type='button'
                          variant='secondary'
                          size='sm'
                          className='dash-secondary-button margin-separator'
                          onClick={this.handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          size='sm'
                          type='button'
                          className='dash-primary-button'
                          onClick={this.handleSearch}
                        >
                          Search
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Accordion>
            </Col>
          </Row>

          <table className='table-header table table-striped table-center mt-1'>
            <thead className='table-header'>
              <tr>
                <th>
                  <Tooltip
                    classes={{
                      tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                      arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement='top'
                    title='Refresh Data'
                  >
                    <button
                      className='dash-show-message-button dash-refresh-button'
                      onClick={this.handleRefresh}
                    >
                      <FontAwesomeIcon
                        className='dash-card-title'
                        icon={faSync}
                      />
                    </button>
                  </Tooltip></th>
                <th className='th-click' onClick={() => this.orderByName()}>
                  Name
                  <Tooltip
                    classes={{
                      tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                      arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement='top'
                    title='Click on the header to sort the data'
                  >
                    <i className='fas fa-info-circle ml-2'></i>
                  </Tooltip>
                </th>
                <th className='th-click' onClick={() => this.orderByRole()}>Role</th>
                {/* <th>Firm</th> */}
                <th className='th-click' onClick={() => this.orderByMobile()}>Mobile</th>
                <th className='th-click' onClick={() => this.orderByEmail()}>Email</th>
                <th className='width-50' />
                <th className='width-50' />
                <th className='width-50' />
                <th className='width-50' />
              </tr>
            </thead>
            <tbody>
              {this.state.displayedUsers.map(function (session: any, i) {
                return (
                  <tr key={'session-' + i}>
                    <td className='dash-grid-overflow'>
                      <Tooltip
                        classes={{
                          tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                          arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement='top'
                        title='Edit'
                      >
                        <button
                          className='dash-show-message-button cursor-pointer'
                          onClick={(event) =>
                            myclass.handelIsEditShowing(event, session.UserId)
                          }
                        >
                          <i className='far fa-edit'></i>
                        </button>
                      </Tooltip>
                      {myclass.state.isEditShowing &&
                        myclass.state.editId === session.UserId && (
                          <NewUser
                            ref={myclass.addModalRef}
                            isAddShowing={myclass.state.isEditShowing}
                            userId={myclass.state.editId}
                            onModalCloseClick={() => myclass.handlemodalClose()}
                          />
                        )}
                    </td>
                    <td className='dash-word-break-all dash-grid-overflow'>
                      <OverlayTrigger
                        trigger='click'
                        rootClose
                        overlay={
                          <Popover
                            id='user-Data'
                            className='dash-user-card-popover'
                          >
                            <UserCard userId={session.UserId} />
                          </Popover>
                        }
                      >
                        <div className='d-flex align-items-center cursor-pointer dash-grid-overflow'>
                          <Avatar
                            className={classNames(
                              'dash-grid-avatar',
                              session.UserId === myclass.state.currentUserID
                                ? 'd-none'
                                : ''
                            )}
                            src={session.imageUrl}
                            alt=''
                            style={{ overflow : 'hidden' }}
                          />
                          <span className='align-middle'>
                            {session.FullName}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td>{session.Role?.RoleName}</td>
                    {/* <td>{session.Firm?.FirmName}</td> */}
                    <td>{session.Mobile}</td>
                    <td>
                      <a
                        className='dash-email-link'
                        href={'mailto:' + session.Email}
                      >
                        {session.Email}
                      </a>
                    </td>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                          arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement='top'
                        title='Tasks'
                      >
                        <div>
                          <Link
                            className='dash-show-message-button cursor-pointer d-block'
                            to={{
                              pathname: '/tasks',
                              state: { userId: session.UserId },
                            }}
                          >
                            <span className='dash-icons-task dash-font-size-22'></span>
                          </Link>
                        </div>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                          arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement='top'
                        title='Matters'
                      >
                        <div>
                          <Link
                            className='dash-show-message-button cursor-pointer d-block'
                            to={{
                              pathname: '/matters/mattersManagement',
                              state: { userId: session.UserId },
                            }}
                          >
                            <span className='dash-icons-matter dash-font-size-26'></span>
                          </Link>
                        </div>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        classes={{
                          tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                          arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                        }}
                        arrow
                        placement='top'
                        title='Firms'
                      >
                        <div>
                          <Link
                            type='button'
                            className='dash-show-message-button cursor-pointer d-block'
                            to={{
                              pathname: '/firms',
                              state: { userId: session.UserId },
                            }}
                          >
                            <span className='dash-icons-firms dash-font-size-22'></span>
                          </Link>
                        </div>
                      </Tooltip>
                    </td>
                    <td className='dash-grid-overflow'> 
                      <ChannelCounterComponent
                        search={{
                          id: session.UserId,
                          type: 'GROUP',
                          showImg: true,
                          object: session,
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {this.state.noResults && (
            <h3 className='margin-separator-top-search text-center text-white'>
              No results found
            </h3>
          )}
        </div>
      </div>
    );
  }
}

export default Users;
