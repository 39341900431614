export interface User {
  UserId: string;
  Username: string;
  Password: string;
}

export enum UserActionTypes {
  LOADING = 'loading',
  SUCCESS = 'success',
}
