import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import Header from 'components/header/Header';
import { useUserState } from 'contexts/UserAuthContext';
// import { apiClient } from 'config/api';
import { setupInterceptors } from 'config/api';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  roles,
  ...rest
}: any) {
  const { user, isLoading }: any = useUserState();
  const history: any = useHistory();
  const userObj = JSON.parse(localStorage.getItem('userData') || '{}');
  const currentUserRole = userObj?.Role?.RoleName;

  user &&
    setupInterceptors(user.signInUserSession?.accessToken.jwtToken, history);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <h1>Loading</h1>
        ) : user?.attributes ? (
          // !roles &&
          roles?.indexOf(currentUserRole) === -1 ? (
            <Redirect to='/firms' />
          ) : (
            <>
              <Container fluid>
                <Row>
                  <Col className='px-0'>
                    <Header />
                  </Col>
                </Row>
              </Container>
              <main className='dash-page-content py-5'>
                <Container fluid className='px-5'>
                  <Component {...props} />
                </Container>
              </main>
            </>
          )
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
}
