import { combineReducers } from 'redux';
import channelReducer from './channelReducer';
import clientChatReducer from './clientChatReducer';
import userReducer from './userReducer';
import channelMetadataReducer from './channelMetadataReducer';
import messageCenterReducer from './messageCenterReducer';
import { actionTypes } from 'redux/constants/actionTypes';

const appReducer = combineReducers({
    channelReducer,
    clientChatReducer,
    userReducer,
    channelMetadataReducer,
    messageCenterReducer,
});

const rootReducer = (state, action) => {
    if (action.type === actionTypes.SIGN_OUT) {
      state = undefined
    }
  
    return appReducer(state, action)
}

export default rootReducer;

