import React, { Component } from 'react';
import { ReactSortable } from 'react-sortablejs';
import {
  Card,
  Row,
  Col,
  Container,
  Spinner,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import CreateTaskAdmin from '../Admin/tasks/createTaskAdmin';
//import EditTaskAdmin from "../Admin/tasks/editTaskAdmin";
//import data from "./taskBoard.data";
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';
import UserCard from '../profile/userCard';
import ChannelCounterComponent from 'shared/channelCounterComponent';
import { Tasks } from 'services/Api';
interface DueDateTasksState {
  thisDayList: any[];
  thisWeekList: any[];
  thisMonthList: any[];
  laterList: any[];
  thisDayCount: number;
  thisWeekCount: number;
  thisMonthCount: number;
  laterCount: number;
  isIncludePast: Boolean;
  taskData: {
    TaskId: null;
    Matter: { MatterId: '' };
    Resource: { UserId: '' };
    TaskStatus: { TaskStatusId: '' };
    Priority: { PriorityId: '' };
    DueDate: Date;
    TaskTitle: '';
    BillRate: '';
    Cost: '';
    EffortAct: '';
    EffortEst: '';
    BillingCode: '';
    Instructions: '';
    BillingNarrativeId: '1';
  };
  isAddShowing: Boolean;
  isEditShowing: Boolean;
  EditId: string;
  AddDueDate: string;
  loading: boolean;
  currentUserID: string;
}
export default class TasksBoardDueDate extends Component<
  {},
  DueDateTasksState
> {
  private DueDate = {
    today: 1,
    thisWeek: 2,
    thisMonth: 3,
    later: 4,
  };
  private editModalRef: any;
  private addModalRef: any;
  constructor(props: any) {
    super(props);
    this.editModalRef = React.createRef();
    this.addModalRef = React.createRef();
    this.state = {
      thisDayList: [],
      thisWeekList: [],
      thisMonthList: [],
      laterList: [],
      thisDayCount: 0,
      thisWeekCount: 0,
      thisMonthCount: 0,
      laterCount: 0,
      isIncludePast: false,
      taskData: {
        TaskId: null,
        Matter: { MatterId: '' },
        Resource: { UserId: '' },
        TaskStatus: { TaskStatusId: '' },
        Priority: { PriorityId: '' },
        DueDate: new Date(),
        TaskTitle: '',
        BillRate: '',
        Cost: '',
        EffortAct: '',
        EffortEst: '',
        BillingCode: '',
        Instructions: '',
        BillingNarrativeId: '1',
      },
      isAddShowing: false,
      isEditShowing: false,
      EditId: '',
      AddDueDate: '',
      loading: false,
      currentUserID: '',
    };
  }
  fetchData = async () => {
    let thisWeekList;
    let thisMonthList;
    await Tasks.index()
      .then(async (response) => {
        let templateData: any = [];
        for (let temp of response.data) {
          if (temp.Resource?.ProfileImageURL) {
            temp['imageUrl'] = await Storage.get(
              temp.Resource?.ProfileImageURL,
            );
          }
          templateData.push(temp);
        }
        const thisDayList = templateData.filter((task) =>
          moment(new Date(task.DueDate)).isSame(new Date(), 'day'),
        );
        if (!this.state.isIncludePast) {
          thisWeekList = templateData.filter(
            (task) =>
              moment(new Date(task.DueDate)).isSame(new Date(), 'week') &&
              !moment(new Date(task.DueDate)).isSame(new Date(), 'day') &&
              moment(new Date(task.DueDate)).isAfter(new Date()),
          );
          thisMonthList = templateData.filter(
            (task) =>
              moment(new Date(task.DueDate)).isSame(new Date(), 'month') &&
              !moment(new Date(task.DueDate)).isSame(new Date(), 'week') &&
              !moment(new Date(task.DueDate)).isSame(new Date(), 'day') &&
              moment(new Date(task.DueDate)).isAfter(new Date()),
          );
        } else {
          thisWeekList = templateData.filter(
            (task) =>
              moment(new Date(task.DueDate)).isSame(new Date(), 'week') &&
              !moment(new Date(task.DueDate)).isSame(new Date(), 'day'),
          );
          thisMonthList = templateData.filter(
            (task) =>
              moment(new Date(task.DueDate)).isSame(new Date(), 'month') &&
              !moment(new Date(task.DueDate)).isSame(new Date(), 'week') &&
              !moment(new Date(task.DueDate)).isSame(new Date(), 'day'),
          );
        }
        const laterList = templateData.filter(
          (task) =>
            !moment(new Date(task.DueDate)).isSame(new Date(), 'month') &&
            !moment(new Date(task.DueDate)).isSame(new Date(), 'week') &&
            !moment(new Date(task.DueDate)).isSame(new Date(), 'day') &&
            moment(new Date(task.DueDate)).isAfter(new Date()),
        );
        this.setState({
          thisDayList: thisDayList,
          thisWeekList: thisWeekList,
          thisMonthList: thisMonthList,
          laterList: laterList,
          thisDayCount: thisDayList.length,
          thisWeekCount: thisWeekList.length,
          thisMonthCount: thisMonthList.length,
          laterCount: laterList.length,
        });
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    let currentUser: any = localStorage.getItem('userData');
    const currentUserID: any = JSON.parse(currentUser ? currentUser : '{}');
    this.setState({ loading: true, currentUserID: currentUserID.UserId });

    this.fetchData();
  }
  movedTask = async (e, DueDate) => {
    let taskId = e.item.id;
    let taskDueDate;
    let myDate = new Date();
    const { data: response } = await Tasks.single(taskId);
    if (DueDate === this.DueDate.today) {
      taskDueDate = moment(moment(myDate).format('YYYY/MM/DD HH:mm:ss')).format(
        'YYYY-MM-DD HH:mm:ss',
      );
    } else if (DueDate === this.DueDate.thisWeek) {
      taskDueDate = moment(
        moment(myDate).endOf('week').format('YYYY/MM/DD HH:mm:ss'),
      ).format('YYYY-MM-DD HH:mm:ss');
    } else if (DueDate === this.DueDate.thisMonth) {
      taskDueDate = moment(
        moment(myDate).endOf('month').format('YYYY/MM/DD HH:mm:ss'),
      ).format('YYYY-MM-DD HH:mm:ss');
    } else {
      taskDueDate = moment(
        moment(myDate)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY/MM/DD HH:mm:ss'),
      ).format('YYYY-MM-DD HH:mm:ss');
    }
    this.setState(
      (state) => ({
        taskData: Object.assign(
          {},
          state.taskData,
          { TaskId: response.TaskId },
          {
            Matter: { MatterId: response.MatterTask[0]?.Matter?.MatterId },
          },
          {
            Resource: { UserId: response.Resource?.UserId },
          },
          {
            TaskStatus: { TaskStatusId: response.TaskStatus?.TaskStatusId },
          },
          { Priority: { PriorityId: response.Priority?.PriorityId } },
          { DueDate: new Date(taskDueDate) },
          { TaskTitle: response.TaskTitle },
          { BillRate: response.BillRate },
          { Cost: response.Cost },
          { EffortAct: response.EffortAct },
          { EffortEst: response.EffortEst },
          { BillingCode: response.BillingCode },
          { Instructions: response.Instructions },
          { BillingNarrativeId: '1' },
        ),
      }),
      () => {
        Tasks.create(this.state.taskData)
          .then((response) => {
            console.log(response);
            if (DueDate === this.DueDate.today) {
              this.setState({ thisDayCount: this.state.thisDayCount + 1 });
            } else if (DueDate === this.DueDate.thisWeek) {
              this.setState({
                thisWeekCount: this.state.thisWeekCount + 1,
              });
            } else if (DueDate === this.DueDate.thisMonth) {
              this.setState({
                thisMonthCount: this.state.thisMonthCount + 1,
              });
            } else {
              this.setState({ laterCount: this.state.laterCount + 1 });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    );
  };
  removedTask = async (e, DueDate) => {
    if (DueDate === this.DueDate.today) {
      this.setState({ thisDayCount: this.state.thisDayCount - 1 });
    } else if (DueDate === this.DueDate.thisWeek) {
      this.setState({ thisWeekCount: this.state.thisWeekCount - 1 });
    } else if (DueDate === this.DueDate.thisMonth) {
      this.setState({ thisMonthCount: this.state.thisMonthCount - 1 });
    } else {
      this.setState({ laterCount: this.state.laterCount - 1 });
    }
  };
  handleIsIncludePastChange = (e) => {
    let checkedStatus = e.target.checked;
    this.setState(
      (state) => ({
        isIncludePast: checkedStatus,
      }),
      () => this.fetchData(),
    );
  };
  handlemodalClose = async () => {
    this.setState({
      isAddShowing: false,
      isEditShowing: false,
    });

    this.fetchData();
  };
  itemDoubledClick = (e, id) => {
    this.setState({ isEditShowing: true });
    this.setState({ EditId: id });
  };
  handleAddClick = (e, dueDate) => {
    this.setState({ isAddShowing: true });
    this.setState({ AddDueDate: dueDate });
  };
  render() {
    const {
      thisDayList,
      thisWeekList,
      thisMonthList,
      laterList,
      thisDayCount,
      thisWeekCount,
      thisMonthCount,
      laterCount,
    } = this.state;
    let myclass = this;
    return (
      <Container>
        {this.state.loading && (
          <div className="dash-page-spinner-container">
            <Col>
              <Spinner
                className="mr-1 dash-page-spinner"
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </Col>
          </div>
        )}
        <div className="dash-grid-body">
          <Row>
            <Col>
              <div className="custom-control  custom-checkbox dash-checkbox mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input dash-checkbox-input"
                  id="defaultUnchecked"
                  onChange={this.handleIsIncludePastChange}
                />
                <label
                  className="custom-control-label dash-checkbox-label"
                  htmlFor="defaultUnchecked"
                >
                  Include Past Due Dates
                </label>
              </div>
            </Col>
          </Row>
          <Row className="dash-task-due-date">
            <Col md="3">
              <Card className="dash-tasks-status-card  dash-tasks-status-card--red">
                <Card.Header>
                  24 hours
                  <span
                    className={
                      thisDayCount > 0
                        ? 'dash-tasks-status-card_count ml-2'
                        : 'd-none'
                    }
                  >
                    ({thisDayCount})
                  </span>
                  <i
                    className="fa fa-plus cursor-pointer float-right mt-1"
                    aria-hidden="true"
                    onClick={(event) =>
                      this.handleAddClick(event, this.DueDate.today)
                    }
                  ></i>
                  {this.state.isAddShowing && (
                    <CreateTaskAdmin
                      ref={this.addModalRef}
                      isAddShowing={this.state.isAddShowing}
                      onModalCloseClick={() => this.handlemodalClose()}
                      dueDateParam={this.state.AddDueDate}
                    />
                  )}
                </Card.Header>
                {this.state.isEditShowing && (
                  <CreateTaskAdmin
                    ref={this.addModalRef}
                    isAddShowing={this.state.isEditShowing}
                    taskId={this.state.EditId}
                    onModalCloseClick={() => this.handlemodalClose()}
                  />
                )}

                <ReactSortable
                  list={thisDayList}
                  setList={(newthisDayList) =>
                    this.setState({ thisDayList: newthisDayList })
                  }
                  animation={150}
                  group="shared-group-name"
                  onAdd={(event) => this.movedTask(event, this.DueDate.today)}
                  onRemove={(event) =>
                    this.removedTask(event, this.DueDate.today)
                  }
                >
                  {thisDayList.map(function (item: any, i) {
                    return (
                      <Item
                        id={item.TaskId}
                        key={item.TaskId}
                        className="dash-tasks-status-item cursor-pointer d-flex align-items-center"
                        onDoubleClick={(event) =>
                          myclass.itemDoubledClick(event, item.TaskId)
                        }
                      >
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          overlay={
                            <Popover
                              id="user-Data"
                              className="dash-user-card-popover"
                            >
                              <UserCard userId={item?.Resource?.UserId} />
                            </Popover>
                          }
                        >
                          <Avatar
                            className={classNames(
                              'dash-grid-avatar cursor-pointer',
                              item?.Resource?.UserId ===
                                myclass.state.currentUserID
                                ? 'd-none'
                                : '',
                            )}
                            src={item.imageUrl}
                            alt=""
                          />
                        </OverlayTrigger>
                        <span className="dash-tasks-status-title">
                          {item.TaskTitle}
                        </span>
                        <ChannelCounterComponent
                          search={{
                            id: item.TaskId,
                            type: 'TASK',
                            showImg: false,
                            object: item,
                          }}
                        />
                      </Item>
                    );
                  })}
                </ReactSortable>
              </Card>
            </Col>
            <Col md="3">
              <Card className="dash-tasks-status-card  dash-tasks-status-card--orange">
                <Card.Header>
                  This Week
                  <span
                    className={
                      thisWeekCount > 0
                        ? 'dash-tasks-status-card_count ml-2'
                        : 'd-none'
                    }
                  >
                    ({thisWeekCount})
                  </span>
                  <i
                    className="fa fa-plus cursor-pointer float-right mt-1"
                    aria-hidden="true"
                    onClick={(event) =>
                      this.handleAddClick(event, this.DueDate.thisWeek)
                    }
                  ></i>
                </Card.Header>
                <ReactSortable
                  list={thisWeekList}
                  setList={(newthisWeekList) =>
                    this.setState({ thisWeekList: newthisWeekList })
                  }
                  animation={150}
                  group="shared-group-name"
                  onAdd={(event) =>
                    this.movedTask(event, this.DueDate.thisWeek)
                  }
                  onRemove={(event) =>
                    this.removedTask(event, this.DueDate.thisWeek)
                  }
                >
                  {thisWeekList.map((item: any) => (
                    <Item
                      id={item.TaskId}
                      key={item.TaskId}
                      className="dash-tasks-status-item cursor-pointer d-flex align-items-center"
                      onDoubleClick={(event) =>
                        myclass.itemDoubledClick(event, item.TaskId)
                      }
                    >
                      {' '}
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        overlay={
                          <Popover
                            id="user-Data"
                            className="dash-user-card-popover"
                          >
                            <UserCard userId={item?.Resource?.UserId} />
                          </Popover>
                        }
                      >
                        <Avatar
                          className={classNames(
                            'dash-grid-avatar cursor-pointer',
                            item?.Resource?.UserId === myclass.state.currentUserID
                              ? 'd-none'
                              : '',
                          )}
                          src={item.imageUrl}
                          alt=""
                        />
                      </OverlayTrigger>
                      <span className="dash-tasks-status-title">
                        {item.TaskTitle}
                      </span>
                      <ChannelCounterComponent
                        search={{
                          id: item.TaskId,
                          type: 'TASK',
                          showImg: false,
                          object: item,
                        }}
                      />
                    </Item>
                  ))}
                </ReactSortable>
              </Card>
            </Col>
            <Col md="3">
              <Card className="dash-tasks-status-card  dash-tasks-status-card--green">
                <Card.Header>
                  This Month
                  <span
                    className={
                      thisMonthCount > 0
                        ? 'dash-tasks-status-card_count ml-2'
                        : 'd-none'
                    }
                  >
                    ({thisMonthCount})
                  </span>
                  <i
                    className="fa fa-plus cursor-pointer float-right mt-1"
                    aria-hidden="true"
                    onClick={(event) =>
                      this.handleAddClick(event, this.DueDate.thisMonth)
                    }
                  ></i>
                </Card.Header>
                <ReactSortable
                  list={thisMonthList}
                  setList={(newthisMonthList) =>
                    this.setState({ thisMonthList: newthisMonthList })
                  }
                  animation={150}
                  group="shared-group-name"
                  onAdd={(event) =>
                    this.movedTask(event, this.DueDate.thisMonth)
                  }
                  onRemove={(event) =>
                    this.removedTask(event, this.DueDate.thisMonth)
                  }
                >
                  {thisMonthList.map((item: any) => (
                    <Item
                      id={item.TaskId}
                      key={item.TaskId}
                      className="dash-tasks-status-item cursor-pointer d-flex align-items-center"
                      onDoubleClick={(event) =>
                        myclass.itemDoubledClick(event, item.TaskId)
                      }
                    >
                      {' '}
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        overlay={
                          <Popover
                            id="user-Data"
                            className="dash-user-card-popover"
                          >
                            <UserCard userId={item?.Resource?.UserId} />
                          </Popover>
                        }
                      >
                        <Avatar
                          className={classNames(
                            'dash-grid-avatar cursor-pointer',
                            item?.Resource?.UserId === myclass.state.currentUserID
                              ? 'd-none'
                              : '',
                          )}
                          src={item.imageUrl}
                          alt=""
                        />
                      </OverlayTrigger>
                      <span className="dash-tasks-status-title">
                        {item.TaskTitle}
                      </span>
                      <ChannelCounterComponent
                        search={{
                          id: item.TaskId,
                          type: 'TASK',
                          showImg: false,
                          object: item,
                        }}
                      />
                    </Item>
                  ))}
                </ReactSortable>
              </Card>
            </Col>
            <Col md="3">
              <Card className="dash-tasks-status-card  dash-tasks-status-card--blue">
                <Card.Header>
                  Later
                  <span
                    className={
                      laterCount > 0
                        ? 'dash-tasks-status-card_count ml-2'
                        : 'd-none'
                    }
                  >
                    ({laterCount})
                  </span>
                  <i
                    className="fa fa-plus cursor-pointer float-right mt-1"
                    aria-hidden="true"
                    onClick={(event) =>
                      this.handleAddClick(event, this.DueDate.later)
                    }
                  ></i>
                </Card.Header>
                <ReactSortable
                  list={laterList}
                  setList={(newlaterList) =>
                    this.setState({ laterList: newlaterList })
                  }
                  animation={150}
                  group="shared-group-name"
                  onAdd={(event) => this.movedTask(event, this.DueDate.later)}
                  onRemove={(event) =>
                    this.removedTask(event, this.DueDate.later)
                  }
                >
                  {laterList.map((item: any) => (
                    <Item
                      id={item.TaskId}
                      key={item.TaskId}
                      className="dash-tasks-status-item cursor-pointer d-flex align-items-center"
                      onDoubleClick={(event) =>
                        myclass.itemDoubledClick(event, item.TaskId)
                      }
                    >
                      {' '}
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        overlay={
                          <Popover
                            id="user-Data"
                            className="dash-user-card-popover"
                          >
                            <UserCard userId={item?.Resource?.UserId} />
                          </Popover>
                        }
                      >
                        <Avatar
                          className={classNames(
                            'dash-grid-avatar cursor-pointer',
                            item?.Resource?.UserId === myclass.state.currentUserID
                              ? 'd-none'
                              : '',
                          )}
                          src={item.imageUrl}
                          alt=""
                        />
                      </OverlayTrigger>
                      <span className="dash-tasks-status-title">
                        {item.TaskTitle}
                      </span>
                      <ChannelCounterComponent
                        search={{
                          id: item.TaskId,
                          type: 'TASK',
                          showImg: false,
                          object: item,
                        }}
                      />
                    </Item>
                  ))}
                </ReactSortable>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const Item = styled.div`
  flex-grow: 1;
  padding: 0.3rem;
  border-radius: 0.2rem;
  background-color: #fff6;
  margin-bottom: 0.3rem;
  &.sortable-ghost {
    background-color: #c8ebfb;
  }
`;
