import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MessageCenter } from '../../../services/Api/MessageCenter'

// import TwilioLobby from './twilioLobby';
import TwilioRoom from "./twilioRoom";
import "./session.css";

const TwilioVideo = (props) => {
  // const [username, setUsername] = useState('');
  // const [roomName, setRoomName] = useState('');
  // const [matter, setMatter] = useState('');
  const [token, setToken] = useState();
  let history = useHistory();

  const { matter, username } = props;
  const roomName = `${matter} ${new Date().toLocaleDateString("en-US")}`;

  useEffect(() => {
    // setMatter(props.matter);
    const setTwilioSessionToken = async () => {
      const videoToken = await MessageCenter.videoToken(username,matter);

      setToken(videoToken.data.token); 
    };
    setTwilioSessionToken();
  }, [matter, username]);

  // const handleUsernameChange = useCallback((event) => {
  //   setUsername(event.target.value);
  // }, []);

  // const handleRoomNameChange = useCallback((event) => {
  //   setRoomName(event.target.value);
  // }, []);

  // const handleSubmit = useCallback(
  //   async (event) => {
  //     event.preventDefault();
  //     const data = await (
  //       await fetch(
  //         `https://backend.dash.law/twilio/generateVideoToken?Identity=${username}&Room=${roomName}`
  //       )
  //     ).json();
  //     console.log(data);
  //     setToken(data.token);
  //   },
  //   [username, roomName]
  // );

  const handleLogout = () => {
    setToken("");
    history.push("/initSession/twilio");
  };

  let render;
  if (token) {
    render = (
      <TwilioRoom
        roomName={roomName}
        token={token}
        handleLogout={handleLogout}
      />
    );
  } else {
    render = <h1>Loading..</h1>;
    //   // render = (
    //   //   <TwilioLobby
    //   //     username={username}
    //   //     roomName={roomName}
    //   //     handleUsernameChange={handleUsernameChange}
    //   //     handleRoomNameChange={handleRoomNameChange}
    //   //     handleSubmit={handleSubmit}
    //   //   />
    //   // );
  }
  return render;
};

export default TwilioVideo;