import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Card,
  Col,
  ButtonGroup,
  Button,
  Spinner,
  Accordion,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSync } from "@fortawesome/free-solid-svg-icons";
//import history from "../../../services/history";
// import Modal from 'react-bootstrap/Modal'
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import { Multiselect } from "multiselect-react-dropdown";
// import Icon from '@material-ui/core/Icon';
import CreateTaskAdmin from "./createTaskAdmin";
//import EditTaskAdmin from "./editTaskAdmin";
import { Storage } from "aws-amplify";
import { Avatar } from "@material-ui/core";
import classNames from "classnames";
import UserCard from "../../profile/userCard";
import ChannelCounterComponent from "shared/channelCounterComponent";
import { Users, Tasks, Matters, Priorities, Firms } from "services/Api";
import Tooltip from "@material-ui/core/Tooltip";
import Workbook from "react-excel-workbook";
import UploadDocumentModal from "../../UploadDocumentModal/uploadDocumentModal";

interface AdminTasksState {
  tasks: [];
  matters: [];
  priorities: [];
  taskStatus: [];
  users: [];
  firms: [];
  taskFilter: {
    matterIds: [];
    title: "";
    userIds: [];
    priorityId: "";
    TaskStatusId: "";
    firmId: "";
    dueDateFrom: Date;
    dueDateTo: Date;
  };
  displayedTasks: [];
  FilteredMatterTopass: any;
  isEditShowing: Boolean;
  isAddShowing: Boolean;
  filteredStatus: any;
  editId: string;
  noResults: boolean;
  channels: any[];
  counter: number;
  loading: boolean;
  currentUserID: string;
  props: any;
  isUploadShowing: boolean;
  uploadId: any;
}
class AdminTasks extends React.Component<{ location }, AdminTasksState> {
  private params: any;
  private matterMultiSelectRef: any;
  private userMultiSelectRef: any;
  private editModalRef: any;
  private addModalRef: any;
  private currentTasks: any;
  private uploadModalRef: any;

  constructor(props: any) {
    super(props);
    this.matterMultiSelectRef = React.createRef();
    this.userMultiSelectRef = React.createRef();
    this.editModalRef = React.createRef();
    this.addModalRef = React.createRef();
    this.uploadModalRef = React.createRef();
    this.state = {
      tasks: [],
      matters: [],
      priorities: [],
      taskStatus: [],
      filteredStatus: "",
      users: [],
      firms: [],
      taskFilter: {
        matterIds: [],
        title: "",
        userIds: [],
        priorityId: "",
        TaskStatusId: "",
        firmId: "",
        dueDateFrom: new Date(new Date().setMonth(new Date().getMonth() - 5)),
        dueDateTo: new Date(new Date().setMonth(new Date().getMonth() + 5)),
      },
      FilteredMatterTopass: "",
      displayedTasks: [],
      isAddShowing: false,
      isEditShowing: false,
      editId: "",
      noResults: false,
      channels: [],
      counter: 0,
      loading: false,
      currentUserID: "",
      props: this.props,
      isUploadShowing: false,
      uploadId: "",
    };
  }

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === "number" ? parseInt(value) : value;
    this.setState((state) => ({
      taskFilter: Object.assign({}, state.taskFilter, {
        [name]: value,
      }),
    }));
  };

  changeDateHandler = (e: any, name: any) => {
    this.setState((state) => ({
      taskFilter: Object.assign({}, state.taskFilter, {
        [name]: e,
      }),
    }));
  };

  changeMatterMultiSelect = (selectedList: any, selectedItem: any) => {
    this.setState((state) => ({
      taskFilter: Object.assign({}, state.taskFilter, {
        matterIds: selectedList,
      }),
    }));
  };
  changeUserMultiSelect = (selectedList: any, selectedItem: any) => {
    this.setState((state) => ({
      taskFilter: Object.assign({}, state.taskFilter, {
        userIds: selectedList,
      }),
    }));
  };
  componentDidMount() {
    if (this.props.location.state?.filteredStatus > 0) {
      this.setState((prevState) => ({
        taskFilter: {
          ...prevState.taskFilter,
          TaskStatusId: this.props.location.state.filteredStatus,
        },
      }));
      this.handleSearch();
    }
    let currentUser: any = localStorage.getItem("userData");
    const currentUserID: any = JSON.parse(currentUser ? currentUser : "{}");
    this.setState({ currentUserID: currentUserID.UserId });
    let myclass = this;
    myclass.params = myclass.props;
    // let userId = this.props.location?.state.userId;
    // let matterId = myclass.params.matterId;
    if (this.props.location?.state) {
      this.setState({
        FilteredMatterTopass: this.props.location?.state.matterId,
      });
    }
    this.fetchData();
  }

  fetchData = async () => {

    this.setState({ loading: true })
    const firmId = this.props.location.state?.firmId;
    const clientId = this.props.location.state?.clientId;
    const matterId = this.props.location.state?.matterId;
    const statusId = this.props.location.state?.statusId;
    const userId = this.props.location.state?.userId;

    await Tasks.index()
      .then((response: any) => {
        this.setState({ tasks: response.data });
        if (!firmId && !clientId && !matterId && !statusId && !userId) {
          this.setDisplayedData(response.data);
          this.currentTasks = response.data;
        }
        this.setState(
          (state) => ({
            taskFilter: Object.assign({}, state.taskFilter, {
              dueDateFrom: new Date().setMonth(new Date().getMonth() - 5),
              dueDateTo: new Date().setMonth(new Date().getMonth() + 5),
            }),
          }),
          () => {
            if (!firmId && !clientId && !matterId && !statusId && !userId) {
              this.handleSearch();
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
    await Firms.index()
      .then((response: any) => {
        this.setState({ firms: response.data });

      }).then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
    if (firmId > 0) {
      this.setState(
        (state) => ({
          taskFilter: Object.assign({}, state.taskFilter, {
            firmId: firmId,
          }),
        }),
        () => {
          this.handleSearch();
        }
      );
    }

    await Matters.index()
      .then((response: any) => {
        this.setState({ matters: response.data });
        if (clientId > 0) {
          const MatterTasks: any = this.state.matters.filter((matter: any) => {
            return clientId.toString() === matter.Client?.ClientId.toString();
          });
          this.setState(
            (state) => ({
              taskFilter: Object.assign({}, state.taskFilter, {
                matterIds: MatterTasks,
              }),
            }),
            () => {
              this.handleSearch();
            }
          );
        } else if (matterId) {
          const MatterTasks: any = this.state.matters.filter((mycase: any) => {
            return matterId.toString() === mycase.MatterId.toString();
          });
          this.setState(
            (state) => ({
              taskFilter: Object.assign({}, state.taskFilter, {
                matterIds: MatterTasks,
              }),
            }),
            () => {
              this.handleSearch();
            }
          );
        }

      })
      .catch((error) => {
        console.log(error);
      });

    await Priorities.index()
      .then((response: any) => {
        this.setState({ priorities: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    await Tasks.status()
      .then((response: any) => {
        this.setState({ taskStatus: response.data });
        if (statusId > 0) {
          this.setState(
            (state) => ({
              taskFilter: Object.assign({}, state.taskFilter, {
                TaskStatusId: statusId,
              }),
            }),
            () => {
              this.handleSearch();
            }
          );
        }
        setTimeout(() => { this.setState({ loading: false }) }, 1500);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });

    await Users.index()
      .then((response: any) => {
        this.setState({ users: response.data });
        if (userId) {
          const filterUser = response.data.find((u) =>
            Number(u.UserId === Number(userId))
          );
          if (filterUser) {
            this.setState(
              (state) => ({
                taskFilter: Object.assign({}, state.taskFilter, {
                  userIds: [filterUser],
                }),
              }),
              () => {
                this.handleSearch();
              }
            );
            this.setState({ loading: false })
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false })
      });
  }

  orderByMatter() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.MatterTask[0]?.Matter?.MatterTitle.localeCompare(b.MatterTask[0]?.Matter?.MatterTitle)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByTitle() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.TaskTitle.localeCompare(b.TaskTitle)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByPriority() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.Priority?.PriorityName.localeCompare(b.Priority?.PriorityName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByDueDate() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.DueDate.localeCompare(b.DueDate)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByStatus() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.TaskStatus?.TaskStatusName.localeCompare(b.TaskStatus?.TaskStatusName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByUser() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.Resource?.FullName.localeCompare(b.Resource?.FullName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByClient() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.MatterTask[0]?.Matter?.Client?.ClientName.localeCompare(b.MatterTask[0]?.Matter?.Client?.ClientName)
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByEffort() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.EffortEst - b.EffortEst
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }
  orderByBilling() {
    let myData: any = this.state.displayedTasks;
    myData.sort((a, b) => {
      return a.BillRate - b.BillRate
    });
    if (myData[0] === this.currentTasks[0]) {
      const reverseData = myData.reverse();
      this.setState({ displayedTasks: reverseData });
      this.currentTasks = [];
    } else {
      this.setState({ displayedTasks: myData });
      this.currentTasks = this.state.displayedTasks;
    }
  }

  handleSearch = () => {
    let matterIds: any;
    let userIds: any;
    if (this.state.taskFilter.matterIds.length > 0) {
      matterIds = this.state.taskFilter.matterIds.map(
        ({ MatterId }) => MatterId
      );
    } else {
      matterIds = [];
    }
    if (this.state.taskFilter.userIds.length > 0) {
      userIds = this.state.taskFilter.userIds.map(({ UserId }) => UserId);
    } else {
      userIds = [];
    }
    let filteredTasks: any = this.state.tasks.filter((task: any) => {
      return (
        task.TaskTitle.toLowerCase().includes(
          this.state.taskFilter.title.toLowerCase()
        ) &&
        (task.TaskStatus?.TaskStatusId.toString() ===
          this.state.taskFilter.TaskStatusId.toString() ||
          this.state.taskFilter.TaskStatusId === "") &&
        (task.Priority?.PriorityId.toString() ===
          this.state.taskFilter.priorityId.toString() ||
          this.state.taskFilter.priorityId === "") &&
        (userIds.includes(task.Resource?.UserId) || userIds.length === 0) &&
        (matterIds.includes(task.MatterTask[0]?.Matter?.MatterId) ||
          matterIds.length === 0) &&
        (this.state.taskFilter.dueDateFrom.toString() === "" ||
          new Date(task.DueDate) >=
          new Date(this.state.taskFilter.dueDateFrom)) &&
        (this.state.taskFilter.dueDateTo.toString() === "" ||
          (new Date(task.DueDate) <=
            new Date(this.state.taskFilter.dueDateTo) &&
            (this.state.taskFilter.firmId !== ""
              ? task.MatterTask && task.MatterTask.length > 0
                ? task.MatterTask.find(
                  (m) =>
                    m.Matter &&
                    m.Matter.Firm.FirmId ===
                    Number(this.state.taskFilter.firmId)
                )
                : false
              : true)))
      );
    });

    if (
      filteredTasks.length === 0
    ) {
      this.setState({ noResults: true });
    } else {
      this.setState({ noResults: false });
    }
    this.setState({ displayedTasks: filteredTasks });
  };

  handleReset = () => {
    let to = new Date().setMonth(new Date().getMonth() + 5);
    let from = new Date().setMonth(new Date().getMonth() - 5);
    this.matterMultiSelectRef.current.resetSelectedValues();
    this.userMultiSelectRef.current.resetSelectedValues();
    this.setState(
      (state) => ({
        taskFilter: Object.assign(
          {},
          state.taskFilter,
          { matterIds: "" },
          { title: "" },
          { userIds: "" },
          { priorityId: "" },
          { TaskStatusId: "" },
          { dueDateFrom: from },
          { dueDateTo: to },
          { typeId: "" },
          { firmId: "" }
        ),
      }),
      () => {
        this.matterMultiSelectRef.current.resetSelectedValues();
        this.userMultiSelectRef.current.resetSelectedValues();

        this.handleSearch();
      }
    );
  };
  submitForm = (e: any) => {
    e.preventDefault();
    this.handleSearch();
  };
  handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  };
  handlemodalClose = async () => {
    this.setState({
      isAddShowing: false,
      isEditShowing: false,
      loading: true,
      isUploadShowing: false,
    });
    await Tasks.index()
      .then((response: any) => {
        this.setState({ tasks: response.data });
        this.setDisplayedData(response.data);
      })
      .catch((error) => console.log(error));
    this.setState({
      loading: false,
    });
  };
  handelIsEditShowing = (e, id) => {
    this.setState({ editId: id, isEditShowing: true });
  };
  setDisplayedData = async (data) => {
    let templateData: any = [];
    for (let temp of data) {
      if (temp.Resource?.ProfileImageURL) {
        temp["imageUrl"] = await Storage.get(temp.Resource?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    this.setState(
      (state) => ({ displayedTasks: templateData }),
      () => {
        this.handleSearch();
      }
    );
  };
  handleRefresh = async () => {
    this.fetchData();
  };
  handleModalCancel = () => {
    this.setState({
      isAddShowing: false,
      isEditShowing: false,
      isUploadShowing: false,
    });
  };
  handelIsUploadShowing = (e, id) => {
    this.setState({ uploadId: id, isUploadShowing: true });
  };
  render() {
    const {
      displayedTasks,
      matters,
      priorities,
      taskStatus,
      users,
      taskFilter,
    } = this.state;
    let myclass = this;
    return (
      <div>
        {this.state.loading && (
          <div className="dash-page-spinner-container">
            <Col>
              <Spinner
                className="mr-1 dash-page-spinner"
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </Col>
          </div>
        )}
        <div className="dash-grid-body">
          <Row className="mb-4">
            <Col md={12} className="d-flex justify-content-center">
              <div className="float-left position-absolute dash-left-3 dash-top-3">
                <ButtonGroup>
                  <Button
                    className="btn btn-xs btn-info dash-back-button mb-0 "
                    onClick={() => this.state.props.history.goBack()}
                  >
                    Back
                  </Button>
                </ButtonGroup>
              </div>
              <div className="dash-page-title">
                <h3 className="mb-0 text-center">Tasks</h3>
                <div className="dash-line-icon"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Accordion>
                <Card className="dash-card overflow-visible">
                  <Card.Header className="dash-card-header">
                    <span className="dash-card-title">Search Criteria</span>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="0"
                      className="float-right"
                    >
                      <FontAwesomeIcon
                        className="dash-card-title"
                        icon={faFilter}
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Card.Body className="dash-card-body">
                    <Accordion.Collapse eventKey="0">
                      <form onSubmit={this.submitForm}>
                        <Row className="form-group">
                          <Col md={2}>
                            <div className="dash-form-label">Matter</div>
                          </Col>
                          <Col md={4}>
                            <Multiselect
                              name="matterIds"
                              ref={this.matterMultiSelectRef}
                              options={matters} // Options to display in the dropdown
                              selectedValues={taskFilter.matterIds} // Preselected value to persist in dropdown
                              onSelect={this.changeMatterMultiSelect} // Function will trigger on select event
                              onKeyDown={this.handleKeyDown}
                              showCheckbox={true}
                              displayValue="MatterTitle" // Property name to display in the dropdown options
                            />
                            {/*  <select
                          id="matters"
                          name="matterId"
                          className="form-control dash-form-control"
                          placeholder="All"
                          value={taskFilter.matterId}
                          onChange={this.changeHandler}
                          onKeyDown={this.handleKeyDown}
                        >
                          <option value="">All </option>
                          {matters.map((x: any, i) => (
                            <option value={x.MatterId} key={x.MatterId}>
                              {x.MatterTitle}
                            </option>
                          ))}
                        </select>*/}
                          </Col>

                          <Col md={2}>
                            <div className="dash-form-label">Title</div>
                          </Col>
                          <Col md={4}>
                            <input
                              type="text"
                              className="form-control dash-form-control"
                              id="title"
                              name="title"
                              value={taskFilter.title}
                              onChange={(e) => {
                                let val = e.target.value;
                                this.setState((state) => ({
                                  taskFilter: Object.assign(
                                    {},
                                    state.taskFilter,
                                    {
                                      title: val,
                                    }
                                  ),
                                }));
                              }}
                              onKeyDown={this.handleKeyDown}
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col md={2}>
                            <div className="dash-form-label">Users</div>
                          </Col>
                          <Col md={4}>
                            <Multiselect
                              name="userIds"
                              ref={this.userMultiSelectRef}
                              options={users} // Options to display in the dropdown
                              selectedValues={taskFilter.userIds} // Preselected value to persist in dropdown
                              onSelect={this.changeUserMultiSelect} // Function will trigger on select event
                              onKeyDown={this.handleKeyDown}
                              showCheckbox={true}
                              displayValue="FullName" // Property name to display in the dropdown options
                            />
                          </Col>

                          <Col md={2}>
                            <div className="dash-form-label">Priority</div>
                          </Col>
                          <Col md={4}>
                            <select
                              id="priorities"
                              name="priorityId"
                              className="form-control dash-form-control"
                              placeholder="All"
                              value={taskFilter.priorityId}
                              onChange={this.changeHandler}
                              onKeyDown={this.handleKeyDown}
                            >
                              <option value="">All </option>
                              {priorities.map((x: any, i) => (
                                <option value={x.PriorityId} key={x.PriorityId}>
                                  {x.PriorityName}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col md={2}>
                            <div className="dash-form-label">Status</div>
                          </Col>
                          <Col md={4}>
                            <select
                              id="taskStaus"
                              name="TaskStatusId"
                              className="form-control dash-form-control"
                              placeholder="All"
                              value={taskFilter.TaskStatusId}
                              onChange={this.changeHandler}
                              onKeyDown={this.handleKeyDown}
                            >
                              <option value="">All </option>
                              {taskStatus.map((x: any, i) => (
                                <option
                                  value={x.TaskStatusId}
                                  key={x.TaskStatusId}
                                >
                                  {x.TaskStatusName}
                                </option>
                              ))}
                            </select>
                          </Col>

                          <Col md={2}>
                            <div className="dash-form-label">Firm</div>
                          </Col>
                          <Col md={4}>
                            <select
                              name="firmId"
                              className="form-control dash-form-control"
                              value={this.state.taskFilter.firmId}
                              onChange={this.changeHandler}
                              onKeyDown={this.handleKeyDown}
                            >
                              <option value="">All</option>
                              {this.state.firms &&
                                this.state.firms.map((firm: any, key) => (
                                  <option value={firm.FirmId} key={key}>
                                    {firm.FirmName}
                                  </option>
                                ))}
                            </select>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col md={2}>
                            <div className="dash-form-label">Due Date From</div>
                          </Col>
                          <Col md={4}>
                            <label className="dash-datepicker">
                              <DatePicker
                                className="form-control dash-form-control"
                                name="dueDateFrom"
                                selected={taskFilter.dueDateFrom}
                                onChange={(e) =>
                                  this.changeDateHandler(e, "dueDateFrom")
                                }
                                onKeyDown={this.handleKeyDown}
                              />
                            </label>
                          </Col>
                          <Col md={2}>
                            <div className="dash-form-label">Due Date To</div>
                          </Col>
                          <Col md={4}>
                            <label className="dash-datepicker">
                              <DatePicker
                                className="form-control dash-form-control"
                                name="dueDateTo"
                                selected={taskFilter.dueDateTo}
                                onChange={(e) =>
                                  this.changeDateHandler(e, "dueDateTo")
                                }
                                onKeyDown={this.handleKeyDown}
                              />
                            </label>
                          </Col>
                          {/* <Col md={2}>
                        <div className="dash-form-label">Type</div>
                      </Col>
                      <Col md={4}>
                        <select
                          name="typeId"
                          className="form-control dash-form-control"
                          placeholder="All"
                          value={taskFilter.typeId}
                          onChange={this.changeHandler}
                          onKeyDown={this.handleKeyDown}
                        >
                          <option value="">All </option>
                        </select>
                      </Col>*/}
                        </Row>
                      </form>
                    </Accordion.Collapse>
                    <div className="clearfix">
                      <button
                        className="btn btn-reset btn-w-m mr-2"
                        onClick={() => this.setState({ isAddShowing: true })}
                      >
                        Add Task
                      </button>
                      {this.state.isAddShowing && (
                        <CreateTaskAdmin
                          ref={this.addModalRef}
                          isAddShowing={this.state.isAddShowing}
                          filteredMatterP={this.state.FilteredMatterTopass}
                          onModalCloseClick={() => this.handlemodalClose()}
                        />
                      )}
                      <Workbook
                        filename="tasks.xlsx"
                        element={
                          <button className="btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset">
                            Export Data
                          </button>
                        }
                      >
                        <Workbook.Sheet data={displayedTasks} name="tasks">
                          <Workbook.Column
                            label="Matter"
                            value={(col) =>
                              col.MatterTask[0]?.Matter?.MatterTitle
                                ? col.MatterTask[0]?.Matter?.MatterTitle
                                : ""
                            }
                          />
                          <Workbook.Column label="Title" value="TaskTitle" />
                          <Workbook.Column
                            label="Priority"
                            value={(col) =>
                              col.Priority?.PriorityName
                                ? col.Priority?.PriorityName
                                : ""
                            }
                          />
                          <Workbook.Column label="DueDate" value="DueDate" />
                          <Workbook.Column
                            label="Status"
                            value={(col) =>
                              col.TaskStatus?.TaskStatusName
                                ? col.TaskStatus?.TaskStatusName
                                : ""
                            }
                          />
                          <Workbook.Column
                            label="User"
                            value={(col) =>
                              col.Resource?.FullName
                                ? col.Resource?.FullName
                                : ""
                            }
                          />
                          <Workbook.Column
                            label="Effort(Est.)"
                            value={(col) =>
                              col.EffortEst ? col.EffortEst + " hrs" : ""
                            }
                          />
                          <Workbook.Column label="Billing" value="BillRate" />
                        </Workbook.Sheet>
                      </Workbook>
                      <ButtonGroup className="float-right clearfix">
                        <Button
                          type="button"
                          variant="secondary"
                          size="sm"
                          className="dash-secondary-button margin-separator"
                          onClick={this.handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          size="sm"
                          type="button"
                          className="dash-primary-button"
                          onClick={this.handleSearch}
                        >
                          Search
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Accordion>{" "}
            </Col>
          </Row>
          {/* <table-client [(ngModel)]="PagedRows" [dataSource]="FilteredRows" [gridOptions]="GridOptions">
 <div className="table-header clearfix">
   <export-csv-client [options]="ExportCsvOptions"></export-csv-client>
   <button className="btn btn-xs  btn-search" (click)="InviteUser()">
     <i className="fa fa-user-plus"> Invite Member</i>
   </button>

   <Pagination></Pagination>
 </div> */}

          <table className="table table-striped table-center mt-1">
            <thead>
              <tr>
                <th>
                  <Tooltip
                    classes={{
                      tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                      arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement="top"
                    title="Refresh Data"
                  >
                    <button
                      className="dash-show-message-button dash-refresh-button"
                      onClick={this.handleRefresh}
                    >
                      <FontAwesomeIcon
                        className="dash-card-title"
                        icon={faSync}
                      />
                    </button>
                  </Tooltip>
                </th>
                <th></th>
                <th className='th-click' onClick={() => this.orderByMatter()}>Matter
                  <Tooltip
                    classes={{
                      tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                      arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement='top'
                    title='Click on the header to sort the data'
                  >
                    <i className='fas fa-info-circle ml-2'></i>
                  </Tooltip></th>
                <th className='th-click' onClick={() => this.orderByTitle()}>Title</th>
                <th className='th-click' onClick={() => this.orderByPriority()}>Priority</th>
                <th className='th-click' onClick={() => this.orderByDueDate()}>Due Date</th>
                <th className='th-click' onClick={() => this.orderByStatus()}>Status</th>
                <th className="width-100 th-click" onClick={() => this.orderByUser()}>User</th>
                <th className="width-100 th-click" onClick={() => this.orderByClient()}>Client</th>
                <th className="width-100 th-click" onClick={() => this.orderByEffort()}>Effort(Est.)</th>
                <th className='th-click' onClick={() => this.orderByBilling()}>Billing</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {displayedTasks &&
                displayedTasks.map(function (session: any, i) {
                  return (
                    <tr key={"session-" + i}>
                      <td>
                        <Tooltip
                          classes={{
                            tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                            arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="Upload Document"
                        >
                          <button
                            className="dash-show-message-button cursor-pointer"
                            // onClick={() => history.push(`/EditMatter/${c.MatterId}`)}
                            onClick={(event) =>
                              myclass.handelIsUploadShowing(
                                event,
                                session.TaskId
                              )
                            }
                          >
                            <i className="fas fa-cloud-upload-alt"></i>
                          </button>
                        </Tooltip>
                        {myclass.state.isUploadShowing &&
                          myclass.state.uploadId === session.TaskId && (
                            <UploadDocumentModal
                              ref={myclass.uploadModalRef}
                              isModalShowing={myclass.state.isUploadShowing}
                              itemId={myclass.state.uploadId}
                              onModalCloseClick={() =>
                                myclass.handlemodalClose()
                              }
                              handleModalCancel={myclass.handleModalCancel}
                              itemName={session.TaskTitle}
                              resourceType="Task"
                            />
                          )}
                      </td>
                      <td>
                        <Tooltip
                          classes={{
                            tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                            arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="Edit"
                        >
                          <button
                            className="dash-show-message-button cursor-pointer"
                            onClick={(event) =>
                              myclass.handelIsEditShowing(event, session.TaskId)
                            }
                          >
                            <i className="far fa-edit"></i>
                          </button>
                        </Tooltip>
                        {myclass.state.isEditShowing &&
                          myclass.state.editId === session.TaskId && (
                            <CreateTaskAdmin
                              ref={myclass.addModalRef}
                              isAddShowing={myclass.state.isEditShowing}
                              taskId={myclass.state.editId}
                              onModalCloseClick={() =>
                                myclass.handlemodalClose()
                              }
                            />
                          )}
                      </td>
                      <td className="dash-word-break-all">
                        {session.MatterTask[0]?.Matter?.MatterTitle}
                      </td>
                      <td className="dash-word-break-all">
                        {session.TaskTitle}
                      </td>
                      <td>{session.Priority?.PriorityName}</td>
                      <td>
                        <Moment format="YYYY/MM/DD">{session.DueDate}</Moment>
                      </td>
                      <td>
                        {session.TaskStatus?.TaskStatusId === 1 && (
                          <span className="badge dash-status-badge_open">
                            {session.TaskStatus?.TaskStatusName}
                          </span>
                        )}
                        {session.TaskStatus?.TaskStatusId === 2 && (
                          <span className="badge  dash-status-badge_inProgress">
                            {session.TaskStatus?.TaskStatusName}
                          </span>
                        )}
                        {session.TaskStatus?.TaskStatusId === 3 && (
                          <span className="badge  dash-status-badge_blocked">
                            {session.TaskStatus?.TaskStatusName}
                          </span>
                        )}
                        {session.TaskStatus?.TaskStatusId === 4 && (
                          <span className="badge  dash-status-badge_done">
                            {session.TaskStatus?.TaskStatusName}
                          </span>
                        )}
                      </td>
                      <td>
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          overlay={
                            <Popover
                              id="user-Data"
                              className="dash-user-card-popover"
                            >
                              <UserCard userId={session.Resource?.UserId} />
                            </Popover>
                          }
                        >
                          <div className="d-flex align-items-center cursor-pointer">
                            <Avatar
                              className={classNames(
                                "dash-grid-avatar",
                                session.Resource?.UserId ===
                                  myclass.state.currentUserID
                                  ? "d-none"
                                  : ""
                              )}
                              src={session.imageUrl}
                              alt=""
                            />
                            <span className="align-middle">
                              {session.Resource?.FullName}($
                              {session.BillRate?.BillRate})
                            </span>
                          </div>
                        </OverlayTrigger>
                      </td>
                      <td>
                        {session.MatterTask && session.MatterTask.length > 0
                          ? session.MatterTask[0]?.Matter?.Client?.ClientName
                          : "no client"}{" "}
                      </td>
                      <td>{session.EffortEst} hrs</td>
                      <td>${session.BillRate}</td>
                      <td>
                        <div>
                          <ChannelCounterComponent
                            search={{
                              id: session.TaskId,
                              type: "TASK",
                              showImg: true,
                              object: session,
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <Tooltip
                          classes={{
                            tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                            arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="Matters"
                        >
                          <div>
                            <Link
                              className="dash-show-message-button cursor-pointer d-block"
                              to={{
                                pathname: "/matters/mattersManagement",
                                state: {
                                  matterTask:
                                    session.MatterTask[0]?.Matter?.MatterTitle,
                                },
                              }}
                            >
                              <span className="dash-icons-matter dash-font-size-26"></span>
                            </Link>
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          classes={{
                            tooltip: "dash-tooltip", // class name, e.g. `classes-nesting-root-x`
                            arrow: "dash-tooltip-arrow", // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="View Documents"
                        >
                          <Link
                            className="dash-show-message-button cursor-pointer d-block"
                            to={{
                              pathname: "/documentCenter",
                              state: { taskId: session.TaskId },
                            }}
                          >
                            <span className="dash-icons-document-center dash-font-size-26"></span>
                          </Link>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {this.state.noResults && (
            <h3 className="margin-separator-top-search text-center text-white">
              No results found
            </h3>
          )}
        </div>
      </div>
    );
  }
}

export default AdminTasks;
