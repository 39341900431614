import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function GeneralTypeCounter({ search = { type: '' } }) {
  const dispatch = useDispatch();
  const metadataChannels = useSelector(
    (state: any) => state.channelMetadataReducer.channelsMetadata,
  );
  const channels = useSelector((state: any) => state.channelReducer.channels);
  const currentUser = useSelector((state: any) => state.userReducer.user);
  const currentChannel = useSelector(
    (state: any) => state.channelReducer.currentChannel,
  );

  const [pendingMessages, setPendingMessages] = useState(0);
  useEffect(() => {
    let filteredChannels = [];
    let counter = 0;
    if (search.type === 'MATTER') {
      filteredChannels = channels.filter(
        (currentChannel) =>
          currentChannel.Matter
      );
      
    } else if (search.type === 'TASK') {
      filteredChannels = channels.filter(
        (currentChannel) =>
          currentChannel.Task
      );
    }
    if (filteredChannels && filteredChannels.length > 0) {
      filteredChannels.forEach((myChannel: any) => {
        if (
          !currentChannel ||
          (currentChannel && currentChannel.Id !== myChannel.Id)
        ) {
          const currentMetadata: any = metadataChannels.find(
            (metadataChannel: any) =>
              metadataChannel.Channel &&
              metadataChannel.Channel.Id === myChannel.Id,
          );
          if (currentMetadata) {
            const unconsumedMessages =
              myChannel.Messages.length - currentMetadata.lastMessageReadIndex;
            counter = counter + unconsumedMessages;
          } else {
            const metadataObject = {
              lastMessageReadIndex: 0,
              userId: currentUser.UserId,
              channelId: myChannel.Id,
              Channel: myChannel,
              lastMessageSentIndex: myChannel.Messages.length,
            };
            metadataChannels.push(metadataObject);
            counter = counter + myChannel.Messages.length;
          }
        } else {
          const currentMetadata: any = metadataChannels.find(
            (metadataChannel: any) =>
              metadataChannel.Channel &&
              metadataChannel.Channel.Id === currentChannel.Id,
          );
          if (currentMetadata) {
            const unconsumedMessages =
              currentChannel.Messages.length -
              currentMetadata.lastMessageReadIndex;
            setPendingMessages(
              (currentCounter) => currentCounter - unconsumedMessages,
            );
          }
        }
      });
    }
    setPendingMessages((currentCounter) => (currentCounter = counter));

    return () => {
      setPendingMessages(0);
    };
  }, [dispatch, channels, metadataChannels, currentUser, currentChannel, search]);

  return (
    <span className="badge badge-pill dash-icon-badge dash-icon-badge_message">
      {pendingMessages > 0 ? pendingMessages : ''}
    </span>
  );
}
