import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import validator from "validator";
import moment from "moment";
import { Storage } from "aws-amplify";
import { Users as UsersApi } from "services/Api";

import CancelConfirmationModal from "../CancelConfirmationModal/cancelConfirmationModal";
import { Avatar } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

Storage.configure({
  bucket: "user-profile122920-dev",
  level: "public",
});

interface ResourceState {
  UserId: any;
  UserName: "";
  FirstName: "";
  LastName: "";
  Role: any;
  State: any;
  Department: any;
  Email: any;
  City: any;
  Mobile: any;
  DefaultPage: any;
  DefaultPages: any;
  Fax: any;
  BirthDate: any;
  BillRate: any;
  Address: any;
  CurrentDefaultPage: any;
  loading: boolean;
  showErrors: boolean;
  loadingData: boolean;
  formTouched: boolean;
  showCancelConfirm: boolean;
  imgSrc: any;
  maxBirthDate: Date;
  props: any;
  isDarkMode: boolean;
  clickedClass: any;
  body: any;
  lightTheme: any;
  darkTheme: any;
  theme: any;
}
class Profile extends React.Component<{ history }, ResourceState> {
  private fileInput: any;

  constructor(props: any) {
    super(props);
    this.fileInput = React.createRef();

    this.state = {
      UserId: null,
      UserName: "",
      FirstName: "",
      LastName: "",
      Role: null,
      State: null,
      Department: null,
      Email: "",
      CurrentDefaultPage: "",
      City: null,
      Mobile: "",
      Fax: null,
      DefaultPages: "",
      DefaultPage: "",
      BirthDate: null,
      BillRate: null,
      Address: null,
      loading: false,
      showErrors: false,
      loadingData: false,
      formTouched: false,
      showCancelConfirm: false,
      maxBirthDate: moment(new Date()).subtract(1, "days").toDate(),
      imgSrc: "",
      props: this.props,
      isDarkMode: true,
      clickedClass: "clicked",
      body: document.body,
      lightTheme: "light",
      darkTheme: "dark",
      theme: "",
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const AdminPages = [
      {
        DefaultPage: "Firms",
      },
      {
        DefaultPage: "Matters",
      },
      {
        DefaultPage: "Users",
      },
      {
        DefaultPage: "Tasks",
      },
      {
        DefaultPage: "Clients",
      },
      {
        DefaultPage: "Task Template",
      },
      {
        DefaultPage: "Matter Templates",
      },
      {
        DefaultPage: "Performance",
      },
      {
        DefaultPage: "Tasks Calendar",
      },
      {
        DefaultPage: "Tasks Board",
      },
      {
        DefaultPage: "Message Center",
      },
      {
        DefaultPage: "Meetings",
      },
      {
        DefaultPage: "Document Center",
      },
    ];
    const LawyerPages = [
      {
        DefaultPage: "Firms",
      },
      {
        DefaultPage: "Clients",
      },
      {
        DefaultPage: "Templates",
      },
      {
        DefaultPage: "Matters",
      },
      {
        DefaultPage: "Tasks",
      },
      {
        DefaultPage: "Performance",
      },
      {
        DefaultPage: "Tasks Calendar",
      },
      {
        DefaultPage: "Tasks Board",
      },
      {
        DefaultPage: "Message Center",
      },
      {
        DefaultPage: "Session",
      },
      {
        DefaultPage: "Document Center",
      }
    ];

    const currentUser: any = JSON.parse(
      localStorage.getItem("userData") || "{}"
    );

    if (
      currentUser.Role.RoleName === "System Admin" ||
      currentUser.Role.RoleName === "State Admin" ||
      currentUser.Role.RoleName === "County Admin" ||
      currentUser.Role.RoleName === "Court Admin" ||
      currentUser.Role.RoleName === "Department Admin" ||
      currentUser.Role.RoleName === "Outside Law Firm Admin" ||
      currentUser.Role.RoleName === "Law Firm Admin"
    ) {
      this.setState({ DefaultPages: AdminPages });
    } else {
      this.setState({ DefaultPages: LawyerPages });
    }

    if (currentUser.DefaultPage === "") {
      this.setState({ CurrentDefaultPage: "Templates" });
    } else {
      this.setState({ CurrentDefaultPage: currentUser.DefaultPage });
    }

    if (currentUser) {
      // if (currentUser.ProfileImageURL) {
      //   try {
      //     const fullSrc = await Storage.get(currentUser.ProfileImageURL);
      //     this.setState({ imgSrc: fullSrc });
      //   } catch (err) {
      //     alert(err.message ? err.message : err);
      //   }
      // }

      const name = currentUser.FullName.split(" ", 2);
      this.setState({
        UserId: currentUser.UserId,
        UserName: currentUser.UserName,
        FirstName: name[0],
        LastName: name[1],
        Role: currentUser.Role?.RoleName,
        State: currentUser.StateId,
        Department: currentUser.DepartmentId,
        Email: currentUser.Email,
        City: currentUser.City,
        Mobile: currentUser.Mobile,
        Fax: currentUser.Fax,
        BirthDate: currentUser.BirthDate
          ? new Date(currentUser.BirthDate)
          : null,
        BillRate: currentUser.BillRate,
        Address: currentUser.Address,
        imgSrc: currentUser.ProfileImageURL,
        loadingData: false,
        isDarkMode: currentUser.isDarkMode,
      });
    }
  };

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === "number" ? parseInt(value) : value;
    value = type === "checkbox" ? e.target.checked : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    this.setState({ formTouched: true });
  };

  redirectDefaultPage = () => {
    if (this.state.CurrentDefaultPage === "Firms") {
      this.props.history.push("/firms");
    } else if (this.state.CurrentDefaultPage === "Tasks Calendar") {
      this.props.history.push("/tasksCalendar");
    } else if (this.state.CurrentDefaultPage === "Matters") {
      this.props.history.push("/matters/mattersManagement");
    } else if (this.state.CurrentDefaultPage === "Users") {
      this.props.history.push("/users");
    } else if (this.state.CurrentDefaultPage === "Tasks") {
      this.props.history.push("/tasks");
    } else if (this.state.CurrentDefaultPage === "Clients") {
      this.props.history.push("/clients/clientsManagement");
    } else if (this.state.CurrentDefaultPage === "Task Template") {
      this.props.history.push("/settings/taskTemplate");
    } else if (this.state.CurrentDefaultPage === "Performance") {
      this.props.history.push("/dashboard");
    } else if (this.state.CurrentDefaultPage === "Tasks Board") {
      this.props.history.push("/tasksBoard");
    } else if (this.state.CurrentDefaultPage === "Templates") {
      this.props.history.push("/matterTemplates");
    } else if (this.state.CurrentDefaultPage === "Message Center") {
      this.props.history.push("/messageCenter");
    } else if (this.state.CurrentDefaultPage === "Meetings") {
      this.props.history.push("/session");
    } else if (this.state.CurrentDefaultPage === "Performance") {
      this.props.history.push("Dashboard");
    } else if (this.state.CurrentDefaultPage === "DocumentCenter") {
      this.props.history.push("/documentCenter");
    } else {
      this.props.history.push("/matters/mattersManagement");
    }
  };

  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));
    this.setState({ formTouched: true });
  };

  handleCloseCancelConfirm = () => {
    this.setState({ showCancelConfirm: false });
  };

  isContainSpace = (str) => {
    var pattern = /\s/;
    return pattern.test(str);
  };

  isValidName = (str) => {
    var pattern = /^[a-zA-Z]*$/;
    return pattern.test(str);
  };

  hasNumbers = (t) => {
    var regex = /\d/g;
    return regex.test(t);
  }

  imageChanged = (e) => {
    this.setState({
      imgSrc: URL.createObjectURL(e.target.files[0]),
    });
  };

  SaveProfile = async (e) => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (
      this.state.FirstName !== "" &&
      this.state.LastName !== "" &&
      this.state.Email !== "" &&
      this.state.Mobile !== "" &&
      this.isValidName(this.state.FirstName) && 
      this.isValidName(this.state.LastName)

    ) {
      const file = this.fileInput.current.files[0];
      let userProfile: any;
      this.setState({ loading: true, loadingData: true });

      if (file) {
        const fileName = this.state.UserName + "/" + file.name;
        const folder = "profiles";
        try {
          const result: any = await Storage.put(folder + "/" + fileName, file, {
            contentType: file.type,
            level: "public",
          });
          userProfile = result.key;
          const fullSrc = await Storage.get(result.key);
          this.setState({ imgSrc: fullSrc });
        } catch (err) {
          toast.error(`Profile not saved ${err.message ? err.message : err}`);
          this.setState({ loading: false, loadingData: false });
        }
      }

      const profileObj = {
        UserId: this.state.UserId,
        FullName: `${this.state.FirstName} ${this.state.LastName} `,
        Email: this.state.Email,
        Mobile: this.state.Mobile,
        City: this.state.City,
        DefaultPage: this.state.DefaultPage,
        BirthDate: this.state.BirthDate,
        Address: this.state.Address,
        Fax: this.state.Fax,
        BillRate: this.state.BillRate,
        isDarkMode: this.state.isDarkMode,
      };

      if (file) {
        profileObj["ProfileImageURL"] = userProfile;
      }

      await UsersApi.update(profileObj)
        .then((response) => {
          this.setState({ loading: false, loadingData: false });
          toast.success("Data saved successfully!");
          if (!this.state.isDarkMode) {
            document.body.classList.replace("dark", "light");
          } else {
            document.body.classList.replace("light", "dark");
          }
        })
        .catch((err) => {
          toast.error(`Profile not saved ${err.message ? err.message : err}`);
          this.setState({ loading: false, loadingData: false });
        });

      let localUser: any = JSON.parse(localStorage.getItem("userData") || "{}");
      profileObj["ProfileImageURL"] = this.state.imgSrc;
      localUser = { ...localUser, ...profileObj };
      localStorage.setItem("userData", JSON.stringify(localUser));
    }
  };
  render() {
    console.log(this.state.DefaultPages);

    let myPages = Object.entries(this.state.DefaultPages);
    console.log(myPages);
    let userImg = "";
    if (this.state.imgSrc) {
      userImg = this.state.imgSrc;
    }
    return (
      <React.Fragment>
        <form onSubmit={this.SaveProfile}>
          <div className="mb-0">
            <ButtonGroup className="margin-separator-bot">
              <Button
                className="btn btn-xs btn-info dash-back-button  "
                onClick={() => this.state.props.history.goBack()}
              >
                Back
              </Button>
            </ButtonGroup>
            <div>
              {this.state.loadingData && (
                <div className="dash-page-spinner-container">
                  <Col>
                    <Spinner
                      className="mr-1 dash-page-spinner"
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </Col>
                </div>
              )}
              <div>
                <Row>
                  <Col className="justify-content-center d-flex mb-3">
                    <label
                      htmlFor="contained-button-file"
                      className="cursor-pointer ml-5 pl-4"
                    >
                      <Avatar
                        alt=""
                        src={userImg}
                        className="dash-profile-user-img cursor-pointer"
                      />
                    </label>
                    <input
                      accept="*/*"
                      className="d-none"
                      id="contained-button-file"
                      type="file"
                      ref={this.fileInput}
                      onChange={(e) => {
                        this.setState({ formTouched: true });
                        this.imageChanged(e);
                      }}
                    />
                  </Col>
                </Row>
                <div className="dash-profile-inputs-container">
                  <Row className="form-group">
                    <Col md={2}>
                      <div className=" dash-form-label">
                        First Name
                        <span className="dash-require-astrike"></span>
                      </div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <input
                        type="text"
                        className="form-control dash-form-control"
                        id="FirstName"
                        name="FirstName"
                        value={this.state.FirstName || ""}
                        onChange={this.changeHandler}
                      />
                      {this.state.showErrors === true &&
                        validator.isEmpty(this.state.FirstName) && (
                          <span className="dash-error-message">Required</span>
                        )}
                      { this.state.showErrors === true && this.isContainSpace(this.state.FirstName) && !this.hasNumbers(this.state.FirstName) && (
                          <span className="dash-error-message">
                            must not contain spaces
                          </span>
                        )}
                      {this.state.showErrors === true &&
                        this.hasNumbers(this.state.FirstName) && !this.isContainSpace(this.state.FirstName) && (
                          <span className="dash-error-message">
                            must not include Numbers
                          </span>
                        )}
                         {this.state.showErrors === true &&
                        this.hasNumbers(this.state.FirstName) && this.isContainSpace(this.state.FirstName) && (
                          <span className="dash-error-message">
                            must not include space and numbers
                          </span>
                        )}
                    </Col>
                    <Col md={2}>
                      <div className=" dash-form-label">
                        Last Name
                        <span className="dash-require-astrike"></span>
                      </div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <input
                        type="text"
                        className="form-control dash-form-control"
                        id="LastName"
                        name="LastName"
                        value={this.state.LastName || ""}
                        onChange={this.changeHandler}
                      />
                      {this.state.showErrors === true &&
                        validator.isEmpty(this.state.LastName) && (
                          <span className="dash-error-message">Required</span>
                        )}
                      {this.state.showErrors === true && this.isContainSpace(this.state.LastName) && !this.hasNumbers(this.state.LastName) && (
                          <span className="dash-error-message">
                            must not contain spaces
                          </span>
                        )}
                      {this.state.showErrors === true &&
                        this.hasNumbers(this.state.LastName) && !this.isContainSpace(this.state.LastName) && (
                          <span className="dash-error-message">
                            must not include Numbers
                          </span>
                        )}
                         {this.state.showErrors === true &&
                        this.hasNumbers(this.state.LastName) && this.isContainSpace(this.state.LastName) && (
                          <span className="dash-error-message">
                            must not include space and numbers
                          </span>
                        )}
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Col md={2}>
                      <div className=" dash-form-label">City</div>
                    </Col>

                    <Col md={4} className="pr-5">
                      <input
                        type="text"
                        className="form-control dash-form-control"
                        id="City"
                        name="City"
                        value={this.state.City || ""}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col md={2}>
                      <div className="dash-form-label">
                        Mobile
                        <span className="dash-require-astrike"></span>
                      </div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <input
                        type="text"
                        className="form-control dash-form-control"
                        id="Mobile"
                        name="Mobile"
                        value={this.state.Mobile || ""}
                        onChange={this.changeHandler}
                      />
                      {this.state.showErrors === true &&
                        validator.isEmpty(this.state.Mobile) && (
                          <span className="dash-error-message">Required</span>
                        )}
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Col md={2}>
                      <div className="dash-form-label">Birth Date</div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <label className="dash-datepicker">
                        <DatePicker
                          name="BirthDate"
                          className="form-control dash-form-control"
                          selected={this.state.BirthDate || ""}
                          onChange={(e) =>
                            this.changeDateHandler(e, "BirthDate")
                          }
                          showYearDropdown
                          maxDate={this.state.maxBirthDate}
                        />
                      </label>
                    </Col>
                    <Col md={2}>
                      <div className=" dash-form-label">Fax</div>
                    </Col>

                    <Col md={4} className="pr-5">
                      <input
                        type="text"
                        className="form-control dash-form-control"
                        id="Fax"
                        name="Fax"
                        value={this.state.Fax || ""}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Col md={2}>
                      <div className="dash-form-label">
                        Email
                        <span className="dash-require-astrike"></span>
                      </div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <input
                        type="email"
                        className="form-control dash-form-control"
                        id="Email"
                        name="Email"
                        value={this.state.Email || ""}
                        onChange={this.changeHandler}
                      />
                      {this.state.showErrors === true &&
                        validator.isEmpty(this.state.Email) && (
                          <span className="dash-error-message">Required</span>
                        )}
                    </Col>

                    <Col md={2}>
                      <div className="dash-form-label">Address</div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <input
                        type="text"
                        className="form-control dash-form-control"
                        id="Address"
                        name="Address"
                        value={this.state.Address || ""}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Col md={2}>
                      <div className="dash-form-label">Default Page</div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <select
                        id="DefaultPage"
                        name="DefaultPage"
                        value={this.state.DefaultPage || ""}
                        className="form-control dash-form-control"
                        onChange={this.changeHandler}
                      >
                        <option>{this.state.CurrentDefaultPage}</option>
                        {myPages.map((x: any, i) => (
                          <option key={"x" + i} value={x[1].DefaultPage}>
                            {x[1].DefaultPage}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={2}>
                      <div className="dash-form-label">Fixed Cost</div>
                    </Col>
                    <Col md={4} className="pr-5">
                      <input
                        type="text"
                        className="form-control dash-form-control"
                        id="BillRate"
                        name="BillRate"
                        value={this.state.BillRate || ""}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Col md={2}>
                      <div className="dash-form-label">Enable Dark Mode</div>
                    </Col>
                    <Col md={4} className="pl-1">
                      <Switch
                        checked={this.state.isDarkMode}
                        onChange={(e) => {
                          this.changeHandler(e);
                        }}
                        color="primary"
                        name="isDarkMode"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                        classes={{
                          root: "dash-switch",
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-5" style={{ marginTop: 25 }}>
                      <ButtonGroup className="float-right">
                        <Button
                          type="reset"
                          className="dash-secondary-button margin-separator"
                          onClick={this.redirectDefaultPage}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="dash-primary-button"
                          disabled={this.state.loading}
                          onClick={this.SaveProfile}
                        >
                          {this.state.loading && (
                            <Spinner
                              className="mr-1 dash-button-spinner"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}

                          {this.state.loading ? (
                            <span>Saving...</span>
                          ) : (
                            <span>Save</span>
                          )}
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </div>
                <Row className="justify-content-center my-3">
                  <Col md={8}>
                    <div className="dash-profile-user-details">
                      <Row>
                        <Col>
                          <p>Role: {this.state.Role}</p>
                        </Col>
                        <Col>
                          <p>State: {this.state.State}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>Department: {this.state.Department}</p>
                        </Col>
                        <Col>
                          <p>Rate: ${this.state.BillRate}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>Matters: </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>Tasks: </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </form>

        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={this.state.showCancelConfirm}
            onModalYesClick={() =>
              this.props.history.push("/matters/mattersManagement")
            }
            onModalNoClick={() => this.setState({ showCancelConfirm: false })}
          ></CancelConfirmationModal>
        )}
      </React.Fragment>
    );
  }
}

export default Profile;
