import React, { useState } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Auth, Storage } from 'aws-amplify';
import { Spinner } from 'react-bootstrap';

import { useUserDispatch } from 'contexts/UserAuthContext';
import useForm from 'shared/useForm';
import { UserActionTypes } from 'types/user';
import { ValidateIdentity } from 'shared/utils';

import { Users } from 'services/Api';
import { CheckUserExistence } from 'services/Api/Auth';

const logo = require('../../assets/img/logo.png');

const Login = () => {
  const dispatch = useUserDispatch();
  let history: any = useHistory();
  const stateSchema = {
    username: { value: '', error: '' },
    password: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    username: {
      required: true,
      validator: {
        func: (value: string) =>
          /* eslint-disable-next-line */
          /^([A-Za-z0-9._%\+\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/.test(
            value.trim()
          ),
        error: 'Email / Phone is not valid.',
      },
    },
    password: {
      required: true,
    },
  };

  const { values, errors, dirty, handleOnChange, disable } = useForm(
    stateSchema,
    stateValidatorSchema
  );

  const { username, password } = values;
  const [loading, setLoading] = useState(false);
  let filteredIdentity;

  // const [password, setPassword] = useState('');

  const _handleClick = async () => {
    setLoading(true);
    try {
      filteredIdentity = ValidateIdentity(username);
      const loggedUser = await Auth.signIn(filteredIdentity.trim(), password);

      dispatch({
        type: UserActionTypes.SUCCESS,
        payload: loggedUser,
      });

      if (loggedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const location = {
          pathname: '/changePassword',
          state: {
            user: { identity: filteredIdentity.trim(), password },
            challengeName: 'NEW_PASSWORD_REQUIRED',
          },
        };
        history.push(location);
      } else {
        const isEmailVerified = loggedUser.attributes.email_verified;
        const isPhoneVerified = loggedUser.attributes.phone_number_verified;
        if (!isEmailVerified) {
          if (isPhoneVerified) {
            try {
              await Auth.verifyCurrentUserAttribute('email');
            } catch (err) {
              if (!err.message) {
                alert(`Error sending code: ${err}`);
              } else {
                alert(`Error sending code: ${err.message}`);
              }
            }
          }
          const location = {
            pathname: '/verifyCode',
            state: {
              username: username,
              email_verified: isEmailVerified,
              phone_verified: isPhoneVerified,
            },
          };
          history.push(location);
        } else {
          const userObj = await Users.singleByUsername(
            loggedUser.attributes.sub
          )
            .then(async (response) => {
              return response.data;
            })
            .catch((error) => {
              return error.message;
            });

          if (userObj.ProfileImageURL) {
            const fullSrc = await Storage.get(userObj.ProfileImageURL, {
              expires: 604800,
            });
            userObj.ProfileImageURL = fullSrc;
          }
          const currentUserRole = userObj.Role;
          localStorage.setItem('user', JSON.stringify(currentUserRole));
          localStorage.setItem('userData', JSON.stringify(userObj));
          if (
            currentUserRole.RoleName === 'System Admin' ||
            currentUserRole.RoleName === 'State Admin' ||
            currentUserRole.RoleName === 'County Admin' ||
            currentUserRole.RoleName === 'Court Admin' ||
            currentUserRole.RoleName === 'Department Admin' ||
            currentUserRole.RoleName === 'Outside Law Firm Admin' ||
            currentUserRole.RoleName === 'Law Firm Admin'
          ) {
            if (userObj.DefaultPage === 'Firms') {
              const location = {
                pathname: '/firms',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Matters') {
              const location = {
                pathname: '/matters/mattersManagement',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Users') {
              const location = {
                pathname: '/users',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Tasks') {
              const location = {
                pathname: '/tasks',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Performance') {
              const location = {
                pathname: '/dashboard',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Tasks Calendar') {
              const location = {
                pathname: '/tasksCalendar',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Tasks Board') {
              const location = {
                pathname: '/tasksBoard',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Clients') {
              const location = {
                pathname: '/clients/clientsManagement',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Task Template') {
              const location = {
                pathname: '/settings/taskTemplate',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Templates') {
              const location = {
                pathname: '/matterTemplates',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Message Center') {
              const location = {
                pathname: '/messageCenter',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Meetings') {
              const location = {
                pathname: '/session',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'DocumentCenter') {
              const location = {
                pathname: '/documentCenter',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else {
              const location = {
                pathname: '/matters/mattersManagement',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            }
          } else {
            if (userObj.DefaultPage === 'Meetings') {
              const location = {
                pathname: '/session',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Firms') {
              const location = {
                pathname: '/firms',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Clients') {
              const location = {
                pathname: '/clients/clientsManagement',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Templates') {
              const location = {
                pathname: '/matterTemplates',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Matters') {
              const location = {
                pathname: '/matters/mattersManagement',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Tasks') {
              const location = {
                pathname: '/tasks',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Performance') {
              const location = {
                pathname: '/Dashboard',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Tasks Calendar') {
              const location = {
                pathname: '/tasksCalendar',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Tasks Board') {
              const location = {
                pathname: '/tasksBoard',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'Message Center') {
              const location = {
                pathname: '/messageCenter',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else if (userObj.DefaultPage === 'DocumentCenter') {
              const location = {
                pathname: '/documentCenter',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            } else {
              const location = {
                pathname: '/matters/mattersManagement',
                state: {
                  role: userObj.FullName,
                },
              };
              history.push(location);
            }
          }
          if (!userObj.isDarkMode) {
            document.body.classList.replace('dark', 'light');
          } else {
            document.body.classList.replace('light', 'dark');
          }
        }
      }
    } catch (err) {
      let errMsg = '';
      if (err.code === 'UserNotFoundException') {
        const isUserExist = await CheckUserExistence(filteredIdentity);
        const notVerifiedUser = Object.assign(
          {},
          ...isUserExist?.Attributes.map((attribute) => ({
            [attribute.Name]: attribute.Value,
          }))
        );
        notVerifiedUser['username'] = isUserExist?.Username;
        notVerifiedUser['userStatus'] = isUserExist?.UserStatus;
        console.log(isUserExist);

        const isPhoneVerified =
          notVerifiedUser.phone_number_verified === 'true' ? true : false;
        const isEmailVerified =
          notVerifiedUser.email_verified === 'true' ? true : false;

        const location = {
          pathname: '/verifyCode',
          state: {
            username: notVerifiedUser['username'],
            email_verified: isEmailVerified,
            phone_verified: isPhoneVerified,
          },
        };

        if (!isPhoneVerified) {
          if (filteredIdentity.includes('@')) {
            setLoading(false);
            alert(
              `Please validate phone first, then validate the email address`
            );
          } else if (notVerifiedUser['userStatus'] === 'UNCONFIRMED') {
            await Auth.resendSignUp(notVerifiedUser.username);
            history.push(location);
          } else {
            try {
              await Auth.signIn(notVerifiedUser.email, password);
              await Auth.verifyCurrentUserAttribute('phone_number');
              history.push(location);
            } catch (err) {
              setLoading(false);
              err.message ? alert(err.message) : alert(err);
            }
          }
        } else if (!isEmailVerified) {
          try {
            await Auth.signIn(notVerifiedUser.phone_number, password);
            await Auth.verifyCurrentUserAttribute('email');
            history.push(location);
          } catch (err) {
            setLoading(false);
            if (!err.message) {
              alert(`Error sending code for verifying current user: ${err}`);
            } else {
              alert(
                `Error sending code for verifying current user: ${err.message}`
              );
            }
          }
        } else {
          setLoading(false);
          errMsg = 'This user may not exist.';
          alert(errMsg);
        }
      } else {
        setLoading(false);
        alert(`Login ${err.message}`);
      }
    }
  };

  const individualSignUp = async () => {
    setLoading(true);
    const location = '/register/individual' 
    setTimeout(function () {
      history.push(location);
    }, 1000);
  };
  const entitySignUp = async () => {
    setLoading(true);
    const location ='/register/entity' 
    setTimeout(function () {
      history.push(location);
    }, 1000);
  };
  const handleForgotPassword = () => {
    const location = {
      pathname: '/forgotPassword',
    };
    history.push(location);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      _handleClick();
    }
  };

  return (
    <div className='animated fadeInLeft h-100 totalSize'>
      <BrowserRouter>
        <Container fluid className='dash-entry-container'>
          <Row className='justify-content-center h-100'>
            <Col md={{ span: 7, offset: 4 }} className='my-auto text-center'>
              <img src={logo} className='dash-entry-logo mb-5 pb-4' alt='' />
              <CardGroup className='card-group dash-entry-card-group'>
                <Card className='card py-2 px-4 dash-entry-card'>
                  <Card.Body className='card-body'>
                    <h2 className='dash-entry-title'>Login</h2>
                    <p className='dash-entry-sub-title my-4'>
                      Sign In to your account
                    </p>
                    <Form name='form' noValidate>
                      <div className='dash-middle-section-height'>
                      <Form.Group>
                        <InputGroup>
                          <input
                            type='text'
                            className='form-control dash-form-control'
                            placeholder='username'
                            name='username'
                            value={username}
                            onChange={handleOnChange}
                            onKeyDown={_handleKeyDown}
                            required
                          />
                        </InputGroup>
                        {errors.username && dirty.username && (
                          <p className='error'>{errors.username}</p>
                        )}
                        {/* <div className="invalid-text">Email is required</div> */}
                      </Form.Group>
                      <Form.Group>
                        <InputGroup>
                          <input
                            type='password'
                            className='form-control dash-form-control'
                            placeholder='Password'
                            name='password'
                            value={password}
                            onChange={handleOnChange}
                            onKeyDown={_handleKeyDown}
                            required
                          />
                        </InputGroup>
                        {errors.password && dirty.password && (
                          <p className='error'>{errors.password}</p>
                        )}
                        {/* <div className="invalid-text">Email is required</div> */}
                      </Form.Group>
                      </div>
                      <div>
                        <Button
                          block
                          disabled={disable}
                          variant='outline-light'
                          className='dash-primary-button mt-5 mx-auto'
                          onClick={_handleClick}
                        >
                          Login
                        </Button>
                        {loading && (
                          <div className='dash-page-spinner-container'>
                            <Col>
                              <Spinner
                                className='mr-1 dash-page-spinner'
                                as='span'
                                animation='border'
                                role='status'
                                aria-hidden='true'
                              />
                            </Col>
                          </div>
                        )}
                        <Button
                          variant='link'
                          onClick={handleForgotPassword}
                          block
                        >
                          Forgot your password
                        </Button>
                      </div>

                      {/* <Button variant="primary" size="sm">Forgot password?</Button> */}
                    </Form>
                  </Card.Body>
                </Card>
                <div className='dash-vertical-divider'></div>
                <Card className='card py-2 px-3 dash-entry-card'>
                  <Card.Body className='card-body text-center'>
                    <div>
                      <h2 className='dash-entry-title'>Sign Up</h2>
                      <p className='my-4'> &nbsp;</p>

                      <p className='dash-entry-signup-text mb-4 dash-middle-section-height'>
                        If you are interested in knowing more about DASH and
                        participating in Beta testing, please sign up and create
                        your firm.{' '}
                      </p>
                      <Button
                        type='button'
                        variant='outline-light'
                        className='dash-primary-button mt-4 mr-2'
                        onClick={individualSignUp}
                      >
                        Individual Sign up
                      </Button>
                      <Button
                        type='button'
                        variant='outline-light'
                        className='dash-primary-button mt-4'
                        onClick={entitySignUp}
                      >
                        Entity Registration
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    </div>
  );
};

export default Login;
