import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Sessions } from 'services/Api';

interface ScheduleState {
  topic: '';
  type: '2';
  start: '';
  duration: '';
}
class ScheduleMeeting extends React.Component<{ history }, ScheduleState> {
  constructor(props: any) {
    super(props);

    this.state = {
      topic: '',
      type: '2',
      start: '',
      duration: '',
    };
  }

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  submitHandler = (e: any) => {
    e.preventDefault();
    console.log(this.state);

    Sessions.scheduleZoomMeeting(this.state)
      .then((response) => {
        console.log(response);
        this.props.history.push('/session');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handelCancel = () => {
    this.setState({
      topic: '',
      type: '2',
      start: '',
      duration: '',
    });
  };
  render() {
    return (
      <div>
        <form onSubmit={this.submitHandler}>
          <div className='mb-0'>
            <Row>
              <Col md={12}>
                <Card className='dash-card'>
                  <Card.Header className='dash-card-header'>
                    <span className='dash-card-title'>Schedule Meeting</span>
                    <ButtonGroup className='float-right'>
                      <Button type='submit' className='dash-primary-button'>
                        Save
                      </Button>
                    </ButtonGroup>
                  </Card.Header>
                  <Card.Body className='dash-card-body'>
                    <Row className='form-group'>
                      <Col md={2}>
                        <div className='dash-form-label'>Topic</div>
                      </Col>
                      <Col md={4}>
                        <input
                          type='text'
                          className='form-control dash-form-control'
                          id='topic'
                          name='topic'
                          value={this.state.topic}
                          onChange={this.changeHandler}
                        />
                      </Col>
                      <Col md={2}>
                        <div className='dash-form-label'>Type</div>
                      </Col>
                      <Col md={4}>
                        <input
                          type='text'
                          className='form-control dash-form-control'
                          id='type'
                          name='type'
                          value={this.state.type}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className='form-group'>
                      <Col md={2}>
                        <div className='dash-form-label'>Start time</div>
                      </Col>
                      <Col md={4}>
                        <input
                          type='text'
                          className='form-control dash-form-control'
                          id='start'
                          name='start'
                          value={this.state.start}
                          onChange={this.changeHandler}
                        />
                      </Col>
                      <Col md={2}>
                        <div className='dash-form-label'>Duration</div>
                      </Col>
                      <Col md={4}>
                        <input
                          type='text'
                          className='form-control dash-form-control'
                          id='duration'
                          name='duration'
                          value={this.state.duration}
                          onChange={this.changeHandler}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    );
  }
}

export default ScheduleMeeting;
