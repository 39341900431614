import React from "react";
import Grid from "@material-ui/core/Grid";
import BarChart from "./barChart";
import PieChart from "./pieChart";
import DonutChart from "./donutChart";

import NegativeChart from "./negativeChart";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import "./view.css";
import { ButtonGroup, Row, Col } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#999999",
  },
}));

export default function ViewChart(props) {
  const classes = useStyles();

  return (
    <div className=" animated fadeInLeft">
      <Row className="mb-4">
        <Col md={12} className="d-flex justify-content-center">
          <div className="float-left position-absolute dash-left-3 dash-top-3">
            <ButtonGroup className="margin-separator margin-separator-bot">
              <button
                className="btn btn-xs btn-info dash-back-button mb-0 "
                onClick={() => props.history.goBack()}
              >
                Back
              </button>
            </ButtonGroup>
          </div>
          <div className="dash-page-title">
            <h3 className="mb-0 text-center">Performance</h3>
            <div className="dash-line-icon"></div>
          </div>
        </Col>
      </Row>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <NegativeChart {...props} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <PieChart {...props} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <BarChart {...props} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <DonutChart {...props} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
