const Roles = {
  Admin: {
    SystemAdmin: 'System Admin',
    StateAdmin: 'State Admin',
    CountyAdmin: 'County Admin',
    CourtAdmin: 'Court Admin',
    LawFirmAdmin: 'Law Firm Admin',
    DepartmentAdmin: 'Department Admin',
    OutsideLawFirmAdmin: 'Outside Law Firm Admin',
  },
  User: {
    CourtClerk: 'Court Clerk',
    DepartmentResource: 'Department Resource',
    DepartmentFirm: 'Department Firm',
    DepartmentFirmResource: 'Department Firm Resource',
    DepartmentConsumers: 'Department Consumers',
    LawFirmResource: 'Law Firm Resource',
    LawFirmConsumer: 'Law Firm Consumer',
    LegalProfessional: 'Legal Professional',
    Resource: 'Resource',
    Judge: 'Judge',
  },
  CustomAdminUser: {
    SystemAdmin: 'System Admin',
    StateAdmin: 'State Admin',
    CountyAdmin: 'County Admin',
    CourtAdmin: 'Court Admin',
    LawFirmAdmin: 'Law Firm Admin',
    DepartmentAdmin: 'Department Admin',
    OutsideLawFirmAdmin: 'Outside Law Firm Admin',
    LegalProfessional: 'Legal Professional',
  }
};

const AdminRoles = Object.values(Roles.Admin);
const UserRoles = Object.values(Roles.User);
const CustomAdminUser = Object.values(Roles.CustomAdminUser);
const AllRoles = [...AdminRoles, ...UserRoles];

export { AdminRoles, UserRoles, AllRoles, CustomAdminUser };
