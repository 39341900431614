import React, { useRef, useState, useEffect } from 'react';

import {
  Row,
  Spinner,
  Card,
  Col,
  Button,
  ButtonGroup,
  Accordion,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faTasks,
  faPlusCircle, faSync
} from '@fortawesome/free-solid-svg-icons';
import AddMatter from '../Admin/matters/addMatter';
import CreateTaskAdmin from '../Admin/tasks/createTaskAdmin';
// import Icon from '@material-ui/core/Icon';
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import UserCard from '../profile/userCard';
import { Users, Templates, Matters, Priorities, Clients } from 'services/Api';
import Tooltip from '@material-ui/core/Tooltip';
import Workbook from 'react-excel-workbook';
import moment from 'moment';

export default function MatterTemplates(props) {
  const filterInitialState = {
    Title: '',
    typeId: '',
    stageId: '',
    priorityId: '',
    statusId: '',
    responsibleId: '',
    clientId: '',
  };
  const searchForm: any = useRef<HTMLFormElement>(null);
  let addModalRef: any = useRef<any>(null);
  const [templates, setTemplates] = useState([]);
  const [displayedTemplates, setDisplayedTemplates] = useState([]);
  const [templateFilter, setTemplateFilter] = useState(filterInitialState);
  const [runSearch, setRunSearch] = useState(false);
  const [isEditShowing, setIsEditShowing] = useState(false);
  const [isAddShowing, setIsAddShowing] = useState(false);
  const [isAddMatterShowing, setIsAddMatterShowing] = useState(false);
  const [isNewTemplateShowing, setIsNewTemplateShowing] = useState(false);
  const [editId, seteditId] = useState(false);
  const [newTaskTemplateId, setTaskTemplateId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [status, setStatus] = useState([]);
  const [stage, setStage] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [clients, setClients] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [matterTemplateToPass, setMatterTemplateToPass] = useState();
  const [currentUserID, setCurrentUserID] = useState();
  React.useEffect(() => {
    let currentUser: any = localStorage.getItem('userData');
    const currentUserID: any = JSON.parse(currentUser ? currentUser : '{}');
    setCurrentUserID(currentUserID.UserId);
    _handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runSearch]);
  const changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    setTemplateFilter({
      ...templateFilter,
      [name]: value,
    });
  };
  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);

      await Templates.taskTemplates();

      const userObj: any = await Users.index();
      setUsers(userObj.data);

      await Templates.matterTemplates()
        .then((response) => {
          setTemplates(response.data);
          setDisplayedData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get CaseTypes
      await Matters.lookups
        .types()
        .then((response) => {
          setTypes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      //get priorities
      await Priorities.index()
        .then((response) => {
          setPriorities(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get status
      await Matters.lookups
        .statuses()
        .then((response) => {
          setStatus(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get stage
      await Matters.lookups
        .stages()
        .then((response) => {
          setStage(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //get clients
      await Clients.index()
        .then((response) => {
          setClients(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchMyAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTemplates]);

  const _handleSearch = () => {
    const filteredFirms = templates.filter((temp: any) => {
      return (
        temp.MatterTemplateTitle.toLowerCase().includes(
          templateFilter.Title.toLowerCase(),
        ) &&
        (temp.MatterStatus?.MatterStatusId.toString() ===
          templateFilter.statusId.toString() ||
          templateFilter.statusId === '') &&
        (temp.Priority?.PriorityId.toString() ===
          templateFilter.priorityId.toString() ||
          templateFilter.priorityId === '') &&
        (temp.MatterType?.MatterTypeId.toString() ===
          templateFilter.typeId.toString() ||
          templateFilter.typeId === '') &&
        (temp.MatterStage?.MatterStageId.toString() ===
          templateFilter.stageId.toString() ||
          templateFilter.stageId === '') &&
        (temp.Client?.ClientId.toString() ===
          templateFilter.clientId.toString() ||
          templateFilter.clientId === '') &&
        (temp.Manager?.UserId.toString() ===
          templateFilter.responsibleId.toString() ||
          templateFilter.responsibleId === '')
      );
    });
    if (
      filteredFirms.length !== displayedTemplates.length &&
      filteredFirms.length === 0
    ) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
    setDisplayedTemplates(filteredFirms);
  };
  const _handleReset = async () => {
    await setTemplateFilter(filterInitialState);
    setRunSearch(!runSearch);
  };
  // setFirmFilter([...firmFilter, { FirmName: null, roleId: null}]);
  // setFirmFilter((firmFilter: any) => filterInitialState);

  const submitForm = (e: any) => {
    e.preventDefault();
    _handleSearch();
  };
  const setDisplayedData = async (data) => {
    let templateData: any = [];
    for (let temp of data) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    setDisplayedTemplates(templateData);
    setLoading(false);
  };

  const handleModalCancel = () => {
    setIsEditShowing(false);
    setIsAddShowing(false);
    setIsAddMatterShowing(false);
    setIsNewTemplateShowing(false);
  };

  const handlemodalClose = async () => {
    setLoading(true);
    setIsAddMatterShowing(false);
    setIsEditShowing(false);
    setIsAddShowing(false);
    setIsNewTemplateShowing(false);

    await Templates.matterTemplates()
      .then((response) => {
        setTemplates(response.data);
        setDisplayedData(response.data);
      })
      .then(() => {
        setRunSearch(!runSearch);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      _handleSearch();
    }
  };
  const orderByTitle = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.MatterTemplateTitle.localeCompare(b.MatterTemplateTitle)
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
    // setRunSearch(!runSearch);
  };
  const orderByType = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.MatterType?.MatterTypeName.localeCompare(b.MatterType?.MatterTypeName)
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByStage = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.MatterStage?.MatterStageName.localeCompare(b.MatterStage?.MatterStageName)
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByPriority = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.Priority?.PriorityName.localeCompare(b.Priority?.PriorityName)
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByClient = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.Client?.ClientName.localeCompare(b.Client?.ClientName)
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByResponsible = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.Manager?.FullName.localeCompare(b.Manager?.FullName)
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByStatus = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.MatterStatus?.MatterStatusName.localeCompare(b.MatterStatus?.MatterStatusName)
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByEffort = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.TotalEffort - b.TotalEffort
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByBilling = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.TotalBilling - b.TotalBilling
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const orderByCount = async () => {
    let templateData: any = [];
    let temp: any = []
    for (temp of displayedTemplates) {
      if (temp.Manager?.ProfileImageURL) {
        temp['imageUrl'] = await Storage.get(temp.Manager?.ProfileImageURL);
      }
      templateData.push(temp);
    }
    const myData: any = templateData;
    myData.sort((a, b) => {
      return a.MatterTemplateTaskTemplate?.length.toString().localeCompare(b.MatterTemplateTaskTemplate?.length.toString())
    });
    if (myData[1] === displayedTemplates[1]) {
      const reverseData = myData.reverse()
      setDisplayedTemplates(reverseData);
    } else {
      setDisplayedTemplates(myData);
    }
  };
  const handelIsEditShowing = (e, id) => {
    seteditId(id);
    setIsEditShowing(true);
  };
  const handelIsAddMatterShowing = (e, id) => {
    setMatterTemplateToPass(id);
    setIsAddMatterShowing(true);
  };
  const handelIsNewTemplateShowing = (e, id) => {
    setTaskTemplateId(id);
    setIsNewTemplateShowing(true);
  };
  const handleRefresh = async () => {
    setLoading(true);
    await Templates.matterTemplates()
      .then((response) => {
        setTemplates(response.data);
        setRunSearch(!runSearch);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      {loading && (
        <div className="dash-page-spinner-container">
          <Col>
            <Spinner
              className="mr-1 dash-page-spinner"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </Col>
        </div>
      )}
      <div className="dash-grid-body">
        <Row className="mb-4">
          <Col md={12} className="d-flex justify-content-center">
            <div className="float-left position-absolute dash-left-3 dash-top-3">
              <ButtonGroup>
                <Button
                  className="btn btn-xs btn-info dash-back-button mb-0 "
                  onClick={() => props.history.goBack()}
                >
                  Back
                </Button>
              </ButtonGroup>
            </div>
            <div className="dash-page-title">
              <h3 className="mb-0 text-center">Matter Templates</h3>
              <div className="dash-line-icon"></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accordion>
              <Card className="dash-card">
                <Card.Header className="dash-card-header">
                  <span className="dash-card-title">Search Criteria</span>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="float-right"
                  >
                    <FontAwesomeIcon
                      className="dash-card-title"
                      icon={faFilter}
                    />
                  </Accordion.Toggle>

                </Card.Header>
                <Card.Body className="dash-card-body">
                  <Accordion.Collapse eventKey="0">
                    <form ref={searchForm} onSubmit={submitForm}>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Title</div>
                        </Col>
                        <Col md={4}>
                          <input
                            type="text"
                            className="form-control dash-form-control"
                            id="Title"
                            name="Title"
                            value={templateFilter.Title}
                            onChange={changeHandler}
                            onKeyDown={handleKeyDown}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="dash-form-label">Type</div>
                        </Col>
                        <Col md={4}>
                          <select
                            name="typeId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={templateFilter.typeId}
                            onChange={(evt) =>
                              setTemplateFilter({
                                ...templateFilter,
                                typeId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {types.map((x: any, i) => (
                              <option key={'x' + i} value={i + 1}>
                                {x.MatterTypeName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Stage</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="stageId"
                            name="stageId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={templateFilter.stageId}
                            onChange={(evt) =>
                              setTemplateFilter({
                                ...templateFilter,
                                stageId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {stage.map((x: any, i) => (
                              <option key={'x' + i} value={i + 1}>
                                {x.MatterStageName}
                              </option>
                            ))}
                          </select>
                        </Col>

                        <Col md={2}>
                          <div className="dash-form-label">Priority</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="priorityId"
                            name="priorityId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={templateFilter.priorityId}
                            onChange={(evt) =>
                              setTemplateFilter({
                                ...templateFilter,
                                priorityId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {priorities.map((x: any, i) => (
                              <option key={x.PriorityId} value={x.PriorityId}>
                                {x.PriorityName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Status</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="statusId"
                            name="statusId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={templateFilter.statusId}
                            onChange={(evt) =>
                              setTemplateFilter({
                                ...templateFilter,
                                statusId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {status.map((x: any, i) => (
                              <option
                                key={x.MatterStatusId}
                                value={x.MatterStatusId}
                              >
                                {x.MatterStatusName}
                              </option>
                            ))}
                          </select>
                        </Col>

                        <Col md={2}>
                          <div className="dash-form-label">Responsible</div>
                        </Col>
                        <Col md={4}>
                          <select
                            name="responsibleId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={templateFilter.responsibleId}
                            onChange={(evt) =>
                              setTemplateFilter({
                                ...templateFilter,
                                responsibleId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {users.map((x: any, i) => (
                              <option key={x.UserId} value={x.UserId}>
                                {x.FullName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={2}>
                          <div className="dash-form-label">Client</div>
                        </Col>
                        <Col md={4}>
                          <select
                            id="clientId"
                            name="clientId"
                            className="form-control dash-form-control"
                            placeholder="All"
                            value={templateFilter.clientId}
                            onChange={(evt) =>
                              setTemplateFilter({
                                ...templateFilter,
                                clientId: evt.target.value,
                              })
                            }
                            onKeyDown={handleKeyDown}
                          >
                            <option value="">All</option>
                            {clients.map((x: any, i) => (
                              <option key={x.ClientId} value={x.ClientId}>
                                {x.ClientName}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </form>
                  </Accordion.Collapse>
                  <div className="clearfix">
                    <button
                      type="button"
                      className="btn btn-reset btn-w-m mr-2"
                      onClick={() => setIsAddShowing(true)}
                    >
                      Add Matter Template
                    </button>
                    {isAddShowing && (
                      <AddMatter
                        ref={addModalRef}
                        isAddShowing={isAddShowing}
                        onModalCloseClick={() => handlemodalClose()}
                        isTemplate="false"
                        handleModalCancel={handleModalCancel}
                      />
                    )}
                    <Workbook
                      filename="matter Template.xlsx"
                      element={
                        <button className="btn dash-export-button px-4 box-shadow-focus-none outline-focus-unset">
                          Export Data
                        </button>
                      }
                    >
                      <Workbook.Sheet
                        data={displayedTemplates}
                        name="Templates"
                      >
                        <Workbook.Column
                          label="Title"
                          value="MatterTemplateTitle"
                        />
                        <Workbook.Column
                          label="Type"
                          value={(col) =>
                            col.MatterType?.MatterTypeName
                              ? col.MatterType?.MatterTypeName
                              : ''
                          }
                        />
                        <Workbook.Column
                          label="Stage"
                          value={(col) =>
                            col.MatterStage?.MatterStageName
                              ? col.MatterStage?.MatterStageName
                              : ''
                          }
                        />
                        <Workbook.Column
                          label="Priority"
                          value={(col) =>
                            col.Priority?.PriorityName
                              ? col.Priority?.PriorityName
                              : ''
                          }
                        />
                        <Workbook.Column
                          label="Responsible"
                          value={(col) =>
                            col.Manager?.FullName ? col.Manager?.FullName : ''
                          }
                        />
                        <Workbook.Column
                          label="Client"
                          value={(col) =>
                            col.Client?.ClientName ? col.Client?.ClientName : ''
                          }
                        />
                        <Workbook.Column
                          label="Status"
                          value={(col) =>
                            col.MatterStatus?.MatterStatusName
                              ? col.MatterStatus?.MatterStatusName
                              : ''
                          }
                        />
                        <Workbook.Column
                          label="Total Effort(Est.)"
                          value="TotalEffort"
                        />
                        <Workbook.Column
                          label="Total Billing"
                          value="TotalBilling"
                        />
                        <Workbook.Column
                          label="Tasks Count"
                          value={(col) =>
                            col.MatterTemplateTaskTemplate?.length
                              ? col.MatterTemplateTaskTemplate?.length
                              : ''
                          }
                        />
                      </Workbook.Sheet>
                    </Workbook>

                    <ButtonGroup className="float-right">
                      <Button
                        type="reset"
                        //variant="outline-secondary"
                        className="dash-secondary-button mr-2"
                        // size="sm"
                        onClick={() => _handleReset()}
                      >
                        Reset
                      </Button>
                      <Button
                        size="sm"
                        type="submit"
                        className="dash-primary-button"
                        onClick={_handleSearch}
                      >
                        Search
                      </Button>
                    </ButtonGroup>
                  </div>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <table className="table table-striped table-center mt-1">
          <thead>
            <tr>
              <th>
                <Tooltip
                  classes={{
                    tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                    arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                  }}
                  arrow
                  placement='top'
                  title='Refresh Data'
                >
                  <button
                    className='dash-show-message-button dash-refresh-button'
                    onClick={handleRefresh}
                  >
                    <FontAwesomeIcon
                      className='dash-card-title'
                      icon={faSync}
                    />
                  </button>
                </Tooltip></th>
              <th></th>
              <th className='th-click' onClick={() => orderByTitle()}>Title
                <Tooltip
                  classes={{
                    tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                    arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                  }}
                  arrow
                  placement='top'
                  title='Click on the header to sort the data'
                >
                  <i className='fas fa-info-circle ml-2'></i>
                </Tooltip></th>
              <th className='th-click' onClick={() => orderByType()}>Type</th>
              <th className='th-click' onClick={() => orderByStage()}>Stage</th>
              <th className='th-click' onClick={() => orderByPriority()}>Priority</th>
              <th className='th-click' onClick={() => orderByResponsible()}>Responsible</th>
              <th className='th-click' onClick={() => orderByClient()}>Client</th>
              <th className='th-click' onClick={() => orderByStatus()}>Status</th>
              <th className='th-click' onClick={() => orderByEffort()}>Total Effort(Est.)</th>
              <th className='th-click' onClick={() => orderByBilling()}>Total Billing</th>
              <th className='th-click' onClick={() => orderByCount()}>Tasks Count</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {displayedTemplates &&
              displayedTemplates.map((temp: any, index) => {
                return (
                  <Tooltip key={index}
                    classes={{
                      tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                      arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                    }}
                    arrow
                    placement="top"
                    title={'Created Date ' + moment(temp.createdAt).format('YYYY-MM-DD HH:mm:ss') + ' --    Updated Date ' + moment(temp.updatedAt).format('YYYY-MM-DD HH:mm:ss')}

                  >
                    <tr key={index}>
                      <td>
                        <Tooltip
                          classes={{
                            tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                            arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="Edit"
                        >
                          <button
                            className="dash-show-message-button cursor-pointer"
                            // onClick={() => history.push(`/EditMatter/${c.MatterId}`)}
                            onClick={(event) =>
                              handelIsEditShowing(event, temp.TemplateMatterId)
                            }
                          >
                            <i className="far fa-edit"></i>
                          </button>
                        </Tooltip>
                        {isEditShowing && editId === temp.TemplateMatterId && (
                          <AddMatter
                            ref={addModalRef}
                            isAddShowing={isEditShowing}
                            matterId={editId}
                            onModalCloseClick={() => handlemodalClose()}
                            handleModalCancel={handleModalCancel}
                            isTemplate="true"
                          />
                        )}
                      </td>
                      <td>
                        <Tooltip
                          classes={{
                            tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                            arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="Create a new Matter based on this template"
                        >
                          <button
                            className="dash-show-message-button cursor-pointer"
                            onClick={(event) =>
                              handelIsAddMatterShowing(
                                event,
                                temp.TemplateMatterId,
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="text-white"
                            />
                          </button>
                        </Tooltip>
                        {isAddMatterShowing && (
                          <AddMatter
                            ref={addModalRef}
                            isAddShowing={isAddMatterShowing}
                            onModalCloseClick={() => handlemodalClose()}
                            matterTemplateToReceive={matterTemplateToPass}
                            handleModalCancel={handleModalCancel}
                          />
                        )}
                      </td>

                      <td className='dash-word-break-all'>{temp.MatterTemplateTitle}</td>
                      <td>{temp.MatterType?.MatterTypeName}</td>
                      <td>{temp.MatterStage?.MatterStageName}</td>
                      <td>{temp.Priority?.PriorityName}</td>
                      <td>
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          overlay={
                            <Popover
                              id="user-Data"
                              className="dash-user-card-popover"
                            >
                              <UserCard userId={temp.Manager?.UserId} />
                            </Popover>
                          }
                        >
                          <div className="d-flex align-items-center cursor-pointer">
                            <Avatar
                              className={classNames(
                                'dash-grid-avatar',
                                temp.Manager?.UserId === currentUserID
                                  ? 'd-none'
                                  : '',
                              )}
                              src={temp.imageUrl}
                              alt=""
                            />
                            <span className="align-middle">
                              {temp.Manager?.FullName}
                            </span>
                          </div>
                        </OverlayTrigger>
                      </td>
                      <td>{temp.Client?.ClientName}</td>
                      <td>{temp.MatterStatus?.MatterStatusName}</td>
                      <td>{temp.TotalEffort}</td>
                      <td>{temp.TotalBilling}</td>
                      <td>{temp.MatterTemplateTaskTemplate?.length}</td>

                      <td>
                        <Tooltip
                          classes={{
                            tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                            arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="Task Templates"
                        >
                          <div
                            className={
                              temp.MatterTemplateTaskTemplate?.length === 0
                                ? 'dash-disabled-wrapper'
                                : ''
                            }
                          >
                            <Link
                              className={classNames(
                                'btn dash-show-message-button border-0',
                                temp.MatterTemplateTaskTemplate?.length === 0
                                  ? 'dash-disabled'
                                  : '',
                              )}
                              to={{
                                pathname: '/settings/taskTemplate',
                                state: {
                                  matterTemplateId: temp.TemplateMatterId,
                                },
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTasks}
                                size="2x"
                                className="text-white  cursor-pointer"
                              />
                            </Link>
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          classes={{
                            tooltip: 'dash-tooltip', // class name, e.g. `classes-nesting-root-x`
                            arrow: 'dash-tooltip-arrow', // class name, e.g. `classes-nesting-label-x`
                          }}
                          arrow
                          placement="top"
                          title="New Task Template"
                        >
                          <div>
                            <button
                              className="dash-show-message-button cursor-pointer"
                              onClick={(event) =>
                                handelIsNewTemplateShowing(
                                  event,
                                  temp.TemplateMatterId,
                                )
                              }
                            >
                              <span className="dash-icons-new-task dash-font-size-22"></span>
                            </button>
                            {isNewTemplateShowing && (
                              <CreateTaskAdmin
                                ref={addModalRef}
                                isAddShowing={isNewTemplateShowing}
                                matterTemplateP={newTaskTemplateId}
                                onModalCloseClick={() => handlemodalClose()}
                                isTemplate="true"
                              />
                            )}
                          </div>
                        </Tooltip>
                      </td>
                      {/* <td>
                    <button className="dash-delete-button">Delete</button>
                  </td> */}
                    </tr>
                  </Tooltip>);
              })}
          </tbody>
        </table>
        {noResults && (
          <h3 className="margin-separator-top-search text-center text-white">
            No results found
          </h3>
        )}
      </div>
    </div>
  );
}
