import React from 'react';
import { useLocation } from 'react-router-dom';
import './session.css';
import TwilioVideo from './twilioVideo';

const logo = require('../../../assets/img/twilio-logo-red.png');

const TwilioApp = ({ role }: any) => {
  const location: any = useLocation();
  const username: string = role;
  console.log(role);

  return (
    <div className="app">
      <header></header>
      <img src={logo} className="logo-img-navbar-twilio" alt="" />
      <main>
        <TwilioVideo {...location.state} username={username} />
      </main>
    </div>
  );
};

export default TwilioApp;
