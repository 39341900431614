import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Container, Button, Col } from 'react-bootstrap';
import { Auth, Storage } from 'aws-amplify';
import { Users } from 'services/Api';

import useForm from '../../shared/useForm';

const ChangePassword = (props: any) => {
  const history: any = useHistory();
  const isCodeDisplay = props.location.state && props.location.state.identity;

  const stateSchema = {
    code: { value: '', error: '' },
    password: { value: '', error: '' },
    confirm_password: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    code: {
      required: isCodeDisplay,
    },
    password: {
      required: true,
    },
    confirm_password: {
      required: true,
      validator: {
        func: (value: string) => {
          return value === values.password;
        },
        error: 'Confirm password should match with the password.',
      },
    },
  };

  const onSubmitForm = (state: object) => {
    alert(JSON.stringify(state, null, 2));
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { code, password, confirm_password } = values;

  const _handleChangePassword = async () => {
    try {
      if (props.location.state && props.location.state.challengeName) {
        const user: any = props.location.state.user;
        const loggedUser = await Auth.signIn(user.identity, user.password);
        await Auth.completeNewPassword(loggedUser, password, []);
        const currentUser = await Auth.currentUserInfo();

        const userObj: any = await Users.singleByUsername(
          currentUser.attributes.sub
        )
          .then(async (response) => {
            return response.data;
          })
          .catch((error) => {
            return error.message;
          });

        if (userObj.ProfileImageURL) {
          const fullSrc = await Storage.get(userObj.ProfileImageURL);
          userObj.ProfileImageURL = fullSrc;
        }
        const currentUserRole = userObj.Role;
        localStorage.setItem('user', JSON.stringify(currentUserRole));
        localStorage.setItem('userData', JSON.stringify(userObj));
        if (
          currentUserRole.RoleName === 'System Admin' ||
          currentUserRole.RoleName === 'State Admin' ||
          currentUserRole.RoleName === 'County Admin' ||
          currentUserRole.RoleName === 'Court Admin' ||
          currentUserRole.RoleName === 'Department Admin' ||
          currentUserRole.RoleName === 'Outside Law Firm Admin' ||
          currentUserRole.RoleName === 'Law Firm Admin'
        ) {
          if (userObj.DefaultPage === 'Firms') {
            const location = {
              pathname: '/firms',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Matters') {
            const location = {
              pathname: '/matters/mattersManagement',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Users') {
            const location = {
              pathname: '/users',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Tasks') {
            const location = {
              pathname: '/tasks',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Performance') {
            const location = {
              pathname: '/dashboard',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Tasks Calendar') {
            const location = {
              pathname: '/tasksCalendar',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Tasks Board') {
            const location = {
              pathname: '/tasksBoard',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Clients') {
            const location = {
              pathname: '/clients/clientsManagement',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Task Template') {
            const location = {
              pathname: '/settings/taskTemplate',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Templates') {
            const location = {
              pathname: '/matterTemplates',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Message Center') {
            const location = {
              pathname: '/messageCenter',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Meetings') {
            const location = {
              pathname: '/session',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'DocumentCenter') {
            const location = {
              pathname: '/documentCenter',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else {
            const location = {
              pathname: '/matters/mattersManagement',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          }
          
          if (userObj.DefaultPage === 'Meetings') {
            const location = {
              pathname: '/session',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Firms') {
            const location = {
              pathname: '/firms',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Clients') {
            const location = {
              pathname: '/clients/clientsManagement',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Templates') {
            const location = {
              pathname: '/matterTemplates',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Matters') {
            const location = {
              pathname: '/matters/mattersManagement',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Tasks') {
            const location = {
              pathname: '/tasks',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Performance') {
            const location = {
              pathname: '/Dashboard',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Tasks Calendar') {
            const location = {
              pathname: '/tasksCalendar',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Tasks Board') {
            const location = {
              pathname: '/tasksBoard',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'Message Center') {
            const location = {
              pathname: '/messageCenter',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else if (userObj.DefaultPage === 'DocumentCenter') {
            const location = {
              pathname: '/documentCenter',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          } else {
            const location = {
              pathname: '/matters/mattersManagement',
              state: {
                role: userObj.FullName,
              },
            };
            history.push(location);
          }
         
        } else {
          const location = {
            pathname: '/login',
            state: {
              role: userObj.FullName,
            },
          };

          history.push(location);
          history.replace(location);
          history.go('/login');
        }
      } else {
        const identity: any = props.location.state.identity;
        await Auth.forgotPasswordSubmit(identity, code, password);
        const location = {
          pathname: '/login',
        };
        history.push(location);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <form onSubmit={ handleOnSubmit } className="totalSize">
      <Container fluid className="mb-0 animated fadeInLeft ">
        <Row className={ `justify-content-center h-100 dash-register-container dash-change-password-container` }>
          <Col md={ { span: 5, offset: 4 } } className="my-5 py-3">
            <div className="dash-register-content">
              <div className="dash-register-title pt-2">Change Password</div>
              <div className="card-body">
                { isCodeDisplay && (
                  <Row className="form-group">
                    <label
                      htmlFor="password"
                      className="col-sm-4 dash-form-label "
                    >
                      Verification code
                    </label>
                    <Col className="col-sm-8">
                      <input
                        type="text"
                        className="form-control dash-form-control "
                        id="code"
                        name="code"
                        value={ code }
                        onChange={ handleOnChange }
                      />
                      { errors.code && dirty.code && (
                        <p className="error">{ errors.code }</p>
                      ) }
                    </Col>
                  </Row>
                ) }
                <Row className="form-group">
                  <label
                    htmlFor="password"
                    className="col-sm-4 dash-form-label "
                  >
                    Password
                  </label>
                  <Col className="col-sm-8">
                    <input
                      type="password"
                      className="form-control dash-form-control "
                      id="password"
                      name="password"
                      value={ password }
                      onChange={ handleOnChange }
                    />
                    { errors.password && dirty.password && (
                      <p className="error">{ errors.password }</p>
                    ) }
                  </Col>
                </Row>
                <Row className="form-group">
                  <label
                    htmlFor="cPassword"
                    className="col-sm-4 dash-form-label "
                  >
                    Confirm password
                  </label>
                  <Col className="col-sm-8 ">
                    <input
                      type="password"
                      className="form-control dash-form-control"
                      id="confirm_password"
                      name="confirm_password"
                      value={ confirm_password }
                      onChange={ handleOnChange }
                    />
                    { errors.confirm_password && dirty.confirm_password && (
                      <p className="error">{ errors.confirm_password }</p>
                    ) }
                  </Col>
                </Row>
                <div className="text-right">
                  <Button
                    type="button"
                    variant="outline-light"
                    className="dash-primary-button mt-4 mr-2"
                    disabled={ disable }
                    onClick={ _handleChangePassword }
                  >
                    Change Password
                  </Button>
                </div>
              </div>{ ' ' }
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ChangePassword;
