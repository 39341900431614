import { actionTypes } from "../constants/actionTypes";

export function setClient(client) {
  return async (dispatch) => {
    dispatch(actionReducer(client, actionTypes.GET_CLIENT_SUCCESS));
  };
}
const actionReducer = (payload, type) => ({
  type,
  payload,
});
