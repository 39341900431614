import { actionTypes } from '../constants/actionTypes';
import { Storage } from 'aws-amplify';
import { MessageCenter } from 'services/Api';

export function getChannels(user) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_CHANNELS_START));
    try {
      const { data: channels } = await MessageCenter.channels(user.UserId);

      let channelsData: any = [];
      const setImage = async () => {
        for (let channel of channels) {
          if (
            channel.Messages.length > 0 &&
            channel.Messages[channel.Messages.length - 1].messageSender
              .ProfileImageURL
          ) {
            channel['imageUrl'] = await Storage.get(
              channel.Messages[channel.Messages.length - 1].messageSender
                .ProfileImageURL,
            );
          }
          channelsData.push(channel);
        }
        return channelsData;
      };
      await setImage();
      dispatch(actionReducer(channelsData, actionTypes.GET_CHANNELS_SUCCESS));
    } catch (error) {
      dispatch(actionReducer(error, actionTypes.GET_CHANNELS_FAIL));
    }
  };
}

export function getChannelsMetadata() {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_CHANNELS_METADATA_START));
    try {
      const { data: channels } = await MessageCenter.channelByUser();
      dispatch(
        actionReducer(channels, actionTypes.GET_CHANNELS_METADATA_SUCCESS),
      );
    } catch (error) {
      dispatch(actionReducer(error, actionTypes.GET_CHANNELS_METADATA_FAIL));
    }
  };
}

export function SetCurrentChannel(channel) {
  return async (dispatch) => {
    dispatch(actionReducer(channel, actionTypes.SET_CURRENT_CHANNEL));
  };
}

export function addNewItemToChannel(channel) {
  return async (dispatch) => {
    dispatch(actionReducer(channel, actionTypes.ADD_NEW_CHANNEL));
  };
}

export function setPendingMessages(counter) {
  return async (dispatch) => {
    dispatch(actionReducer(counter, actionTypes.SET_PENDING_MESSAGES));
  };
}

export function sendMessage(messageObj, client) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.SEND_MESSAGE_START));
    try {
      const { data: message } = await MessageCenter.create(messageObj);

      dispatch(actionReducer(message, actionTypes.SEND_MESSAGE_SUCCESS));
      client.emit('msgToServer', message);
    } catch (error) {
      dispatch(actionReducer(error, actionTypes.SEND_MESSAGE_FAIL));
    }
  };
}

export function UpdateChannels(messageObj) {
  return async (dispatch) => {
    if (messageObj.Channel.ChannelType !== 'GROUP') {
      const channelType = messageObj.Channel.ChannelType;
      const id =
        messageObj.Channel.ChannelType === 'MATTER'
          ? messageObj.Channel.Matter.MatterId
          : messageObj.Channel.Task.TaskId;
      const {data: isAvailable} = await MessageCenter.isAvailableChannel(
        channelType,
        id,
      );
      if (isAvailable) {
        dispatch(actionReducer(messageObj, actionTypes.UPDATE_CHANNELS));
      }
    } else {
      const group: any[] = messageObj.Channel.Group.GroupUser;
      const userStored = localStorage.getItem('userData');
      if (userStored) {
        const user = JSON.parse(userStored);
        const exist = group.find((u) => u.UserId === user.UserId);
        if (exist) {
          dispatch(actionReducer(messageObj, actionTypes.UPDATE_CHANNELS));
        }
      }
    }
  };
}

export function setMetadataChannels(metadata) {
  return async (dispatch) => {
    dispatch(actionReducer(metadata, actionTypes.SET_METADATA_CHANNELS));
  };
}

export function saveMetadataChannels(metadataItem) {
  return async (dispatch) => {
    const { data: metadata } = await MessageCenter.createUserMetaData(
      metadataItem,
    );

    dispatch(actionReducer(metadata, actionTypes.UPDATE_METADATA_ITEM));
  };
}

export function setNewMessageNotification(newMessage) {
  return async (dispatch) => {
    dispatch(
      actionReducer(newMessage, actionTypes.SET_NEW_MESSAGE_NOTIFICATION),
    );
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
