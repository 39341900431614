import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import colors from '../../assets/scss/colors.js';
import { Tasks } from 'services/Api';
require('highcharts/modules/exporting')(Highcharts);

interface interfaceState {
  openTasks: any;
  blockedTasks: any;
  inProgressTasks: any;
  doneTasks: any;
}

class PieChart extends React.Component<{ history }, interfaceState> {
  constructor(props) {
    super(props);

    this.state = {
      openTasks: 0,
      blockedTasks: 0,
      inProgressTasks: 0,
      doneTasks: 0,
    };
  }

  async componentDidMount() {
    let openCounter = 0;
    let blockedCounter = 0;
    let inProgressCounter = 0;
    let doneCounter = 0;
    const { data: tasksObj } = await Tasks.index();
    const listTasks = tasksObj.map(task => {
      return {
        ...task,
        BillRate: task.BillRate ? parseFloat(task.BillRate) : 0.00,
        Cost: task.Cost ? parseFloat(task.Cost) : 0.00,
        EffortAct: task.EffortAct ? parseFloat(task.EffortAct) : 0.00,
        EffortEst: task.EffortEst ? parseFloat(task.EffortEst) : 0.00,
      }
    });
    listTasks.forEach((item) => {
      console.log("item", item)
      if (item.TaskStatus && item.TaskStatus.TaskStatusName === 'Open') {
        openCounter = openCounter + 1;
      } else if (item.TaskStatus && item.TaskStatus.TaskStatusName === 'Blocked') {
        blockedCounter = blockedCounter + 1;
      } else if (item.TaskStatus && item.TaskStatus.TaskStatusName === 'In Progress') {
        inProgressCounter = inProgressCounter + 1;
      } else if (item.TaskStatus && item.TaskStatus.TaskStatusName === 'Done') {
        doneCounter = doneCounter + 1;
      }
      return {
        item,
      };
    });
    this.setState({ blockedTasks: blockedCounter });
    this.setState({ inProgressTasks: inProgressCounter });
    this.setState({ openTasks: openCounter });
    this.setState({ doneTasks: doneCounter });
  }

  handleClickEvent = (e) => {
    if (e.point.name.toLowerCase() === 'blocked') {
      this.props.history.push({
        pathname: '/tasks',
        state: { filteredStatus: 3 },
      });
    } else if (e.point.name.toLowerCase() === 'in progress') {
      this.props.history.push({
        pathname: '/tasks',
        state: { filteredStatus: 2 },
      });
    } else if (e.point.name.toLowerCase() === 'open') {
      this.props.history.push({
        pathname: '/tasks',
        state: { filteredStatus: 1 },
      });
    } else if (e.point.name.toLowerCase() === 'done') {
      this.props.history.push({
        pathname: '/tasks',
        state: { filteredStatus: 4 },
      });
    }
  };

  render() {
    const config = {
      chart: { type: 'pie' },
      title: { text: ['Task Count'] },
      tooltip: {
        enabled: ['showTooltip'] ? ['showTooltip'] : false,
      },
      plotOptions: {
        pie: {
          depth: ['depth'],
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: ['showLabel'] ? ['showLabel'] : false,
            format: '<b>{point.name}</b>: {point.percentage:.2f} %',
          },
          showInLegend: true,
          point: {
            events: {
              click: (e) => {
                this.handleClickEvent(e);
              },
            },
          },
        },
      },

      legend: {
        enabled: ['showLegend'] ? ['showLegend'] : false,
      },
      series: [
        {
          name: 'Count',
          point: {},
          data: [
            {
              name: 'Open',
              y: this.state.openTasks,
              sliced: true,
              selected: true,
              color: colors.dash_status_blue,
            },
            {
              name: 'In Progress',
              y: this.state.inProgressTasks,
              color: colors.dash_status_green,
            },
            {
              name: 'Blocked',
              y: this.state.blockedTasks,
              color: colors.dash_status_pink,
            },
            {
              name: 'Done',
              y: this.state.doneTasks,
              color: colors.dash_status_gray,
            },
          ],
          dataLabels: {
            color: colors.dash_black,
            font: '14px Segoe UI, Verdana, sans-serif',
            boxShadow: 'none',
            stroke: 'none',
          },
        },
      ],
      navigation: {
        buttonOptions: {
          height: 30,
          width: 30,
          symbolX: 16,
          symbolY: 15,
          theme: {
            fill: '#E3E3E8',
          },
        },
      },
    };

    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={config}
          containerProps={{ style: { height: '400px' } }}
        />
        {/* <h3>Hovering over {hoverData}</h3> */}
      </div>
    );
  }
}

export default PieChart;
