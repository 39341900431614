import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../../../assets/sharedDesign/sharedDesign.scss';
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import validator from 'validator';
import CancelConfirmationModal from '../../CancelConfirmationModal/cancelConfirmationModal';
import { States, Firms, Clients } from 'services/Api';
import InputMask from 'react-input-mask';

import { API } from 'aws-amplify';

// call function to send email template
// API.post('dashAPI', 'sendTemplate', {
//   headers: {},
//   body: {
//     templateObj: {
//       sendToMail: 'ebrahim0hamdy@gmail.com',
//       sendToName: 'Ebrahim aboelndr',
//       sendBy: 'Adel',
//     },
//   },
// });

interface ClientState {
  RegisTax: string;
  RegisNo: any;
  FirmId: any;
  ClientName: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  StateId: any;
  ContactName: string;
  ContactEmail: string;
  ContactMobile: string;
  ClientLocation: string;
  FirmLocation: string;
  ContactFax: string;
  ClientId: null;
  startDate: any;
  JobTitle: '';
  ZipCode: any;
  ContactTitle: '';
  Firms: [];
  States: [];
  show: boolean;
  international: boolean;
  loading: boolean;
  showErrors: boolean;
  loadingData: boolean;
  formTouched: boolean;
  showCancelConfirm: boolean;
}
class NewClient extends React.Component<
  { isAddShowing: any; onModalCloseClick(): any; clientId?: any },
  ClientState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      RegisTax: '',
      RegisNo: 0,
      ClientName: '',
      FirmId: '',
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      StateId: null,
      ContactName: '',
      ContactEmail: '',
      ContactMobile: '',
      ZipCode: '',
      ContactFax: '',
      ClientId: null,
      startDate: new Date(),
      JobTitle: '',
      ClientLocation: '',
      FirmLocation: '',
      ContactTitle: '',
      Firms: [],
      States: [],
      show: this.props.isAddShowing,
      loading: false,
      showErrors: false,
      loadingData: false,
      international: false,
      formTouched: false,
      showCancelConfirm: false,
    };
  }

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    this.setState({ formTouched: true });
  };
  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));
    this.setState({ formTouched: true });
  };
  changeCKHandler = (e: any, name: any) => {
    this.setState({ international: !this.state.international });
  };
  componentDidMount() {
    if (this.props.clientId) {
      this.setState({ loadingData: true });
    }

    States.index()
      .then((response) => {
        console.log(response.data);
        this.setState({ States: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    Firms.index()
      .then((response) => {
        console.log(response.data);
        this.setState({ Firms: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    const clientId = this.props.clientId;
    if (clientId) {
      this.setState({ loadingData: true });

      Clients.single(clientId)
        .then((response) => {
          this.setState({
            ClientId: response.data.ClientId,
            ClientName: response.data.ClientName,
            AddressLine1: response.data.AddressLine1,
            AddressLine2: response.data.AddressLine2,
            ContactName: response.data.ContactName,
            ContactMobile: response.data.ContactMobile,
            ContactFax: response.data.ContactFax,
            ContactEmail: response.data.ContactEmail,
            City: response.data.City,
            FirmId: response.data.FirmId,
            StateId: response.data.State?.StateId,
            ContactTitle: response.data.ContactTitle,
            JobTitle: response.data.ContactJobTitle,
            RegisNo: response.data.RegistrationNo,
            ClientLocation: response.data.ClientLocation,
            FirmLocation: response.data.FirmLocation,
            ZipCode: response.data.ZipCode,
            startDate: response.data.RegistrationDate
              ? new Date(response.data.RegistrationDate)
              : null,
            RegisTax: response.data.RegistrationTaxNumber,
            loadingData: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  sendEmail = async () => {
    await API.post('dashAPI', '/sendTemplate', {
      headers: {},
      body: {
        templateObj: {
          sendToMail: this.state.ContactEmail,
          sendToName: this.state.ContactName,
          sendBy: 'Adel',
        },
      },
    }).then((response) => {
      console.log(response);
    });
  };
  submitHandler = (e: any) => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (
      this.state.ClientName !== '' &&
      this.state.AddressLine1 !== '' &&
      this.state.AddressLine2 !== '' &&
      this.state.City !== '' &&
      this.state.ContactName !== '' &&
      this.state.ContactEmail !== '' &&
      this.state.ContactMobile !== ''
    ) {
      this.setState({ loading: true });

      const newClient = {
        FirmId: this.state.FirmId?.toString() === '' ? null : this.state.FirmId,
        ClientName: this.state.ClientName,
        AddressLine1: this.state.AddressLine1,
        AddressLine2: this.state.AddressLine2,
        City: this.state.City,
        State: {
          StateId: this.state.StateId === '0' ? null : this.state.StateId,
        },
        ContactName: this.state.ContactName,
        ClientLocation: this.state.ClientLocation,
        FirmLocation: this.state.FirmLocation,
        ZipCode: this.state.ZipCode,
        ContactEmail: this.state.ContactEmail,
        ContactMobile: this.state.ContactMobile,
        ContactFax: this.state.ContactFax,
        ClientId: this.state.ClientId,
        ContactTitle: this.state.ContactTitle ? this.state.ContactTitle : null,
        ContactJobTitle: this.state.JobTitle ? this.state.JobTitle : null,
        RegistrationNo: this.state.RegisNo ? this.state.RegisNo : null,
        RegistrationDate: this.state.startDate
          ? moment(new Date(this.state.startDate)).format('YYYY-MM-DD HH:mm:ss')
          : null,
        RegistrationTaxNumber: this.state.RegisTax ? this.state.RegisTax : null,
      };

      Clients.create(newClient)
        .then((response) => {
          this.setState({ loading: false });
          toast.success('Data saved successfully!');
          if (!this.props.clientId) {
            this.sendEmail();
          }
          this.handleClose();
        })
        .catch((error) => {
          toast.error('Data not saved');
          this.setState({ loading: false });
        });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.onModalCloseClick();
  };
  checkPhonePattern = (e: any) => {
    // const re = /[+0-9() ]+/g;
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          size='xl'
          backdrop='static'
          className='pt-5'
          scrollable={true}
        >
          <form onSubmit={this.submitHandler}>
            <div className='mb-0'>
              <Row>
                <Col md={12}>
                  <Card className='dash-card'>
                    <Modal.Header className='p-0'>
                      <Card.Header className='dash-card-header'>
                        <span className='dash-card-title'>
                          {this.props.clientId ? 'Edit' : 'Add'} Client
                        </span>

                        <ButtonGroup className='float-right'>
                          <Button
                            type='button'
                            variant='secondary'
                            size='sm'
                            onClick={() => {
                              this.state.formTouched
                                ? this.setState({ showCancelConfirm: true })
                                : this.handleClose();
                            }}
                            className='dash-secondary-button margin-separator'
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            className='dash-primary-button'
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <Spinner
                                className='mr-1 dash-button-spinner'
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}

                            {this.state.loading ? (
                              <span>Saving...</span>
                            ) : (
                              <span>Save</span>
                            )}
                          </Button>
                        </ButtonGroup>
                      </Card.Header>
                    </Modal.Header>
                    {this.state.loadingData && (
                      <div className='dash-page-spinner-container'>
                        <Col>
                          <Spinner
                            className='mr-1 dash-page-spinner'
                            as='span'
                            animation='border'
                            role='status'
                            aria-hidden='true'
                          />
                        </Col>
                      </div>
                    )}
                    <Modal.Body className='px-0 pt-0 pb-5 dash-card-body'>
                      <Card.Body>
                        <div className='dash-card-subsection mb-5'>
                          <h3 className='dash-card-subsection-title'>
                            Basic Info
                          </h3>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Client Name
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='ClientName'
                                name='ClientName'
                                value={this.state.ClientName || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.ClientName) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>Firm Name</div>
                            </Col>
                            <Col md={4}>
                              <select
                                className='form-control dash-form-control'
                                id='FirmId'
                                name='FirmId'
                                value={this.state.FirmId || ''}
                                onChange={this.changeHandler}
                              >
                                <option value='0'>None</option>
                                {this.state.Firms.map((x: any, i) => (
                                  <option key={'x' + i} value={x.FirmId}>
                                    {x.FirmName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Client Location
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='ClientLocation'
                                name='ClientLocation'
                                value={this.state.ClientLocation || ''}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Firm Location
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='FirmLocation'
                                name='FirmLocation'
                                value={this.state.FirmLocation || ''}
                                onChange={this.changeHandler}
                              />
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Address1
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='AdressLine1'
                                name='AddressLine1'
                                value={this.state.AddressLine1 || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.AddressLine1) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Address2
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='AdressLine2'
                                name='AddressLine2'
                                value={this.state.AddressLine2 || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.AddressLine2) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                City
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='City'
                                name='City'
                                value={this.state.City || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.City) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>State</div>
                            </Col>
                            <Col md={4}>
                              <select
                                className='form-control dash-form-control'
                                id='StateId'
                                name='StateId'
                                value={this.state.StateId || ''}
                                onChange={this.changeHandler}
                              >
                                <option value='0'>None</option>
                                {this.state.States.map((x: any, i) => (
                                  <option key={x.StateId} value={x.StateId}>
                                    {x.StateName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label margin-separator-top3'>
                                Zip Code
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control number-dash margin-separator-top3'
                                id='ZipCode'
                                name='ZipCode'
                                value={this.state.ZipCode || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.ZipCode) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                        </div>
                        <div className='dash-card-subsection mb-5'>
                          <h3 className='dash-card-subsection-title'>
                            Contact Info
                          </h3>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>Title</div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='ContactTitle'
                                name='ContactTitle'
                                value={this.state.ContactTitle || ''}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Name
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='ContactName'
                                name='ContactName'
                                value={this.state.ContactName || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.ContactName) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>Job Title</div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='JobTitle'
                                name='JobTitle'
                                value={this.state.JobTitle || ''}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Email
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='email'
                                className='form-control dash-form-control'
                                id='ContactEmail'
                                name='ContactEmail'
                                value={this.state.ContactEmail || ''}
                                onChange={this.changeHandler}
                              />
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.ContactEmail) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>
                                Mobile
                                <span className='dash-require-astrike'></span>
                              </div>
                            </Col>
                            <Col md={4}>
                              {!this.state.international === true && (
                                <InputMask
                                  mask='999 999-9999'
                                  type='text'
                                  className='form-control dash-form-control'
                                  id='ContactMobile'
                                  name='ContactMobile'
                                  placeholder='[area code]-xxx-xxxx'
                                  value={this.state.ContactMobile || ''}
                                  onChange={this.changeHandler}
                                  onKeyPress={this.checkPhonePattern}
                                />
                              )}
                              {this.state.international === true && (
                                <input
                                  type='text'
                                  className='form-control dash-form-control'
                                  id='ContactMobile'
                                  name='ContactMobile'
                                  placeholder='[country code]-[area code]-xxx-xxxx'
                                  value={this.state.ContactMobile || ''}
                                  onChange={this.changeHandler}
                                  onKeyPress={this.checkPhonePattern}
                                />
                              )}
                              {this.state.showErrors === true &&
                                validator.isEmpty(this.state.ContactMobile) && (
                                  <span className='dash-error-message'>
                                    Required
                                  </span>
                                )}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.international}
                                    onChange={this.changeCKHandler}
                                    name='international'
                                    color='secondary'
                                  />
                                }
                                label='International'
                              />
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>Fax</div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='text'
                                className='form-control dash-form-control'
                                id='ContactFax'
                                name='ContactFax'
                                value={this.state.ContactFax || ''}
                                onChange={this.changeHandler}
                                onKeyPress={this.checkPhonePattern}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className='dash-card-subsection mb-5'>
                          <h3 className='dash-card-subsection-title'>
                            Registration
                          </h3>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>Client No.</div>
                            </Col>
                            <Col md={4}>
                              <input
                                type='number'
                                className='form-control dash-form-control number-dash'
                                id='RegisNo'
                                name='RegisNo'
                                value={this.state.RegisNo || ''}
                                onChange={this.changeHandler}
                              />
                            </Col>
                            <Col md={2}>
                              <div className='dash-form-label'>Date</div>
                            </Col>
                            <Col md={4}>
                              <label className='dash-datepicker'>
                                <DatePicker
                                  name='startDate'
                                  className='form-control dash-form-control'
                                  selected={this.state.startDate || ''}
                                  onChange={(e) =>
                                    this.changeDateHandler(e, 'startDate')
                                  }
                                />
                              </label>
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}>
                              <div className='dash-form-label'>EIN.</div>
                            </Col>
                            <Col md={4}>
                              {/*     <input
                                type='text'
                                className='form-control dash-form-control'
                                id='RegisTax'
                                name='RegisTax'
                                value={this.state.RegisTax || ''}
                                onChange={this.changeHandler}
                              />*/}
                              <InputMask
                                mask='99-9999999'
                                className='form-control dash-form-control'
                                id='RegisTax'
                                name='RegisTax'
                                onChange={this.changeHandler}
                                value={this.state.RegisTax || ''}
                                alwaysShowMask={true}
                              />
                            </Col>
                          </Row>
                          <Row className='form-group'>
                            <Col md={2}></Col>
                            <Col className='dash-form-label text-left'>
                              Fields with{' '}
                              <span className='dash-require-astrike'></span> are
                              required fields.
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Modal.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </form>
        </Modal>
        {this.state.showCancelConfirm && (
          <CancelConfirmationModal
            isShow={this.state.showCancelConfirm}
            onModalYesClick={() => this.handleClose()}
            onModalNoClick={() => this.setState({ showCancelConfirm: false })}
          ></CancelConfirmationModal>
        )}
      </React.Fragment>
    );
  }
}

export default NewClient;
