import React from 'react';
import clsx from 'clsx';
import { Router } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Amplify from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';

import config from './aws-exports';
import history from './services/history';
import Routes from './routes';
import { UserProvider } from 'contexts/UserAuthContext';

Amplify.configure({
  ...config,
  Analytics: {
    disabled: false,
  },
});

const App = () => {
  return (
    <UserProvider>
      <div className={clsx('App', 'h-100')}>
        <CssBaseline />
        <Container fluid className='h-100'>
          <Row className='h-100'>
            <Col className='px-0 h-100'>
              <Router history={history}>
                <Routes />
              </Router>
            </Col>
          </Row>
        </Container>
      </div>
    </UserProvider>
  );
};

export default App;
