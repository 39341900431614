import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Avatar } from '@material-ui/core';

import { AllRoles, CustomAdminUser } from 'types/roles';
import MessageCenterComponent from 'shared/messageCenterComponent';
import GeneralTypeCounter from 'shared/generalTypeCounter';

function NavLinks() {
  const pathname = window.location.pathname;
  const userData: any = JSON.parse(localStorage.getItem('userData') || '{}');
  const userDataName = userData.FullName.split(' ', 2);

  const navLinks = [
    {
      item: '/firms',
      name: 'firms',
      roles: AllRoles,
      icon: 'dash-icons-firms',
    },
    {
      item: '/clients/clientsManagement',
      name: 'Clients',
      roles: CustomAdminUser,
      icon: 'far fa-handshake',
    },
    {
      item: '/users',
      name: 'Users',
      roles: AllRoles,
      icon: 'fas fa-user-friends',
    },
    {
      item: '/matters/mattersManagement',
      name: 'Matters',
      roles: AllRoles,
      icon: 'dash-icons-matter',
    },
    {
      item: '/tasks',
      name: 'Tasks',
      roles: AllRoles,
      icon: 'fas fa-thumbtack',
    },
    {
      item: '/matterTemplates',
      name: 'Matter Templates',
      icon: 'dash-icons-matter-template',
      roles: AllRoles,
    },
    {
      item: '/settings/taskTemplate',
      name: 'Tasks Templates',
      icon: 'far fa-file-alt',
      roles: AllRoles,
    },
    {
      item: '/dashboard',
      name: 'performance',
      icon: 'dash-icons-dashboard',
      roles: AllRoles,
    },
    {
      item: '/tasksCalendar',
      name: 'Tasks Calendar',
      icon: 'dash-icons-calendar',
      roles: AllRoles,
    },
    {
      item: '/tasksBoard',
      name: 'Tasks Board',
      icon: 'fas fa-tasks',
      roles: AllRoles,
    },
    {
      item: '/messageCenter',
      name: 'Message Center',
      icon: 'dash-icons-message',
      roles: AllRoles,
    },
    {
      item: '/session',
      name: 'meetings',
      icon: 'dash-icons-session',
      roles: AllRoles,
    },
    {
      item: '/documentCenter',
      name: 'Documents Center',
      icon: 'dash-icons-document-center',
      roles: AllRoles,
    },
    {
      item: '/profile',
      name: 'profile',
      roles: AllRoles,
    },
  ];

  return (
    <Nav key={ pathname } className='mx-auto'>
      {navLinks.map((navLink: any, index) =>
        navLink.name === 'profile' ? (
          <NavLink
            key={ index }
            className='dash-header-menu-item text-center pt-1 pb-0'
            to='/profile'
          >
            <Avatar
              src={ userData.ProfileImageURL }
              className='mx-auto dash-header-user-img'
              alt=''
            />
            <span className='dash-header-menu-text'>{ userDataName[0] }</span>
          </NavLink>
        ) : (
          navLink.roles?.includes(userData.Role.RoleName) && (
            <NavLink
              key={ index }
              className='dash-header-menu-item text-center pt-1 pb-0'
              to={ navLink.item }
            >
              <div className={ `dash-header-menu-icon ${navLink.icon}` } />
              {navLink.badge && (
                <span className='badge badge-pill dash-icon-badge'>
                  {navLink.badge }
                </span>
              ) }
              {navLink.name === 'Message Center' && <MessageCenterComponent /> }
              {navLink.name === 'Matters' && (
                <GeneralTypeCounter
                  search={ {
                    type: 'MATTER',
                  } }
                />
              ) }
              {navLink.name === 'Tasks' && (
                <GeneralTypeCounter
                  search={ {
                    type: 'TASK',
                  } }
                />
              ) }
              <span className='dash-header-menu-text'>{ navLink.name }</span>
            </NavLink>
          )
        )
      ) }
    </Nav>
  );
}

export default NavLinks;
