import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Container, Button, Col } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import useForm from '../../shared/useForm';

const ForgotPassword = (props: any) => {
  const history: any = useHistory();

  const stateSchema = {
    identity: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    identity: {
      required: true,
      validator: {
        func: (value: string) =>
          /* eslint-disable-next-line */
          /^([A-Za-z0-9._%\+\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/.test(
            value.trim()
          ),
        error: 'Email / Phone is not valid.',
      },
    },
  };

  const onSubmitForm = (state: object) => {
    alert(JSON.stringify(state, null, 2));
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { identity } = values;

  const handleForgotPassword = async () => {
    let filteredIdentity = '';

    if (!identity.includes('+') && !identity.includes('+1')) {
      const emailOrPhoneCheck = /\S+@\S+\.\S+/;
      !emailOrPhoneCheck.test(identity)
        ? identity.length === 10
          ? (filteredIdentity = `+1${identity}`)
          : (filteredIdentity = `+${identity}`)
        : (filteredIdentity = identity);

      filteredIdentity = filteredIdentity.startsWith('+00')
        ? filteredIdentity.replace('+00', '+')
        : filteredIdentity;
    }

    filteredIdentity = filteredIdentity ? filteredIdentity : identity;
    try {
      await Auth.forgotPassword(filteredIdentity.trim());
    } catch (err) {
      alert(`Forgot password error ${err.message ? errors.message : err}`);
    }

    const location = {
      pathname: '/changePassword',
      state: {
        identity: filteredIdentity,
      },
    };
    history.push(location);
  };

  return (
    <form onSubmit={handleOnSubmit} className='totalSize'>
      <Container fluid className='mb-0 animated fadeInLeft '>
        <Row
          className={`justify-content-center h-100 dash-register-container dash-forgot-password-container`}
        >
          <Col md={{ span: 5, offset: 4 }} className='my-5 py-3'>
            <div className='dash-register-content'>
              <div className='dash-register-title pt-2'>
                Password assistance
              </div>
              <div className='card-body'>
                <Row className='form-group'>
                  <label
                    htmlFor='identity'
                    className='col-sm-4 dash-form-label '
                  >
                    Email or mobile phone number
                  </label>
                  <Col className='col-sm-8'>
                    <input
                      type='text'
                      className='form-control dash-form-control'
                      id='identity'
                      name='identity'
                      value={identity}
                      onChange={handleOnChange}
                    />
                    {errors.identity && dirty.identity && (
                      <p className='error'>{errors.identity}</p>
                    )}
                  </Col>
                </Row>
                <div className='text-right'>
                  <Button
                    type='button'
                    variant='outline-light'
                    className='dash-primary-button mt-4 mr-2'
                    disabled={disable}
                    onClick={handleForgotPassword}
                  >
                    Continue
                  </Button>
                </div>
              </div>{' '}
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ForgotPassword;
