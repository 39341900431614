import { get } from 'config/api';

const User_API = 'User';

export const Roles = {
  index: (type = null) => {
    if (type === 'entity') {
     return get(`${User_API}/GetEntityRoles`)
    } else if (!type) {
      return get(`${User_API}/GetRoles`)
    } else {
      return get(`${User_API}/GetUserRoles`)
    }
  },
  single: (id: number) => get(`${User_API}/GetRoleById?RoleId=${id}`),
};
