import React, { useState } from 'react';
import {  useHistory,useParams } from 'react-router-dom';
import { Row, Container, Button, Col } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { Auth } from 'aws-amplify';
import { Users, Roles } from 'services/Api';
import { toast } from 'react-toastify';
import useForm from '../../shared/useForm';

const Register = (props) => {
  const { type }:any = useParams();
  const history: any = useHistory();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles]: any = useState([]);
  const stateSchema = {
    first_name: { value: '', error: '' },
    last_name: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
    role: { value: '', error: '' },
    confirm_password: { value: '', error: '' },
    password: { value: '', error: '' },
    entity_name: { value: '', error: '' },
    entity_phone: { value: '', error: '' },
  };

  const fetchData = async () => {
    if (roles.length <= 0) {
      await Roles.index(type)
        .then(async (response) => {
          const defaultRole = {
            RoleId: '',
            RoleTypeId: 0,
            RoleName: 'Select Role',
          };
          setRoles([defaultRole].concat(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  fetchData();

  const stateValidatorSchema = {
    first_name: {
      required: true,
      validator: {
        func: (value: string) => /^[a-zA-Z]+$/.test(value),
        error: 'Invalid first name format.',
      },
    },
    last_name: {
      required: true,
      validator: {
        func: (value: string) => /^[a-zA-Z]+$/.test(value),
        error: 'Invalid last name format.',
      },
    },
    email: {
      required: true,
      validator: {
        func: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        error: 'Invalid email format.',
      },
    },
    phone: {
      required: true,
    },
    role: {
      required: true,
    },
    password: {
      required: true,
    },
    confirm_password: {
      required: true,
      validator: {
        func: (value: string) => {
          return value === values.password;
        },
        error: 'Confirm password should match with the password.',
      },
    },
    entity_name: {
      required: type === 'entity' ? true : false,
    },
    entity_phone: {
      required: type === 'entity' ? true : false,
    },
  };

  const onSubmitForm = (state: object) => {
    alert(JSON.stringify(state, null, 2));
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  let {
    first_name,
    last_name,
    email,
    phone,
    role,
    password,
    confirm_password,
    entity_name,
    entity_phone,
  } = values;

  email = email.toLowerCase();
  const _handleRegister = async () => {
    const username = uuid();
    try {
      const isEmailExist = await Auth.confirmSignUp(
        email.toLowerCase(),
        '00000'
      )
        .then((data) => data)
        .catch((err) => err.message);

      phone = phone.length === 10 ? `+1${phone}` : `+${phone}`;
      phone = phone.startsWith('+00') ? phone.replace('+00', '+') : phone;
      phone = phone.trim();

      let isPhoneExist = await Auth.confirmSignUp(phone, '00000')
        .then((data) => data)
        .catch((err) => err.message);

      if (
        isEmailExist === 'Username/client id combination not found.' &&
        isPhoneExist === 'Username/client id combination not found.'
      ) {
        const registeredUser = await Auth.signUp({
          username,
          password,
          attributes: {
            'email': email.trim(),
            'phone_number': phone,
            'custom:role': role,
          },
        });
        Users.create({
          FullName: `${first_name.trim()} ${last_name.trim()}`,
          UserName: registeredUser.userSub,
          Email: email.trim(),
          Mobile: phone,
          Password: password,
          Role: {
            RoleId: role,
          },
          DefaultPage: 'Firms',
        })
          .then(function (response) {
            setLoading(true);
            const location = {
              pathname: '/verifyCode',
              state: {
                username: username,
                email_verified: false,
                phone_verified: false,
              },
            };
            setTimeout(function () {
              history.push(location);
            }, 1000);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (
        isEmailExist ===
          'User cannot be confirmed. Current status is CONFIRMED' &&
        isEmailExist !==
          'User cannot be confirmed. Current status is FORCE_CHANGE_PASSWORD'
      ) {
        toast.error('Email already in use');
      } else if (
        isPhoneExist ===
          'User cannot be confirmed. Current status is CONFIRMED' &&
        isPhoneExist !==
          'User cannot be confirmed. Current status is FORCE_CHANGE_PASSWORD'
      ) {
        toast.error('Phone already in use');
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <form onSubmit={handleOnSubmit} className='totalSize'>
      <Container fluid className='mb-0 animated fadeInLeft '>
        <Row
          className={`justify-content-center h-100 dash-register-container dash-register-container--${type}`}
        >
          <Col md={{ span: 5, offset: 4 }} className='my-5 py-3'>
            <div className='dash-register-content'>
              <div className='dash-register-title pt-2'>Register {type}</div>
              <div className='card-body'>
                <Row className='form-group'>
                  <label
                    htmlFor='first_name'
                    className='col-sm-4 dash-form-label '
                  >
                    {' '}
                    First name
                  </label>
                  <Col className='col-sm-8'>
                    <input
                      type='text'
                      className='form-control dash-form-control '
                      id='first_name'
                      name='first_name'
                      value={first_name}
                      onChange={handleOnChange}
                    />
                    {errors.first_name && dirty.first_name && (
                      <p className='error'>{errors.first_name}</p>
                    )}
                  </Col>
                </Row>
                <Row className='form-group'>
                  <label
                    htmlFor='last_name'
                    className='col-sm-4 dash-form-label '
                  >
                    Last name
                  </label>
                  <Col className='col-sm-8'>
                    <input
                      type='text'
                      className='form-control dash-form-control '
                      id='last_name'
                      name='last_name'
                      value={last_name}
                      onChange={handleOnChange}
                    />
                    {errors.last_name && dirty.last_name && (
                      <p className='error'>{errors.last_name}</p>
                    )}
                  </Col>
                </Row>
                <Row className='form-group'>
                  <label htmlFor='email' className='col-sm-4 dash-form-label '>
                    E-mail
                  </label>
                  <Col className='col-sm-8'>
                    <input
                      type='text'
                      className='form-control dash-form-control '
                      id='email'
                      name='email'
                      value={email}
                      onChange={handleOnChange}
                    />
                    {errors.email && dirty.email && (
                      <p className='error'>{errors.email}</p>
                    )}
                  </Col>
                </Row>
                <Row className='form-group'>
                  <label htmlFor='phone' className='col-sm-4 dash-form-label '>
                    Phone
                  </label>
                  <Col className='col-sm-8'>
                    <input
                      type='text'
                      className='form-control dash-form-control '
                      id='phone'
                      name='phone'
                      value={phone}
                      onChange={handleOnChange}
                    />
                    {errors.phone && dirty.phone && (
                      <p className='error'>{errors.phone}</p>
                    )}
                  </Col>
                </Row>
                <Row className='form-group'>
                  <label
                    htmlFor='password'
                    className='col-sm-4 dash-form-label '
                  >
                    Password
                  </label>
                  <Col className='col-sm-8'>
                    <input
                      type='password'
                      className='form-control dash-form-control '
                      id='password'
                      name='password'
                      value={password}
                      onChange={handleOnChange}
                    />
                    {errors.password && dirty.password && (
                      <p className='error'>{errors.password}</p>
                    )}
                  </Col>
                </Row>
                <Row className='form-group'>
                  <label
                    htmlFor='cPassword'
                    className='col-sm-4 dash-form-label '
                  >
                    Confirm password
                  </label>
                  <Col className='col-sm-8 '>
                    <input
                      type='password'
                      className='form-control dash-form-control'
                      id='confirm_password'
                      name='confirm_password'
                      value={confirm_password}
                      onChange={handleOnChange}
                    />
                    {errors.confirm_password && dirty.confirm_password && (
                      <p className='error'>{errors.confirm_password}</p>
                    )}
                  </Col>
                </Row>
                <Row className='form-group'>
                  <label
                    htmlFor='cPassword'
                    className='col-sm-4 dash-form-label '
                  >
                    Role
                  </label>
                  <Col className='col-sm-8'>
                    <select
                      className='form-control dash-form-control'
                      value={role}
                      id='role'
                      name='role'
                      onChange={handleOnChange}
                    >
                      {/* <option value="">Select role</option> */}
                      {roles.map((role: any, index: any) => {
                        return (
                          <option value={role.RoleId} key={index}>
                            {role.RoleName}
                          </option>
                        );
                      })}
                    </select>
                    {/* {!role && dirty.role && ( */}
                    <p className='error'>{errors.role}</p>
                    {/* )} */}
                  </Col>
                </Row>
                {type === 'entity' && (
                  <div>
                    <h3 className='dash-register-title mb-3'>Entity</h3>
                    <Row className='form-group'>
                      <label
                        htmlFor='phone'
                        className='col-sm-4 dash-form-label '
                      >
                        Entity name
                      </label>
                      <Col className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control dash-form-control '
                          id='entity_name'
                          name='entity_name'
                          value={entity_name}
                          onChange={handleOnChange}
                        />
                        {errors.entity_name && dirty.entity_name && (
                          <p className='error'>{errors.entity_name}</p>
                        )}
                      </Col>
                    </Row>
                    <Row className='form-group'>
                      <label
                        htmlFor='phone'
                        className='col-sm-4 dash-form-label '
                      >
                        Entity Phone
                      </label>
                      <Col className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control dash-form-control '
                          id='entity_phone'
                          name='entity_phone'
                          value={entity_phone}
                          onChange={handleOnChange}
                        />
                        {errors.entity_phone && dirty.entity_phone && (
                          <p className='error'>{errors.entity_phone}</p>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
                {loading && (
                  <div className='dash-page-spinner-container'>
                    <Col>
                      <Spinner
                        className='mr-1 dash-page-spinner'
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                      />
                    </Col>
                  </div>
                )}
                <div className='text-right'>
                  <Button
                    type='button'
                    variant='outline-light'
                    className='dash-primary-button mt-4 mr-2'
                    disabled={disable}
                    onClick={_handleRegister}
                  >
                    Register
                  </Button>
                </div>
              </div>{' '}
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default Register;
