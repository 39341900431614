import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Sessions, Tasks, Matters } from 'services/Api';

interface ScheduleState {
  SessionId: any;
  Title: string;
  StartTime: Date;
  Duration: any;
  Description: string;
  ZoomMeetingId: any;
  MatterId: any;
  TaskId: any;
  meetings: any;
  tasks: any;
  matters: any;
}
class EditSession extends React.Component<{ history }, ScheduleState> {
  private params: any;
  constructor(props: any) {
    super(props);

    this.state = {
      SessionId: null,
      Title: '',
      StartTime: new Date(),
      Duration: '',
      Description: '',
      ZoomMeetingId: null,
      MatterId: null,
      TaskId: null,
      meetings: [],
      tasks: [],
      matters: [],
    };
  }

  componentDidMount() {
    this.fetchData();
    this.params = this.props;
    const sessionId = this.params.id;

    Sessions.create(sessionId)
      .then((response) => {
        console.log(response.data);
        this.setState({ Title: response.data.Title });
        this.setState({ SessionId: response.data.SessionId });
        this.setState({ Duration: response.data.Duration });
        this.setState({ StartTime: new Date(response.data.StartTime) });
        this.setState({ Description: response.data.Description });
        this.setState({ ZoomMeetingId: response.data.ZoomMeetingId });
        this.setState({ TaskId: response.data.TaskId });
        this.setState({ MatterId: response.data.Matter.MatterId });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchData = async () => {
    await Sessions.zoomMeetingsList()
      .then((response) => {
        this.setState({ meetings: response.data.meetings });
      })
      .catch((error) => {
        console.log(error);
      });

    await Tasks.index()
      .then((response) => {
        this.setState({ tasks: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    await Matters.index()
      .then((response) => {
        this.setState({ matters: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  submitHandler = async (e: any) => {
    e.preventDefault();
    const zoomData = {
      topic: this.state.Title,
      type: '2',
      start: this.state.StartTime,
      duration: this.state.Duration,
    };
    const zoomResponse = await Sessions.scheduleZoomMeeting(zoomData);

    const session = {
      Title: this.state.Title,
      StartTime: this.state.StartTime,
      Duration: this.state.Duration,
      Description: this.state.Description,
      ZoomMeetingId: zoomResponse.data.id,
      ZoomLink: zoomResponse.data.start_url,
      MatterId: this.state.MatterId,
      TaskId: this.state.TaskId,
    };
    await Sessions.create(session);
    this.props.history.push('/session');
  };

  changeDateHandler = (e: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));
  };

  handelCancel = () => {
    this.setState({
      Title: '',
      StartTime: new Date(),
      Duration: '',
      Description: '',
      ZoomMeetingId: '',
      MatterId: '',
      TaskId: '',
    });
    this.props.history.push('/session');
  };
  render() {
    const { meetings, matters, tasks } = this.state;
    return (
      <div>
        <form onSubmit={this.submitHandler}>
          <div className='mb-0'>
            <Row>
              <Col md={12}>
                <Card className='dash-card'>
                  <Card.Header className='dash-card-header'>
                    <span className='dash-card-title'>Edit Session</span>
                    <ButtonGroup className='float-right'>
                      <Button
                        type='button'
                        variant='secondary'
                        size='sm'
                        onClick={this.handelCancel}
                        className='dash-secondary-button margin-separator'
                      >
                        Cancel
                      </Button>
                      <Button type='submit' className='dash-primary-button'>
                        Save
                      </Button>
                    </ButtonGroup>
                  </Card.Header>
                  <Card.Body className='dash-card-body'>
                    <Row className='form-group'>
                      <Col md={2}>
                        <div className='dash-form-label'>Title</div>
                      </Col>
                      <Col md={4}>
                        <input
                          type='text'
                          className='form-control dash-form-control'
                          id='Title'
                          name='Title'
                          value={this.state.Title}
                          onChange={this.changeHandler}
                        />
                      </Col>
                      <Col md={2}>
                        <div className='dash-form-label'>Meeting</div>
                      </Col>
                      <Col md={4}>
                        <select
                          id='priorities'
                          name='priorities'
                          className='form-control dash-form-control'
                          placeholder='All'
                        >
                          <option>All</option>
                          {meetings.map((x: any) => (
                            <option key={x.id} value={x.id}>
                              {x.topic}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                    <Row className='form-group'>
                      <Col md={2}>
                        <div className='dash-form-label'>Matter</div>
                      </Col>
                      <Col md={4}>
                        <select
                          id='priorities'
                          name='priorities'
                          className='form-control dash-form-control'
                          placeholder='All'
                          value={this.state.MatterId || ''}

                        >
                          <option>All</option>
                          {matters.map((x: any) => (
                            <option key={x.MatterId} value={x.MatterId}>
                              {x.MatterTitle}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col md={2}>
                        <div className='dash-form-label'>Task</div>
                      </Col>
                      <Col md={4}>
                        <select
                          id='priorities'
                          name='priorities'
                          className='form-control dash-form-control'
                          placeholder='All'
                          value={this.state.TaskId || ''}
                        >
                          <option>All</option>
                          {tasks.map((x: any) => (
                            <option key={x.TaskId} value={x.TaskId}>
                              {x.TaskTitle}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                    <Row className='form-group'>
                      <Col md={2}>
                        <div className='dash-form-label'>Start time</div>
                      </Col>
                      <Col md={4}>
                        <label className='dash-datepicker'>
                          <DatePicker
                            className='form-control dash-form-control'
                            id='DueDate'
                            name='DueDate'
                            selected={this.state.StartTime}
                            onChange={(e) =>
                              this.changeDateHandler(e, 'StartTime')
                            }
                          />
                        </label>
                      </Col>
                      <Col md={2}>
                        <div className='dash-form-label'>Duration</div>
                      </Col>
                      <Col md={4}>
                        <input
                          type='number'
                          className='form-control dash-form-control'
                          id='Duration'
                          name='Duration'
                          value={this.state.Duration}
                          onChange={this.changeHandler}
                        />
                      </Col>
                    </Row>
                    <Row className='form-group'>
                      <Col md={2}>
                        <div className=' dash-form-label'>Description</div>
                      </Col>
                      <Col md={10}>
                        <textarea
                          id='Description'
                          name='Description'
                          value={this.state.Description}
                          onChange={this.changeHandler}
                          className='form-control dash-form-control'
                          data-rows='3'
                        ></textarea>
                      </Col>
                    </Row>
                    <Row className='form-group'>
                      <Col md={2}></Col>
                      <Col className='dash-form-label text-left'>
                        Fields with{' '}
                        <span className='dash-require-astrike'></span> are
                        required fields.
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    );
  }
}

export default EditSession;
