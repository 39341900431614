import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import awsmobile from '../../aws-exports';

AWS.config.update({
  region: awsmobile.aws_cognito_region,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const SignIn = async (email: string, password: string) => {
  try {
    const response = await Auth.signIn(email, password);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SignUp = async (
  username: string,
  password: string,
  ...attributes: Array<any>
) => {
  try {
    const response = await Auth.signUp({ username, password, ...attributes });
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ConfirmSignUp = async (username: string, code: string) => {
  try {
    const response = await Auth.confirmSignUp(username, code, {
      forceAliasCreation: true,
    });
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SignOut = async () => {
  try {
    const response = await Auth.signOut();
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CheckCurrentUser = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser();
    const { attributes, signInUserSession } = response;
    return { attributes, jwtToken: signInUserSession.accessToken.jwtToken };
  } catch (error) {
    throw new Error(error.message);
  }
};

const CheckEmailExists = async (email: string) => {
  try {
    const response = await Auth.confirmSignUp(email, '00000');
    if (response === 'Username/client id combination not found.') return true;
    else if (
      response === 'User cannot be confirmed. Current status is CONFIRMED'
    )
      return false;
    else return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CheckPhoneExists = async (phone: string) => {
  try {
    const response = await Auth.confirmSignUp(phone, '00000');
    if (response === 'Username/client id combination not found.') return true;
    else if (
      response === 'User cannot be confirmed. Current status is CONFIRMED'
    )
      return false;
    else return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SendResettingPassCode = async (identity: any) => {
  try {
    const response = await Auth.forgotPassword(identity.trim());
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ForgotPasswordSubmit = async (newPassObj: any) => {
  const { identity, code, password } = newPassObj;
  try {
    const response = await Auth.forgotPasswordSubmit(identity, code, password);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CompleteNewPassword = async (credentials: any) => {
  const { user, password } = credentials;
  try {
    const response = await Auth.completeNewPassword(user, password, []);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CheckUserExistence = async (userId) => {
  const cognito = new AWS.CognitoIdentityServiceProvider({
    region: awsmobile.aws_cognito_region,
  });

  const validateEmail = /\S+@\S+\.\S+/;
  const isEmailValid = validateEmail.test(userId);
  const filterBy = isEmailValid ? 'email' : 'phone_number';

  const listUsersResponse: any = await cognito
    .listUsers({
      UserPoolId: awsmobile.aws_user_pools_id,
      Filter: `${filterBy} = "${userId}"`,
      Limit: 1,
    })
    .promise();

  const user = listUsersResponse.Users[0];
  return user;
};

export {
  SignIn,
  SignOut,
  CheckCurrentUser,
  CheckEmailExists,
  CheckPhoneExists,
  SignUp,
  ConfirmSignUp,
  SendResettingPassCode,
  ForgotPasswordSubmit,
  CompleteNewPassword,
  CheckUserExistence,
};
