import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Button, ButtonGroup } from 'react-bootstrap';
import CreateTaskAdmin from '../Admin/tasks/createTaskAdmin';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import AddEditCalendarEvent from './newCalendarEvent';
import SessionEdit from './sessionEdit';
import colors from '../../assets/scss/colors.js';
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';

import { OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import UserCard from '../profile/userCard';
import { Calendar as CalendarApi, Tasks, Sessions } from 'services/Api';
const DnDCalendar: any = withDragAndDrop(Calendar);
interface ResourceState {
  events: any;
  tasks: any;
  sessions: any;
  isTaskEditOpen: boolean;
  isModalEventOpen: boolean;
  isModalSessionOpen: boolean;
  taskId: any;
  UserId: any;
  CalendarId: any;
  Title: any;
  Description: any;
  StartDate: any;
  EndDate: any;
  TaskDesc: any;
  TaskEnd: any;
  TaskImgUrl: any;
  TaskStart: any;
  TaskStatus: any;
  TaskTaskId: any;
  TaskTaskUserId: any;
  TaskTitle: any;
  TaskUserId: any;
  TaskUserImg: any;
  sessionId: any;
  currentTask: any;
  currentEvent: any;
  currentUserID: string;
  props: any;
}

class TaskCalendar extends React.Component<{}, ResourceState> {
  private localizer = momentLocalizer(moment);
  private taskModalRef: any;
  private sessionModalRef: any;
  private addEditEventModalRef: any;
  private status = {
    open: 1,
    inProgress: 2,
    blocked: 3,
    done: 4,
  };

  constructor(props: any) {
    super(props);

    this.taskModalRef = React.createRef();
    this.sessionModalRef = React.createRef();
    this.addEditEventModalRef = React.createRef();

    this.state = {
      events: [],
      tasks: [],
      sessions: [],
      isTaskEditOpen: false,
      isModalEventOpen: false,
      isModalSessionOpen: false,
      taskId: '',
      CalendarId: '',
      UserId: '',
      Title: '',
      Description: '',
      StartDate: '',
      EndDate: '',
      TaskDesc: '',
      TaskEnd: '',
      TaskImgUrl: '',
      TaskStart: '',
      TaskStatus: '',
      TaskTaskId: '',
      TaskTaskUserId: '',
      TaskTitle: '',
      TaskUserId: '',
      TaskUserImg: '',
      sessionId: '',
      currentTask: {},
      currentEvent: {},
      currentUserID: '',
      props: this.props,
    };
  }

  changeHandler = (e: any) => {
    let { name, value, type } = e.target;
    value = type === 'number' ? parseInt(value) : value;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async componentDidMount() {
    this.fetch();
  }

  async fetch() {
    let currentUser: any = localStorage.getItem('userData');
    const currentUserID: any = JSON.parse(currentUser ? currentUser : '{}');
    this.setState({ currentUserID: currentUserID.UserId });
    const calendarEvents = await CalendarApi.index();

    const tasks: any = await Tasks.index();

    let tasksData: any = [];
    for (let task of tasks.data) {
      if (task.Resource?.ProfileImageURL) {
        task['imageUrl'] = await Storage.get(task.Resource?.ProfileImageURL);
      }
      tasksData.push(task);
    }
    const sessions = await Sessions.index();

    let events = calendarEvents.data.map((event) => {
      return {
        userId: event.UserId,
        title: event.Title,
        desc: event.Description,
        start: new Date(event.StartDate),
        end: new Date(event.EndDate),
        eventId: event.CalendarId,
      };
    });

    events = events.concat(
      tasksData.map((event) => {
        return {
          userId: event.TaskId,
          title: event.TaskTitle,
          desc: event.Instructions,
          start: new Date(event.DueDate),
          end: new Date(event.DueDate),
          taskId: event.TaskId,
          status: event.TaskStatus?.TaskStatusId,
          taskUserId: event.Resource?.UserId,
          userImg: event.Resource?.ProfileImageURL,
          imgUrl: event.imageUrl,
        };
      })
    );

    events = events.concat(
      sessions.data.map((event) => {
        return {
          userId: event.SessionId,
          title: event.Title,
          desc: event.Description,
          start: new Date(event.StartTime),
          end: new Date(event.StartTime),
          sessionId: event.SessionId,
        };
      })
    );

    this.setState({ events: events });
  }

  eventStyleGetter = (event) => {
    let status = event.status;
    let backgroundColor = '';
    let color = '';
    if (status === this.status.open) {
      backgroundColor = colors.dash_status_blue;
      color = '#fff';
    } else if (status === this.status.inProgress) {
      backgroundColor = colors.dash_status_green;
      color = '#333333';
    } else if (status === this.status.blocked) {
      backgroundColor = colors.dash_status_pink;
      color = '#fff';
    } else if (status === this.status.done) {
      backgroundColor = colors.dash_status_gray;
      color = '#fff';
    }
    var style = {
      backgroundColor: backgroundColor,
      color: color,
      fontSize: '16px',
    };
    return {
      style: style,
    };
  };

  onCalendarClick = async (task) => {
    console.log(task);
    if (task.eventId || task === 'newEvent') {
      this.setState({ currentEvent: task, isModalEventOpen: true });
    } else if (task.taskId) {
      this.setState({ isTaskEditOpen: true, taskId: task.taskId });
    } else if (task.sessionId) {
      this.setState({ isModalSessionOpen: true, sessionId: task.sessionId });
    }
  };

  onEventResize = (data) => {
    const { start, end } = data;
    this.setState((state) => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };

  onEventDrop = async (data) => {
    try {
      if (data.event.taskId) {
        this.setState({
          TaskStart: data.start,
          TaskStatus: data.event.status,
          TaskTaskId: data.event.taskId,
          TaskTaskUserId: data.event.taskUserId,
        });
        const taskObj = this.getCalendarTaskEventObj();
        const moveCalendarTask = await Tasks.create(taskObj);
        console.log(moveCalendarTask);
      } else {
        this.setState({
          CalendarId: data.event.eventId,
          UserId: data.event.userId,
          Title: data.event.title,
          StartDate: data.start,
          EndDate: data.end,
        });
        const eventObj = this.getCalendarEventObj();
        const moveCalendar = await CalendarApi.create(eventObj);
        console.log(moveCalendar);
      }
      this.fetch();
    } catch (err) {
      alert(`Saving event error ${err.message ? err.message : err}`);
    }
  };
  getCalendarTaskEventObj = () => {
    return {
      DueDate: this.state.TaskStart,
      TaskStatusId: this.state.TaskStatus,
      TaskId: this.state.TaskTaskId,
    };
  };
  getCalendarEventObj = () => {
    return {
      CalendarId: this.state.CalendarId || null,
      UserId: this.state.UserId,
      Title: this.state.Title,
      Description: this.state.Description,
      StartDate: this.state.StartDate,
      EndDate: this.state.EndDate,
    };
  };
  addEditEvent = async (event) => {
    this.setState({ isModalEventOpen: true });
  };
  handleModalClose = async (type: any, changedObj?) => {
    if (type === 'task') {
      this.setState({ isTaskEditOpen: false });
      if (changedObj && changedObj.title) {
        const updatedEvents = this.state.events.map((taskEvent) => {
          if (taskEvent.taskId === this.state.taskId)
            taskEvent.title = changedObj.title;
          return taskEvent;
        });
        this.setState({ events: updatedEvents });
      }
    } else if (type === 'event') {
      this.setState({ isModalEventOpen: false });

      if (!this.state.currentEvent.eventId) {
        this.setState({ events: [...this.state.events, changedObj] });
      } else {
        if (changedObj && changedObj.title) {
          const updatedEvents = this.state.events.map((event) => {
            if (event.eventId === this.state.currentEvent.eventId) {
              // event.title = changedObj.title;
              // event.desc = changedObj.desc;
              event = changedObj;
            }

            return event;
          });
          this.setState({ events: updatedEvents });
        }
      }
    } else if (type === 'session') {
      this.setState({ isModalSessionOpen: false });
      if (changedObj && changedObj.title) {
        const updatedEvents = this.state.events.map((sessionEvent) => {
          if (sessionEvent.sessionId === this.state.sessionId)
            sessionEvent.title = changedObj.title;
          return sessionEvent;
        });
        this.setState({ events: updatedEvents });
      }
    }
    this.setState({ isModalEventOpen: false });
    this.fetch();
  };
  getImageURL = async (imgUrl) => {
    const imgSrc = await Storage.get(imgUrl);
    return imgSrc;
  };
  customEvent = ({ event }) => {
    let statusIcon = '';
    if (event.status === this.status.open) {
      statusIcon = 'play-circle';
    } else if (event.status === this.status.inProgress) {
      statusIcon = 'tasks';
    } else if (event.status === this.status.blocked) {
      statusIcon = 'pause-circle';
    } else if (event.status === this.status.done) {
      statusIcon = 'check-circle';
    }
    // if (event.userImg) {
    //   event["imageUrl"] = await this.getImageURL(event.userImg);
    // }
    return (
      <div className='position-relative'>
        <div className=' mb-0'>
          {event.status && (
            <OverlayTrigger
              trigger='click'
              rootClose
              overlay={
                <Popover id='user-Data' className='dash-user-card-popover'>
                  <UserCard userId={event.taskUserId} />
                </Popover>
              }
            >
              <Avatar
                className={classNames(
                  'dash-grid-avatar float-left mr-1 cursor-pointer',
                  event.taskUserId === this.state.currentUserID ? 'd-none' : ''
                )}
                src={event.imgUrl}
                alt=''
              />
            </OverlayTrigger>
          )}
          <div className='dash-calender-event-title mt-1'>
            {event.status && (
              <span
                className={'dash-calender-event-icon fas fa-' + statusIcon}
              ></span>
            )}
            {event.title}
            {event.status && (
              <span className='badge badge-pill dash-icon-badge dash-icon-badge_calender'>
                2
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className='mb-5'>
        <Row className='mb-4'>
        <Col md={12} className="d-flex justify-content-center">
            <div className="float-left position-absolute dash-left-3 dash-top-3">
            <ButtonGroup className='margin-separator margin-separator-bot'>
              <Button
                className='btn btn-xs btn-info mr-2'
                onClick={() => this.state.props.history.goBack()}
              >
                Back
              </Button>
              <Button
                className='btn btn-xs btn-info'
                onClick={() => this.onCalendarClick('newEvent')}
              >
                Add new event
              </Button>
            </ButtonGroup>
            </div>
            <div className='dash-page-title'>
              <h3 className='mb-0 text-center'>Tasks Calendar</h3>
            <div className='dash-line-icon'></div>
            </div>
          </Col>
        </Row>
        <DnDCalendar
          localizer={this.localizer}
          events={this.state.events}
          onDoubleClickEvent={this.onCalendarClick}
          onEventDrop={this.onEventDrop}
          onEventResize={this.onEventResize}
          startAccessor='start'
          endAccessor='end'
          className='dash-calendar'
          components={{
            event: this.customEvent,
          }}
          eventPropGetter={this.eventStyleGetter}
        />
        {this.state.isTaskEditOpen && (
          <CreateTaskAdmin
            ref={this.taskModalRef}
            isAddShowing={this.state.isTaskEditOpen}
            taskId={this.state.taskId}
            onModalCloseClick={this.handleModalClose}
          />
        )}
        {this.state.isModalEventOpen && (
          <AddEditCalendarEvent
            ref={this.addEditEventModalRef}
            isModalOpen={this.state.isModalEventOpen}
            event={this.state.currentEvent}
            onModalClose={this.handleModalClose}
          />
        )}
        {this.state.isModalSessionOpen && (
          <SessionEdit
            ref={this.sessionModalRef}
            isModalOpen={this.state.isModalSessionOpen}
            sessionId={this.state.sessionId}
            onModalClose={this.handleModalClose}
          />
        )}
      </div>
    );
  }
}

export default TaskCalendar;
