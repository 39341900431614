import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ChannelComponent from './channelComponent';
import { SetCurrentChannel } from 'redux/actions/channelActions';

export default function ChatMessage() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(SetCurrentChannel(null));
    };
  });

  return <ChannelComponent />;
}
